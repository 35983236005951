import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LoginModel, LoginRequest} from "./login.model";
import {Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";

@Injectable({
  providedIn: 'root'
})
export class LoginService extends HttpEformService{

  featureUrl = this.url + "login"
  constructor(protected override http: HttpClient) {
    super(http)
  }

  login(loginRequest: LoginRequest) : Observable<LoginModel>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(`${loginRequest.email}:${loginRequest.password}`)
      })
    }
    // this.featureUrl = "http://localhost:8006/eform/login"
    return this.http
      .post<LoginModel>(this.featureUrl, null, httpOptions);
  }
}
