import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  constructor(private router: Router) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
  }

  continue(){
      // this.router.navigate(["/eform/change-password"]);
      this.router.navigate(["/eform/login"]);
  }

}
