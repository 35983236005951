import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {KontakDataPribadiRoutingModule} from "./kontak-data-pribadi-routing.module";
import {GlobalModule} from "../global/global.module";
import {KontakDataPribadiComponent} from "./kontak-data-pribadi.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalModule,
    NgbModule,
    KontakDataPribadiRoutingModule
  ],
  declarations: [
    KontakDataPribadiComponent
  ]
})
export class KontakDataPribadiModule {

}
