import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-rincian-data-kesehatan',
  templateUrl: './rincian-data-kesehatan.component.html',
  styleUrls: ['./rincian-data-kesehatan.component.css']
})
export class RincianDataKesehatanComponent {

  rincianDataKesehatanForm: FormGroup;
  productInfo: RiplayUmumModel = {};
  dataPribadi: any = {};
  isFromDashboard: boolean = false;
  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };

  constructor(private router: Router,
              private formBuilder: FormBuilder) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.rincianDataKesehatanForm = formBuilder.group({
      beratBadan: [null, Validators.required],
      tinggiBadan: [null, Validators.required],
      pregnantFlag: ['',],
      pregnantPeriod: [''],
    }, {validators: this.customFormGroupValidator})

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.dataPribadi.jenisKelamin = this.nasabah.nasabahNewCovering.gender
      this.productInfo.referenceNumber = this.nasabah.nasabahNewCovering.referenceNo
      this.productInfo.partnerId = this.nasabah.nasabahNewCovering.partnerId
      this.productInfo.productcode = this.nasabah.nasabahNewCovering.productCode
      this.rincianDataKesehatanForm.patchValue({
        beratBadan: this.nasabah.nasabahNewCovering.weight,
        tinggiBadan: this.nasabah.nasabahNewCovering.height,
        pregnantFlag: this.nasabah.nasabahNewCovering.pregnantFlag,
        pregnantPeriod: this.nasabah.nasabahNewCovering.pregnantPeriod,
      })
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonDataPribadi = localStorage.getItem('data-pribadi')
    if (jsonProduct != null && jsonDataPribadi != null) {
      this.productInfo = JSON.parse(jsonProduct);
      this.dataPribadi = JSON.parse(jsonDataPribadi);
    }
  }

  continue() {
    if (this.rincianDataKesehatanForm.valid) {
      localStorage.setItem("rincian-data-kesehatan", JSON.stringify(this.rincianDataKesehatanForm.value))
      if (this.productInfo.partnercode == "BSI0524") {
        this.router.navigate(['eform/health-question-bsi'])
      } else {
        this.router.navigate(['eform/keterangan-kesehatan'])
      }
    }
  }

  back() {
    this.router.navigate(['eform/kontak-data-kantor'])
  }

  customFormGroupValidator(form: FormGroup) {
    if (form.get('pregnantFlag')!.value == "Y" && !form.get('pregnantPeriod')!.value) {
      form.get('pregnantPeriod')!.setErrors({requiredIfPregnantFlagTrue: true});
    } else {
      form.get('pregnantPeriod')!.setErrors(null);
    }
    return null;
  }
}
