import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {DokumenResponseModel} from "../service/model/dokumen-response.model";
import {NbSubmitResponse, PremiData} from "../service/model/keterangan-kesehatan.model";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {DownloadDocumentService} from "../service/download-document/download-document.service";
import {DownloadResponseModel} from "../service/model/download-response.model";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-final-decision',
  templateUrl: './final-decision.component.html',
  styleUrls: ['./final-decision.component.css']
})
export class FinalDecisionComponent {

  todayDate: Date = new Date()
  isPreview: boolean = true;
  productInfo: RiplayUmumModel = {};
  dokumenResponseModel: DokumenResponseModel = {};
  newCoveringResponse: NbSubmitResponse = {};
  premiData: PremiData = {};
  downloadResponseModel: DownloadResponseModel = {}
  calculator: CalculatorResponse = {};
  rincianPertanggangan: any = {};
  dataPribadi: any;
  memberStatus: any;
  pageState: string = "PREVIEW";
  extraPremi: number = 0;

  isFromDashboard: boolean = false;
  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };

  constructor(private route: Router,
              private newCoveringService: NewCoveringService,
              private downloadService: DownloadDocumentService) {
    let jsonProduct = localStorage.getItem('selected-product')
    let jsonDataPribadi = localStorage.getItem('data-pribadi')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonPertanggungan = localStorage.getItem('rincian-pertanggungan')
    let jsonNewCoveringResponse = localStorage.getItem('new-covering-response')
    let jsonDokumen = localStorage.getItem('dokumen-upload')

    if (jsonProduct && jsonDataPribadi && jsonCalculator && jsonPertanggungan) {
      this.productInfo = JSON.parse(jsonProduct);
      this.dataPribadi = JSON.parse(jsonDataPribadi);
      this.calculator = JSON.parse(jsonCalculator);
      this.extraPremi = Number(this.calculator.totalPremium) - Number(this.calculator.standardPremium)
      this.rincianPertanggangan = JSON.parse(jsonPertanggungan)
    }

    if (jsonNewCoveringResponse) {
      this.newCoveringResponse = JSON.parse(jsonNewCoveringResponse);
      this.premiData = JSON.parse(this.newCoveringResponse.premiData!)
      console.log("response ", this.newCoveringResponse)
      console.log("response premi", this.newCoveringResponse.premiData)
      this.pageState = this.newCoveringResponse.memberStatus == "REJECTED" ? "REJECTED" : "PREVIEW"
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.productInfo.referenceNumber = this.nasabah.nasabahNewCovering.referenceNo
      this.rincianPertanggangan.uangPertanggungan = this.nasabah.nasabahNewCovering.sumAssured
      this.dataPribadi = {
        namaLengkap: this.nasabah.nasabahNewCovering.fullName
      }
    }

    if (jsonDokumen) {
      this.dokumenResponseModel = JSON.parse(jsonDokumen);
      this.setPageState()
    }
  }

  downloadAcceptance() {
    this.downloadService.downloadDocument({
      referenceNo: this.productInfo.referenceNumber,
      docType: "ACCEPTANCE"
    }).subscribe(res => {
      const link = document.createElement("a")
      link.href = 'data:application/octet-stream;base64,' + res.document;
      link.download = 'Surat Akseptasi ' + this.productInfo.referenceNumber + '.pdf'
      link.click()
      link.remove()
    })
  }

  downloadRejected() {
    this.downloadService.downloadDocument({
      referenceNo: this.productInfo.referenceNumber,
      docType: "REJECTION"
    }).subscribe(res => {
      const link = document.createElement("a")
      link.href = 'data:application/octet-stream;base64,' + res.document;
      link.download = 'Surat Penolakan ' + this.productInfo.referenceNumber + '.pdf'
      link.click()
      link.remove()
    })
  }

  downloadAvailable(status: string) {
    if (this.downloadResponseModel.statusId == "9") {
      alert("Mohon untuk menunggu dan refresh halaman ini setelah 30 detik")
    }
    if (status == "REJECTED") {
      this.downloadRejected()
    } else {
      this.downloadAcceptance()
    }
  }

  continue() {
    this.route.navigateByUrl('eform/spajk-riplay')
  }

  backHomePage() {
    this.route.navigate(['eform/menu'])
  }

  setPageState() {
    if (this.newCoveringResponse.memberStatus != null) {
      switch (this.newCoveringResponse.memberStatus) {
        case "SUBMITTED":
          this.pageState = "ADDITIONALINFORMATION"
          break
        case "ACCEPTED":
        case "APPROVED":
          this.pageState = "SUBMITTED"
          break
        case "REJECTED":
          this.pageState = "REJECTED"
          break
        default:
          this.route.navigate(['eform/error'])
          break
      }
    }
  }
}
