import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { DraftSavedComponent } from "./draft-saved.component";
import { LayoutComponent } from "../layout/layout.component";

const routes: Routes = [
  {
    path: 'draft-saved',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DraftSavedComponent,
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DraftSavedRoutingModule { }
