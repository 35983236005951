import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.css']
})
export class FormHeaderComponent implements AfterViewInit {

  @Input() formHeaderTitle: string = '';
  @Input() step: number = 1;
  @Input() dariStep: number = 4;

  @ViewChild('stepCounter')
  stepCounter!: ElementRef;

  ngAfterViewInit(): void {
    for (let i = 0; i < this.dariStep; i++) {
      const stepDiv = document.createElement('div')
      stepDiv.className = 'allianz-progress-step step-inactive'
      if (i < this.step) {
        stepDiv.classList.remove('step-inactive')
        stepDiv.classList.add('step-active')
      }
      this.stepCounter.nativeElement.appendChild(stepDiv)
    }
  }
}
