import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";
import {SaveDraftResponseModel} from "../model/save-draft.model";

@Injectable({
  providedIn: 'root'
})
export class DraftService extends HttpEformService {

  constructor(protected override http: HttpClient) {
    super(http)
  }

  saveDraft(req: any): Observable<any> {
    let url = this.url + "nb/draft";
    return this.http
      .post<SaveDraftResponseModel>(url, req);
  }

  getDraft(req: any): Observable<any> {
    let url = this.url + "nb/querydraft";
    // let url = "http://localhost:8006/eform/" + "nb/querydraft";
    return this.http
      .post<any>(url, req);
  }

}
