import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {NewCoveringService} from "../../service/new-covering/new-covering.service";
import html2canvas from 'html2canvas';
import jspdf from "jspdf";
import {LoginModel} from "../../login/shared/login.model";
import {PremiData} from "../../service/model/keterangan-kesehatan.model";
import {DownloadDocumentService} from "../../service/download-document/download-document.service";
import {NasabahModel} from "../../service/model/dashboard.model";

@Component({
  selector: 'app-spajk-riplay',
  templateUrl: './spajk-riplay.component.html',
  styleUrls: ['./spajk-riplay.component.css']
})
export class SpajkRiplayComponent implements AfterViewInit {
  dataSpajk: any = {};
  tandaTangan: any;
  dataPribadi: any;
  calculator: any;
  rincianPertanggungan: any;
  @ViewChild('spajkWraper') spajkWrapper!: ElementRef;
  @ViewChild('riplayView') riplayWrapper!: ElementRef;
  documentArray: string[] = []
  isLoading: boolean = true;
  today: Date = new Date()
  marketingChecked: string | null = "";
  loginResponse: LoginModel = {}
  premiData: PremiData = {}
  isSpajkDownloaded: boolean = false;
  isRiplayDownloaded: boolean = false;
  isLoadingSpajk: boolean = false;
  isLoadingRiplay: boolean = false;
  isFromDashboard: boolean = false;

  // maritalStatus = this.spajk.maritalStatus;
  constructor(private router: Router,
              private newCoveringService: NewCoveringService,
              private documentService: DownloadDocumentService) {

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    let jsonDummy = localStorage.getItem('new-business')!
    let jsonTandaTangan = localStorage.getItem('tanda-tangan')!
    let jsonDataPribadi = localStorage.getItem('data-pribadi')!
    let jsonCalculator = localStorage.getItem('calculator')!
    let jsonRincianPertanggungan = localStorage.getItem('rincian-pertanggungan')!
    let jsonLogin = sessionStorage.getItem('login')!
    let jsonNewCoveringResponse = localStorage.getItem('new-covering-response')!
    let jsonPremiData = JSON.parse(JSON.parse(jsonNewCoveringResponse).premiData)
    let jsonKontakPribadi = localStorage.getItem('kontak-pribadi')!
    let jsonKontakKantor = localStorage.getItem('kontak-kantor')!
    let jsonSelectedProduk = localStorage.getItem('selected-product')!
    this.marketingChecked = localStorage.getItem('marketing-checked')

    this.dataSpajk = {
      ...JSON.parse(jsonDummy),
      ...JSON.parse(jsonDataPribadi),
      ...JSON.parse(jsonCalculator),
      ...JSON.parse(jsonRincianPertanggungan),
      ...JSON.parse(jsonLogin),
      ...JSON.parse(jsonKontakPribadi),
      ...JSON.parse(jsonKontakKantor),
      ...JSON.parse(jsonSelectedProduk),
      ...JSON.parse(jsonNewCoveringResponse),
      ...jsonPremiData,
      emailcorrespodensi: "Y",
      ecertificate: "Y"
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem('from-dashboard')!)
    if (this.isFromDashboard) {
      this.mapNasabahToDataSpajk()
    }

    if (jsonTandaTangan != null) {
      this.tandaTangan = JSON.parse(jsonTandaTangan)
    }

    if (jsonLogin != null) {
      this.loginResponse = JSON.parse(jsonLogin);
    }

    // this.dataDummy()
    this.dobParse();
    this.isLoading = !(this.tandaTangan == null)
    window.setTimeout(() => {
      this.isLoading = false;
    }, 90000)

    console.log("spa", this.dataSpajk)
  }

  @Input('spajk')
  get spajk() {
    return this.dataSpajk;
  }

  continue() {
    if (this.tandaTangan && this.isRiplayDownloaded && this.isSpajkDownloaded) {
      this.isLoading = true;
      this.documentService.generateLetterBtpn({
        "refnum": this.spajk.referenceNumber,
        "memberstatus": "APPROVED"
      }).subscribe( res =>{
        console.log("generate let", res)
        this.router.navigate(['eform/final'])
      })
    } else if (!this.tandaTangan) {
      this.router.navigate(['/eform/summary'])
    } else {
      alert('Mohon untuk mengunduh dokumen SPAJK dan RIPLAY Personal')
    }
  }

  getCharatIndex(i: any): string {
    return this.spajk.emailaddressstaff[i];
  }

  sendImage() {
    this.convertToImage(this.spajkWrapper, "SPAJK")
    // this.convertToImage(this.riplayWrapper, "RIPLAY")
  }

  dobParse(): void {
    const dobP = this.dataSpajk.dob.split('-');
    this.dataSpajk.thn = dobP[0];
    this.dataSpajk.bln = dobP[1];
    this.dataSpajk.tgl = dobP[2];
  }

  convertToImage(elementRef: ElementRef, fileType: string) {
    const element = elementRef.nativeElement;
    if (screen.width < 1024) {
      element.setAttribute("content", "width=1200px");
    }
    html2canvas(element, {
      allowTaint: true,
      removeContainer: true,
      backgroundColor: "white",
      imageTimeout: 15000,
      logging: true,
      scale: 0.5,
      useCORS: true
    })
      .then((canvas) => {
        canvas.toBlob(blob => {
          this.newCoveringService.uploadDocument(this.dataSpajk.referenceNo, blob, fileType).subscribe(res => {
            if (res.statusId == "6" || res.statusId == "1") {
              this.documentArray.push(fileType + " SUCCESS")
            } else {
              this.documentArray.push(fileType + " FAILED")
            }
            this.isLoading = !!(this.documentArray.length != 2 && this.tandaTangan);

            if (this.documentArray.length == 1) {
              this.convertToImage(this.riplayWrapper, "RIPLAY")
            }
          }, error => {
            this.documentArray.push(fileType + " FAILED")
            this.isLoading = !!(this.documentArray.length != 2 && this.tandaTangan);

            if (this.documentArray.length == 1) {
              this.convertToImage(this.riplayWrapper, "RIPLAY")
            }
          })
        })
      })
      .catch(err => {
        console.error("log error", err)
      });
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    if (this.tandaTangan) {
      this.isLoading = true;
      // this.sendImage()
      this.newCoveringService.uploadDocument(this.dataSpajk.referenceNo, this.newCoveringService.dataURItoBlob(this.spajk.fileInput), "KTPID")
        .subscribe(res => {
          localStorage.setItem("dokumen-upload", JSON.stringify(res))
        })
    }
  }

  downloadSpajk() {
    this.isLoadingSpajk = true;
    this.generatePDF(this.spajkWrapper, this.spajk.nomorAplikasi + '_SPAJK')
  }

  downloadRiplay() {
    this.isLoadingRiplay = true;
    this.generatePDF(this.riplayWrapper, this.spajk.nomorAplikasi + '_RIPLAY')
  }

  generatePDF(elementRef: ElementRef, fileType: string) {
    const doc = new jspdf();

    // Get HTML content to be converted to PDF
    const content = elementRef.nativeElement;

    // Find all elements with the page-break class
    const elements = content.getElementsByClassName('page-break');

    // Convert HTML content to PDF pages based on page-break class
    this.htmlToPDF(doc, elements, fileType);
  }

  private htmlToPDF(doc: jspdf, elements: HTMLCollectionOf<Element>, fileType: string) {
    // Options for html2canvas
    const options = {
      scale: 1.3,
      useCORS: true,  // Use CORS to handle cross-origin images
      allowTaint: true,
      logging: true,
      scrollY: 0,
    };

    // Function to recursively add pages to PDF
    const addPageRecursive = (index: number) => {
      if (index >= elements.length) {

        let blob = new Blob([doc.output('blob')], {type: 'application/pdf'})
        if (fileType.includes("SPAJK")) {
          this.newCoveringService.uploadDocument(this.spajk.referenceNumber!, blob, "SPAJK").subscribe(() => {

          })
          doc.save("SPAJK " +  this.dataSpajk.referenceNo)
          this.isSpajkDownloaded = true;
          this.isLoadingSpajk = false;
          localStorage.setItem('download-spajk', 'true')
        }
        if (fileType.includes("RIPLAY")) {
          this.newCoveringService.uploadDocument(this.spajk.referenceNumber!, blob, "RIPLAY").subscribe(() => {

          })
          doc.save("RIPLAY " +  this.dataSpajk.referenceNo)
          // let blob = new Blob([doc.output('blob')], {type: 'application/pdf'})
          this.isRiplayDownloaded = true;
          this.isLoadingRiplay = false;
          localStorage.setItem('download-riplay', 'true')
        }
        return;
      }

      const element = elements[index] as HTMLElement;

      // Use html2canvas to convert each element to canvas
      html2canvas(element, options).then((canvas) => {
        // Calculate PDF height based on the canvas height and width
        const imgWidth = 210; // PDF page width in mm (A4 size)
        const imgHeight = canvas.height * imgWidth / canvas.width; // Calculate PDF height

        // Add a new page for each element (except the first one)
        if (index > 0) {
          doc.addPage();
        }

        // Convert canvas to PDF using jsPDF
        const imgData = canvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');

        // Move to the next element recursively
        addPageRecursive(index + 1);
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        // Handle errors if needed
      });
    };

    // Start adding pages from the first element
    addPageRecursive(0);
  }

  mapNasabahToDataSpajk() {
    let nasabah: NasabahModel = JSON.parse(localStorage.getItem('nasabah')!)
    let selectedItem: any = JSON.parse(localStorage.getItem('selected-item')!)

    this.dataSpajk.policynumber = nasabah.nasabahNewCovering.policyNo
    this.dataSpajk.healthQuestionFlag = nasabah.nasabahNewCovering.healthQuestionFlag
    this.dataSpajk.emailKoresponden = nasabah.nasabahNewCovering.emailCorrespondenceFlag
    this.dataSpajk.bungaPinjaman = 20
    // this.todayDate = new Date(nasabah.nasabahUw.createdDate ?? "")

    this.dataSpajk = {
      ...this.dataSpajk,
      // uwStatus: nasabah.nasabahUw.uwStatus,
      gender: selectedItem.gender,
      dob: selectedItem.dob,
      entryAge: selectedItem.entryAge,
      pekerjaan: selectedItem.pekerjaan,
      tenure: selectedItem.tenure,
      sumAssured: nasabah.nasabahNewCovering.sumAssured,
      height: nasabah.nasabahNewCovering.height,
      weight: nasabah.nasabahNewCovering.weight,
      weightGainlossKg: nasabah.nasabahNewCovering.weightGainlossKg,
      weightGainlossFlag: nasabah.nasabahNewCovering.weightGainlossFlag,
      smokingPcs: nasabah.nasabahNewCovering.smokingPcs,
      smokingFlag: nasabah.nasabahNewCovering.smokingFlag,
      preexistingConditionFlag: nasabah.nasabahNewCovering.preexistingConditionFlag,
      chestPain: nasabah.nasabahNewCovering.chestPain,
      stroke: nasabah.nasabahNewCovering.stroke,
      heartBloodVessel: nasabah.nasabahNewCovering.heartBloodVessel,
      bloodDisorder: nasabah.nasabahNewCovering.bloodDisorder,
      leukimia: nasabah.nasabahNewCovering.leukimia,
      lungs: nasabah.nasabahNewCovering.lungs,
      cancerTumor: nasabah.nasabahNewCovering.cancerTumor,
      diabetic: nasabah.nasabahNewCovering.diabetic,
      mentalDisorders: nasabah.nasabahNewCovering.mentalDisorders,
      brainAbnormalities: nasabah.nasabahNewCovering.brainAbnormalities,
      stomachIntenstine: nasabah.nasabahNewCovering.stomachIntenstine,
      liver: nasabah.nasabahNewCovering.liver,
      drugAbuse: nasabah.nasabahNewCovering.drugAbuse,
      alcoholic: nasabah.nasabahNewCovering.alcoholic,
      aidsHiv: nasabah.nasabahNewCovering.aidsHiv,
      pregnantFlag: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.pregnantFlag : "",
      pregnantPeriod: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.pregnantPeriod : "",
      othersFlag: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.othersFlag : "",
      othersRemark: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.othersRemark : "",
      familyHealthFlag: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.familyHealthFlag : "",
      familyHealthCondition: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.familyHealthCondition : "",
      rejectionPolicyFlag: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.rejectionPolicyFlag : "",
      rejectionPolicyHistory: nasabah.nasabahNewCovering.healthQuestionFlag == "Y" ? nasabah.nasabahNewCovering.rejectionPolicyHistory : "",
      productName: nasabah.nasabahNewCovering.productName,
      productcode: nasabah.nasabahNewCovering.productCode,
      referenceNumber: nasabah.nasabahNewCovering.referenceNo
      // kindOfProduct: nasabah.nasabahUw.productName,
      // policynumber: nasabah.nasabahUw.se belom

    }

  }
}
