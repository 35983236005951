import {LayoutComponent} from "../layout/layout.component";
import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {MenuComponent} from "./menu.component";

const routes: Routes = [
  {
    path: 'menu',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: MenuComponent,
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule { }
