import {Component} from '@angular/core';
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DraftService} from "../service/save-draft/save-draft.service";
// import * as brancsOfBankJSON from '../global/shared/data-json/brances-of-bank.json';
import * as brancsOfBankJSON from '../global/shared/data-json/bsi_branch_code.json';
import {LabelValueType} from '../global/shared/data-type/label-value';
import {BrancesOfBankType} from '../global/shared/data-type/brances-of-bank';
import {NasabahModel} from "../service/model/dashboard.model";
import {Location} from '@angular/common';

@Component({
  selector: 'app-pengisian-data-pribadi-bsi',
  templateUrl: './pengisian-data-pribadi-bsi.component.html',
  styleUrls: ['./pengisian-data-pribadi-bsi.component.css']
})
export class PengisianDataPribadiBsiComponent {

  productInfo: RiplayUmumModel = {};
  dataPribadiForm: FormGroup;
  calculatorModel: CalculatorResponse = {}
  selectedItem: any;
  rincianPertanggungan: any = {}
  isFromDashboard: boolean = false;
  login: any = {};

  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahCalculator: {}
  };
  branchAreaList: Array<BrancesOfBankType> = brancsOfBankJSON["default"];
  branchList: Array<LabelValueType> = [];

  filteredBranches: Array<LabelValueType> = [];

  listStatusPerkawinan: any[] = [
    {
      statusPerkawinan: "Belum Menikah",
      value: "S"
    },
    {
      statusPerkawinan: "Menikah",
      value: "M"
    },
    {
      statusPerkawinan: "Janda/Duda",
      value: "W"
    }];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private draftService: DraftService,
    private location: Location
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonPertanggungan = localStorage.getItem('rincian-pertanggungan')
    let jsonLogin = sessionStorage.getItem('login')
    if (jsonProduct != null && jsonPertanggungan != null) {
      this.productInfo = JSON.parse(jsonProduct);
      this.rincianPertanggungan = JSON.parse(jsonPertanggungan);

    }

    if (jsonLogin) {
      this.login = JSON.parse(jsonLogin);
    }

    this.dataPribadiForm = formBuilder.group({
      nik: ['', [Validators.required, this.requiredDigitsValidator(16)]],
      namaLengkap: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      namaAlias: ['', Validators.pattern('^[a-zA-Z ]*$')],
      statusPerkawinan: ['', Validators.required],
      tempatLahir: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      usia: [{value: this.calculatorModel.entryAge, disabled: true}],
      nomorAplikasi: ['', Validators.required],
      kantorCabang: ['', Validators.required],
      areaKantorCabang: ['', Validators.required],
    })

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      let jsonSelectedItem = localStorage.getItem("selected-item");
      if (jsonSelectedItem !== null) {
        this.selectedItem = JSON.parse(jsonSelectedItem);
        this.dataPribadiForm.patchValue({
          usia: this.selectedItem.entryAge,
        })
      }
    } else {
      let calculatorString = localStorage.getItem("calculator");
      if (calculatorString !== null) {
        this.calculatorModel = JSON.parse(calculatorString);
        this.dataPribadiForm.patchValue({
          usia: this.calculatorModel.entryAge,
        })
      }
    }

    this.draftService.getDraft({
      userId: 1,
      pageId: "draftCover1",
      partnerId: this.isFromDashboard ? this.nasabah.nasabahUw.partnerId! : this.productInfo.partnerId,
      productCode: this.isFromDashboard ? this.nasabah.nasabahUw.productCode! : this.productInfo.productcode,
      referenceNo: this.isFromDashboard ? this.nasabah.nasabahUw.referenceNo! : this.productInfo.referenceNumber,
    }).subscribe(res => {
      if (res.statusId == 1) {
        // let queryDraft = JSON.parse(res)
        let selectedBranchArea = ""
        for (let i = 0; i < this.branchAreaList.length; i++) {
          for (let j = 0; j < this.branchAreaList[i].brances!.length; j++) {
            if (this.branchAreaList[i].brances![j].value == res.branchCode) {
              selectedBranchArea = this.branchAreaList[i].region!
              this.branchList = this.branchAreaList[i].brances || [];
            }
          }
        }
        this.dataPribadiForm.patchValue({
          nik: res.identityNo,
          namaLengkap: res.fullName,
          namaAlias: res.allias,
          statusPerkawinan: res.maritalStatus,
          tempatLahir: res.pob,
          nomorAplikasi: res.applicationNo,
          kantorCabang: res.branchName + '-' + res.branchCode,
          areaKantorCabang: selectedBranchArea
        })
      }
    })
  }

  onSelectionBranchAreaChange(event: Event) {
    const selectedOption = (event.target as HTMLSelectElement).value;
    const branchListFiltered: Array<BrancesOfBankType> = this.branchAreaList.filter(data => data.region === selectedOption);
    if (branchListFiltered.length > 0) {
      this.branchList = branchListFiltered[0].brances || [];
    }
  }

  requiredDigitsValidator(maxDigits: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value && value.toString().length != maxDigits) {
        return {maxDigits: true};
      }
      return null;
    };
  }

  back() {
    this.location.back();
  }

  continue() {
    if (this.dataPribadiForm.valid) {
      localStorage.setItem("data-pribadi", JSON.stringify(this.dataPribadiForm.value))
      this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
        this.router.navigate(["eform/kontak-data-pribadi"]);
      })
    }
  }

  saveDraft() {
    this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
      this.router.navigate(["eform/draft-saved"])
    })
  }

  buildSaveRequest(): any {
    return {
      userId: 1,
      pageId: "draftCover1",
      partnerId: this.isFromDashboard ? this.nasabah.nasabahUw.partnerId! : this.productInfo.partnerId,
      productCode: this.isFromDashboard ? this.nasabah.nasabahUw.productCode! : this.productInfo.productcode,
      referenceNo: this.isFromDashboard ? this.nasabah.nasabahUw.referenceNo! : this.productInfo.referenceNumber,
      identityNo: this.dataPribadiForm.controls['nik'].value.toString(),
      fullName: this.dataPribadiForm.controls['namaLengkap'].value,
      alias: this.dataPribadiForm.controls['namaAlias'].value,
      maritalStatus: this.dataPribadiForm.controls['statusPerkawinan'].value,
      occupation: this.rincianPertanggungan.pekerjaan,
      pob: this.dataPribadiForm.controls['tempatLahir'].value,
      entryAge: this.isFromDashboard ? this.selectedItem.entryAge.toString() : this.calculatorModel.entryAge!.toString(),
      applicationNo: this.dataPribadiForm.controls['nomorAplikasi'].value,
      branchCode: this.dataPribadiForm.controls['kantorCabang'].value.split('-')[1],
      branchName: this.dataPribadiForm.controls['kantorCabang'].value.split('-')[0]
    }
  }
}
