import { Injectable } from "@angular/core";
import * as RecordRTC from "recordrtc";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VideoRecordingService {
  private mediaStream: any;
  private recorder: any;
  private blob: any;
  private _mediaStream = new Subject<any>();
  private _blob = new Subject<any>();

  // getObservable
  getMediaStream() {
    return this._mediaStream.asObservable();
  }

  getBlob() {
    return this._blob.asObservable();
  }

  getVideo() {
    return this.blob
  }

  startRecording() {
    this.handleRecording();
  }

  async handleRecording() {
    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    });
    const kb = 4 * 1024;
    const preferredBitRatePerSecond = 100 * kb;
    this._mediaStream.next(this.mediaStream);
    this.recorder = new RecordRTC(this.mediaStream, {
      type: 'video',
      canvas: {
        width: 480,
        height: 640
      },
      audioBitsPerSecond: preferredBitRatePerSecond,
      videoBitsPerSecond: preferredBitRatePerSecond,
    });
    this.recorder.startRecording();
  }

  stopRecording() {
    if (!this.recorder) {
      return;
    }
    this.recorder.stopRecording(() => {
      this.blob = this.recorder.getBlob();
      this._blob.next(URL.createObjectURL(this.blob));
      this.mediaStream.stop();
      this.recorder.destroy();
      this.mediaStream = null;
      this.recorder = null;
    })
  }

  downloadRecording() {
    RecordRTC.invokeSaveAsDialog(this.blob, `${Date.now()}.webm`);
  }

  clearRecording() {
    this.blob = null;
    this.recorder = null;
    this.mediaStream = null;
  }

}
