<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK-syariah.png" width="357" height="auto">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div *ngIf="this.pageState == 'APPROVED'">
    <div class="text-center">
      <img src="assets/Data-Berhasil-Disimpan-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Simulasi Pengajuan Disetujui</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd/M/Y HH:mm:ss"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 color-dark-orange">No Referensi: <b>{{productInfo.referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue p-1 my-3">Lanjutkan pengajuan dengan melengkapi data Calon Pihak Yang Diasuransikan untuk mendapatkan Surat Akseptasi (CN).</p>
    <div class="d-flex flex-row justify-content-between flex-gap mx-3">
      <span>Total Pembiayaan</span>
      <span>{{totalPembiayaan | currency: 'IDR' : 'symbol' : '4.0-0'}}</span>
    </div>
    <div class="d-flex flex-row justify-content-between flex-gap mx-3">
      <span>Kontribusi Dasar</span>
      <span>{{kontribusiDasar | currency: 'IDR' : 'symbol' : '4.0-0'}}</span>
    </div>
    <div class="d-flex flex-row justify-content-between flex-gap mx-3">
      <span>Kontribusi Tambahan</span>
      <span>{{kontribusiExtra | currency: 'IDR' : 'symbol' : '4.0-0'}}</span>
    </div>
    <div class="d-flex flex-row justify-content-between flex-gap mx-3">
      <span>Total Kontribusi</span>
      <span>{{kontribusiTotal | currency: 'IDR' : 'symbol' : '4.0-0'}}</span>
    </div>
    <div class="d-flex flex-row flex-gap mx-3">
      <p class="description-input">Kontribusi sudah termasuk Kontribusi atribusi (jika ada) dan Imbal Jasa Bank</p>
    </div>
    <div class="d-flex flex-row justify-content-between flex-gap mx-3">
      <span>Total Kontribusi/Total Pembiayaan</span>
      <span>{{ratio + 0.0001 | number : '1.4'}} %</span>
    </div>

    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle color-dark-orange mt-3 py-3 flex-grow-1" type="button" (click)="backToMenu()">Simpan dan Kembali ke Halaman Awal</button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="continue()">Simpan dan Lanjutkan Pengajuan</button>
    </div>
  </div>

  <div *ngIf="pageState=='REFERUW' || pageState=='SUBMITTED'">
    <div class="text-center">
      <img src="assets/Diperlukan-Informasi-Tambahan-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Pengajuan Membutuhkan Informasi Tambahan</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd/M/Y HH:mm:ss"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 color-dark-orange">No Referensi: <b>{{productInfo.referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue p-1 my-3">Aplikasi dan data calon Pihak Yang Diasuransikan (PYD) membutuhkan informasi tambahan untuk diproses. Silahkan melanjutkan pengisian data Calon PYD.</p>

    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle color-dark-orange mt-3 py-3 flex-grow-1" type="button" (click)="backToMenu()">Simpan dan Kembali ke Halaman Awal</button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="continue()">Simpan dan Lanjutkan Pengajuan</button>
    </div>
  </div>

  <div  *ngIf="pageState=='REJECTED'">
    <div class="text-center">
      <img src="assets/Ditolak-eSPAJK-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Simulasi Pengajuan Ditolak</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd/M/Y HH:mm:ss"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 color-dark-orange">No Referensi: <b>{{productInfo.referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue">Calon Pihak Yang Diasuransikan tidak dapat melanjutkan pendaftaran asuransi karena Calon Pihak Yang Diasuransikan belum memenuhi syarat dan ketentuan Underwriting Allianz.</p>

    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle mt-3 py-3 flex-grow-1" type="button" (click)="backToMenu()"><b>Kembali ke Halaman Awal</b></button>
    </div>
  </div>
</div>
