import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {HealthQuestionBsiRoutingModule} from "./health-question-bsi-routing.module";
import {HealthQuestionBsiComponent} from "./health-question-bsi.component";
import {GlobalModule} from "../../global/global.module";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HealthQuestionBsiRoutingModule,
    GlobalModule
  ],
  declarations: [
    HealthQuestionBsiComponent
  ]
})
export class HealthQuestionBsiModule { }
