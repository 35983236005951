import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CalculatorService} from "../../rincian-pertanggungan/shared/calculator.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RiplayUmumModel} from "../../riplay-umum/shared/riplay-umum.model";
import {CalculatorResponse} from "../../rincian-pertanggungan/shared/calculator.model";
import {DatePipe} from "@angular/common";
import {DraftService} from "../../service/save-draft/save-draft.service";

@Component({
  selector: 'app-rincian-pertanggungan-bsi',
  templateUrl: './rincian-pertanggungan-bsi.component.html',
  styleUrls: ['./rincian-pertanggungan-bsi.component.css']
})
export class RincianPertanggunganBsiComponent {

  queryParam: any;
  productInfo: RiplayUmumModel = {};
  rincianForm: FormGroup;
  calculatorResponse: CalculatorResponse = {}
  masaPertanggunganBulan: any[] = [];
  listPekerjaan: any[] = ["Karyawan", "Pensiunan", "Ibu Rumah Tangga", "PNS", "Lainnya"];
  minPembiayaan: number = 0;
  maxPembiayaan: number = 0;


  today: Date = new Date();
  minDate: string = "";
  maxDate: string = "";

  public maxInsuraceStartDate: string = "";
  public minInsuraceStartDate: string = "";
  public minTenureDate: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private calculatorService: CalculatorService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private draftService: DraftService
  ) {
    this.setMinTenureDate()

    this.rincianForm = formBuilder.group({
      dob: ['', [Validators.required]],
      fullName: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      nomorRekening: [''],
      uangPertanggungan: ['', [Validators.required, this.uangPertanggunganMinMaxValidator()]],
      tanggalMulaiAsuransi: ['', [Validators.required, this.effectiveDateValidator()]],
      tanggalAkhirAsuransi: ['', [Validators.required, this.minTenureDateValidator.bind(this)]],
      // tanggalAkhirAsuransi: ['', [Validators.required]],
      kontribusiDasar: ['', Validators.required],
      standardPremiFlag: [false],
      penaltyFlag: [false],
      generalInsuranceFlag: [false],
      notaryFlag: [false],
      penaltyAmount: [''],
      generalInsuranceAmount: [''],
      notaryAmount: [''],
      extraPremiumRate: [''],
      totalSumAssured: [''],
      totalPremi: [''],
      pekerjaan: ['', [Validators.required]],
    }, {validators: this.customFormGroupValidator})

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.queryParam = {
      productcode: this.route.snapshot.queryParamMap.get('productcode'),
      partnerid: this.route.snapshot.queryParamMap.get('partnerid')
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }
    if (this.productInfo.productcode == "SPKTAMBSI") {
      this.minPembiayaan = 5000000
      this.maxPembiayaan = 1500000000
    } else if (this.productInfo.productcode == "SPKTAPBSI") {
      this.minPembiayaan = 10000000
      this.maxPembiayaan = 350000000
    }

    for (let i = 6; i <= 180; i += 6) {
      this.masaPertanggunganBulan.push(i)
    }

    this.calculateMinMaxDates()
    this.setMinMaxOfInsuranceStartDate()
  }

  customFormGroupValidator(form: FormGroup) {
    const penaltyAmount = form.get('penaltyAmount')!
    if (form.get('penaltyFlag')!.value && penaltyAmount.value == "" || penaltyAmount.value === "0") {
      penaltyAmount.setErrors({requiredIfPenaltyFlagTrue: true});
    } else {
      penaltyAmount.setErrors(null);
    }
  }

  validateCalculator() {
    this.formControl['uangPertanggungan'].patchValue(this.formControl['uangPertanggungan'].value.replace(/[^0-9]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
    let tanggalLahir = Number(this.formControl['dob'].value.split("-")[0])
    let tanggalLahirValid = tanggalLahir >= 1900
    let tanggalAkad = Number(this.formControl['tanggalMulaiAsuransi'].value.split("-")[0])
    let tanggalAkadValid = tanggalAkad == new Date().getFullYear()
    let tanggalAkhirValid = Number(this.formControl['tanggalAkhirAsuransi'].value.split("-")[0]) >= new Date().getFullYear()

    let tanggalAkhir = new Date(this.formControl['tanggalAkhirAsuransi'].value)
    let tanggalMulai = new Date(this.formControl['tanggalMulaiAsuransi'].value)

    //set to end day of month
    tanggalAkhir = new Date(tanggalAkhir.getFullYear(), tanggalAkhir.getMonth() + 1, 0);

    let year = tanggalAkhir.getFullYear();
    let month = ('0' + (tanggalAkhir.getMonth() + 1)).slice(-2); // Months are zero-based
    let day = ('0' + tanggalAkhir.getDate()).slice(-2);
    let formattedTanggalAkhir = `${year}-${month}-${day}`;

    let tenure = this.ceilMonthsBetween(tanggalMulai, tanggalAkhir)
    console.log('ini', tenure)

    if (this.formControl['dob'].valid
      && this.formControl['tanggalMulaiAsuransi'].valid
      && this.formControl['tanggalAkhirAsuransi'].valid
      && this.formControl['uangPertanggungan'].valid
      && tanggalLahirValid
      && tanggalAkadValid
      && tanggalAkhirValid
    ) {
      this.calculatorService.getCalculator({
        partnerCode: this.productInfo.partnercode,
        productCode: this.productInfo.productcode,
        referenceNo: this.productInfo.referenceNumber,
        dob: this.formControl['dob'].value,
        effectiveDate: this.formControl['tanggalMulaiAsuransi'].value,
        tenure: tenure.toString(),
        sumAssured: this.formControl['uangPertanggungan'].value.replace(/[^0-9]+/g, ''),
        standardPremiFlag: this.formControl['standardPremiFlag'].value ? "Y" : "N",
        penaltyFlag: this.formControl['penaltyFlag'].value ? "Y" : "N",
        notaryFlag: this.formControl['notaryFlag'].value ? "Y" : "N",
        generalInsuranceFlag: this.formControl['generalInsuranceFlag'].value ? "Y" : "N",
        penaltyAmount: this.formControl['penaltyAmount'].value.replace(/[^0-9]+/g, ''),
        generalInsuranceAmount: this.formControl['generalInsuranceAmount'].value.replace(/[^0-9]+/g, ''),
        notaryAmount: this.formControl['notaryAmount'].value.replace(/[^0-9]+/g, ''),
        extraPremiumRate: this.formControl['extraPremiumRate'].value.replace(/[^0-9]+/g, ''),
        maturityDate: formattedTanggalAkhir,
        gender: "M"
      }).subscribe(res => {
        this.calculatorResponse = res;
        if (res.statusId === "1") {
          this.rincianForm.patchValue({
            kontribusiDasar: res.standardPremium!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
            totalSumAssured: res.totalSumAssured!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
            totalPremi: res.totalPremium!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          })

        } else {
          let errorMessage = "Mohon cek kembali masukkan anda";
          this.rincianForm.patchValue({
            kontribusiDasar: '',
            totalSumAssured: '',
            totalPremi: '',
          })

          if (res.statusDescription?.includes("Invalid entry age: Input entry age is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputAge = errorArray[7].replace(".", "")
            let ageMin = errorArray[13]
            let ageMax = errorArray[15]
            errorMessage = "Umur " + inputAge + " tidak sesuai, Umur harus diantara " + ageMin + " sampai dengan " + ageMax;
          } else if (res.statusDescription?.includes("Invalid sum assured: Input sum assured is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputUang = errorArray[7].replace(".", "")
            let uangMin = errorArray[13]
            let uangMax = errorArray[15]
            errorMessage = "Uang Pertanggungan " + inputUang + " tidak sesuai, Uang pertanggungan harus diantara " + uangMin + " sampai dengan " + uangMax;
          } else if (res.statusDescription?.includes("Invalid tenure: Input tenure is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputBulan = errorArray[5].replace(".", "")
            let bulanMin = errorArray[10]
            let bulanMax = errorArray[12]
            errorMessage = "Masa Pertanggungan " + inputBulan + " bulan tidak sesuai, masa pertanggungan harus diantara " + bulanMin + " bulan sampai dengan " + bulanMax + " bulan.";
          } else if (res.statusDescription?.includes("Maturity age is not in maximum coverage age")) {
            errorMessage = "Umur saat jatuh tempo dengan masa pertanggungan tidak sah"
          } else if (res.statusDescription?.includes("No Cover Matching For Partner")) {
            errorMessage = "Cover tidak ditemukan untuk produk " + this.productInfo.productname + " dengan umur dan bulan masa pertanggungan"
          } else if (res.statusDescription?.includes("Partner Code is not valid, please contact administrator")) {
            errorMessage = "Kode partner tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("Product Code is not valid, please contact administrator")) {
            errorMessage = "Kode produk tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("Partner is not valid, please contact administrator")) {
            errorMessage = "Partner dengan produk tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("YYYY-MM-DD format required")) {
            errorMessage = "Masukkan tanggal tidak sah, mohon cek kembali masukkan tanggal"
          }
          alert(errorMessage);
        }
        localStorage.setItem("calculator", JSON.stringify(res))
        let rincianPertanggungan = {
          ...this.rincianForm.value,
          kontribusiDasar: this.formControl['uangPertanggungan'].value.replaceAll(".", "")
        }
        localStorage.setItem("rincian-pertanggungan", JSON.stringify(rincianPertanggungan))
      })
    }
  }

  uangPertanggunganMinMaxValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value.replaceAll('.', '');
      if (Number(value) > this.maxPembiayaan || Number(value) < this.minPembiayaan) {
        return {'uangPertanggunganError': true}
      }
      return null;
    };
  }

  // effectiveDateValidator() {
  //   return (control: AbstractControl): { [key: string]: boolean } | null => {
  //     const value = control.value;
  //     const bulanInput = new Date(value)
  //     bulanInput.setHours(0)
  //     const oneMonthBeforeStart = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
  //     const endOfMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 2, 0);
  //     let validateMonth = bulanInput >= oneMonthBeforeStart && bulanInput <= endOfMonth;
  //     if (value != this.today.getFullYear() && !validateMonth) {
  //       return {'effectiveDateError': true};
  //     }
  //     return null;
  //   };
  // }

  effectiveDateValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      const bulanInput = new Date(value)
      bulanInput.setHours(0)

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      const day30 = new Date();
      day30.setDate(day30.getDate() + 30);

      const validateMonth = bulanInput > yesterday && bulanInput <= day30;

      if (value != this.today.getFullYear() && !validateMonth) {
        return {'effectiveDateError': true};
      }
      return null;
    };
  }

  minTenureDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const selectedDate = new Date(control.value);
    const minDate = new Date(this.minTenureDate);
    console.log("selected", selectedDate)
    console.log("mindate", minDate)
    if (selectedDate < minDate) {
      return { 'minDate': true };
    }
    return null;
  }

  calculateMinMaxDates(): void {
    const currentMonth = this.today.getMonth();
    const currentYear = this.today.getFullYear();

    // Calculate the first day of the previous month or year
    if (currentMonth === 0) {
      // If the current month is January, set the minDate to the first day of the previous year
      this.minDate = this.datePipe.transform(new Date(currentYear - 1, 11, 1), 'yyyy-MM-dd', 'id-ID')!;
    } else {
      // Otherwise, set the minDate to the first day of the previous month
      this.minDate = this.datePipe.transform(new Date(currentYear, currentMonth - 1, 1), 'yyyy-MM-dd', 'id-ID')!;
    }

    // Calculate the last day of the next month
    this.maxDate = this.datePipe.transform(new Date(currentYear, currentMonth + 2, 0), 'yyyy-MM-dd', 'id-ID')!;
  }

  ceilMonthsBetween(startDate: Date, endDate: Date) {
    // Parse the dates
    // const startDate = new Date(date1);
    // const endDate = new Date(date2);

    // Calculate the difference in years and months
    const yearDiff = endDate.getFullYear() - startDate.getFullYear();
    console.log("year", yearDiff)
    const monthDiff = endDate.getMonth() - startDate.getMonth();
    console.log('month', monthDiff)
    // Calculate the difference in days
    console.log("entry", startDate.getDate())
    console.log("entry2", endDate.getDate())
    const dayDiff = endDate.getDate() - startDate.getDate();

    // Total months difference including partial months
    let totalMonths = yearDiff * 12 + monthDiff;
    console.log("total", totalMonths)

    // Adjust for partial month
    if (dayDiff > 0) {
      totalMonths += 1;
    }
    console.log("diff", dayDiff)
    if(yearDiff == 0 && monthDiff == 0 && dayDiff <= 29) {
      totalMonths = 1
    }
    console.log('total month', totalMonths)

    // Apply Math.ceil to the total months difference
    return Math.ceil(totalMonths);
  }

  get formControl() {
    return this.rincianForm.controls;
  }

  continue() {
    this.validateCalculator()
    if (this.rincianForm.valid) {
      this.draftService.saveDraft({
        userId: 1,
        pageId: "draftCover1",
        partnerId: this.productInfo.partnerId,
        productCode: this.productInfo.productcode,
        referenceNo: this.productInfo.referenceNumber,
        fullName: this.rincianForm.controls['fullName'].value,
        identityNo: '',
        maritalStatus: '',
        alias: '',
        occupation: this.rincianForm.controls['pekerjaan'].value,
        pob: '',
        entryAge: '',
        applicationNo:'',
        branchCode: '',
        branchName: ''
      }).subscribe(() => {
        this.router.navigate(["eform/rincian-kesehatan-bsi"], {
          queryParams: this.queryParam
        });
      })
    }
  }

  back() {
    this.router.navigate(['eform/riplay'], {
      queryParams: this.queryParam
    })
  }

  private setMinMaxOfInsuranceStartDate() {
    const today = new Date();
    this.minInsuraceStartDate = this.formatDate(today); // set minimum date as today
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30); // set maximum date as 30 days from today
    this.maxInsuraceStartDate = this.formatDate(maxDate);
    console.log("min insurance start date", this.minInsuraceStartDate)
    console.log("max insurance start date", this.maxInsuraceStartDate)
  }

  private setMinTenureDate() {
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 30); // set minimum date as 30 days from today
    this.minTenureDate = this.formatDate(minDate);
    console.log("min tenure date", this.minTenureDate)
  }

  private formatDate(date: Date): string {
    // format the date as YYYY-MM-DD
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
}
