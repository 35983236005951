<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK-syariah.png" width="357">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div *ngIf="this.pageState == 'APPROVED'">
    <div class="text-center">
      <img src="assets/Data-Berhasil-Disimpan-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Pengajuan Disetujui</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 text-warning">No Referensi:
      <b>{{productInfo.referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue p-1 my-3"> Selanjutnya, mohon untuk mengunggah dokumen
      identitas diri, SPAJPK Syariah, dan RIPLAY Personal yang sudah ditandatangani. </p>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <div class="d-flex flex-row flex-gap">
        <a class="btn btn-back mt-3 py-3 flex-grow-1" type="button" (click)="downloadAkseptasi()">
          <span *ngIf="this.isLoadingAkseptasi" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
          Unduh Surat Akseptasi (CN)
        </a>

        <a class="btn btn-back mt-3 py-3 flex-grow-1" type="button" (click)="downloadTagihan()">
          <span *ngIf="this.isLoadingTagihan" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
          Unduh Surat Tagihan
        </a>
      </div>
      <a class="btn btn-back mt-3 py-3 flex-grow-1" [hidden]="true" type="button" #btnAcceptance>UnduhAkseptasi</a>
      <a class="btn btn-back mt-3 py-3 flex-grow-1" [hidden]="true" type="button" #btnAcceptance2>UnduhTagihan</a>
      <button class="btn btn-outline-warning bg-warning-subtle mt-3 py-3 flex-grow-1" type="button"
              (click)="uploadDokumen()">Unggah Dokumen
      </button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backToMenu()">Kembali ke Halaman
        Awal
      </button>
    </div>
  </div>
  <div *ngIf="pageState=='REFERUW' || pageState=='SUBMITTED'">
    <div class="text-center">
      <img src="assets/Diperlukan-Informasi-Tambahan-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Lengkapi Dokumen via E-mail</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 text-warning">No Referensi:
      <b>{{productInfo.referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue p-1 my-3">Silahkan cetak SPAJPK Syariah dan RIPLAY
      Personal untuk ditandatangani oleh calon PYD dan harap menghubungi PIC Allianz untuk informasi lebih lanjut</p>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle mt-3 py-3 flex-grow-1" type="button"
              (click)="continueDashboard()">Menuju halaman Dashboard
      </button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backToMenu()">Kembali ke Halaman
        Awal
      </button>
    </div>
  </div>
</div>
