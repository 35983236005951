import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbToastModule} from "@ng-bootstrap/ng-bootstrap";
import {CollapsibleContentComponent} from "./collapsible-content/collapsible-content.component";
import {AllianzInputComponent} from "./allianz-input/allianz-input.component";
import {DropdownComponent} from "./dropdown/dropdown.component";
import {SwitchQuestionComponent} from "./switch-question/switch-question.component";
import {NumberSeparatorDirective} from "./number-separator/number-separator.directive";
import { LimitCharacterDirective } from './limit-character/limit-character.directive';
import {FormHeaderComponent} from "./form-header/form-header.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbToastModule,
  ],
  declarations: [
    CollapsibleContentComponent,
    AllianzInputComponent,
    DropdownComponent,
    SwitchQuestionComponent,
    FormHeaderComponent,
    NumberSeparatorDirective,
    LimitCharacterDirective,
  ],
  exports: [
    CollapsibleContentComponent,
    AllianzInputComponent,
    DropdownComponent,
    SwitchQuestionComponent,
    FormHeaderComponent,
    NumberSeparatorDirective,
    LimitCharacterDirective,
  ]
})
export class GlobalModule {

}
