import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent {

  registrationForm: FormGroup;

  constructor(private router: Router, private formBuilder: FormBuilder) {

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.registrationForm = formBuilder.group({
      namaStaff: ['', Validators.required],
      emailStaff: ['', [Validators.required, Validators.email]],
      nomorHP: ['', [Validators.required, Validators.pattern(/^0\d{0,14}$/)]],
      perusahaan: ['', Validators.required],
      cabang1: ['', Validators.required],
      cabang2: ['', Validators.required],
      cabang3: ['', Validators.required],
      kataSandi: ['', Validators.required],
      agreement: ['', Validators.requiredTrue],
    })
  }

  onSubmit() {
    if (this.registrationForm.valid) {
      this.router.navigate(["/eform/login"]);
    }
  }

}
