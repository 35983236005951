import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {VideoRecordingService} from "../service/video-recording.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NewCoveringService} from "../service/new-covering/new-covering.service";

type RecordingState = 'NONE' | 'RECORDING' | 'RECORDED';

@Component({
  selector: 'app-video-pernyataan',
  templateUrl: './video-pernyataan.component.html',
  styleUrls: ['./video-pernyataan.component.css']
})
export class VideoPernyataanComponent {
  todayDate: Date = new Date();
  @ViewChild('videoElement') videoElement: any;
  videoBlobUrl: any = null;
  video: any = {};
  state: RecordingState = 'NONE';
  isLoading: boolean = false;

  productInfo: any;
  dataPribadi: any;
  timeoutId: number | undefined;

  constructor(
    private router: Router,
    private videoRecordingService: VideoRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private newCoveringService: NewCoveringService
  ) {
    this.videoRecordingService.getMediaStream().subscribe((data) => {
      this.video.srcObject = data;
      this.ref.detectChanges();
    });
    this.videoRecordingService.getBlob().subscribe((data) => {
      this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data);
      this.video.srcObject = null;
      this.ref.detectChanges();
    });

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonDataPribadi = localStorage.getItem('data-pribadi')
    if (jsonProduct != null && jsonDataPribadi) {
      this.productInfo = JSON.parse(jsonProduct);
      this.dataPribadi = JSON.parse(jsonDataPribadi);
    }
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.video = this.videoElement.nativeElement;
  }

  startRecording() {
    this.videoRecordingService.startRecording();
    this.timeoutId = window.setTimeout(() => {
      this.stopRecording()
    }, 16500)
    this.state = 'RECORDING';
  }

  stopRecording() {
    window.clearTimeout(this.timeoutId)
    this.videoRecordingService.stopRecording();
    this.state = 'RECORDED';
  }

  downloadRecording() {
    this.videoRecordingService.downloadRecording();
  }

  clearRecording() {
    this.videoRecordingService.clearRecording();
    this.video.srcObject = null;
    this.videoBlobUrl = null;
    this.state = 'NONE';
  }

  continue() {
    if (this.video.srcObject || this.videoBlobUrl) {
      let blob = new Blob()
      this.isLoading = true;
      this.newCoveringService.uploadDocument(this.productInfo.referenceNumber, this.videoRecordingService.getVideo(), "VIDEO").subscribe(res => {

        this.router.navigateByUrl('eform/spajk-riplay')
      })
    }
  }

  back() {
    this.router.navigate(['eform/summary'])
  }
}
