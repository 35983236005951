import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RincianKesehatanBsiRoutingModule} from "./rincian-kesehatan-bsi-routing.module";
import {GlobalModule} from "../../global/global.module";
import {RincianKesehatanBsiComponent} from "./rincian-kesehatan-bsi.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RincianKesehatanBsiRoutingModule,
    GlobalModule
  ],
  declarations: [
    RincianKesehatanBsiComponent
  ]
})
export class RincianKesehatanBsiModule { }
