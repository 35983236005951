import {Component, Input} from '@angular/core';

@Component({
  selector: 'allianz-input',
  templateUrl: './allianz-input.component.html',
  styleUrls: ['./allianz-input.component.css']
})
export class AllianzInputComponent {
  @Input("titleInput") titleInput : String = "Title";
  @Input("descriptionInput") descriptionInput : String = "Description";
}
