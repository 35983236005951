import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RiplayUmumModel} from "../../riplay-umum/shared/riplay-umum.model";
import {Router} from "@angular/router";
import {CalculatorResponse} from "../../rincian-pertanggungan/shared/calculator.model";
import {NewCoveringService} from "../../service/new-covering/new-covering.service";

@Component({
  selector: 'app-rincian-kesehatan-bsi',
  templateUrl: './rincian-kesehatan-bsi.component.html',
  styleUrls: ['./rincian-kesehatan-bsi.component.css']
})
export class RincianKesehatanBsiComponent {

  rincianDataKesehatanForm: FormGroup;
  productInfo: RiplayUmumModel = {};
  calculator: CalculatorResponse = {};

  listJenisKelamin: any[] = [
    {
      jenisKelamin: "Pria",
      value: "M"
    },
    {
      jenisKelamin: "Wanita",
      value: "F"
    }];

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private newCoveringService: NewCoveringService) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.rincianDataKesehatanForm = formBuilder.group({
      beratBadan: [null, Validators.required],
      tinggiBadan: [null, Validators.required],
      pregnantFlag: ['',],
      pregnantPeriod: [''],
      jenisKelamin: ['', Validators.required]
    }, {validators: this.customFormGroupValidator})


    let jsonProduct = localStorage.getItem('selected-product')
    let jsonCalculator = localStorage.getItem('calculator')
    if (jsonProduct !== null && jsonCalculator !== null) {
      this.productInfo = JSON.parse(jsonProduct)
      this.calculator = JSON.parse(jsonCalculator)
    }
  }

  customFormGroupValidator(form: FormGroup) {
    for (let el in form.controls) {
      if (form.controls[el].errors) {
      }
    }
    if (form.get('jenisKelamin')!.value == "F" && form.get('pregnantFlag')!.value == "Y" && !form.get('pregnantPeriod')!.value) {
      form.get('pregnantPeriod')!.setErrors({requiredIfPregnantFlagTrue: true});
    } else {
      form.get('pregnantPeriod')!.setErrors(null);
    }
    return null;
  }

  continue() {
    if (this.rincianDataKesehatanForm.valid) {
      if (!localStorage.getItem("rincian-data-kesehatan")) {
        localStorage.setItem("rincian-data-kesehatan", JSON.stringify(this.rincianDataKesehatanForm.value))
      }
      if (this.calculator.healthQuestionFlag != "N") {
        this.router.navigate(['eform/health-question-bsi'])
      } else {
        this.newCoveringService.autoUW(this.newCoveringService.buildAutoUW()).subscribe(res => {
          localStorage.setItem("auto-uw-result", JSON.stringify(res))
          this.router.navigate(['eform/preview-bsi'])
        })
      }
    }
  }

  back() {
    this.router.navigate(['eform/rincian-pertanggungan-bsi'])
  }
}
