<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
    <div class="info-box d-flex rounded-3" role="alert">
      <i class="bi-info-circle"></i>
      <p>Mohon untuk membaca Ringkasan berikut dengan seksama.</p>
    </div>
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center pt-2">
  <div class="text-start mb-2">
    <b class="color-light-blue">Tertanggung</b>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Nama Tertanggung</div>
    <div><b>{{this.dataPribadi.namaLengkap}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Tanggal Lahir</div>
    <div><b>{{this.rincianPertanggungan.dob}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Jenis Kelamin</div>
    <div><b>{{this.dataPribadi.jenisKelamin == "M" ? "PRIA" : "WANITA"}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Masa Pertanggungan</div>
    <div><b>{{this.rincianPertanggungan.masaPertanggungan}} bulan</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Cara pembayaran Premi</div>
    <div><b>Sekaligus</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Mata Uang</div>
    <div><b>Rupiah</b></div>
  </div>
  <div class="text-start mb-2">
    <b class="color-light-blue">Rincian Tagihan</b>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Nama Produk</div>
    <div><b>{{this.selectedProduct.productname}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Jenis Produk</div>
    <div><b>Asuransi Jiwa Kumpulan</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Uang Pertanggungan</div>
    <div><b>{{this.rincianPertanggungan.uangPertanggungan | currency: 'IDR' : 'symbol' : '4.0-0'}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Premi Dasar</div>
    <div><b>{{this.calculator.standardPremium | currency: 'IDR' : 'symbol' : '4.0-0'}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Extra Premi</div>
    <div><b>{{this.premiData.extraPremium | currency: 'IDR': 'symbol': '4.0-0'}}</b></div>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div>Total Premi</div>
    <div><b>{{this.calculator.totalPremium | currency: 'IDR' : 'symbol' : '4.0-0'}}</b></div>
  </div>
  <div class="agreement-consent d-flex justify-content-center mt-3 flex-column">
    <div>
      <input class="form-check-input border-primary" type="checkbox" id="agreementConsent" [(ngModel)]="isTtdAgreementChecked">
      <label class="form-check-label" for="agreementConsent">
        <b>
          Saya telah membaca, mengerti dan menyetujui <a class="color-light-blue"
                                                         (click)="togglePernyataanKuasa(kuasa)">Pernyataan
          & Kuasa</a>,
          dan <a class="color-light-blue" (click)="togglePrivasi(privasi)">Kebijakan Privasi</a>
        </b></label>
    </div>

    <div>
      <input class="form-check-input border-primary" type="checkbox" id="agreementMarketing" [(ngModel)]="isMarketingChecked">
      <label class="form-check-label" for="agreementMarketing">
        <b>(Opsional) Saya telah membaca, mengerti dan menyetujui <a class="color-light-blue"
                                                                     (click)="togglePernyataanKuasa(marketing)">Persetujuan
          Pemasaran</a></b>
      </label>
    </div>
  </div>
</div>

<div class="container form-container pb-3 bg-white text-center">
  <div class="d-flex justify-content-between">
    <div>Tanda Tangan Tertanggung*</div>
    <div>
      <button class="btn-reset bg-dark-blue py-0 text-white rounded-3 no-border" (click)="clearCandidate()"><i
        class="bi-repeat"></i> Ulangi Tanda Tangan
      </button>
    </div>
  </div>
  <div class="rounded-3 py-3 mb-3">
    <div id="ttdCandidate" #ttdCandidateContainer>
      <signature-pad
        id="padpad"
        class="border rounded-2"
        style="background-color: white"
        #signatureCandidate [options]="signaturePadOptionsCandidate" (drawEnd)="drawCompleteCandidate()">
      </signature-pad>
    </div>
  </div>
  <div class="agreement-consent d-flex justify-content-center mt-3">
    <input class="form-check-input border-primary" type="checkbox" id="inputTtdCandidate" [(ngModel)]="isTtdCandidateChecked">
    <label class="form-check-label" for="inputTtdCandidate">
      <b>
      Saya telah menerima, membaca, mendapat penjelasan dari Tenaga Pemasar serta memahami dan menyetujui semua
      informasi di Ilustrasi dan Ringkasan Informasi Produk dan Layanan (RIPLAY) Personal ini.
      </b>
    </label>
  </div>
</div>


<div class="container form-container pb-3 bg-white text-center">
  <div class="d-flex justify-content-between">
    <div>Tanda tangan Sales Bank*</div>
    <div>
      <button class="btn-reset bg-dark-blue py-0 text-white rounded-3 no-border" (click)="clearBank()"><i
        class="bi-repeat"></i> Ulangi Tanda Tangan
      </button>
    </div>
  </div>
  <div class="rounded-3 py-3 mb-3 no-border">
    <div id="ttdSales" #ttdSalesContainer>
      <!--      <div>-->
      <!--        <p class="bg-warning">test</p>-->
      <signature-pad
        style="background-color: white;"
        class="border rounded-3"
        #signatureBank [options]="signaturePadOptionsBank" (drawEnd)="drawCompleteSales()">


      </signature-pad>
      <!--      </div>-->

    </div>
  </div>
  <div class="agreement-consent d-flex justify-content-center mt-3">
    <input class="form-check-input border-primary" type="checkbox" id="inputTtdStaff" [(ngModel)]="isTtdStaffChecked">
    <label class="form-check-label" for="inputTtdStaff">
      <b>
      Saya dengan ini menyatakan bahwa saya telah mereferensikan produk Asuransi Jiwa Pembiayaan Allianz kepada Calon
      Tertanggung dan menyaksikan bahwa Calon Tertanggung telah mengisi dan menandatangani eSPAJK
      </b>
    </label>
  </div>
  <div class="d-flex flex-row justify-content-center flex-gap mt-4">
    <div class="d-flex flex-column mb-2 flex-grow-1 text-start">
      <p class="title-input m-0">Tempat/Ditandatangani di: *</p>
      <input class="bottom-border" type="text" [(ngModel)]="tandaTangan.tempatTandaTangan">
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-gap">
    <button class="rounded-2 btn-continue mt-3 flex-grow-1" type="submit" (click)="continue()">LANJUT
    </button>
  </div>
</div>

<ng-template #kuasa let-modal>
  <div>
    <button type="button" class="btn-close btn-close m-2 position-absolute top-0 end-0 shadow-none" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="m-3 text-justify">
    <h3 class="color-dark-blue">Pernyataan dan Surat Kuasa</h3>
    <p class="px-4 mt-3">
      Saya, sebagai Calon Pemegang Polis / Calon Tertanggung / Pembayar Premi ("Saya"), telah mengisi Surat Permohonan
      Asuransi Jiwa Kumpulan ("SPAJK") ini dengan benar dan jujur dan dengan ini pula menyatakan dan menyetujui:
    </p>
    <ol>
      <li>
        Semua keterangan yang diberikan di dalam SPAJK ini dan keterangan lain yang Saya sampaikan kepada PT Asuransi
        Allianz Life Indonesia (selanjutnya disebut "Allianz") atau kepada pemeriksa kesehatan yang ditunjuk oleh
        Allianz
        adalah benar sudah Saya tuliskan di dalam SPAJK Kumpulan ini (termasuk yang ditulis di dalam dokumen lainnya
        yang
        menjadi kelengkapan dari permohonan asuransi ini, jika ada) dan tidak ada keterangan - keterangan dan hal-hal
        lain
        yang Saya sembunyikan, serta Saya sendiri yang menandatangani SPAJK Kumpulan ini.
      </li>
      <li>
        Pembayaran yang dilakukan oleh Saya untuk pembayaran Premi berdasarkan SPAJK Kumpulan ini tidak berasal dari
        maupun
        digunakan untuk tujuan tindak pidana pencucian uang dan pendanaan terorisme sebagaimana dimaksud UU No. 8 Tahun
        2010
        tentang Pencegahan Dan Pemberantasan Tindak Pidana Pencucian Uang dan UU No. 9 Tahun 2013 tentang Pencegahan Dan
        Pemberantasan Tindak Pidana Pendanaan Terorisme (dan/ atau perubahannya), termasuk tidak terlibat dan/atau
        terdapat
        dalam daftar pendanaan proliferasi senjata pemusnah massal. Apabila ada indikasi pelanggaran atas Undang-Undang
        dan
        ketentuan-ketentuan tersebut di atas, maka Allianz akan melaksanakan kewajiban sesuai dengan ketentuan yang
        berlaku
        atau atas perintah Lembaga Negara yang berwenang, termasuk melakukan kewajiban pelaporan dan memberikan laporan
        atas
        transaksi keuangan yang mencurigakan. Dalam rangka memenuhi kewajiban Uji Tuntas Nasabah, Saya bersedia untuk
        memberikan data yang diminta sesuai dengan peraturan yang berlaku dan Saya bersedia untuk melakukan pengkinian
        data
        dalam hal terdapat perubahan data dikemudian hari.
      </li>
      <li>
        Saya dengan ini memberikan persetujuan dan izin, kepada Allianz untuk:
        <ol class="lower-alpha">
          <li>
            menyimpan dan memproses data dan/atau informasi pribadi Saya (termasuk data dan informasi kesehatan serta
            finansial
            Saya) dalam rangka dan/atau untuk setiap tujuan berikut ini:
            <ol class="lower-roman">
              <li>proses pengajuan permohonan asuransi;</li>
              <li>penerbitan Polis dan/atau Sertifikat Asuransi</li>
              <li>penilaian risiko asuransi;</li>
              <li>kegiatan operasional Allianz;</li>
              <li>pengajuan dan penanganan setiap klaim (termasuk namun tidak terbatas pada pemeriksaan dan investigasi
                klaim);
              </li>
              <li>pembayaran manfaat asuransi/klaim;</li>
              <li>keperluan ko-asuransi termasuk penanganan klaim yang timbul atas ko asuransi;</li>
              <li>pelayanan nasabah termasuk penanganan keluhan/komplain;</li>
              <li>pembayaran komisi;</li>
              <li>kerja sama dalam rangka penemuan atau pencegahan penipuan dan kejahatan keuangan;</li>
              <li>setiap proses hukum yang melibatkan Allianz dan/atau tenaga pemasarnya;</li>
              <li>kerja sama reasuransi dan/atau retrosesi;</li>
              <li>kepatuhan terhadap perintah pengadilan, hukum dan peraturan perundang-undangan yang berlaku;
                dan/atau
              </li>
              <li>proses lainnya yang terkait dengan penyelenggaraan kegiatan asuransi.</li>
            </ol>
          </li>
          <li>
            mengungkapkan data dan/atau informasi pribadi Saya (termasuk data dan informasi kesehatan serta finansial
            Saya)
            kepada pihak-pihak berikut ini:
            <ol class="lower-roman">
              <li>ahli medis dan/atau pihak ketiga lainnya (termasuk mereka yang memberikan perawatan, pelayanan
                kesehatan dan/atau
                layanan lainnya kepada Saya);
              </li>
              <li>setiap perusahaan (termasuk perusahaan penyedia layanan, perusahaan asuransi, dan perusahaan
                reasuransi), mitra,
                orang atau pihak ketiga yang ditunjuk dan/atau bekerja sama dengan Allianz;
              </li>
              <li>perusahaan induk Allianz, afiliasi, anak perusahaan, agen dan perwakilannya dan/atau usaha patungan;
                dan/atau
              </li>
              <li>otoritas, asosiasi asuransi dan/atau badan pemerintah (regulator), dalam rangka dan/atau untuk setiap
                tujuan
                sebagaimana yang diuraikan dalam butir 3 (a) di atas.
              </li>
            </ol>
          </li>
          <li>
            Menyimpan serta memproses data dan/atau informasi pribadi Saya (termasuk data dan informasi kesehatan serta
            finansial Saya dan mengungkapkan data dan/atau informasi pribadi tersebut untuk menanggapi perintah
            pengadilan atau proses hukum atau permintaan dari regulator manapun atau pihak berwenang atau untuk
            melindungi terhadap
            penipuan atau aktivitas ilegal lainnya atau untuk tujuan manajemen risiko atau untuk memungkinkan Allianz
            untuk
            melakukan upaya hukum yang tersedia atau membatasi kerusakan yang mungkin terjadi terhadap Allianz, dan/atau
            mematuhi
            hukum dan proses hukum termasuk tetapi tidak terbatas pada peraturan tentang FATCA dan CRS (“Kebutuhan
            Pelaporan
            Pertukaran Informasi Antar Negara”). Saya juga bersedia untuk memberikan informasi/dokumen yang diperlukan
            guna memenuhi kebutuhan terkait (“Informasi Terkait”) dan dengan segera menyampaikan pengikian data kepada
            Allianz jika ada perubahan atas Informasi Terkait tersebut.
          </li>
        </ol>
      </li>
      <li>
        Bahwa apabila ternyata pernyataan, keterangan, dokumen dan jawaban yang Saya berikan tidak benar / diragukan
        kebenarannya, maka Saya memahami serta menyetujui sepenuhnya bahwa Sertifikat Asuransi menjadi batal dan Allianz
        dibebaskan dari segala kewajibannya membayar Uang Pertanggungan, segala gugatan, tuntutan, klaim atau bagian
        dari
        itu dalam bentuk dan nama apapun, maupun mengembalikan Premi, baik saat ini maupun di kemudian hari.
      </li>
      <li>
        Pertanggungan asuransi akan mulai berlaku penuh bila hal-hal berikut ini dipenuhi:
        <ol class="lower-alpha">
          <li>Pertanggungan atas Tertanggung mulai berlaku terhitung sejak tanggal Fasilitas Pembiayaan dicairkan oleh
            Pemegang Polis
            kepada Tertanggung.
          </li>
          <li>Premi beserta biaya-biaya lainnya (apabila ada), telah dibayar lunas.</li>
        </ol>
      </li>
      <li>
        Saya, dengan ini memberi kuasa dan izin kepada:
        <ol class="lower-alpha">
          <li>setiap dokter, rumah sakit, klinik, puskesmas, laboratorium, lembaga / instansi lainnya, perusahaan
            asuransi, badan
            hukum, perorangan atau organisasi lainnya, yang mempunyai catatan atau mengetahui keadaan atau kesehatan
            Saya,
            suami/istri atau putra/putri Saya, riwayat pengobatan atau perawatan di rumah sakit, nasihat-nasihat dokter
            baik
            selama Saya, suami/istri atau putra/putri Saya masih hidup atau meninggal dunia; dan/atau
          </li>
          <li>setiap dokter, rumah sakit, klinik, puskesmas, laboratorium, lembaga / instansi lainnya, perusahaan
            asuransi, badan
            hukum, perorangan atau organisasi lainnya yang ditunjuk dan/atau direkomendasikan oleh Allianz.
          </li>
        </ol>
      </li>
      <li>
        Saya dengan ini melepaskan ketentuan-ketentuan yang termaktub dalam Pasal 1813, 1814, dan 1816 Kitab
        Undang-Undang
        Hukum Perdata, serta pemberian kuasa ini tidak dapat ditarik kembali dan mengikat para pengganti/ahli waris/
        penerusnya dan orang yang ditunjuk dan tetap berlaku setelah Saya meninggal atau dalam keadaan cacat atau berada
        di
        bawah pengampuan. Salinan (fotokopi) surat kuasa ini adalah sah dan mempunyai kekuatan hukum yang sama seperti
        aslinya.
      </li>
      <li>
        Apabila Saya membatalkan Polis dalam jangka waktu 14 (empat belas) hari sejak Polis Saya terima, maka Allianz
        wajib
        mengembalikan sejumlah Premi yang telah Saya bayarkan dikurangi biaya-biaya (apabila ada). Komponen biaya
        pengurang
        tersebut termasuk namun tidak terbatas pada bea meterai, biaya administrasi dan biaya pemeriksaan kesehatan
        (apabila
        ada).
      </li>
      <li>
        Saya menyadari sepenuhnya bahwa Allianz memiliki hak untuk menolak / menerima, membatalkan / mengubah (dalam hal
        ini
        termasuk untuk menambahkan / mengurangi) ketentuan dalam perjanjian pertanggungan ini, termasuk antara lain
        untuk
        menentukan bahwa apabila Calon Tertanggung ditolak kepesertaannya oleh Allianz, maka seluruh Premi yang
        dibayarkan
        dikembalikan dikurangi biaya-biaya (apabila ada).
      </li>
      <li>
        Saya mengerti bahwa dalam kapasitas Saya sebagai Calon Tertanggung, Saya dapat membatalkan pertanggungan ini dan
        berhak untuk menerima Premi yang sudah dibayarkan dikurangi biaya-biaya (apabila ada).
      </li>
      <li>
        Saya sadar dan sudah mengetahui serta memahami sepenuhnya produk yang akan dibeli dan segala risiko atas produk
        tersebut dan Saya telah mendapatkan penjelasan atas produk yang akan Saya beli dan segala risiko atas produk
        tersebut berdasarkan SPAJK Kumpulan ini.
      </li>
      <li>
        Saya telah mendapatkan penjelasan dan menerima Ringkasan Informasi Produk dan Layanan (RIPLAY) Umum dan RIPLAY
        Personal.
      </li>
      <li>
        Dalam hal Saya memilih korespondensi melalui email dan/atau Sertifikat Asuransi versi elektronik sebagaimana
        keterangan yang Saya berikan dalam SPAJK Kumpulan ini, maka Saya menyatakan dan menyetujui setiap bentuk
        korespondensi dan/atau Sertifikat Asuransi versi elektronik dikirimkan melalui email.
      </li>
      <li>
        Saya mengerti bahwa untuk mempelajari lebih lanjut tentang bagaimana Allianz melindungi data pribadi dan hak-hak
        Saya dengan mengakses tautan berikut: https://www.allianz.co.id/kebijakan-privasi.html.
      </li>
      <li>
        Saya mengakui dan menyetujui bahwa SPAJK Kumpulan ini dapat ditandatangani dengan tanda tangan elektronik (baik
        seluruhnya atau sebagian), yang harus dianggap sebagai tanda tangan asli untuk semua tujuan dan yang memiliki
        kekuatan hukum yang sama dengan tanda tangan asli (basah). Saya mengakui dan menyetujui bahwa, dalam setiap
        proses
        hukum dalam setiap hal yang sehubungan dengan SPAJK Kumpulan ini dan/atau produk asuransi dan/atau permohonan
        ini,
        Saya secara tegas mengesampingkan setiap hak untuk (i) mengajukan pembelaan atau pelepasan tanggung jawab /
        kewajiban; dan/atau (ii) membatalkan SPAJK Kumpulan ini, atas alasan penandatanganan SPAJK Kumpulan ini oleh
        Saya
        dengan tanda tangan elektronik.
      </li>
      <li>
        Saya menyatakan bahwa Saya tidak tunduk pada Qanun No. 11 Tahun 2018 tentang Lembaga Keuangan Syari’ah dan
        peraturan
        pelaksanaannya (sebagaimana dapat diubah, ditambah atau digantikan dari waktu ke waktu) sehingga tidak ada
        larangan
        bagi Saya untuk mengajukan dan menandatangani SPAJK Kumpulan dan membeli produk asuransi non-syari’ah.
      </li>
      <li>
        Dengan menandatangani SPAJK Kumpulan ini, maka Saya telah menyetujui pernyataan dan kuasa ini.
      </li>
    </ol>
  </div>
</ng-template>

<ng-template #privasi let-modal>
  <div>
    <button type="button" class="btn-close btn-close m-2 position-absolute top-0 end-0 shadow-none" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="m-3 text-justify">
    <h3 class="color-dark-blue text-center">Pemberitahuan Privasi</h3>
    <p>
      Pemberitahuan Privasi ini menjelaskan bagaimana jenis data apa saja yang akan dikumpulkan, diproses dan digunakan,
      tujuan pemrosesan data, dengan siapa data Anda dibagikan beserta hak-hak Anda sebagai subjek data.
    </p>
    <p>
      Pemberitahuan Privasi ini berlaku untuk setiap data yang diperoleh oleh PT Asuransi Allianz Life Indonesia
      (“Allianz Indonesia”) melalui penggunaan formulir E-SPAJK oleh Anda.
    </p>
    <p>
      Pemberitahuan Privasi ini dapat diperbarui dari waktu ke waktu, harap membaca Pemberitahuan Privasi ini dengan
      seksama dan periksa secara berkala.
    </p>

    <b>Pernyataan Data Pribadi adalah Benar</b>
    <p>
      Subjek Data, dalam hal ini adalah Calon Tertanggung, menyetujui bahwa data dan informasi pribadi (termasuk data
      dan informasi kesehatan dan finansial) dari Calon Tertanggung (“Data Pribadi”) yang dicantumkan dalam formulir
      E-SPAJK ini adalah benar dan tidak ada keterangan atau hal-hal lain yang disembunyikan. Apabila terdapat kesalahan
      atau ketidakakuratan mengenai Data Pribadi, maka Calon Tertanggung dapat mengubah dan/atau melakukan pengkinian
      Data Pribadi dengan menghubungi sales representative dari Bank terkait untuk kemudian dapat dilanjutkan ke Allianz
      Indonesia.
    </p>

    <b>Allianz Indonesia sebagai Pengendali Data Pribadi</b>
    <p>
      PT Asuransi Allianz Life Indonesia (“Allianz Indonesia”) Allianz Indonesia adalah Pengendali Data Pribadi sesuai
      dengan Undang-Undang Nomor 27 Tahun 2022 tentang Pelindungan Data Pribadi (“UU PDP”). Sebagai Pengendali Data
      Pribadi, Allianz Indonesia berhak untuk mengumpulkan dan memproses Data Pribadi dengan tujuan tertentu yang telah
      disepakati oleh Subjek Data dan sesuai dengan peraturan perundang-undangan yang berlaku.
    </p>

    <b> Tujuan Pemrosesan & Dasar Hukum Pemrosesan Data</b>
    <p>
      Tujuan dari pemrosesan Data Pribadi ini (“Tujuan Pemrosesan”) adalah sebagai tahap identifikasi guna kebutuhan
      untuk mengetahui profil dari Calon Tertanggung dalam rangka layanan pelaksanaan jasa asuransi sehubungan dengan
      produk pinjaman (pembiayaan) pada bank mitra dan pertanggungan kepada Calon Tertanggung Allianz Indonesia untuk produk
      Credit Life. Dasar hukum pemrosesan Data Pribadi dari Calon Tertanggung adalah pemenuhan kewajiban kontrak antara
      Allianz
      Indonesia dan Calon Tertanggung.
    </p>

    <p>
      Bagaimana kami akan menggunakan Data Pribadi Anda?
    </p>
    <ul class="disc">
      <li>
        Calon Tertanggung memahami bahwa seluruh Data Pribadi yang dicantumkan dalam formulir E-SPAJK ini akan
        dikumpulkan, diproses dan disimpan oleh Allianz Indonesia untuk pelaksanaan dan pemenuhan Tujuan Pemrosesan
        sebagaimana telah disepakati oleh Calon Tertanggung dengan Allianz Indonesia.
      </li>
      <li>
        Lebih lengkapnya, Allianz Indonesia akan menyimpan, memproses dan/atau menggunakan Data Pribadi Calon
        Tertanggung
        untuk:
        <ol class="lower-alpha">
          <li>proses pengajuan permohonan asuransi;</li>
          <li>penerbitan Polis;</li>
          <li>penilaian risiko asuransi;</li>
          <li>kegiatan operasional Allianz;</li>
          <li>pengajuan dan penanganan setiap klaim (termasuk namun tidak terbatas pada pemeriksaan dan investigasi
            klaim);
          </li>
          <li>pembayaran manfaat asuransi/klaim;</li>
          <li>repositori untuk Allianz Indonesia dan Bank partner terkait;</li>
          <li>keperluan ko-asuransi termasuk penanganan klaim yang timbul atas koasuransi; pelayanan Calon Tertanggung
            termasuk penanganan keluhan/komplain;
          </li>
          <li>pembayaran komisi;</li>
          <li>kerja sama dalam rangka penemuan atau pencegahan penipuan dan kejahatan keuangan;</li>
          <li>setiap proses hukum yang melibatkan Allianz dan/atau tenaga pemasarnya;</li>
          <li>kerja sama reasuransi dan/atau retrosesi;</li>
          <li>kepatuhan terhadap perintah pengadilan, hukum dan peraturan perundang-undangan yang berlaku;</li>
          <li>dan/atau proses lainnya yang terkait dengan penyelenggaraan kegiatan asuransi.</li>
        </ol>
      </li>
      <li>
        Allianz Indonesia juga akan menyimpan, memproses dan mengungkapkan Data Pribadi Calon Tertanggung tersebut untuk
        menanggapi perintah pengadilan atau proses hukum atau permintaan dari regulator manapun atau pihak berwenang
        atau
        untuk melindungi terhadap penipuan atau aktivitas ilegal lainnya atau untuk tujuan manajemen risiko atau untuk
        memungkinkan Allianz Indonesia untuk melakukan upaya hukum yang tersedia atau membatasi kerusakan yang mungkin
        terjadi terhadap Allianz Indonesia, dan/atau mematuhi hukum dan proses hukum termasuk tetapi tidak terbatas pada
        peraturan tentang FATCA dan CRS (“Kebutuhan Pelaporan Pertukaran Informasi Antar Negara”). Calon Tertanggung
        dalam
        hal ini bersedia untuk memberikan informasi/dokumen yang diperlukan guna memenuhi kebutuhan terkait (“Informasi
        Terkait”) dan dengan segera menyampaikan pengkinian data kepada Allianz Indonesia jika ada perubahan atas
        Informasi Terkait tersebut.
      </li>
    </ul>

    <b>
      Data Pribadi yang Kami Kumpulkan
    </b>
    <ul class="disc">
      <li>Dalam rangka pemenuhan dan pencapaian Tujuan Pemrosesan, Allianz Indonesia perlu untuk mengumpulkan Data
        Pribadi
        dari Calon Tertanggung. Data Pribadi yang dibutuhkan dan dibutuhkan antara lain termasuk namun tidak terbatas
        pada: sebagai berikut:
        <ol class="lower-alpha">
          <li>NIK;</li>
          <li>Nama lengkap;</li>
          <li>Alamat Tinggal lengkap;</li>
          <li>Nomor telepon rumah dan/atau handphone;</li>
          <li>Tempat tanggal lahir;</li>
          <li>Alamat Lengkap Kantor Calon Tertanggung;</li>
          <li>Keterangan Kesehatan;</li>
          <li>Riwayat penyakit;</li>
          <li>Nomor rekening Bank;</li>
        </ol>
      </li>
    </ul>

    <b>
      Transfer Data Pribadi
    </b>
    <ul class="disc">
      <li>Calon Tertanggung memahami bahwa Allianz Indonesia dapat mengungkapkan dan/atau melakukan transfer Data
        Pribadi
        kepada pihak-pihak yang berkepentingan dan dianggap perlu, seperti:
        <ol class="lower-alpha">
          <li>ahli medis, ahli independen, dan/atau pihak ketiga lainnya (termasuk mereka yang memberikan perawatan,
            pelayanan
            Kesehatan dan/atau layanan lainnya);
          </li>
          <li>setiap perusahaan (termasuk perusahaan penyedia layanan, perusahaan asuransi dan perusahaan reasuransi),
            mitra,
            orang atau pihak ketiga yang ditunjuk dan/atau bekerja sama dengan Allianz Indonesia;
          </li>
          <li>Bank mitra yang bekerja sama dengan Allianz Indonesia yang dimana kerjasama dalam hal untuk menyediakan
            produk
            pinjaman beserta dengan produk asuransi (Credit Life);
          </li>
          <li>perusahaan induk Allianz Indonesia, afiliasi, anak perusahaan, agen dan perwakilannya dan/atau usaha
            patungan;
            dan/atau
          </li>
          <li>otoritas, asosiasi asuransi dan/atau badan pemerintah (regulator),</li>
        </ol>
        dalam rangka pelaksanaan Tujuan Pemrosesan.
      </li>
      <li>
        Dalam hal kewajiban yang diatur oleh peraturan perundang-undangan dan segala peraturan yang dikeluarkan oleh
        pemerintah dan lembaga-lembaga terkait, Allianz Indonesia berdasarkan hukum dan peraturan yang berlaku tersebut
        dapat melakukan transfer Data Pribadi kepada otoritas yang berwenang seperti Otoritas Jasa Keuangan,
        lembaga-lembaga pemerintah, aparat penegak hukum dan lain-lain dalam rangka kepentingan pemenuhan kewajiban
        hukum.
      </li>
    </ul>

    <b>Penyimpanan Data Pribadi</b>
    <ul class="disc">
      <li>Allianz Indonesia menyimpan Data Pribadi Calon Nasabah dalam rangka pelaksanaan Tujuan Pemrosesan.</li>
      <li>Allianz Indonesia dapat menyimpan Data Pribadi Calon Nasabah dalam jangka waktu tertentu sesuai dengan
        peraturan
        perundang-undangan yang berlaku.
      </li>
    </ul>

    <b>Hak dan Permintaan Subjek Data</b>
    <ul class="disc">
      <li>Calon Tertanggung memiliki hak terhadap Data Pribadi masing-masing (untuk selanjutnya disebut “Hak Subjek
        Data”)
        sesuai dengan peraturan perundang-undangan. Dalam hal ini, Calon Tertanggung berhak untuk mendapatkan informasi
        mengenai pemrosesan dan Tujuan Pemrosesan Data Pribadi (sesuai dengan Pemberitahuan Privasi ini), mengganti,
        melengkapi, memperbaharui dan/atau memperbaiki Data Pribadi masing-masing, meminta penghapusan, menunda atau
        membatasi pemrosesan Data Pribadi.
      </li>
    </ul>

    <b>Hubungi Kami</b>
    <p>
      Apabila terdapat pertanyaan, keluhan atau ingin menghubungi seputar Pemberitahuan Privasi ini, Anda dapat
      menghubungi Allianz Care di 1500-136 atau mengirimkan e-mail ke alamat bpr.bancassurance@allianz.co.id.
    </p>
  </div>
</ng-template>

<ng-template #marketing let-modal>
  <div>
    <button type="button" class="btn-close btn-close m-2 position-absolute top-0 end-0 shadow-none" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="m-3 text-justify">
    <h3 class="color-dark-blue text-center">Persetujuan Pemasaran</h3>
    <p>
      Calon Tertanggung bersedia (i) untuk menerima informasi, penawaran produk dan/atau layanan serta dihubungi oleh
      Allianz, anak perusahaan dan/atau afiliasinya ("Grup Allianz") (serta pihak ketiga yang ditunjuk oleh Grup
      Allianz);
      dan (ii) data pribadi Anda diproses oleh Grup Allianz dan pihak ketiga yang ditunjuk oleh Grup Allianz untuk
      tujuan
      pemasaran, peningkatan kualitas data pribadi serta peningkatan layanan.
    </p>
    <p>
      Dalam hal Anda menjawab menyetujui, Anda mengerti dan menyetujui bahwa persetujuan diatas meliputi: (i)
      persetujuan untuk dihubungi dan/atau dikirimkan
      informasi mengenai penawaran produk dan/atau layanan, survei nasabah dan kampanye produk, melalui setiap media
      komunikasi yang tersedia (seperti surat, surat elektronik (email), Short Message Service (SMS), telepon, aplikasi
      pesan singkat dan media komunikasi digital/elektronik lainnya) oleh Grup Allianz (serta pihak ketiga yang ditunjuk
      oleh Grup Allianz); dan (ii) persetujuan pemberian kuasa kepada Grup Allianz (serta pihak yang ditunjuk oleh Grup
      Allianz) untuk mengungkapkan informasi data pribadi Anda kepada setiap pihak lainnya terkait kepentingan yang
      dimaksud pada poin (i)
    </p>
    <p><b>Perhatian</b> : Dalam hal Anda tidak lagi bersedia dihubungi dan menerima informasi penawaran
      produk dan/atau layanan Grup Allianz (serta pihak ketiga yang ditunjuk oleh Grup Allianz), Anda dapat menghubungi
      AllianzCare di 1500-136 atau mengirimkan email ke alamat contactus@allianz.co.id</p>

  </div>
</ng-template>
