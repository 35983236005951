import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SpajkRiplayBsiRoutingModule} from "./spajk-riplay-bsi-routing.module";
import {GlobalModule} from "../../global/global.module";
import { SpajkRiplayBsiComponent } from './spajk-riplay-bsi.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpajkRiplayBsiRoutingModule,
    GlobalModule,
  ],
  declarations: [
    SpajkRiplayBsiComponent
  ]
})
export class SpajkRiplayBsiModule {

}
