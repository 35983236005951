import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-collapsible-content',
  templateUrl: './collapsible-content.component.html',
  styleUrls: ['./collapsible-content.component.css']
})
export class CollapsibleContentComponent {
  @Input() headerTitle: string;
  @Input() additionalInfo: string;
  isCollapsed: boolean = true;

  constructor() {
    this.headerTitle = "Test"
    this.additionalInfo = "Additional"
    // Initialize any properties or dependencies here
  }

  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
}
