import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {NgSignaturePadOptions, SignaturePadComponent} from "@almothafar/angular-signature-pad";
import {Router} from "@angular/router";
import {NbSubmitResponse, PremiData} from "../service/model/keterangan-kesehatan.model";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements AfterViewInit {
  modalRef: NgbModalRef | undefined;

  @ViewChild('ttdCandidateContainer')
  ttdCandidateContainer!: ElementRef;

  @ViewChild('ttdSalesContainer')
  ttdSalesContainer!: ElementRef;

  @ViewChild('signatureCandidate')
  public signaturePadCandidate!: SignaturePadComponent;
  public signaturePadOptionsCandidate: NgSignaturePadOptions = {
    minWidth: 1,
    canvasHeight: 300,
    canvasWidth: 200,
    penColor: 'black',
    backgroundColor: 'white',
    dotSize: 1,
    maxWidth: 1,
    velocityFilterWeight: 1
  }

  @ViewChild('signatureBank')
  public signaturePadBank!: SignaturePadComponent;
  public signaturePadOptionsBank: NgSignaturePadOptions = {
    minWidth: 1,
    canvasHeight: 300,
    canvasWidth: 500,
    penColor: 'black',
    backgroundColor: 'white',
    dotSize: 1,
    maxWidth: 1,
    velocityFilterWeight: 1
  }

  isTtdCandidateChecked: boolean = false;
  isTtdStaffChecked: boolean = false;
  isTtdAgreementChecked: boolean = false;
  isMarketingChecked: boolean = false;


  ngAfterViewInit() {
    this.signaturePadCandidate.set('canvasWidth', this.ttdCandidateContainer.nativeElement.offsetWidth);
    this.signaturePadCandidate.set('canvasHeight', 200); // Set your preferred height
    this.signaturePadBank.set('canvasWidth', this.ttdSalesContainer.nativeElement.offsetWidth);
    this.signaturePadBank.set('canvasHeight', 200); // Set your preferred height
  }

  kontakKantor: any;
  dataPribadi: any = {};
  rincianPertanggungan: any = {};
  calculator: any = {};
  selectedProduct: any = {};
  kontakPribadi: any;
  newBusiness: NbSubmitResponse = {};
  premiData: PremiData = {}
  tandaTangan = {
    isPreview: false,
    ttdCandidate: "",
    ttdSales: "",
    tempatTandaTangan: ""
  }

  isFromDashboard: boolean = false;
  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };



  constructor(private modalService: NgbModal,
              private router: Router) {
    let jsonPertanggungan = localStorage.getItem('rincian-pertanggungan')
    let jsonPribadi = localStorage.getItem('data-pribadi')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonProduct = localStorage.getItem('selected-product')
    let jsonDummy = localStorage.getItem('new-covering-response')

    if (jsonDummy) {
      this.newBusiness = JSON.parse(jsonDummy)
      this.premiData = JSON.parse(this.newBusiness.premiData!)
      console.log("premi ", this.premiData)
    }

    if (jsonPertanggungan && jsonPribadi && jsonCalculator && jsonProduct) {
      this.rincianPertanggungan = JSON.parse(jsonPertanggungan)
      this.dataPribadi = JSON.parse(jsonPribadi)
      this.calculator = JSON.parse(jsonCalculator)
      this.selectedProduct = JSON.parse(jsonProduct)
      this.calculator.totalPremium = Number(this.premiData.standardPremi) + Number(this.premiData.extraPremium ?? "0")
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.dataPribadi.namaLengkap = this.nasabah.nasabahNewCovering.fullName
      this.rincianPertanggungan.dob = this.nasabah.nasabahNewCovering.dob
      this.dataPribadi.jenisKelamin = this.nasabah.nasabahNewCovering.gender
      this.selectedProduct.productname = this.nasabah.nasabahNewCovering.productName
      this.rincianPertanggungan.uangPertanggungan = this.nasabah.nasabahNewCovering.sumAssured
    }
  }


  clearCandidate() {
    this.tandaTangan.ttdCandidate = ""
    this.signaturePadCandidate.clear();
  }

  clearBank() {
    this.tandaTangan.ttdSales = "";
    this.signaturePadBank.clear();
  }

  drawCompleteCandidate() {
    this.tandaTangan.ttdCandidate = this.signaturePadCandidate.toDataURL();
  }

  drawCompleteSales() {
    this.tandaTangan.ttdSales = this.signaturePadBank.toDataURL();
  }


  togglePernyataanKuasa(content: any) {
    this.modalRef = this.modalService.open(content)
  }

  continue() {
    if (!this.isTtdStaffChecked || !this.isTtdCandidateChecked || !this.isTtdAgreementChecked) {
      alert("Mohon untuk menyetujui ketentuan dan mengisi kolom tanda tangan")
    } else if (!this.tandaTangan.ttdCandidate || !this.tandaTangan.ttdSales) {
      alert("Mohon untuk mengisi tanda tangan E-SPAJK")
    } else if (!this.tandaTangan.tempatTandaTangan) {
      alert("Mohon untuk mengisi tempat tanda tangan E-SPAJK")
    } else {
      localStorage.setItem("tanda-tangan", JSON.stringify(this.tandaTangan))
      localStorage.setItem("marketing-checked", this.isMarketingChecked ? "Y" : "N")

      this.router.navigate(['eform/foto-nasabah-sales'])
    }
  }
}
