<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Pengisian Data Pribadi</b></h2>
    <h6 class="text-center">No Aplikasi : {{isFromDashboard ? selectedItem.referenceNo : productInfo.referenceNumber}}</h6>
  </div>
  <app-form-header formHeaderTitle="Pengisian Data Diri Calon Pihak Yang Diasuransikan" [step]="4" [dariStep]="4"></app-form-header>
</div>
<div class="container form-container">
  <form [formGroup]="dataPribadiForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title">Data Diri Calon Pihak Yang Diasuransikan</p>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['nik'].dirty || form.submitted) && !dataPribadiForm.controls['nik'].valid}">
        <p class="title-input m-0">NIK *</p>
        <input class="bottom-border" type="number" formControlName="nik"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['nik'].dirty || form.submitted) && !dataPribadiForm.controls['nik'].valid}">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Kewarganegaraan</p>
        <input class="bottom-border" type="text" placeholder="Warga Negara Indonesia (WNI)" disabled>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaLengkap'].dirty || form.submitted) && !dataPribadiForm.controls['namaLengkap'].valid}">
        <p class="title-input m-0">Nama Lengkap *</p>
        <input class="bottom-border" type="text" formControlName="namaLengkap"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaLengkap'].dirty || form.submitted) && !dataPribadiForm.controls['namaLengkap'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaAlias'].dirty || form.submitted) && !dataPribadiForm.controls['namaAlias'].valid}">
        <p class="title-input m-0">Nama Alias</p>
        <input class="bottom-border" type="text" formControlName="namaAlias"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaAlias'].dirty || form.submitted) && !dataPribadiForm.controls['namaAlias'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['statusPerkawinan'].dirty || form.submitted) && !dataPribadiForm.controls['statusPerkawinan'].valid}">
        <p class="title-input m-0">Status Perkawinan *</p>
        <select class="bottom-border" formControlName="statusPerkawinan">
          <option *ngFor="let statusPerkawinan of listStatusPerkawinan" [value]="statusPerkawinan.value">
            {{statusPerkawinan.statusPerkawinan}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['tempatLahir'].dirty || form.submitted) && !dataPribadiForm.controls['tempatLahir'].valid}">
        <p class="title-input m-0">Tempat Lahir *</p>
        <input class="bottom-border" type="text" formControlName="tempatLahir"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['tempatLahir'].dirty || form.submitted) && !dataPribadiForm.controls['tempatLahir'].valid}">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Usia</p>
        <input class="bottom-border" type="text" formControlName="usia">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['nomorAplikasi'].dirty || form.submitted) && !dataPribadiForm.controls['nomorAplikasi'].valid}">
        <p class="title-input m-0">Nomor Aplikasi *</p>
        <input class="bottom-border" type="text" formControlName="nomorAplikasi"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['nomorAplikasi'].dirty || form.submitted) && !dataPribadiForm.controls['nomorAplikasi'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['areaKantorCabang'].dirty || form.submitted) && !dataPribadiForm.controls['areaKantorCabang'].valid}">
        <p class="title-input m-0">Area Kantor Cabang *</p>
        <select class="bottom-border" formControlName="areaKantorCabang" (change)="onSelectionBranchAreaChange($event)">
          <option *ngFor="let branchArea of branchAreaList" [value]="branchArea.region">
            {{branchArea.region}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['kantorCabang'].dirty || form.submitted) && !dataPribadiForm.controls['kantorCabang'].valid}">
        <p class="title-input m-0">Kantor Cabang *</p>
        <select class="bottom-border" formControlName="kantorCabang">
          <option *ngFor="let branch of branchList" [value]="branch.label + '-' + branch.value">
            {{branch.label}}
          </option>
        </select>
      </div>


    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn-back rounded-3 mt-3 py-3 flex-grow-1" type="button" (click)="saveDraft()">SIMPAN</button>
      <button class="btn-continue rounded-3 mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
