import { Component } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-draft-saved',
  templateUrl: './draft-saved.component.html',
  styleUrls: ['./draft-saved.component.css']
})
export class DraftSavedComponent {
  todayDate: Date = new Date()
  referenceNumber: string = ""

  constructor(private router: Router) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    let jsonProductInfo = localStorage.getItem('selected-product')
    let jsonItem = localStorage.getItem('selected-item')
    if (jsonProductInfo) {
      this.referenceNumber = JSON.parse(jsonProductInfo).referenceNumber
    }

    if (jsonItem) {
      this.referenceNumber = JSON.parse(jsonItem).referenceNo
    }
  }

  goToDashboard() {
    this.router.navigate(['eform/dashboard'])
  }

  goToMenu() {
    this.router.navigate(['eform/menu'])
  }
}
