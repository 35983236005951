import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {SessionStorageEnum} from "../global/enum/session-storage.enum";
import {PartnerIdEnum} from "../global/enum/partner.enum";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  isItShariaProduct: boolean;

  constructor(private router: Router) {
    this.isItShariaProduct = sessionStorage.getItem(SessionStorageEnum.PARTNER_ID) === JSON.stringify(PartnerIdEnum.BSI_ID);
    localStorage.clear()
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

  }

  goToRiplay() {
    this.router.navigate(["/eform/riplay"]);
  }

  goToDashboard() {
    this.router.navigate(["eform/dashboard"])
  }
}
