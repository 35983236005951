import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { InsuredSummaryComponent } from "./insured-summary.component";
import { LayoutComponent } from "../layout/layout.component";

const routes: Routes = [
  {
    path: 'dashboard',
    component: LayoutComponent,
    children: [
      {
        path: 'insured-summary/:referenceNo/:partnerCode',
        component: InsuredSummaryComponent,
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
