import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import {NbSubmitResponse, PremiData} from "../../service/model/keterangan-kesehatan.model";
import {NewCoveringService} from "../../service/new-covering/new-covering.service";
import {Router} from "@angular/router";
import {NasabahModel, NasabahUWModel} from "../../service/model/dashboard.model";
import {DownloadDocumentService} from "../../service/download-document/download-document.service";

@Component({
  selector: 'app-spajk-riplay-bsi',
  templateUrl: './spajk-riplay-bsi.component.html',
  styleUrls: ['./spajk-riplay-bsi.component.css']
})
export class SpajkRiplayBsiComponent implements AfterViewInit {
  data: any = '{"jenispembiayaan":"PPR","warganegara":"WNI","gender":"Wanita","status":"W","noHp":"081212121121","ecertificate":"Y","emailcorrespodensi":"Y","emailaddressstaff":"mangjojot@partner.com"}';

  @ViewChild('spajkWraper') spajkWrapper!: ElementRef;
  @ViewChild('riplayWrapper') riplayWrapper!: ElementRef;
  @ViewChild('riplayView') riplayView!: ElementRef;
  isLoading: boolean = false;
  dataSpajk: any = {}
  newCoveringResponse: NbSubmitResponse = {}
  premiData: PremiData = {}
  todayDate: Date = new Date();
  documentArray: string[] = []
  isFromDashboard: boolean = false;
  isSpajkDownloaded: boolean = false;
  isRiplayDownloaded: boolean = false;
  letterType: string = "[ACCEPTANCE_INVOICE]";
  isLoadingSpajk: boolean = false;
  isLoadingRiplay: boolean = false;

  constructor(
    private newCoveringService: NewCoveringService,
    private router: Router,
    private documentService: DownloadDocumentService
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    let jsonDummy = localStorage.getItem('new-business')!
    let autoUWresult = localStorage.getItem('auto-uw-result')!
    let autoUW = localStorage.getItem('auto-uw')!
    let jsonDataPribadi = localStorage.getItem('data-pribadi')!
    let jsonCalculator = localStorage.getItem('calculator')!
    let jsonRincianPertanggungan = localStorage.getItem('rincian-pertanggungan')!
    let jsonLogin = sessionStorage.getItem('login')!
    let jsonNewCoveringResponse = localStorage.getItem('new-business-response')!
    let jsonKontakPribadi = localStorage.getItem('kontak-pribadi')!
    let jsonKontakKantor = localStorage.getItem('kontak-kantor')!
    let jsonSelectedProduk = localStorage.getItem('selected-product')!
    let jsonDownloadSpajk = JSON.parse(localStorage.getItem('download-spajk') ?? 'false')
    let jsonDownloadRiplay = JSON.parse(localStorage.getItem('download-riplay') ?? 'false')

    this.isSpajkDownloaded = jsonDownloadSpajk
    this.isRiplayDownloaded = jsonDownloadRiplay

    this.dataSpajk = {
      ...JSON.parse(jsonDummy),
      ...JSON.parse(autoUW),
      ...JSON.parse(jsonDataPribadi),
      ...JSON.parse(jsonCalculator),
      ...JSON.parse(jsonRincianPertanggungan),
      ...JSON.parse(jsonLogin),
      ...JSON.parse(jsonKontakPribadi),
      ...JSON.parse(jsonKontakKantor),
      ...JSON.parse(jsonSelectedProduk),
      ...JSON.parse(autoUWresult),
      emailcorrespodensi: "Y",
      ecertificate: "Y"
    }


    this.isFromDashboard = JSON.parse(localStorage.getItem('from-dashboard')!)
    if (this.isFromDashboard) {
      this.mapNasabahToDataSpajk()
    } else {
      let tanggalAkhir = new Date(this.dataSpajk.tanggalAkhirAsuransi)
      let tanggalMulai = new Date(this.dataSpajk.tanggalMulaiAsuransi)
      tanggalAkhir = new Date(tanggalAkhir.getFullYear(), tanggalAkhir.getMonth() + 1, 0);
      const yearDiff = tanggalAkhir.getFullYear() - tanggalMulai.getFullYear();
      const monthDiff = tanggalAkhir.getMonth() - tanggalMulai.getMonth();

      // Calculate the difference in days
      const dayDiff = tanggalAkhir.getDate() - tanggalMulai.getDate();

      // Total months difference including partial months
      let totalMonths = yearDiff * 12 + monthDiff;

      // Adjust for partial month
      if (dayDiff > 0) {
        totalMonths += 1;
      }
      this.dataSpajk.tenure = totalMonths
      this.dataSpajk.attributionSumAssured = this.dataSpajk.sumAssuredAttribution
      // this.dataSpajk.sumAssuredAttribution = this.dataSpajk.sum
    }

    if (this.dataSpajk.uwStatus == "SUBMITTED" || this.dataSpajk.uwStatus == "REFERUW") {
      this.letterType = "[SUBMITTED]"
    }


    this.newCoveringResponse = JSON.parse(jsonNewCoveringResponse)
    this.generateLetter()
    // this.premiData = JSON.parse(this.newCoveringResponse.premiData!)

    this.setHealthQuestionFalse()
    this.dobParse();
  }

  setHealthQuestionFalse() {
    if (this.dataSpajk.healthQuestionFlag == "N") {
      this.dataSpajk.smokingFlag = null;
      this.dataSpajk.preExistingFlag = null;
      this.dataSpajk.medicalOpinionFlag = null;
      this.dataSpajk.insuranceRejectionFlag = null;
      this.dataSpajk.hospitalCareFlag = null;
      this.dataSpajk.familyMedicalFlag = null;
      this.dataSpajk.weightGainlossFlag = null;
      this.dataSpajk.height = "";
      this.dataSpajk.weight = "";
      this.dataSpajk.weightGainlossKg = "";
    }
  }

  get spajkBsi() {
    return this.dataSpajk;
  }

  splitEmailStaff(email: any): string {
    return email.split("");
  }

  getCharatIndex(i: any): string {
    return this.spajkBsi.emailStaff[i];
  }

  dobParse(): void {
    //split the timezone and year, month, day
    const dobP = this.dataSpajk.dob.split('T')[0].split('-');
    this.spajkBsi.thn = dobP[0];
    this.spajkBsi.bln = dobP[1];
    this.spajkBsi.tgl = dobP[2];
  }

  ngAfterViewInit(): void {
    // this.isLoading = true;
    // this.sendImage()
    // this.riplayWrapper.nativeElement.style.width = "1199px"
  }

  downloadSpajk() {
    this.isLoadingSpajk = true;
    this.generatePDF(this.spajkWrapper, this.spajkBsi.nomorAplikasi + '_SPAJK')
  }

  downloadRiplay() {
    this.isLoadingRiplay = true;
    this.generatePDF(this.riplayView, this.spajkBsi.nomorAplikasi + '_RIPLAY')
  }

  continue() {
    if (this.isSpajkDownloaded && this.isRiplayDownloaded) {
      this.downloadLetter()
    } else {
      alert('Mohon untuk mengunduh dokumen SPAJPK Syariah dan RIPLAY Personal')
    }
    // this.newCoveringService.newBusinessProcessing({
    //   userId: this.dataSpajk.userId,
    //   partnerId: this.dataSpajk.partnerId,
    //   referenceNo: this.dataSpajk.referenceNo,
    //   productCode: this.dataSpajk.productcode
    // }).subscribe(res => {
    //   localStorage.setItem('new-business-processing', JSON.stringify(res))
    // this.router.navigate(['eform/final-bsi'])
    // }, () => {
    //   this.router.navigate(['eform/error'])
    // })
  }

  downloadLetter() {
    this.documentService.generateLetter({
      "userId": this.spajkBsi.userid!,
      "partnerId": this.spajkBsi.partnerId,
      "referenceNo": this.spajkBsi.referenceNumber,
      "productCode": this.spajkBsi.productcode,
      "lettersType": this.letterType
    }).subscribe(() => {
      // localStorage.setItem("download-response", JSON.stringify(res))
      this.router.navigate(['eform/final-bsi'])
    }, () => {
      this.router.navigate(['eform/error'])
    })
  }

  generateLetter() {
    if (!this.isSpajkDownloaded && !this.isRiplayDownloaded) {
      // this.documentService.generateLetter({
      //   "userId": this.spajkBsi.userid!,
      //   "partnerId": this.spajkBsi.partnerId,
      //   "referenceNo": this.spajkBsi.referenceNumber,
      //   "productCode": this.spajkBsi.productcode,
      //   "lettersType": this.letterType
      // }).subscribe(() => {
      // })
    }
  }

  healthQuestionN() {
    if (this.dataSpajk.healthQuestionFlag == "N") {
      this.dataSpajk = {
        ...this.dataSpajk,
        weightGainlossFlag: "N",
        weightGainlossKg: "",
        smokingFlag: "N",
        smokingPcs: 0,
        preExistingFlag: "N",
        chestPainFlag: "N",
        highBloodPresureFlag: "N",
        bloodDisorderFlag: "N",
        lungFlag: "N",
        cancerFlag: "N",
        diabeticFlag: "N",
        mentalDisorderFlag: "N",
        brainDisorderFlag: "N",
        lymphGlandFlag: "N",
        intestinesFlag: "N",
        hepatitisFlag: "N",
        drugAbuseFlag: "N",
        alcoholicFlag: "N",
        kidneyFlag: "N",
        cholesterolFlag: "N",
        nervousDisorderFlag: "N",
        hivAidsFlag: "N",
        othersFlag: "N",
        othersRemark: "",
        familyMedicalFlag: "N",
        familyMedicalDesc: "",
        insuranceRejectionFlag: "N",
        insuranceRejectionDesc: "",
        hospitalCareFlag: "N",
        hospitalCareDesc: "",
        medicalOpinionFlag: "N",
        medicalOpinionDesc: "",
      }
    }
  }

  generatePDF(elementRef: ElementRef, fileType: string) {
    const doc = new jspdf();

    // Get HTML content to be converted to PDF
    const content = elementRef.nativeElement;

    // Find all elements with the page-break class
    const elements = content.getElementsByClassName('page-break');

    // Convert HTML content to PDF pages based on page-break class
    this.htmlToPDF(doc, elements, fileType);
  }

  private htmlToPDF(doc: jspdf, elements: HTMLCollectionOf<Element>, fileType: string) {
    // Options for html2canvas
    const options = {
      scale: 1,
      useCORS: true,  // Use CORS to handle cross-origin images
      allowTaint: true,
      logging: true,
      scrollY: 0,
    };

    // Function to recursively add pages to PDF
    const addPageRecursive = (index: number) => {
      if (index >= elements.length) {

        let blob = new Blob([doc.output('blob')], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank')

        if (fileType.includes("SPAJK")) {
          // let blob = new Blob([doc.output('blob')], {type: 'application/pdf'})
          this.newCoveringService.uploadDocument(this.spajkBsi.referenceNumber!, blob, "SPAJKU").subscribe(() => {

          })
          this.isSpajkDownloaded = true;
          this.isLoadingSpajk = false;
          localStorage.setItem('download-spajk', 'true')
        }
        if (fileType.includes("RIPLAY")) {
          let blob = new Blob([doc.output('blob')], {type: 'application/pdf'})
          this.newCoveringService.uploadDocument(this.spajkBsi.referenceNumber!, blob, "RIPLAYU").subscribe(() => {
            // doc.save(fileType + '.pdf');
          })
          this.isRiplayDownloaded = true;
          this.isLoadingRiplay = false;
          localStorage.setItem('download-riplay', 'true')
        }
        return;
      }

      const element = elements[index] as HTMLElement;

      // Use html2canvas to convert each element to canvas
      html2canvas(element, options).then((canvas) => {
        // Calculate PDF height based on the canvas height and width
        const imgWidth = 210; // PDF page width in mm (A4 size)
        const imgHeight = canvas.height * imgWidth / canvas.width; // Calculate PDF height

        // Add a new page for each element (except the first one)
        if (index > 0) {
          doc.addPage();
        }

        // Convert canvas to PDF using jsPDF
        const imgData = canvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, '', 'FAST');

        // Move to the next element recursively
        addPageRecursive(index + 1);
      }).catch((error) => {
        console.error('Error generating PDF:', error);
        // Handle errors if needed
      });
    };

    // Start adding pages from the first element
    addPageRecursive(0);
  }

  mapNasabahToDataSpajk() {
    let nasabah: NasabahModel = JSON.parse(localStorage.getItem('nasabah')!)
    let selectedItem: any = JSON.parse(localStorage.getItem('selected-item')!)

    switch (nasabah.nasabahUw.productCode) {
      case "SPKPRBSI":
        this.dataSpajk.policynumber = "CL 2024 087"
        break;
      case "SPKTAMBSI":
        this.dataSpajk.policynumber = "CL 2024 085"
        break;
      case "SPKTAPBSI":
        this.dataSpajk.policynumber = "CL 2024 086"
        break;
    }

    if (selectedItem.kontribusiDasar <= 100000000){
      this.dataSpajk.healthQuestionFlag = "N"
    }

    this.todayDate = new Date(nasabah.nasabahUw.createdDate ?? "")

    this.dataSpajk = {
      ...this.dataSpajk,
      uwStatus: nasabah.nasabahUw.uwStatus,
      gender: selectedItem.gender,
      dob: selectedItem.dob,
      entryAge: selectedItem.entryAge,
      pekerjaan: selectedItem.pekerjaan,
      tenure: selectedItem.tenure,
      kontribusiDasar: selectedItem.kontribusiDasar,
      attributionSumAssured: nasabah.nasabahUw.attribution,
      totalPremium: nasabah.nasabahUw.totalPremi,
      height: nasabah.nasabahUw.height,
      weight: nasabah.nasabahUw.weight,
      weightGainlossKg: nasabah.nasabahUw.changeInWeightKg,
      weightGainlossFlag: nasabah.nasabahUw.weightGainOrLoss,
      smokingPcs: nasabah.nasabahUw.smokingFrequency,
      smokingFlag: nasabah.nasabahUw.smokingYN,
      preExistingFlag: this.preExistingDashboard(),
      chestPainFlag: nasabah.nasabahUw.chestPainFlag,
      highBloodPresureFlag: nasabah.nasabahUw.highBloodPresureflag,
      kidneyFlag: nasabah.nasabahUw.kidneyFlag,
      bloodDisorderFlag: nasabah.nasabahUw.bloaddisorderFlag,
      cholesterolFlag: nasabah.nasabahUw.cholesterolFlag,
      lungFlag: nasabah.nasabahUw.lungFlag,
      cancerFlag: nasabah.nasabahUw.cancerFlag,
      diabeticFlag: nasabah.nasabahUw.diabeticFlag,
      mentalDisorderFlag: nasabah.nasabahUw.mentalDisorderFlag,
      nervousDisorderFlag: nasabah.nasabahUw.nervousDisorderFlag,
      brainDisorderFlag: nasabah.nasabahUw.brainDisorderFlag,
      lymphGlandFlag: nasabah.nasabahUw.lymphgLandFlag,
      intestinesFlag: nasabah.nasabahUw.intestinesFlag,
      hepatitisFlag: nasabah.nasabahUw.hepatitisFlag,
      drugAbuseFlag: nasabah.nasabahUw.drugabuseFlag,
      alcoholicFlag: nasabah.nasabahUw.alcoholicFlag,
      hivAidsFlag: nasabah.nasabahUw.hivAidsFlag,
      othersFlag: nasabah.nasabahUw.othersFlag,
      othersRemark: nasabah.nasabahUw.anyDiseaseDesc,
      hospitalCareFlag: nasabah.nasabahUw.hospitalCareFlag,
      hospitalCareDesc: nasabah.nasabahUw.hospitalCareDesc,
      medicalOpinionFlag: nasabah.nasabahUw.medicalOpinionFlag,
      medicalOpinionDesc: nasabah.nasabahUw.medicalOpinionDesc,
      familyMedicalFlag: nasabah.nasabahUw.familyMedicalFlag,
      familyMedicalDesc: nasabah.nasabahUw.familyMedicalDesc,
      insuranceRejectionFlag: nasabah.nasabahUw.insuranceRejectionFlag,
      insuranceRejectionDesc: nasabah.nasabahUw.insuranceRejectionDesc,
      productName: nasabah.nasabahUw.productName,
      productcode: nasabah.nasabahUw.productCode,
      referenceNumber: nasabah.nasabahUw.referenceNo
      // kindOfProduct: nasabah.nasabahUw.productName,
      // policynumber: nasabah.nasabahUw.se belom

    }

  }

  preExistingDashboard(): string {
    let nasabah: NasabahUWModel = JSON.parse(localStorage.getItem('nasabah')!).nasabahUw
    if (
      nasabah.chestPainFlag == "Y" ||
      nasabah.highBloodPresureflag == "Y" ||
      nasabah.kidneyFlag == "Y" ||
      nasabah.bloaddisorderFlag == "Y" ||
      nasabah.cholesterolFlag == "Y" ||
      nasabah.lungFlag == "Y" ||
      nasabah.cancerFlag == "Y" ||
      nasabah.diabeticFlag == "Y" ||
      nasabah.mentalDisorderFlag == "Y" ||
      nasabah.nervousDisorderFlag == "Y" ||
      nasabah.brainDisorderFlag == "Y" ||
      nasabah.lymphgLandFlag == "Y" ||
      nasabah.intestinesFlag == "Y" ||
      nasabah.hepatitisFlag == "Y" ||
      nasabah.drugabuseFlag == "Y" ||
      nasabah.alcoholicFlag == "Y" ||
      nasabah.hivAidsFlag == "Y" ||
      nasabah.othersFlag == "Y"
    ) {
      return "Y"
    }
    return "N"
  }
}
