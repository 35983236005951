<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Rincian Data Kesehatan</b></h2>
    <h6 class="text-center">No Aplikasi : {{productInfo.referenceNumber}}</h6>
  </div>
  <app-form-header formHeaderTitle="Data Kesehatan" [step]="3" [dariStep]="4"></app-form-header>
<!--  <div class="container mt-5 pt-4 form-header">-->
<!--    <div class="row d-flex justify-content-around">-->
<!--      <div class="allianz-progress-step step-active"></div>-->
<!--      <div class="allianz-progress-step step-active"></div>-->
<!--      <div class="allianz-progress-step step-active"></div>-->
<!--      <div class="allianz-progress-step step-active"></div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <p class="col x-2">Step 4 dari 4</p>-->
<!--      <p class="text-end col-8 px-2">Data Kesehatan</p>-->
<!--    </div>-->
<!--  </div>-->
</div>
<div class="container form-container">
  <form [formGroup]="rincianDataKesehatanForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title color-light-blue bold">Keterangan Kesehatan</p>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['beratBadan'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['beratBadan'].valid}">
        <p class="title-input m-0">Berapa berat badan Anda? *</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="1" max="999" formControlName="beratBadan"
                 [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['beratBadan'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['beratBadan'].valid}">
          <span>Kg</span>
        </div>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['tinggiBadan'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['tinggiBadan'].valid}">
        <p class="title-input m-0">Berapa tinggi badan Anda? *</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="1" max="999" formControlName="tinggiBadan"
                 [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['tinggiBadan'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['tinggiBadan'].valid}">
          <span>Cm</span>
        </div>
      </div>

      <div class="my-3" *ngIf="dataPribadi.jenisKelamin == 'F'"
           [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['pregnantFlag'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['pregnantFlag'].valid}">
        <p>Apakah saat ini Anda sedang hamil? *</p>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="pregnantFlag" id="yesPregnant" value="Y"
                 [required]="dataPribadi.jenisKelamin == 'F'">
          <label class="form-check-label" for="yesPregnant">
            Ya
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="pregnantFlag" id="noPregnant" value="N"
                 [required]="dataPribadi.jenisKelamin == 'F'">
          <label class="form-check-label" for="noPregnant">
            Tidak
          </label>
        </div>
      </div>

      <div *ngIf="rincianDataKesehatanForm.controls['pregnantFlag'].value == 'Y'" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['pregnantPeriod'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['pregnantPeriod'].valid}">
        <p class="title-input m-0">Berapa usia kandungan Anda *</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="0" max="9" formControlName="pregnantPeriod"
                 [required]="rincianDataKesehatanForm.controls['pregnantFlag'].value == 'Y'"
                 [ngClass]="{'text-danger' : (rincianDataKesehatanForm.controls['pregnantPeriod'].dirty || form.submitted) && !rincianDataKesehatanForm.controls['pregnantPeriod'].valid}">
          <span>Bulan</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="rounded-3 btn-back mt-3 py-3 flex-grow-1" type="button">KEMBALI</button>
      <button class="rounded-3 btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
