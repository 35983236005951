import {Component, HostListener} from '@angular/core';
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";
import {AutoUWResponse} from "../service/model/keterangan-kesehatan.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-preview-bsi',
  templateUrl: './preview-bsi.component.html',
  styleUrls: ['./preview-bsi.component.css']
})
export class PreviewBsiComponent {
  todayDate: Date = new Date()
  productInfo: RiplayUmumModel = {};
  calculator: CalculatorResponse = {};
  autoUWResult: AutoUWResponse = {};
  pageState: string = "";
  totalPembiayaan: string = "";
  ratio: number = 0;
  kontribusiDasar: string = "";
  kontribusiExtra: string = "";
  kontribusiTotal: string = "";

  constructor(private router: Router) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonAutoUwResult = localStorage.getItem('auto-uw-result')

    if (jsonProduct) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    if (jsonCalculator) {
      this.calculator = JSON.parse(jsonCalculator);
    }

    if (jsonAutoUwResult) {
      this.autoUWResult = JSON.parse(jsonAutoUwResult)
      this.pageState = this.autoUWResult.uwStatus!;
    }

    this.kontribusiExtra = this.autoUWResult.extraPremium ? this.autoUWResult.extraPremium.toString() : "0"
    this.kontribusiDasar = this.autoUWResult.standardPremium ? this.autoUWResult.standardPremium.toString() : "0"
    this.kontribusiTotal = this.autoUWResult.totalPremium ? this.autoUWResult.totalPremium.toString() : "0"
    this.totalPembiayaan = this.autoUWResult.totalSumAssured ? this.autoUWResult.totalSumAssured.toString() : "0"
    this.ratio = (Number(this.autoUWResult.totalPremium) / Number(this.autoUWResult.totalSumAssured)) * 100
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    confirm('Data Sudah Tersubmit dan tidak dapat diubah')
  }

  backToMenu() {
    this.router.navigate(['eform/menu'])
  }

  continue() {
    this.router.navigate(['eform/pengisian-data-pribadi-bsi'])
  }
}
