import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RincianPertanggunganComponent} from "./rincian-pertanggungan.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {GlobalModule} from "../global/global.module";
import {RincianPertanggunganRoutingModule} from "./rincian-pertanggungan-routing.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RincianPertanggunganRoutingModule,
    GlobalModule,
  ],
  declarations: [
    RincianPertanggunganComponent
  ],
  providers: [
    DatePipe
  ]
})
export class RincianPertanggunganModule {
}
