import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";
import {DashboardModel, NasabahModel} from "../model/dashboard.model";

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends HttpEformService {

  constructor(protected override http: HttpClient) {
    super(http)
  }

  dashboard(userId: number, partnerId: string): Observable<DashboardModel[]> {
    let url = this.url + "nb/dashboard";
    // let url = "http://localhost:8006/eform/nb/dashboard";
    return this.http
      .post<DashboardModel[]>(url, {
        userId: userId.toString(),
        partnerId: partnerId
      });
  }

  getNasabah(referenceNumber: string): Observable<NasabahModel> {
    let url = this.url + "nb/nasabah/" + referenceNumber;
    // let url = "http://localhost:8006/eform/nb/nasabah/" + referenceNumber;
    return this.http.get<NasabahModel>(url)
      .pipe(catchError(this.handleError));
  }

  summary(referenceNo: string, partnerCode: string): Observable<DashboardModel[]> {
    let url = this.url + "spajkp-data";
    // let url = "http://localhost:8006/eform/spajkp-data";
    return this.http
      .post<DashboardModel[]>(url, { referenceNo, partnerCode });
  }
}
