import {RouterModule, Routes} from '@angular/router';
import {NgModule} from "@angular/core";
import {LayoutComponent} from "./layout/layout.component";
import {FinalDecisionComponent} from "./final-decision/final-decision.component";

const routes: Routes = [
  {
    path: 'eform',
    loadChildren: () => import('./eform.module').then((m) => m.EformModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
