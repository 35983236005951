import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {Router} from "@angular/router";
import * as pdfjsLib from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry.js';
import jsPDF from "jspdf";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

@Component({
  selector: 'app-upload-dokumen',
  templateUrl: './upload-dokumen.component.html',
  styleUrls: ['./upload-dokumen.component.css']
})
export class UploadDokumenComponent {

  uploadDokumenForm: FormGroup;
  gambarUrl: any = null;
  isCameraActive: boolean = false;
  camera: string = "user";
  spajkDokumen: File | null = null;
  riplayDokumen: File | null = null;
  isInputSpajkRiplayDirty: boolean = false;
  filenameSpajkDokumen: string = "Unggah SPAJPK Syariah yang sudah ditandatangani";
  filenameRiplayDokumen: string = "Unggah RIPLAY Personal yang sudah ditandatangani";
  productInfo: RiplayUmumModel = {};
  dokumenArray: string[] = []
  isLoading: boolean = false;
  canvasWidth: number = 0;
  canvasHeight: number = 0;
  base64Spajk: string[] = []
  base64Riplay: string[] = []

  @ViewChild("video") video!: ElementRef;
  @ViewChild("canvas") canvas!: ElementRef;
  @ViewChild("dokumenKtp") dokumenKtp!: ElementRef;
  @ViewChild("optionCamera") optionCamera!: ElementRef;
  @ViewChild('pdfCanvas') pdfCanvas!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private newCoveringService: NewCoveringService,
    private router: Router) {

    this.uploadDokumenForm = this.formBuilder.group({
      ktp: ['', Validators.required],
    })

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    let jsonSelectedDasboard = localStorage.getItem('selected-item')
    if (jsonSelectedDasboard != null) {
      const dashboard = JSON.parse(jsonSelectedDasboard);
      this.productInfo.referenceNumber = dashboard.referenceNo
    }
  }

  async mulaiCamera(cameraFacing: string) {
    this.dokumenKtp.nativeElement.style.height = "375px"
    this.gambarUrl = ""
    this.isCameraActive = true;

    let constraint: any = {
      video: {
        facingMode: {
          ideal: cameraFacing
        }
      }
    }

    this.video.nativeElement.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
    this.video.nativeElement.addEventListener('loadedmetadata', () => {
      this.canvasWidth = this.video.nativeElement.videoWidth
      this.canvasHeight = this.video.nativeElement.videoHeight
      console.log("h", this.canvasHeight);
      console.log("w", this.canvasWidth);
    });
  }

  ambilGambar(event: any) {
    event.stopPropagation();
    this.isCameraActive = false;
    this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, this.canvasWidth, this.canvasHeight);
    this.gambarUrl = this.canvas.nativeElement.toDataURL('image/jpeg');
    console.log("h", this.canvasHeight);
    console.log("w", this.canvasWidth);

    this.video.nativeElement.srcObject.stop();
    this.dokumenKtp.nativeElement.style.height = "194px"
    // this.dokumenKtp.nativeElement.style.height = this.canvasHeight + "px"
    this.uploadDokumenForm.controls['ktp'].patchValue(this.gambarUrl)
    console.log(this.gambarUrl)
    // this.canvasHeight = 0;
    // this.canvasWidth = 0;
  }

  resetFotoKtp() {
    this.gambarUrl = ""
    this.isCameraActive = false;
    this.uploadDokumenForm.controls['ktp'].patchValue(this.gambarUrl)
    if (this.video.nativeElement.srcObject) this.video.nativeElement.srcObject.stop();
  }

  resetSpajkRiplay() {
    this.filenameRiplayDokumen = "Unggah RIPLAY yang sudah ditandatangani"
    this.filenameSpajkDokumen = "Unggah SPAJK yang sudah ditandatangani"
    this.riplayDokumen = null;
    this.spajkDokumen = null;
    this.isInputSpajkRiplayDirty = false;
  }

  switchCamera() {
    this.video.nativeElement.srcObject.stop()
    this.camera = this.camera == "user" ? "environment" : "user";
    this.mulaiCamera(this.camera)
  }

  inputSpajkDokumen(event: any) {
    if (this.validateFileSize(event)) return;
    const fileInput = event.target;
    if (fileInput.files && fileInput.files[0]) {
      this.filenameSpajkDokumen = fileInput.files[0].name
      this.spajkDokumen = fileInput.files[0]
      this.isInputSpajkRiplayDirty = true;
      fileInput.value = ''
      this.base64Spajk = []
      this.compressPdf("SPAJK", this.spajkDokumen)
    }
  }

  inputRiplayDokumen(event: any) {
    if (this.validateFileSize(event)) return;
    const fileInput = event.target;
    if (fileInput.files && fileInput.files[0]) {
      this.filenameRiplayDokumen = fileInput.files[0].name
      this.riplayDokumen = fileInput.files[0]
      this.isInputSpajkRiplayDirty = true;
      fileInput.value = ''
      this.base64Riplay = []
      this.compressPdf("RIPLAY", this.riplayDokumen)
    }
  }

  validateFileSize(event: any) : boolean {
    console.log("filesize", event.target.files[0].size)
    if(event.target.files[0].size > 26214400) {
      alert("Maksimal Ukuran File adalah 25mb");
      return true;
    }
    return false;
  }

  continue() {
    this.isInputSpajkRiplayDirty = true;
    if (this.uploadDokumenForm.valid && this.riplayDokumen && this.spajkDokumen) {
      this.isLoading = true;
      this.uploadDok("SPAJK", this.convertToBlob(this.base64Spajk, "SPAJK"))
    }
  }

  uploadDok(tipe: string, body: any) {
    this.newCoveringService.uploadDocument(this.productInfo.referenceNumber!, body, tipe).subscribe(res => {
      if (res.statusId == "6" || res.statusId == "1") {
        this.dokumenArray.push(tipe + " SUCCESS")
      } else {
        this.dokumenArray.push(tipe + " FAILED")
      }
      this.uploadUlang()

    }, error => {
      this.dokumenArray.push(tipe + " FAILED")
      this.uploadUlang()
    })
  }

  uploadUlang() {
    this.isLoading = this.dokumenArray.length != 3;

    if (this.dokumenArray.length == 1) {
      this.uploadDok("RIPLAY", this.convertToBlob(this.base64Riplay, "RIPLAY"))
    }
    if (this.dokumenArray.length == 2) {
      this.uploadDok("KTPID", this.newCoveringService.dataURItoBlob(this.uploadDokumenForm.controls['ktp'].value))
    }

    if (this.dokumenArray.length == 3) {
      let success = true;
      for (const status of this.dokumenArray) {
        if (status.includes("FAILED")) {
          success = false;
          break;
        }
      }
      if (success) {
        this.router.navigate(['eform/final-dokumen'])
      } else {
        this.isLoading = false;
        alert("Upload Dokumen Gagal Mohon dicoba kembali atau hubungi pihak Allianz")
      }
    }
  }

  convertToBlob(base64Doc: string[], filename: string = 'default'): Blob {
    const doc = new jsPDF();
    for (let i = 0; i < base64Doc.length; i++) {
      if (i != 0) {
        doc.addPage()
      }
      doc.addImage(base64Doc[i], 'PNG', 0, 0, 210, 300, '', 'SLOW');
    }
    return doc.output("blob")
  }

  compressPdf(docType: string, docFile: File | null): void {
    const reader = new FileReader();
    reader.onload = () => {
      const fileContent = reader.result as string;
      this.readPdf(docType, fileContent)
    };
    reader.readAsDataURL(docFile!);
  }

  back() {
    this.router.navigate(['eform/dashboard'])
  }

  readPdf(docType: string, pdfData: string) {
    // this.base64Spajk = []
    // this.base64Riplay = []
    const loadingTask = pdfjsLib.getDocument({data: this.convertDataURIToBinary(pdfData)});
    loadingTask.promise.then((pdf) => {
      for (let i = 1; i <= pdf.numPages; i++) {
        pdf.getPage(i).then((page) => {
          this.renderPageAsImage(docType, page);
        });
      }
    });
  }

  private renderPageAsImage(docType: string, page: any) {
    const viewport = page.getViewport({scale: 1.4});
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    page.render(renderContext).promise.then(() => {
      // Convert canvas to image (Base64)
      const imgData = canvas.toDataURL('image/png');
      console.log("img", imgData)
      console.log("doctype", docType)
      if(docType == "SPAJK") {
        this.base64Spajk.push(imgData)
      }
      if(docType == "RIPLAY") {
        this.base64Riplay.push(imgData)
      }

    });
  }

  private convertDataURIToBinary(dataURI: string): Uint8Array {
    const base64 = dataURI.split(',')[1];
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
