import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-final-dokumen',
  templateUrl: './final-dokumen.component.html',
  styleUrls: ['./final-dokumen.component.css']
})
export class FinalDokumenComponent {

  todayDate: Date = new Date()
  referenceNumber: string = ""

  constructor(private router: Router) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonSelectedProduct = localStorage.getItem('selected-product')
    let jsonSelectedItem = localStorage.getItem('selected-item')
    if (jsonSelectedProduct) {
      this.referenceNumber = JSON.parse(jsonSelectedProduct).referenceNumber
    }

    if (jsonSelectedItem) {
      this.referenceNumber = JSON.parse(jsonSelectedItem).referenceNo
    }

  }

  goToDashboard() {
    this.router.navigate(['eform/dashboard'])
  }

  goToMenu() {
    this.router.navigate(['eform/menu'])
  }

}
