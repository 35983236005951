import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {RiplayUmumModel, RiplayUmumRequest} from "./riplay-umum.model";
import {Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";

@Injectable({
  providedIn: 'root'
})
export class RiplayUmumService extends HttpEformService {


  featureUrl = this.url + "riplay-umum"

  constructor(protected override http: HttpClient) {
    super(http)
  }

  getRiplay(request: RiplayUmumRequest): Observable<RiplayUmumModel[]> {
    return this.http
      .post<RiplayUmumModel[]>(this.featureUrl, request);
  }
}
