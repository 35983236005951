import {Component, ElementRef, ViewChild} from '@angular/core';
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";
import {NbSubmitResponse} from "../service/model/keterangan-kesehatan.model";
import {Router} from "@angular/router";
import {NewBusinessProcessingResponse} from "../service/model/new-business-processing.model";
import {DownloadLetterResponse} from "../service/model/download-response.model";
import {LoginModel} from "../login/shared/login.model";
import {DownloadDocumentService} from "../service/download-document/download-document.service";

@Component({
  selector: 'app-final-bsi',
  templateUrl: './final-bsi.component.html',
  styleUrls: ['./final-bsi.component.css']
})
export class FinalBsiComponent {

  @ViewChild('btnAcceptance')
  btnAcceptance!: ElementRef;

  @ViewChild('btnAcceptance2')
  btnAcceptance2!: ElementRef;

  todayDate: Date = new Date()
  productInfo: RiplayUmumModel = {};
  newCoveringResponse: NbSubmitResponse = {};
  calculator: CalculatorResponse = {};
  newBusinessProcessing: NewBusinessProcessingResponse = {}
  pageState: string = "";
  downloadResponseModel: DownloadLetterResponse = {}
  login: LoginModel = {}
  isLoadingTagihan: boolean = false;
  isLoadingAkseptasi: boolean = false;

  constructor(
    private router: Router,
    private documentService: DownloadDocumentService
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonNBProcessingResponse = localStorage.getItem('new-business-processing')
    let jsonDashboard = localStorage.getItem('selected-item')
    let jsonLogin = sessionStorage.getItem('login')

    if (jsonProduct) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    if (jsonLogin) {
      this.login = JSON.parse(jsonLogin);
    }

    if (jsonCalculator) {
      this.calculator = JSON.parse(jsonCalculator);
    }

    if (jsonDashboard) {
      this.productInfo.referenceNumber = JSON.parse(jsonDashboard).referenceNo
    }

    if (jsonNBProcessingResponse) {
      this.newBusinessProcessing = JSON.parse(jsonNBProcessingResponse)
      if (this.newBusinessProcessing.memberStatus) {
        this.pageState = this.newBusinessProcessing.memberStatus!;
      } else {
        this.router.navigate(['eform/error'])
      }
    } else {
      this.router.navigate(['eform/error'])
    }
  }

  downloadAkseptasi() {
    this.documentService.downloadDocument({
      referenceNo: this.productInfo.referenceNumber,
      docType: "ACCEPTANCE"
    }).subscribe(res => {
      this.btnAcceptance.nativeElement.download = 'Surat Akseptasi ' + this.productInfo.referenceNumber + '.pdf'
      this.btnAcceptance.nativeElement.href = 'data:application/octet-stream;base64,' + res.document;
      this.btnAcceptance.nativeElement.click()
    })
  }

  downloadTagihan() {
    this.documentService.downloadDocument({
      referenceNo: this.productInfo.referenceNumber,
      docType: "INVOICE"
    }).subscribe(res => {
      this.btnAcceptance.nativeElement.download = 'Surat Tagihan ' + this.productInfo.referenceNumber + '.pdf'
      this.btnAcceptance.nativeElement.href = 'data:application/octet-stream;base64,' + res.document;
      this.btnAcceptance.nativeElement.click()
    })
  }

  backToMenu() {
    this.router.navigate(['eform/menu'])
  }

  continueDashboard() {
    this.router.navigate(['eform/dashboard'])
  }

  uploadDokumen() {
    this.router.navigate(['eform/upload-dokumen'])
  }

  continue() {
    this.router.navigate(['eform/pengisian-data-pribadi-bsi'])
  }
}
