<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img *ngIf="isItShariaProduct" src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
    <img *ngIf="!isItShariaProduct" src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Pengisian Data Pribadi</b></h2>
    <h6 class="text-center">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <app-form-header *ngIf="productInfo.partnerId == environment.partnerIdBTPN" formHeaderTitle="Pengisian Kontak Calon Pihak Yang Diasuransikan" [step]="3" [dariStep]="4"></app-form-header>
  <app-form-header *ngIf="productInfo.partnerId == environment.partnerIdBSI" formHeaderTitle="Pengisian Kontak Calon Pihak Yang Diasuransikan" [step]="4" [dariStep]="4"></app-form-header>
</div>
<div class="container form-container pb-3">
  <form [formGroup]="kontakPribadiForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title color-light-blue bold">Kontak Calon Pihak Yang Diasuransikan</p>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['nomorHPPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['nomorHPPribadi'].valid}">
        <p class="title-input m-0">Nomor HP</p>
        <input class="bottom-border" type="text" formControlName="nomorHPPribadi"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['nomorHPPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['nomorHPPribadi'].valid}">
        <p class="description-input">Pastikan nomor ponsel Anda dapat dihubungi</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['emailPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['emailPribadi'].valid}">
        <p class="title-input m-0">Alamat Email</p>
        <input class="bottom-border" type="text" formControlName="emailPribadi"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['emailPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['emailPribadi'].valid}">
      </div>

      <p class="content-title color-light-blue bold mt-4">Alamat Sesuai Identitas</p>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['alamatPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['alamatPribadi'].valid}">
        <p class="title-input m-0">Alamat Rumah*</p>
        <input class="bottom-border" type="text" formControlName="alamatPribadi" (change)="copyInputAddress('alamatPribadi', 'alamatDomisili')"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['alamatPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['alamatPribadi'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kodePosPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['kodePosPribadi'].valid}">
        <p class="title-input m-0">Kode Pos*</p>
        <input class="bottom-border" type="number" formControlName="kodePosPribadi" (change)="copyInputAddress('kodePosPribadi', 'kodePosDomisili')"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kodePosPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['kodePosPribadi'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['provinsiPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['provinsiPribadi'].valid}">
        <p class="title-input m-0">Provinsi*</p>
        <input class="bottom-border" type="text" formControlName="provinsiPribadi" (change)="copyInputAddress('provinsiPribadi', 'provinsiDomisili')"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['provinsiPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['provinsiPribadi'].valid}"
               (input)="onInput($event, 'provinsiPribadi')">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kotaPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['kotaPribadi'].valid}">
        <p class="title-input m-0">Kota*</p>
        <input class="bottom-border" type="text" formControlName="kotaPribadi" (change)="copyInputAddress('kotaPribadi', 'kotaDomisili')"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kotaPribadi'].dirty || form.submitted) && !kontakPribadiForm.controls['kotaPribadi'].valid}"
               (input)="onInput($event, 'kotaPribadi')">
      </div>

      <p class="content-title color-light-blue bold mt-4" >Alamat Sesuai Domisili</p>
      <div class="d-flex flex-gap mb-2">
        <input type="checkbox" formControlName="alamatSama" (change)="toggleSameAddress($event)" id="alamatSama">
        <label for="alamatSama">Alamat Domisili sama dengan Alamat Identitas</label>
      </div>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['alamatDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['alamatDomisili'].valid}">
        <p class="title-input m-0">Alamat Rumah</p>
        <input class="bottom-border" type="text" formControlName="alamatDomisili" [readOnly]="kontakPribadiForm.controls['alamatSama'].value"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['alamatDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['alamatDomisili'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kodePosDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['kodePosDomisili'].valid}">
        <p class="title-input m-0">Kode Pos</p>
        <input class="bottom-border" type="number" formControlName="kodePosDomisili" [readOnly]="kontakPribadiForm.controls['alamatSama'].value"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kodePosDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['kodePosDomisili'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['provinsiDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['provinsiDomisili'].valid}">
        <p class="title-input m-0">Provinsi</p>
        <input class="bottom-border" type="text" formControlName="provinsiDomisili" [readOnly]="kontakPribadiForm.controls['alamatSama'].value"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['provinsiDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['provinsiDomisili'].valid}"
               (input)="onInput($event, 'provinsiDomisili')">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kotaDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['kotaDomisili'].valid}">
        <p class="title-input m-0">Kota</p>
        <input class="bottom-border" type="text" formControlName="kotaDomisili" [readOnly]="kontakPribadiForm.controls['alamatSama'].value"
               [ngClass]="{'text-danger' : (kontakPribadiForm.controls['kotaDomisili'].dirty || form.submitted) && !kontakPribadiForm.controls['kotaDomisili'].valid}"
               (input)="onInput($event, 'kotaDomisili')">
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn-back rounded-3 mt-3 py-3 flex-grow-1" type="button" (click)="saveDraft()">SIMPAN</button>
<!--      <button class="btn btn-back mt-3 py-3 flex-grow-1" type="button">SIMPAN</button>-->
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
