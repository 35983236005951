import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DraftSavedRoutingModule } from "./draft-saved-routing.module";
import { GlobalModule } from "../global/global.module";
import { DraftSavedComponent } from "./draft-saved.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DraftSavedRoutingModule,
    GlobalModule
  ],
  declarations: [
    DraftSavedComponent
  ]
})
export class DraftSavedModule { }
