<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5 color-light-blue"><b>Pernyataan Tandatangan</b></h2>
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
    <div class="info-box d-flex rounded-3" role="alert">
      <i class="bi-info-circle"></i>
      <p>Untuk verifikasi permohonan pertanggungan anda, kami membutuhkan video pernyataan penandatanganan E-SPAJK dari Nasabah anda. Mohon ikuti Instruksi pada Halaman ini.

      </p>
    </div>
  </div>
</div>
<div class="container form-container bg-white">
  <div class="bg-white p-2 form-content">
    <div class="d-flex justify-content-between">
      <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
    </div>
    <p>Video akan diambil langsung di halaman ini, dan berdurasi maksimum 15 detik.</p>
    <ol>
      <li>Tekan Tombol Record Jika sudah siap untuk memulai rekaman.</li>
      <li>Tekan Stop untuk menghentikan rekaman. Rekaman akan otomatis terhenti setelah 15 detik.</li>
    </ol>
    <p>Jika anda ingin mengulang rekaman, anda bisa menekan tombol Record kembali dan rekaman sebelumnya akan terhapus.</p>

    <div class="video-wrapper">
      <video #videoElement class="video" autoplay controls playsinline>
        <source [src]="videoBlobUrl" />
      </video>
      <div class="button-controller-video">
        <button class="btn btn-continue mx-1" *ngIf="state === 'NONE'" (click)="startRecording()">
          Rekam
        </button>
        <button class="btn btn-continue mx-1" *ngIf="state === 'RECORDING'" (click)="stopRecording()">
          Berhenti
        </button>
        <button class="btn btn-continue mx-1" *ngIf="state === 'RECORDED'" (click)="downloadRecording()">
          Unduh
        </button>
        <button class="btn btn-continue mx-1" *ngIf="state === 'RECORDED'" (click)="clearRecording()">
          Ulangi
        </button>
      </div>
    </div>
    <div class="mt-3">
      <label class="form-check-label mb-3 text-center">
        Saya <b>{{dataPribadi.namaLengkap}}</b>, pada {{this.todayDate | date:"dd - MMMM - Y"}}, dengan ini menyatakan telah mengisi SPAJK elektronik ini untuk <b>{{productInfo.productname}}</b> secara lengkap dan benar dan telah menandatangani secara elektronik SPAJK elektronik ini

      </label>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-gap">
    <button class="rounded-2 btn-continue mt-3 flex-grow-1" type="submit" (click)="continue()" [disabled]="this.isLoading">
      <span *ngIf="this.isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      LANJUT</button>
  </div>
</div>
