<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title color-light-blue">
    <h2 class="text-center mt-5"><b>Pengisian Data Pribadi</b></h2>
    <h6 class="text-center">No Aplikasi : {{isFromDashboard ? nasabah.nasabahNewCovering.referenceNo : productInfo.referenceNumber}}</h6>

  </div>
  <div class="container mt-5 pt-4 form-header">
    <div class="row d-flex justify-content-around">
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-inactive"></div>
    </div>
    <div class="row">
      <p class="col x-2">Step 3 dari 4</p>
      <p class="text-end col-8 px-2">Pengisian Data Pribadi</p>
    </div>
  </div>
</div>
<div class="container form-container">
  <form [formGroup]="dataPribadiForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title">Data Diri Calon Tertanggung</p>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['nik'].dirty || form.submitted) && !dataPribadiForm.controls['nik'].valid}">
        <p class="title-input m-0">NIK *</p>
        <input class="bottom-border" type="number" formControlName="nik"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['nik'].dirty || form.submitted) && !dataPribadiForm.controls['nik'].valid}">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Kewarganegaraan</p>
        <input class="bottom-border" formControlName="kewarganegaraan" type="text">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaLengkap'].dirty || form.submitted) && !dataPribadiForm.controls['namaLengkap'].valid}">
        <p class="title-input m-0">Nama Lengkap *</p>
        <input class="bottom-border" type="text" formControlName="namaLengkap" maxlength="200"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['namaLengkap'].dirty || form.submitted) && !dataPribadiForm.controls['namaLengkap'].valid}">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Nama Alias</p>
        <input class="bottom-border" type="text" formControlName="namaAlias" maxlength="200">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['jenisKelamin'].dirty || form.submitted) && !dataPribadiForm.controls['jenisKelamin'].valid}">
        <p class="title-input m-0">Jenis Kelamin *</p>
        <select class="bottom-border" formControlName="jenisKelamin">
          <option *ngFor="let jenisKelamin of listJenisKelamin" [value]="jenisKelamin.value">
            {{jenisKelamin.jenisKelamin}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['statusPerkawinan'].dirty || form.submitted) && !dataPribadiForm.controls['statusPerkawinan'].valid}">
        <p class="title-input m-0">Status Perkawinan *</p>
        <select class="bottom-border" formControlName="statusPerkawinan">
          <option *ngFor="let statusPerkawinan of listStatusPerkawinan" [value]="statusPerkawinan.value">
            {{statusPerkawinan.statusPerkawinan}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['tempatLahir'].dirty || form.submitted) && !dataPribadiForm.controls['tempatLahir'].valid}">
        <p class="title-input m-0">Tempat Lahir *</p>
        <input class="bottom-border" type="text" formControlName="tempatLahir" maxlength="50"
               [ngClass]="{'text-danger' : (dataPribadiForm.controls['tempatLahir'].dirty || form.submitted) && !dataPribadiForm.controls['tempatLahir'].valid}">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Usia</p>
        <input class="bottom-border" type="text" formControlName="usia">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (dataPribadiForm.controls['perkerjaan'].dirty || form.submitted) && !dataPribadiForm.controls['perkerjaan'].valid}">
        <p class="title-input m-0">Pekerjaan *</p>
        <select class="bottom-border" formControlName="perkerjaan">
          <option *ngFor="let pekerjaan of listPekerjaan" [value]="pekerjaan">
            {{pekerjaan}}
          </option>
        </select>
      </div>


      <div class="d-flex justify-content-between my-3 align-content-center">
        <div>
          <p [ngClass]="{'text-danger' : (dataPribadiForm.controls['fileInput'].dirty || form.submitted) && !dataPribadiForm.controls['fileInput'].valid}">
            Foto Dokumen Identitas Diri *
          </p>
        </div>
        <div>
          <button type="button" class="btn-reset bg-dark-blue py-0" (click)="resetFotoKtp()"><i class="bi-repeat"></i> Ulang</button>
          <button type="button" class="btn-reset bg-dark-blue py-0" (click)="switchCamera()"><i class="bi-repeat"></i> Ganti Kamera</button>
        </div>
      </div>
      <div class="d-flex flex-column">
          <div #dokumenKtp class="rounded-3 py-5 mb-3" (click)="mulaiCamera(this.camera)"
               [ngStyle]="{ 'background-image': 'url(' + gambarUrl + ')',
                            'background-size': 'contain',
                            'background-color': '#eaeaea',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center'}">

            <div class="d-flex flex-column flex-gap justify-content-center align-items-center">
              <video [hidden]="!isCameraActive" #video id="video" width="320" height="240" playsinline autoplay></video>
              <button [hidden]="!isCameraActive" class="btn btn-continue mx-1" type="button" id="click-photo" (click)="ambilGambar($event)">Foto</button>
              <canvas [hidden]="true" #canvas id="canvas" [width]="canvasWidth" [height]="canvasHeight"></canvas>
            </div>

            <div *ngIf="!isCameraActive && !gambarUrl" class="d-flex flex-column flex-gap justify-content-center align-items-center">
              <img src="assets/Camera.png" height="48" width="48">
              <p class="text-center">Foto Dokumen Identitas Diri Anda</p>
            </div>
          </div>

        <div>
          <b class="mb-1">Ketentuan Foto</b>
          <ol>
            <li>Foto harus jelas dan tidak blur</li>
            <li>Menggunakan Foto asli dan bukan fotokopi</li>
            <li>Pastikan hasil foto telah sesuai dengan contoh</li>
          </ol>
        </div>
        <p class="mb-0">Contoh Foto:</p>
        <div class="contoh-foto d-flex flex-gap">
          <div>
            <img src="assets/Bitmap.png">
            <p class="bi-check-circle-fill"> Benar</p>
          </div>
          <div>
            <img src="assets/Bitmap-1.png">
            <p class="bi-x-circle-fill"> Salah</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
<!--      <button class="btn-back rounded-3 mt-3 py-3 flex-grow-1" type="button">SIMPAN</button>-->
      <button class="btn-continue rounded-3 mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
