<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5 color-blue"><b>ELLISE Sales Dashboard</b></h2>
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-2">
  <div class="bg-white form-content pb-3 align-content-center mb-4">
    <p (click)="back()" class="px-2 cursor-pointer text-primary"><i class="bi bi-arrow-left"></i> Kembali</p>
    <div class="px-3">
      <h6 class="text-primary">Calon Pihak Yang Diasuransikan</h6>
      <div class="row fs-14">
        <div class="col">Nama Calon Pihak Yang Diasuransikan</div>
        <div class="col text-end">{{summary.fullName}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Tanggal Lahir</div>
        <div class="col text-end">{{summary.dob | date:"dd-M-Y"}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Jenis Kelamin</div>
        <div class="col text-end">
          <span *ngIf="'F' === summary.gender">Wanita</span>
          <span *ngIf="'M' === summary.gender">Pria</span>
        </div>
      </div>
      <div class="row fs-14">
        <div class="col">Umur</div>
        <div class="col text-end">{{summary.entryAge}} Tahun</div>
      </div>
      <div class="row fs-14">
        <div class="col">Masa Pertanggungan</div>
        <div class="col text-end">{{summary.tenure}} Bulan</div>
      </div>
      <div class="row fs-14">
        <div class="col">No Aplikasi</div>
        <div class="col text-end">{{summary.applicationNo}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Tanggal Mulai Asurannsi</div>
        <div class="col text-end">{{summary.effectiveDate | date:"dd-M-Y"}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Tanggal Akhir Asuransi</div>
        <div class="col text-end">{{summary.maturityDate | date:"dd-M-Y"}}</div>
      </div>
      <div class="row fs-14 mb-3">
        <div class="col">Kantor Cabang</div>
        <div class="col text-end">{{summary.branchDescription}}</div>
      </div>
      <h6 class="text-primary">Rincian Tagihan</h6>
      <div class="row fs-14">
        <div class="col">Nama Produk</div>
        <div class="col text-end">{{summary.loanTypeDesc}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Jenis Produk</div>
        <div class="col text-end">Asuransi Jiwa Pembiayaan Kumpulan</div>
      </div>
      <div class="row fs-14">
        <div class="col">Total Manfaat Asuransi</div>
        <div class="col text-end">{{summary.totalSumAssured | currency: 'IDR' : 'symbol' : '4.0-0'}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Total Kontribusi</div>
        <div class="col text-end">{{summary.totalPremium | currency: 'IDR' : 'symbol' : '4.0-0'}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Kontribusi Extra</div>
        <div class="col text-end">{{summary.extraPremi | currency: 'IDR' : 'symbol' : '4.0-0'}}</div>
      </div>
      <div class="row fs-14">
        <div class="col">Kontribusi Akhir</div>
        <div class="col text-end">{{summary.totalPremium+summary.extraPremi | currency: 'IDR' : 'symbol' : '4.0-0'}}
        </div>
      </div>
      <div class="row fs-14">
        <div class="col">Total Kontribusi/Total Pembiayaan</div>
        <div class="col text-end">{{(summary.totalPremium / summary.totalSumAssured) * 100 | number : '1.2-2'}} %
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedItem.status == 'APPROVED'">
    <button class="btn button-outline-primary w-100 mb-2" type="button" (click)="downloadAcceptanceLetter()">
      <span *ngIf="!isLoading[0]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Surat Akeptasi</span>
      <div *ngIf="isLoading[0]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-100 mb-2" type="button" (click)="downloadBill()">
      <span *ngIf="!isLoading[1]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Surat Tagihan</span>
      <div *ngIf="isLoading[1]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-100 mb-2" type="button" (click)="downloadRiplay()">
      <span *ngIf="!isLoading[2]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Riplay Personal</span>
      <div *ngIf="isLoading[2]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-100 mb-2" type="button" (click)="downloadSPAJK()">
      <span *ngIf="!isLoading[3]"><i class="bi bi-arrow-down fw-bold"></i> Unduh SPAJKP Syariah</span>
      <div *ngIf="isLoading[3]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
  </div>
  <div *ngIf="selectedItem.status == 'REJECTED'">
    <button class="btn button-outline-primary w-100 mb-2" type="button" (click)="downloadRejectionLetter()">
      <span *ngIf="!isLoading[0]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Surat Penolakan</span>
      <div *ngIf="isLoading[0]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-50 mb-2" type="button" (click)="downloadRiplay()">
      <span *ngIf="!isLoading[2]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Riplay Personal</span>
      <div *ngIf="isLoading[2]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-50 mb-2" type="button" (click)="downloadSPAJK()">
      <span *ngIf="!isLoading[3]"><i class="bi bi-arrow-down fw-bold"></i> Unduh SPAJKP Syariah</span>
      <div *ngIf="isLoading[3]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
  </div>
  <div *ngIf="selectedItem.status == 'SUBMITTED' || selectedItem.status == 'REFERUW'">
    <p class="bg-light rounded border shadow color-light-blue px-3 py-2 text-center fs-14"> Silahkan cetak SPAJK Syariah dan RIPLAY
      Personal untuk ditandatangani oleh calon PYD dan harap menghubungi PIC Allianz untuk informasi lebih lanjut.</p>
    <button class="btn button-outline-primary w-50 mb-2" type="button" (click)="downloadRiplay()">
      <span *ngIf="!isLoading[2]"><i class="bi bi-arrow-down fw-bold"></i> Unduh Riplay Personal</span>
      <div *ngIf="isLoading[2]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
    <button class="btn button-outline-primary w-50 mb-2" type="button" (click)="downloadSPAJK()">
      <span *ngIf="!isLoading[3]"><i class="bi bi-arrow-down fw-bold"></i> Unduh SPAJKP Syariah</span>
      <div *ngIf="isLoading[3]" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </button>
  </div>
</div>