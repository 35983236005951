<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
    <div class="info-box d-flex rounded-3" role="alert">
      <i class="bi-info-circle"></i>
      <p>Untuk verifikasi permohonan pertanggungan anda, kami membutuhkan Foto Nasabah anda beserta Tenaga Pemasar dari Pihak Bank. Mohon ikuti Instruksi pada Halaman ini.

      </p>
    </div>
  </div>
</div>
<div class="container form-container bg-white">
  <div class="bg-white p-2 form-content">
    <div class="d-flex justify-content-between">
      <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
    </div>
    <p>Foto akan diambil langsung di halaman ini</p>
    <ol>
      <li>Tekan Tombol Foto jika sudah siap untuk memulai berfoto bersama.</li>
      <li>Pastikan hasilnya bisa di lihat secara jelas.</li>
    </ol>
    <p>Jika anda ingin mengulang foto, anda bisa menekan tombol Ulang dan foto sebelumnya akan terhapus.</p>

    <div class="d-flex justify-content-between my-3 align-content-center">
      <div>
        <p [ngClass]="{'text-danger' : gambarUrl == ''}">
          Foto Nasabah Dengan Staff Bank *
        </p>
      </div>
      <div>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="resetFotoKtp()"><i class="bi-repeat"></i> Ulang</button>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="switchCamera()"><i class="bi-repeat"></i> Ganti Kamera</button>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div #dokumenNasabahDenganSales class="rounded-3 py-5 mb-3" (click)="mulaiCamera(this.camera)"
           [ngStyle]="{ 'background-image': 'url(' + gambarUrl + ')',
                            'background-size': 'contain',
                            'background-color': '#eaeaea',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center'}">

        <div class="d-flex flex-column flex-gap justify-content-center align-items-center">
          <video [hidden]="!isCameraActive" #video id="video" width="320" height="240" playsinline autoplay></video>
          <button [hidden]="!isCameraActive" class="btn btn-continue mx-1" type="button" id="click-photo" (click)="ambilGambar($event)">Foto</button>
          <canvas [hidden]="true" #canvas id="canvas" [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>

        <div *ngIf="!isCameraActive && !gambarUrl" class="d-flex flex-column flex-gap justify-content-center align-items-center">
          <img src="assets/Camera.png" height="48" width="48">
          <p class="text-center">Foto Nasabah dengan Staff Bank</p>
        </div>
      </div>

      <div>
        <b class="mb-1">Ketentuan Foto</b>
        <ol>
          <li>Foto harus jelas dan tidak buram</li>
          <li>Menggunakan foto secara langsung</li>
          <li>Pastikan hasil foto telah sesuai dengan contoh</li>
        </ol>
      </div>
      <p class="mb-0">Contoh Foto:</p>
      <div class="contoh-foto d-flex flex-gap ">
        <div class="mx-3 d-flex flex-column justify-content-center align-items-center">
          <img src="assets/Foto-Nasabah-Sales-1.png" class="crop-image-guide-foto">
          <p class="bi-check-circle-fill"> Benar</p>
        </div>
        <div class="mx-3 d-flex flex-column justify-content-center align-items-center">
          <img src="assets/Foto-Nasabah-Sales-2.png" class="crop-image-guide-foto-dua">
          <p class="bi-x-circle-fill"> Salah</p>
        </div>
        <div class="mx-3 d-flex flex-column justify-content-center align-items-center">
          <img src="assets/Foto-Nasabah-Sales-3.png" class="crop-image-guide-foto-tiga">
          <p class="bi-x-circle-fill"> Salah</p>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-center flex-gap">
    <button class="rounded-2 btn-continue mt-3 flex-grow-1" type="submit" (click)="continue()">
<!--      <span *ngIf="this.isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
      LANJUT</button>
  </div>
</div>
