import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';

@Directive({
  selector: '[appLimitCharacter]'
})
export class LimitCharacterDirective {
  private _limit: number = 16;

  @Input() set limitCharacter(value: number) {
    this._limit = value || 16;
  }
  //
  // @Input() set exclude(value: number) {
  //   this._limit = value || 16;
  // }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputElement = this.el.nativeElement;
    let value = inputElement.value;

    value = value.replace(/[^a-zA-Z]/g, '');

    inputElement.value = value;
  }

}
