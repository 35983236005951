import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {VideoPernyataanRoutingModule} from "./video-pernyataan-routing.module";
import {GlobalModule} from "../global/global.module";
import {AngularSignaturePadModule} from "@almothafar/angular-signature-pad";
import {VideoPernyataanComponent} from "./video-pernyataan.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    VideoPernyataanRoutingModule,
    GlobalModule,
    AngularSignaturePadModule,
  ],
  declarations: [
    VideoPernyataanComponent
  ]
})
export class VideoPernyataanModule {

}
