import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from '../service/dashboard/dashboard.service';
import {DownloadDocumentService} from "../service/download-document/download-document.service";
import {environment} from "../../environments/environment";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-insured-summary',
  templateUrl: './insured-summary.component.html',
  styleUrls: ['./insured-summary.component.css']
})
export class InsuredSummaryComponent {

  public summary: any = {};
  public isLoading = [false, false, false, false];
  public todayDate: Date = new Date();
  public selectedItem: any;
  private referenceNo: string;
  public partnerId: string;
  public nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  }


  constructor(private location: Location,
              private activatedRoute: ActivatedRoute,
              private dashboardService: DashboardService,
              private downloadDocumentService: DownloadDocumentService,
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonSelectedItem = localStorage.getItem('selected-item')
    if (jsonSelectedItem) {
      this.selectedItem = JSON.parse(jsonSelectedItem)
    }
    this.referenceNo = this.activatedRoute.snapshot.paramMap.get('referenceNo') || "";
    this.partnerId = this.activatedRoute.snapshot.paramMap.get('partnerCode') || "";
  }

  ngOnInit() {
    if (this.partnerId == environment.partnerIdBSI) {
      this.dashboardService.summary(this.referenceNo, this.partnerId).subscribe(res => {
        this.summary = res;
      })
    } else {
      this.dashboardService.getNasabah(this.referenceNo).subscribe(res => {
        this.nasabah = res
        this.summary = {
          fullName: res.nasabahNewCovering.fullName,
          dob: res.nasabahNewCovering.dob,
          gender: res.nasabahNewCovering.gender,
          entryAge: res.nasabahNewCovering.entryAge,
          tenure: res.nasabahNewCovering.tenure,
          applicationNo: res.nasabahNewCovering.referenceNo,
          effectiveDate: res.nasabahNewCovering.effectiveDate,
          maturityDate: res.nasabahNewCovering.maturityDate,
          branchDescription: res.nasabahNewCovering.branchDescription,
          loanTypeDesc: res.nasabahNewCovering.productName,
          totalSumAssured: res.nasabahNewCovering.sumAssured,
          standardPremi: res.nasabahNewCovering.standardPremi,
          extraPremi: res.nasabahNewCovering.extraPremi,
          totalPremium: res.nasabahNewCovering.standardPremi + res.nasabahNewCovering.extraPremi!,
        }
      })
    }
  }

  public back(): void {
    this.location.back();
  }

  public downloadAcceptanceLetter(): void {
    this.isLoading[0] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "ACCEPTANCE"
    }).subscribe(res => {
      this.downloadDocument('Akseptasi', res.document)
      this.isLoading[0] = false;
    })
  }

  public downloadRejectionLetter(): void {
    this.isLoading[0] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "REJECTED"
    }).subscribe(res => {
      this.downloadDocument('Penolakan', res.document)
      this.isLoading[0] = false;
    })
  }

  public downloadBill(): void {
    this.isLoading[1] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "INVOICE"
    }).subscribe(res => {
      this.downloadDocument('Tagihan', res.document)
      this.isLoading[1] = false;
    })
  }

  public downloadRiplay(): void {
    this.isLoading[2] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "RIPLAY"
    }).subscribe(res => {
      this.downloadDocument('RIPLAY', res.document)
      this.isLoading[2] = false;
    })
  }

  public downloadSPAJK(): void {
    this.isLoading[3] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "SPAJK"
    }).subscribe(res => {
      this.downloadDocument('SPAJKP', res.document)
      this.isLoading[3] = false;
    })
  }

  public downloadRiplayUnsigned(): void {
    this.isLoading[2] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "RIPLAYU"
    }).subscribe(res => {
      this.downloadDocument('RIPLAYU', res.document)
      this.isLoading[2] = false;
    })
  }

  public downloadSPAJKUnsigned(): void {
    this.isLoading[3] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "SPAJKU"
    }).subscribe(res => {
      this.downloadDocument('SPAJPKU', res.document)
      this.isLoading[3] = false;
    })
  }

  downloadDocument(filename: string, base64: string | undefined) {
    if (base64) {
      const link = document.createElement("a")
      link.href = 'data:application/octet-stream;base64,' + base64;
      link.download = 'Surat ' + filename + ' ' + this.referenceNo + this.extensionFile(base64)
      link.click()
      link.remove()
    } else {
      alert("Dokumen belum tersedia")
    }
  }

  extensionFile(base64: string) {
    const magicNumbers = {
      'JVBERi': '.pdf',
      'iVBORw': '.png',
      '/9j': '.jpg'
    }

    const firstFewChars = base64.substring(0, 10);
    for (const signature in magicNumbers) {
      if (firstFewChars.startsWith(signature)) {
        console.log(magicNumbers[signature])
        return magicNumbers[signature]
      }
    }
    return ".pdf"
  }

  protected readonly environment = environment;
}
