import {Component, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CalculatorService} from "./shared/calculator.service";
import {CalculatorResponse} from "./shared/calculator.model";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-rincian-pertanggungan',
  templateUrl: './rincian-pertanggungan.component.html',
  styleUrls: ['./rincian-pertanggungan.component.css']
})
export class RincianPertanggunganComponent {

  queryParam: any;
  productInfo: RiplayUmumModel = {};
  rincianForm: FormGroup;
  calculatorResponse: CalculatorResponse = {}
  masaPertanggunganBulan: any[] = [];

  today: Date = new Date();
  minDate: string = "";
  maxDate: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private calculatorService: CalculatorService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private datePipe: DatePipe
  ) {
    this.rincianForm = formBuilder.group({
      dob: ['', [Validators.required]],
      nomorRekening: [''],
      uangPertanggungan: ['', [Validators.required, this.uangPertanggunganMinMaxValidator()]],
      masaPertanggungan: ['', [Validators.required]],
      bungaPinjaman: [{value: 20, disabled: true}],
      effectiveDate: ['', [Validators.required, this.effectiveDateValidator()]],
      diskonPremi: [{value: '', disabled: true}],
      premiDasar: ['', Validators.required],
      emailKoresponden: ['Y'],
      eSertifikat: ['Y'],
    })

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.queryParam = {
      productcode: this.route.snapshot.queryParamMap.get('productcode'),
      partnerid: this.route.snapshot.queryParamMap.get('partnerid')
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    for (let i = 6; i <= 180; i += 6) {
      this.masaPertanggunganBulan.push(i)
    }

    this.calculateMinMaxDates()
  }

  continue() {
    this.validateCalculator()
    if (this.rincianForm.valid) {
      this.router.navigate(["eform/pengisian-data-pribadi"], {
        queryParams: this.queryParam
      });
    } else if (this.calculatorResponse.statusId != null) {
      alert(this.calculatorResponse.statusDescription)
    }
  }

  validateCalculator() {
    this.formControl['uangPertanggungan'].patchValue(this.formControl['uangPertanggungan'].value.replace(/[^0-9]+/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.'))
    let tanggalLahir = Number(this.formControl['dob'].value.split("-")[0])
    let tanggalLahirValid = tanggalLahir >= 1900
    let tanggalAkad = Number(this.formControl['effectiveDate'].value.split("-")[0])
    let tanggalAkadValid = tanggalAkad == new Date().getFullYear()

    if (this.formControl['dob'].valid
      && this.formControl['effectiveDate'].valid
      && this.formControl['masaPertanggungan'].valid
      && this.formControl['uangPertanggungan'].valid
      && tanggalLahirValid
      && tanggalAkadValid
    ) {
      this.calculatorService.getCalculator({
        partnerCode: this.productInfo.partnercode,
        productCode: this.productInfo.productcode,
        referenceNo: this.productInfo.referenceNumber,
        dob: this.formControl['dob'].value,
        effectiveDate: this.formControl['effectiveDate'].value,
        tenure: this.formControl['masaPertanggungan'].value,
        sumAssured: this.formControl['uangPertanggungan'].value.replace(/[^0-9]+/g, '')
      }).subscribe(res => {
        this.calculatorResponse = res;
        if (res.statusId === "1") {
          this.rincianForm.patchValue({
            premiDasar: res.standardPremium!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
            diskonPremi: res.discount,
          })
        } else {
          let errorMessage = "Mohon cek kembali masukkan anda";

          if (res.statusDescription?.includes("Invalid entry age: Input entry age is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputAge = errorArray[7].replace(".", "")
            let ageMin = errorArray[13]
            let ageMax = errorArray[15]
            errorMessage = "Umur " + inputAge + " tidak sesuai, Umur harus diantara " + ageMin + " sampai dengan " + ageMax;
          } else if (res.statusDescription?.includes("Invalid sum assured: Input sum assured is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputUang = errorArray[7].replace(".", "")
            let uangMin = errorArray[13]
            let uangMax = errorArray[15]
            errorMessage = "Uang Pertanggungan " + inputUang + " tidak sesuai, Uang pertanggungan harus diantara " + uangMin + " sampai dengan " + uangMax;
          } else if (res.statusDescription?.includes("Invalid tenure: Input tenure is")) {
            let errorArray = res.statusDescription.split(" ")
            let inputBulan = errorArray[5].replace(".", "")
            let bulanMin = errorArray[10]
            let bulanMax = errorArray[12]
            errorMessage = "Masa Pertanggungan " + inputBulan + " bulan tidak sesuai, masa pertanggungan harus diantara " + bulanMin + " bulan sampai dengan " + bulanMax + " bulan.";
          } else if (res.statusDescription?.includes("Maturity age is not in maximum coverage age")) {
            errorMessage = "Umur saat jatuh tempo dengan masa pertanggungan tidak sah"
          } else if (res.statusDescription?.includes("No Cover Matching For Partner")) {
            errorMessage = "Cover tidak ditemukan untuk produk " + this.productInfo.productname + " dengan umur dan bulan masa pertanggungan"
          } else if (res.statusDescription?.includes("Partner Code is not valid, please contact administrator")) {
            errorMessage = "Kode partner tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("Product Code is not valid, please contact administrator")) {
            errorMessage = "Kode produk tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("Partner is not valid, please contact administrator")) {
            errorMessage = "Partner dengan produk tidak sah, hubungi admin allianz"
          } else if (res.statusDescription?.includes("YYYY-MM-DD format required")) {
            errorMessage = "Masukkan tanggal tidak sah, mohon cek kembali masukkan tanggal"
          }
          alert(errorMessage);
        }
        res.premiumRate = this.formControl['bungaPinjaman'].value
        localStorage.setItem("calculator", JSON.stringify(res))
        let rincianPertanggungan = {
          ...this.rincianForm.value,
          premiDasar: this.formControl['premiDasar'].value.replaceAll(".", ""),
          uangPertanggungan: this.formControl['uangPertanggungan'].value.replaceAll(".", "")
        }
        localStorage.setItem("rincian-pertanggungan", JSON.stringify(rincianPertanggungan))
      })
    }
  }

  back() {
    this.router.navigate(['eform/riplay'], {
      queryParams: this.queryParam
    })
  }

  onKeyDown(event: any) {
    event.preventDefault();
  }

  modulusValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      if (value % 6 !== 0) {
        return {'modulusError': true};
      }
      return null;
    };
  }

  effectiveDateValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value;
      const bulanInput = new Date(value)
      bulanInput.setHours(0)
      const oneMonthBeforeStart = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
      const endOfMonth = new Date(this.today.getFullYear(), this.today.getMonth() + 2, 0);
      let validateMonth =  bulanInput >= oneMonthBeforeStart && bulanInput <= endOfMonth;
      if (value != this.today.getFullYear() && !validateMonth) {
        return {'effectiveDateError': true};
      }
      return null;
    };
  }

  uangPertanggunganMinMaxValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value = control.value.replaceAll('.', '');
      if (Number(value) > 500000000 || Number(value) < 1000000) {
        return {'uangPertanggunganError': true}
      }
      return null;
    };
  }

  calculateMinMaxDates(): void {
    const currentMonth = this.today.getMonth();
    const currentYear = this.today.getFullYear();

    // Calculate the first day of the previous month or year
    if (currentMonth === 0) {
      // If the current month is January, set the minDate to the first day of the previous year
      this.minDate = this.datePipe.transform(new Date(currentYear - 1, 11, 1), 'yyyy-MM-dd', 'id-ID')!;
    } else {
      // Otherwise, set the minDate to the first day of the previous month
      this.minDate = this.datePipe.transform(new Date(currentYear, currentMonth - 1, 1), 'yyyy-MM-dd', 'id-ID')!;
    }

    // Calculate the last day of the next month
    this.maxDate = this.datePipe.transform(new Date(currentYear, currentMonth + 2, 0), 'yyyy-MM-dd', 'id-ID')!;
  }

  get formControl() {
    return this.rincianForm.controls;
  }
}
