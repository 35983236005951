import {Injectable} from '@angular/core';
import {HttpEformService} from "../../global/HttpEformService";
import {HttpClient} from "@angular/common/http";
import {
  DownloadDocumentRequest, DownloadDocumentResponse,
  DownloadLetterRequest,
  DownloadRequestModel,
  GenerateLetterRequest
} from "../model/download-request.model";
import {Observable} from "rxjs";
import {DownloadLetterResponse, DownloadResponseModel, GenerateLetterResponse} from "../model/download-response.model";

@Injectable({
  providedIn: 'root'
})
export class DownloadDocumentService extends HttpEformService {

  constructor(protected override http: HttpClient) {
    super(http)
  }

  getAcceptenceLetter(request: DownloadRequestModel) : Observable<DownloadResponseModel>{
    let url = this.url + "acceptance";
    return this.http.
      post<DownloadResponseModel>(url, request)
  }

  generateLetterBtpn(request: any) : Observable<String>{
    let url = this.url + "nb/letters/btpn";
    // let url = "localhost:8006/eform/nb/letters/btpn";
    return this.http
      .post(url, request, { responseType: 'text' })
  }

  getRejectionLetter(request: DownloadRequestModel) : Observable<DownloadResponseModel> {
    let url = this.url + "rejection";
    return this.http.
    post<DownloadResponseModel>(url, request)
  }

  generateLetter(request: GenerateLetterRequest) : Observable<GenerateLetterResponse>{
    let url = this.url + "nb/letters";
    return this.http
      .post<GenerateLetterResponse>(url, request)
  }

  downloadLetter(request: DownloadLetterRequest) : Observable<DownloadLetterResponse> {
    let url = this.url + "nb/get/letters";
    return this.http
      .post<DownloadLetterResponse>(url, request)
  }

  downloadDocument(request: DownloadDocumentRequest) : Observable<DownloadDocumentResponse> {
    let url = this.url + "download/filenet";
    return this.http
      .post<DownloadDocumentResponse>(url, request)
  }
}
