import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  constructor(private router: Router) {
  }

  setAuthHeader(id: string | null, ellise: string | null) {
    sessionStorage.setItem("session-id", id ?? "")
    sessionStorage.setItem("session-ellise", ellise ?? "")
  }

  getAuthHeader(): any {
    let sessionId = sessionStorage.getItem("session-id")
    let sessionEllise = sessionStorage.getItem("session-ellise")
    if (sessionId || sessionEllise) {
      return {
        id: sessionId,
        ellise: sessionEllise
      };
    } else {
      alert("Session tidak ditemukan, mohon login kembali")
      this.router.navigate(['eform/login'])
      return
    }
  }
}
