import {LayoutComponent} from "../layout/layout.component";
import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {RincianPertanggunganComponent} from "./rincian-pertanggungan.component";

const routes: Routes = [
  {
    path: 'rincian-pertanggungan',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: RincianPertanggunganComponent,
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RincianPertanggunganRoutingModule { }
