import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {GlobalModule} from "../global/global.module";
import {ErrorRoutingModule} from "./error-routing.module";
import {ErrorComponent} from "./error.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ErrorRoutingModule,
    GlobalModule
  ],
  declarations: [
    ErrorComponent
  ]
})
export class ErrorModule { }
