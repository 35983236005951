import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {AuthStorageService} from "./auth-storage-service";
import {HttpEformService} from "./HttpEformService";

@Injectable()
export class ElliseInterceptor extends HttpEformService implements HttpInterceptor {

  constructor(
    protected override http: HttpClient,
    private authStorageService: AuthStorageService
  ) {
    super(http)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // return next.handle(req)

    if (req.url.endsWith('/login')) {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            const authId = event.headers.get('X-Id');
            const authEllise = event.headers.get('X-Ellise');
            this.xELLISE = authEllise ?? "";
            this.xID = authId ?? "";
            this.authStorageService.setAuthHeader(authId, authEllise);
          }
        })
      );
    } else {
      const session = this.authStorageService.getAuthHeader();
      const reqClone = req.clone({
        headers: req.headers.set('X-ID', session.id).set('X-ELLISE', session.ellise)
      })
      return next.handle(reqClone)
    }
  }
}
