import {Component} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {DashboardService} from '../service/dashboard/dashboard.service';
import {DownloadDocumentService} from "../service/download-document/download-document.service";

@Component({
  selector: 'app-insured-summary',
  templateUrl: './insured-summary.component.html',
  styleUrls: ['./insured-summary.component.css']
})
export class InsuredSummaryComponent {

  public summary: any;
  public isLoading = [false, false, false, false];
  public todayDate: Date = new Date();
  public selectedItem: any;
  private referenceNo: string;
  private partnerCode: string;


  constructor(private location: Location,
              private activatedRoute: ActivatedRoute,
              private dashboardService: DashboardService,
              private downloadDocumentService: DownloadDocumentService,
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonSelectedItem = localStorage.getItem('selected-item')
    if (jsonSelectedItem) {
      this.selectedItem = JSON.parse(jsonSelectedItem)
    }
    this.referenceNo = this.activatedRoute.snapshot.paramMap.get('referenceNo') || "";
    this.partnerCode = this.activatedRoute.snapshot.paramMap.get('partnerCode') || "";

  }

  ngOnInit() {
    this.dashboardService.summary(this.referenceNo, this.partnerCode).subscribe(res => {
      this.summary = res;
      console.table(this.summary);
    })
  }

  public back(): void {
    this.location.back();
  }

  public downloadAcceptanceLetter(): void {
    this.isLoading[0] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "ACCEPTANCE"
    }).subscribe(res => {
      this.downloadDocument('Akseptasi', res.document)
      this.isLoading[0] = false;
    })
  }

  public downloadRejectionLetter(): void {
    this.isLoading[0] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "REJECTED"
    }).subscribe(res => {
      this.downloadDocument('Penolakan', res.document)
      this.isLoading[0] = false;
    })
  }

  public downloadBill(): void {
    this.isLoading[1] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "INVOICE"
    }).subscribe(res => {
      this.downloadDocument('Tagihan', res.document)
      this.isLoading[1] = false;
    })
  }

  public downloadRiplay(): void {
    this.isLoading[2] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "RIPLAY"
    }).subscribe(res => {
      this.downloadDocument('RIPLAY', res.document)
      this.isLoading[2] = false;
    })
  }

  public downloadSPAJK(): void {
    this.isLoading[3] = true;
    this.downloadDocumentService.downloadDocument({
      referenceNo: this.referenceNo,
      docType: "SPAJK"
    }).subscribe(res => {
      this.downloadDocument('SPAJKP', res.document)
      this.isLoading[3] = false;
    })
  }

  downloadDocument(filename: string, base64: string | undefined) {
    if (base64) {
      const link = document.createElement("a")
      link.href = 'data:application/octet-stream;base64,' + base64;
      link.download = 'Surat ' + filename + ' ' + this.referenceNo + '.pdf'
      link.click()
      link.remove()
    } else {
      alert("Dokumen belum tersedia")
    }
  }
}
