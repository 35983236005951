import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RincianPertanggunganBsiRoutingModule} from "./rincian-pertanggungan-bsi-routing.module";
import {RincianPertanggunganBsiComponent} from "./rincian-pertanggungan-bsi.component";
import {GlobalModule} from "../../global/global.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RincianPertanggunganBsiRoutingModule,
    GlobalModule,
  ],
  declarations: [
    RincianPertanggunganBsiComponent
  ],
  providers: [
    DatePipe
  ]
})
export class RincianPertanggunganBsiModule {
}
