import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {GlobalModule} from "../global/global.module";
import {ForgotPasswordRoutingModule} from "./forgot-password-routing.module";
import {ForgotPasswordComponent} from "./forgot-password.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ForgotPasswordRoutingModule,
    GlobalModule
  ],
  declarations: [
    ForgotPasswordComponent
  ]
})
export class ForgotPasswordModule { }
