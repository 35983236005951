<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Ringkasan Informasi Produk dan Layanan (RIPLAY) Umum</b></h2>
  </div>
  <app-form-header formHeaderTitle="Pilih Produk & Informasi Produk" [step]="1" [dariStep]="4"></app-form-header>
</div>
<div class="container form-container">
  <div class="bg-white p-2 form-content">
    <div class="d-flex justify-content-between flex-row">
      <p (click)="back()" class="cursor-pointer"><i class="bi bi-arrow-left"></i> Kembali</p>
      <p class="text-end">(*) Semua informasi data yang harus diisi</p>
    </div>
    <div class="info-box d-flex p-3" role="alert">
      <i class="bi-info-circle"></i>
      <p>Allianz telah menyediakan Ringkasan Informasi Produk dan Layanan (Umum) ("RIPLAY Umum")</p>
    </div>
    <p>Produk *</p>
    <div class="d-flex flex-column">
      <select class="bottom-border" [(ngModel)]="selectedProductId" (change)="onChange()">
        <option *ngFor="let status of listRiplay" [value]="status.id"> {{status.productname}} </option>
      </select>
    </div>
    <div class="mb-3">
      <i class="color-light-blue"><small>Mohon pilih produk yang sesuai</small></i>
    </div>
    <div class="d-flex flex-column riplay-information">
      <app-collapsible-content headerTitle="Tentang Produk"
        additionalInfo="{{detailProduct.productdescription}}"></app-collapsible-content>
      <app-collapsible-content headerTitle="Keunggulan Produk"
        additionalInfo="{{detailProduct.productexcellence}}"></app-collapsible-content>
      <app-collapsible-content headerTitle="Manfaat Produk"
        additionalInfo="{{detailProduct.productbenefit}}"></app-collapsible-content>
      <app-collapsible-content headerTitle="Syarat & Ketentuan"
        additionalInfo="{{detailProduct.termandcondition}}"></app-collapsible-content>
    </div>
    <p class="text-center mt-4">
      <b>Klik RIPLAY Umum</b> produk di bawah ini
    </p>
    <div [class]="'d-flex justify-content-center ' + (isPDFDisabled ? 'riplay-pdf-disable' : 'riplay-pdf')"
      (click)="openPdf()">
      <img src="assets/allianz-pdf.png" height="24" />
      <p><b><u>RIPLAY Umum {{this.detailProduct.productname ?? ""}}</u></b></p>
    </div>
    <div class="agreement-consent d-flex justify-content-center mt-3">
      <input class="form-check-input opacity-100" type="checkbox" id="flexCheckDefault" [(ngModel)]="isPdfClicked"
        [disabled]="true">
      <label *ngIf="queryParam.partnerid !== '6187346'" class="form-check-label opacity-100" for="flexCheckDefault"> Dengan melanjutkan permohonan ini, Calon Pihak
        Yang Diasuransikan ("PYD") menyatakan bahwa Calon PYD: (1) telah membaca, memahami, dan menyetujui ketentuan
        produk dan RIPLAY Umum; (2) menyetujui korespondensi dilakukan melalui email yang didaftarkan oleh Bank dan/atau
        Calon PYD ke Allianz, dan; (3) menyetujui pengiriman Sertifikat Asuransi dalam versi elektronik. </label>

      <label *ngIf="queryParam.partnerid == '6187346'" class="form-check-label opacity-100" for="flexCheckDefault">Saya telah membaca, setuju dan bersedia melanjutkan</label>

    </div>
  </div>
  <div class="d-flex flex-column">
    <button class="btn-continue mt-3 py-3 rounded-3" type="submit" (click)="onSubmit()">Lanjutkan Pengajuan Perlindungan
      Asuransi</button>
  </div>
</div>
