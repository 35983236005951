<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK-syariah.png" width="357" height="auto">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div>
    <div class="text-center mb-2">
      <img src="assets/Data-Berhasil-Disimpan-Icon.png">
    </div>
    <h2 class="color-light-blue"><b>Draft Tersimpan</b></h2>
    <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd/M/Y HH:mm:ss"}}</p>
    <p class="bg-warning-subtle d-inline-block rounded-3 p-1 color-dark-orange">No Referensi: <b>{{referenceNumber}}</b></p>
    <p class="bg-light rounded border shadow color-light-blue p-1 my-3">Pengajuan telah disimpan dan dapat dilanjutkan melalui menu dashboard pada lain waktu. Akseptasi akan diterima setelah pengajuan selesai.</p>

    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle color-dark-orange mt-3 py-3 flex-grow-1" type="button" (click)="goToDashboard()">Menuju Halaman Dashboard</button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="goToMenu()">Kembali ke Halaman Awal</button>
    </div>
  </div>

</div>
