import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RiplayUmumModel } from "./shared/riplay-umum.model";
import { RiplayUmumService } from "./shared/riplay-umum.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-riplay-umum',
  templateUrl: './riplay-umum.component.html',
  styleUrls: ['./riplay-umum.component.css']
})
export class RiplayUmumComponent implements OnInit {

  dropdownOptions: string[] = [];
  queryParam: any;
  listRiplay: RiplayUmumModel[] = []
  selectedProductId: string = '0';
  detailProduct: RiplayUmumModel = {};
  isPdfClicked: boolean = false;
  isPDFDisabled: boolean = true;

  productForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private riplayService: RiplayUmumService,
    private formBuilder: FormBuilder
  ) {
    localStorage.clear()
    let product = sessionStorage.getItem('product')

    if (product != null) {
      this.queryParam = JSON.parse(product);
    }
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    this.productForm = formBuilder.group({
      id: [''],
      partnerId: [''],
      partnercode: [''],
      productcode: [''],
      productname: [''],
      productdescription: [''],
      productexcellence: [''],
      productbenefit: [''],
      termandcondition: [''],
    })
    this.getRiplayList()
  }

  ngOnInit() {
    this.dropdownOptions = this.listRiplay.map(riplay => {
      return riplay.productcode!;
    })
  }

  back() {
    this.router.navigate(['eform/menu'])
  }

  onSubmit() {
    if (this.isPdfClicked && this.detailProduct.id) {
      this.detailProduct.referenceNumber = this.detailProduct.referenceNumber?.substring(0, 19)
      localStorage.setItem("selected-product", JSON.stringify(this.detailProduct))
      localStorage.setItem("from-dashboard", "false")
      if (this.queryParam.partnerid == environment.partnerIdBSI) {
        this.router.navigate(["eform/rincian-pertanggungan-bsi"], {
          queryParams: this.queryParam
        });
      } else {
        this.router.navigate(["eform/rincian-pertanggungan"], {
          queryParams: this.queryParam
        });
      }
    } else {
      alert("Mohon pilih produk dan buka RIPLAY Umum")
    }
  }

  getRiplayList() {
    this.riplayService.getRiplay(this.queryParam).subscribe(riplay => {
      this.listRiplay = riplay
    })
  }

  onChange() {
    let index = this.listRiplay.findIndex(riplay => {
      return riplay.id == this.selectedProductId
    })
    this.detailProduct = this.listRiplay[index];
    this.productForm.patchValue(this.detailProduct)
    this.isPdfClicked = false
    this.isPDFDisabled = false
  }

  openPdf() {
    if (this.detailProduct.pdffilename) {
      this.isPdfClicked = true;
      window.open(this.detailProduct.pdffilename)
    } else {
      alert("Pilih Produk")
    }
  }

  get formControl() {
    return this.productForm.controls
  }
}
