import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  changePasswordForm: FormGroup

  constructor(private formBuilder: FormBuilder,
              private router: Router) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.changePasswordForm = formBuilder.group({
      emailStaff: ['', [Validators.required, Validators.email]],
      kataSandi: ['', [Validators.required, Validators.minLength(8)]],
      confirmKataSandi: ['', [Validators.required]]

    }, {
      validators: this.passwordValidator()
    })
  }

  passwordValidator() {
    return (formGroup: FormGroup) => {
      const kataSandi = formGroup.controls['kataSandi'];
      const confirmKataSandi = formGroup.controls['confirmKataSandi'];
      if (
        confirmKataSandi.errors &&
        !confirmKataSandi.errors['confirmedValidator']
      ) {
        return;
      }
      if (kataSandi.value !== confirmKataSandi.value) {
        confirmKataSandi.setErrors({confirmedValidator: true});
      } else {
        confirmKataSandi.setErrors(null);
      }
    };
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      this.router.navigate(["/eform/login"]);
    }
  }

}
