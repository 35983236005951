<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Rincian Data Kesehatan</b></h2>
    <h6 class="text-center">No Aplikasi : {{productInfo.referenceNumber}}</h6>
  </div>
  <div class="container mt-5 pt-4 form-header">
    <div class="row d-flex justify-content-around">
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
    </div>
    <div class="row">
      <p class="col x-2">Step 4 dari 4</p>
      <p class="text-end col-8 px-2">Data Kesehatan</p>
    </div>
  </div>
</div>
<div class="container form-container">
  <form [formGroup]="keteranganKesehatanForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title color-light-blue bold">Keterangan Kesehatan</p>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['weightLossKg'].dirty || form.submitted) && !keteranganKesehatanForm.controls['weightLossKg'].valid}">
        <p class="title-input m-0">Berapa perubahan berat badan dalam 12 bulan terakhir? *</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="0" max="99" formControlName="weightLossKg"
                 [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['weightLossKg'].dirty || form.submitted) && !keteranganKesehatanForm.controls['weightLossKg'].valid}">
          <span>Kg</span>
        </div>
      </div>

      <div class="question-container">
        <span class="question-text">Apakah Anda Merokok *</span>
        <label class="switch">
          <input type="checkbox" formControlName="smokingFlag">
          <span class="slider round"></span>
        </label>
      </div>
      <div *ngIf="keteranganKesehatanForm.controls['smokingFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['smokingPcs'].dirty || form.submitted) && !keteranganKesehatanForm.controls['smokingPcs'].valid}">
        <p class="title-input m-0">Jika "Ya" sebutkan jumlah batang per hari?</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="0" max="99" formControlName="smokingPcs"
                 [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['smokingPcs'].dirty || form.submitted) && !keteranganKesehatanForm.controls['smokingPcs'].valid}">
          <span>Batang</span>
        </div>
      </div>

      <!--  =================HEALTH QUESTION UNTUK BTPN============================ -->
      <div>
        <div class="question-container">
          <span class="question-text">Sakit dada *</span>
          <label class="switch">
            <input type="checkbox" formControlName="chestPain">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Darah tinggi *</span>
          <label class="switch">
            <input type="checkbox" formControlName="highBloodPresure">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Stroke *</span>
          <label class="switch">
            <input type="checkbox" formControlName="stroke">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Jantung & Pembuluh Darah *</span>
          <label class="switch">
            <input type="checkbox" formControlName="heartBloodVessel">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Kelainan Darah *</span>
          <label class="switch">
            <input type="checkbox" formControlName="bloodDisorder">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Leukimia *</span>
          <label class="switch">
            <input type="checkbox" formControlName="leukimia">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Paru-paru *</span>
          <label class="switch">
            <input type="checkbox" formControlName="lungs">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Kanker/Tumor *</span>
          <label class="switch">
            <input type="checkbox" formControlName="cancerTumor">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Kencing Manis *</span>
          <label class="switch">
            <input type="checkbox" formControlName="diabetic">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Kelainan Jiwa *</span>
          <label class="switch">
            <input type="checkbox" formControlName="mentalDisorders">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Segala Jenis Kelainan Otak *</span>
          <label class="switch">
            <input type="checkbox" formControlName="brainAbnormalities">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Kelenjar Getah Bening *</span>
          <label class="switch">
            <input type="checkbox" formControlName="lymphgland">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Lambung/Usus *</span>
          <label class="switch">
            <input type="checkbox" formControlName="stomachIntenstine">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Liver *</span>
          <label class="switch">
            <input type="checkbox" formControlName="liver">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Penggunaan/Ketergantungan Obat *</span>
          <label class="switch">
            <input type="checkbox" formControlName="drugAbuse">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">Penggunaan/Ketergantungan Alkohol *</span>
          <label class="switch">
            <input type="checkbox" formControlName="alcoholic">
            <span class="slider round"></span>
          </label>
        </div>

        <div class="question-container">
          <span class="question-text">AIDS/HIV *</span>
          <label class="switch">
            <input type="checkbox" formControlName="aidsHiv">
            <span class="slider round"></span>
          </label>
        </div>
      </div>


      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Jika ada penyakit lainnya, sebutkan?</p>
        <input class="bottom-border" type="text" formControlName="othersRemark">
      </div>

      <div class="question-container">
        <span class="question-text">Apakah ada anggota keluarga terdekat Anda (ayah, ibu, kakak, adik) yang menderita atau meninggal karena penyakit-penyakit di atas? *</span>
        <label class="switch">
          <input type="checkbox" formControlName="familyHealthFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['familyHealthFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['familyHealthCondition'].dirty || form.submitted) && !keteranganKesehatanForm.controls['familyHealthCondition'].valid}">
        <p class="title-input m-0">Jika "Ya" Jelaskan *</p>
        <input class="bottom-border" type="text" formControlName="familyHealthCondition"
               [required]="keteranganKesehatanForm.controls['familyHealthFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['familyHealthCondition'].dirty || form.submitted) && !keteranganKesehatanForm.controls['familyHealthCondition'].valid}">
      </div>

      <div class="question-container">
        <span class="question-text">Apakah permohonan perlindungan asuransi Anda pernah ditolak, ditangguhkan atau dikenakan kontribusi tambahan? *</span>
        <label class="switch">
          <input type="checkbox" formControlName="rejectionPolicyFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['rejectionPolicyFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['rejectionPolicyHistory'].dirty || form.submitted) && !keteranganKesehatanForm.controls['rejectionPolicyHistory'].valid}">
        <p class="title-input m-0">Jika "Ya" Jelaskan *</p>
        <input class="bottom-border" type="text" formControlName="rejectionPolicyHistory"
               [required]="keteranganKesehatanForm.controls['rejectionPolicyFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['rejectionPolicyHistory'].dirty || form.submitted) && !keteranganKesehatanForm.controls['rejectionPolicyHistory'].valid}">
      </div>
      <div class="d-flex flex-row justify-content-center flex-gap">
        <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
      </div>
    </div>
  </form>
</div>
