<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img *ngIf="isItShariaProduct" src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
    <img *ngIf="!isItShariaProduct" src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Pengisian Data Pribadi</b></h2>
    <h6 *ngIf="productInfo.partnerId == environment.partnerIdBTPN" class="text-center">No Aplikasi : {{productInfo.referenceNumber}}</h6>
    <h6 *ngIf="productInfo.partnerId !== environment.partnerIdBTPN" class="text-center">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <app-form-header *ngIf="productInfo.partnerId == environment.partnerIdBTPN" formHeaderTitle="Pengisian Alamat Kantor" [step]="3" [dariStep]="4"></app-form-header>
  <app-form-header *ngIf="productInfo.partnerId == environment.partnerIdBSI" formHeaderTitle="Pengisian Alamat Kantor Calon Pihak Yang Diasuransikan" [step]="4" [dariStep]="4"></app-form-header>
</div>
<div class="container form-container">
  <form [formGroup]="kontakDataKantorForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="cursor-pointer" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p *ngIf="productInfo.partnerId == environment.partnerIdBTPN" class="content-title color-light-blue bold">Alamat Kantor Calon Tertanggung</p>
      <p *ngIf="productInfo.partnerId == environment.partnerIdBSI" class="content-title color-light-blue bold">Alamat Kantor Calon Pihak Yang Diasuransikan</p>
      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Nama Tempat Kerja/Usaha</p>
        <input class="bottom-border" type="text" formControlName="namaKantor" maxlength="200">
      </div>


      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Alamat Tempat Kerja/Usaha</p>
        <input class="bottom-border" type="text" formControlName="alamatKantor" maxlength="200">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Kode Pos</p>
        <input class="bottom-border" type="number" formControlName="kodePosKantor"  maxlength="200">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Provinsi</p>
        <input class="bottom-border" type="text" formControlName="provinsiKantor" (input)="onInput($event, 'provinsiKantor')" maxlength="200">
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Kota</p>
        <input class="bottom-border" type="text" formControlName="kotaKantor" (input)="onInput($event, 'kotaKantor')" maxlength="200">
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn-back rounded-3 mt-3 py-3 flex-grow-1" type="button" (click)="saveDraft()">SIMPAN</button>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
