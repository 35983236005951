import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-switch-question',
  templateUrl: './switch-question.component.html',
  styleUrls: ['./switch-question.component.css']
})
export class SwitchQuestionComponent {
  @Input() question: string = "";
  @Input() initialToggleValue: boolean = false;
  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleValue: boolean = this.initialToggleValue;

  onToggleChange() {
    this.toggleChange.emit(this.toggleValue);
  }
}
