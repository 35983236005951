import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'EFORM';
  constructor(private router: Router) {
    // this.router.navigate(['/eform/spajk-riplay'])
    // this.router.navigate(['eform/video-pernyataan'])
    let login = sessionStorage.getItem('login')
    if (login == null) {
      localStorage.clear()
      this.router.navigate(['/eform/login'])
    }
  }
}
