import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { DashboardService } from "../service/dashboard/dashboard.service";
import { LoginModel } from "../login/shared/login.model";
import { DashboardModel } from "../service/model/dashboard.model";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  searchByList: any[] = [
    {
      label: "Nomor Referensi",
      value: "referenceNo"
    },
    {
      label: "Nama Nasabah",
      value: "fullname"
    }];
  statusList: any[] = [
    {
      label: "Approved",
      value: "APPROVED"
    },
    {
      label: "Unggah Dokumen",
      value: "PENDING_DOCUMENT"
    },
    {
      label: "Lanjutkan Aplikasi",
      value: "PENDING_DATA"
    },
    {
      label: "Active",
      value: "ACTIVE"
    },
    {
      label: "Refer Underwriting",
      value: "REFERUW"
    },
    {
      label: "Ditolak",
      value: "REJECTED"
    },
  ];

  login: LoginModel = {}
  dashboardModels: DashboardModel[] = []
  viewDashboardModels: DashboardModel[] = []
  isLoading: boolean = true;
  selectedSearch: string = "referenceNo";
  selectedStatus: string = "SEMUA";

  constructor(
    private router: Router,
    private dashboard: DashboardService
  ) {
    localStorage.clear()
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    let jsonLogin = sessionStorage.getItem('login')

    if (jsonLogin) {
      this.login = JSON.parse(jsonLogin);
    }

    this.resetTable()
  }

  selectSearch(event: any) {
    this.selectedSearch = event.target.value;
  }

  selectStatus(event: any) {
    event.target.value;
    this.viewDashboardModels = this.dashboardModels.filter(value => {
      return value.status == event.target.value
    })
  }

  resetTable() {
    this.isLoading = true;
    this.dashboard.dashboard(this.login.userid!, this.login.partnerId!).subscribe(res => {
      this.dashboardModels = res;
      this.viewDashboardModels = res;
      this.isLoading = false;
    })
  }

  getStatusClass(status: string | undefined): string {
    switch (status) {
      case 'PENDING_DATA':
        return 'btn-continue bg-info';
      case 'PENDING_DOCUMENT':
        return 'btn-continue bg-light-blue';
      case 'APPROVED':
        return 'btn-continue bg-dark-blue';
      case 'REJECTED':
        return 'btn-danger';
      case 'SUBMITTED':
      case 'REFERUW':
        return 'btn-link border rounded bg-gray color-light-blue';
      case 'ACTIVE':
        return 'btn-continue bg-info';
      default:
        return 'btn-continue ';
    }

  }

  uploadDokumen(data: DashboardModel, isUnduhUlang: boolean) {
    localStorage.setItem('selected-item', JSON.stringify(data))
    if (data.status == "PENDING_DOCUMENT") {
      if (isUnduhUlang) {
        this.lanjutkanAplikasi(data)
      } else {
        this.router.navigate(['eform/upload-dokumen'])
      }
    }
    if (data.status == "PENDING_DATA") {
      this.lanjutkanAplikasi(data)
    }
    if (data.status === 'APPROVED' || data.status === 'REJECTED' || data.status === 'SUBMITTED' || data.status === 'REFERUW') {
      this.router.navigate(['/eform/dashboard/insured-summary/' + data.referenceNo + '/' + this.login.partnerId]);
    }
  }

  endorsement() {

  }

  lanjutkanAplikasi(data: DashboardModel) {
    this.dashboard.getNasabah(data.referenceNo!).subscribe(res => {
      localStorage.setItem("data-dashboard", JSON.stringify(data))
      localStorage.setItem("from-dashboard", "true")
      localStorage.setItem("nasabah", JSON.stringify(res))
      this.router.navigate(['eform/pengisian-data-pribadi-bsi'])
    })
  }

  statusDescription(status: string | undefined): string | undefined {
    switch (status) {
      case "APPROVED":
        return "Disetujui";
      case "PENDING_DOCUMENT":
        return "Unggah Dokumen";
      case "PENDING_DATA":
        return "Lanjutkan Aplikasi";
      case "REJECTED":
        return "Ditolak";
      case "SUBMITTED":
      case "REFERUW":
        return "Tindak Lanjut via Email";
      case "ACTIVE":
        return "Active";
      default:
        return status;
    }
  }

  searchFromTable(event: any) {
    const input = event.target.value
    if (input == "") {
      this.viewDashboardModels = this.dashboardModels
    } else {
      this.viewDashboardModels = this.dashboardModels.filter(value => {
        if (this.selectedSearch == "referenceNo") {
          return value.referenceNo?.toLowerCase().includes(input.toString().toLowerCase());
        }
        if (this.selectedSearch == "fullname") {
          return value.fullName?.toLowerCase().includes(input.toString().toLowerCase());
        }
        return
      });
    }
  }

  back() {
    this.router.navigate(['eform/menu'])
  }
}
