import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CalculatorRequest, CalculatorResponse} from "./calculator.model";
import {Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";

@Injectable({
  providedIn: 'root'
})
export class CalculatorService extends HttpEformService {

  featureUrl = this.url + "nb/calculator"
  constructor(protected override http: HttpClient) {
    super(http)
  }

  getCalculator(request: CalculatorRequest) : Observable<CalculatorResponse>{
    return this.http
      .post<CalculatorResponse>(this.featureUrl,request);
  }
}
