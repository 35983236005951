<style>
  .spajk-riplay-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    overflow: auto;
    border: 1px blue solid;
    position: relative;
    top: 16%;
    left: 0;
    background-color: white;
    /*zoom: 0.4;*/
    /*-moz-transform: scale(0.4);*/
    /*padding-left: 10px;*/
  }
  .spajk-wrapper {
    overflow: auto;
    height: 3850px;
  }
  .riplay-wrapper {
    height: 2709px;
  }
  .print-width {
    width: 1199px;
  }
  .pad-left{
    padding-left: 10px;
  }
  .title-middle{
    font-weight: 400;
    font-size: 17px;
    background-color: midnightblue;
    padding-left: 30px;
    color: white;
  }
  .sub-title{
    font-weight: 500;
    font-size: 16px;
    background-color: midnightblue;
    color: white;
    padding-left: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .li-span{
    font-size: 12px;
    display: block;
  }
</style>
<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="../../../assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
    <div class="info-box d-flex p-3 rounded-3" role="alert">
      <i class="bi-info-circle"></i>
      <p>Allianz telah menyediakan Ringkasan Informasi Produk dan Layanan (Umum) ("RIPLAY Umum")</p>
    </div>
  </div>
</div>
<div class="container form-container pb-3 bg-white">
  <div class="master-spajk-riplay px-4">
    <div class="spajk-riplay-wrapper">
      <div class="spajk-wrapper print-width" id="spajk-wrapper" style="padding-bottom: 100px;" #spajkWraper>
        <style>
          /* table{
              width: 100%;
              border: 1px solid black;
          } */
          *{
            font-size: 14px;
          }
          .calon-tertanggung, .produk, .rincian-pertanggungan, .keterangan-kesehatan, .last-table{
            width: 100%;
            border: 1px solid black;
            border-collapse: collapse;
          }
          .sub-title-keterangan-kesehatan-table, .keterangan-kesehatan-table, .last-table{
            width: 100%;
            border-collapse: collapse;
            font-size: 13px;
          }
          .jenis-asuransi, .manfaat-asuransi{
            width: 49%;
            border: 1px solid black;
            border-collapse: collapse;
            display: inline-block;
          }
          .manfaat-asuransi{
            float: right;
          }

          .title-detail{
            background-color: rgb(214, 214, 214);
            color: rgb(0, 0, 0);
            font-weight: bold;
            font-size: 13px;
            padding: 3px;
            border-bottom: 1px solid black;
          }
          .sub-title-keterangan-kesehatan{
            background-color: rgb(128, 128, 128);
            color: white;
          }
          p{
            font-size: 13px;
            display: flex;
            text-align: justify;
          }
          .td-no-urut, .abc-urut{
            width: 2%;
          }
          .checkbox-input{
            margin: 10px;
          }
          .label-check-box{
            font-size: 13px;
          }
          .keterangan-calon{
            font-size: 14px;
          }
          input{
            transform: scale(1);
          }
          .calon-tertanggung td, .rincian-pertanggungan td{
            padding-left: 10px;
            font-size: 14px;
            padding-top: 2px;
            padding-bottom: 2px;
          }
          .td-depan-width{
            width: 23%;
          }
          .td-ket-kes{
            width: 25%;
            padding-left: 15px;
          }
        </style>

        <table style="margin: auto; width: 90%; padding-top: 65px;">
          <tr>
            <td>
              <table style="width: 100%;">
                <tr>
                  <td>
                    <table>
                      <tr><td style="padding-bottom: 20px;"><img style="width: 55%;" src="../../../assets/logo/logo.png" alt=""></td></tr>
                      <tr><td style="font-size: 14px; font-weight: 500;">SURAT PERMOHONAN ASURANSI JIWA KREDIT KUMPULAN (SPAJK)</td></tr>
                      <tr><td colspan="3" align="left" style="font-size: 14px;">HARAP DIISI DENGAN HURUF CETAK</td></tr>
                      <tr><td colspan="3" align="left"style="font-size: 12px;">( sesuai dengan data yang diberikan kepada bank/perusahaan pemberi kredit )</td></tr>
                    </table>
                  </td>
                  <td align="right">
                    <table style="width: 100%;">
                      <tr><td align="right" style="width: 50%;font-size: 20px;font-weight: 700;">PT Asuransi Allianz Life Indonesia</td></tr>
                      <tr>
                        <td align="right">
                          <style>
                            .tbl-info-prd, .tr-info-prd, .td-info-prd{
                              border: 1px solid;
                              width: 100%;
                              border-collapse: collapse;
                              margin-top: 5%;
                            }
                            .td-info-prd{
                              width: 37%;
                              padding-left: 10px;
                              font-size: 14px;
                            }
                            .td-info-prd-val{
                              padding-left: 10px;
                              font-size: 14px;
                            }
                          </style>
                          <table class="tbl-info-prd">
                            <tr class="tr-info-prd"><td class="td-info-prd">NAMA PRODUK</td><td class="td-info-prd-val">{{spajk.productName}}</td></tr>
                            <tr class="tr-info-prd"><td class="td-info-prd">JENIS ASURANSI</td><td class="td-info-prd-val">Asuransi Jiwa</td></tr>
                            <tr class="tr-info-prd"><td class="td-info-prd">MANFAAT ASURANSI</td><td class="td-info-prd-val">Meninggal Dunia</td></tr>
                            <tr class="tr-info-prd">
                              <td class="td-info-prd">JENIS PINJAMAN</td>
                              <td>
                                <input style="margin-top: 5px;margin-bottom: 5px;" class="checkbox-input" [checked]= "spajk.loanTypeCode==='1'?true:false" type="checkbox" value="KPR" id="KPR"><label class="label-check-box" style="margin-right: 30px;" for="KPR">KPR</label>
                                <input style="margin-top: 5px;margin-bottom: 5px;" class="checkbox-input" [checked]= "spajk.loanTypeCode==='2'?true:false" type="checkbox" value="KPM" id="KPM"><label class="label-check-box" style="margin-right: 30px;" for="KPM">KPM</label>
                                <input style="margin-top: 5px;margin-bottom: 5px;" class="checkbox-input" [checked]= "spajk.loanTypeCode==='3'?true:false" type="checkbox" value="KTA" id="KTA"><label class="label-check-box" style="margin-right: 30px;" for="KTA">KTA</label>
                                <input style="margin-top: 5px;margin-bottom: 5px;" class="checkbox-input" [checked]= "spajk.loanTypeCode==='4'?true:false" type="checkbox" value="KMG" id="KMG"><label class="label-check-box" style="margin-right: 30px;" for="KMG">KMG</label>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="border" colspan="3" style="height: 20px;"></td>
          </tr>
          <!-- ***************************starts of calon-tertanggung****************************** -->
          <tr>
            <td colspan="3">
              <table border="0" class="calon-tertanggung">
                <tr>
                  <td colspan="6" class="title-detail" style="font-size: 13px;">1. CALON TERTANGGUNG</td>
                </tr>
                <tr>
                  <td class="td-depan-width">Nama lengkap (Sesuai Identitas)</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="border-bottom: 1px solid;width: 100%;display: inline-block;">{{spajk.fullName}}</span></td>
                </tr>
                <tr>
                  <td>No KTP/SIM</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="border-bottom: 1px solid;width: 100%;display: inline-block;">{{spajk.identityNumber}}</span></td>

                </tr>
                <tr>
                  <td>Tempat Lahir: </td>
                  <td>:</td>
                  <td><span style="border-bottom: 1px solid;width: 100%;display: inline-block;">{{spajk.pob}}</span></td>
                  <td colspan="3">Tanggal Lahir:
                    <span style="border-bottom: 1px solid;width: 7%;display: inline-block;text-align: center;">{{spajk.tgl}}</span><span>&nbsp;/&nbsp;</span>
                    <span style="border-bottom: 1px solid;width: 7%;display: inline-block;text-align: center;">{{spajk.bln}}</span><span>&nbsp;/&nbsp;</span>
                    <span style="border-bottom: 1px solid;width: 7%;display: inline-block;text-align: center;">{{spajk.thn}}</span><span>&nbsp;/</span>
                    <span>&nbsp;&nbsp;(dd/mm/yy)</span>
                  </td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>:</td>
                  <td>
                    <form>
                      <input style="margin-left: 0px;" class="checkbox-input" [checked]= "spajk.gender==='M'?true:false" type="checkbox" value="M" id="pria"><label class="label-check-box" style="margin-right: 30px;" for="pria">Pria</label>
                      <input class="checkbox-input" [checked]= "spajk.gender=='F'?true:false" type="checkbox" value="F" id="wanita"><label class="label-check-box" style="margin-right: 30px;" for="wanita">Wanita</label>
                    </form>
                  </td>
                  <td colspan="3" align="right">Status:
                    <input class="checkbox-input" [checked]= "spajk.maritalStatus==='M'?true:false" name="status" type="checkbox" value="M" id="m"><label class="label-check-box" style="margin-right: 30px;" for="m">Kawin</label>
                    <input class="checkbox-input" [checked]= "spajk.maritalStatus==='S'?true:false" name="status" type="checkbox" value="S" id="s"><label class="label-check-box" style="margin-right: 30px;" for="s">Belum Kawin</label>
                    <input class="checkbox-input" [checked]= "spajk.maritalStatus==='W'?true:false" name="status" type="checkbox" value="W" id="w"><label class="label-check-box" style="margin-right: 30px;" for="w">Janda/Duda</label>
                  </td>
                  <!--  -->
                </tr>
                <!-- style="vertical-align:top; -->
                <tr>
                  <td>Alamat Tempat Tinggal Saat ini</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="height: 25px; width:100%; display: inline-block;border-bottom: 1px solid;">{{spajk.address}} | {{spajk.city}} | {{spajk.province}}</span></td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="4">
                    <table width="100%">
                      <tr>
                        <td><span>Kelurahan: </span><span style="height: 25px; width:71%; display: inline-block;border-bottom: 1px solid;"></span></td>
                        <td><span> Kecamatan: </span><span style="height: 25px; width:71%; display: inline-block;border-bottom: 1px solid;"></span></td>
                        <td><span> Kode Pos: </span><span style="height: 25px; width:71%; display: inline-block;border-bottom: 1px solid;">{{spajk.zipcode}}</span></td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td >No. HP</td>
                  <td>:</td>
                  <td colspan="4">
                    <span align="center" style="width: 25%; display: inline-block;border-bottom: 1px solid;">{{spajk.mobilePhoneNo}}</span>
                    <span>Telp. Rumah: </span><span align="center" style="width:25%; display: inline-block;border-bottom: 1px solid;">{{spajk.homephoneno}}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    No. Rekening Tabungan
                  </td>
                  <td>:</td>
                  <td colspan="4"><span style="width:63%; display: inline-block;border-bottom: 1px solid;">{{spajk.loanAccountNo}}</span>(No. Rekening yang terdaftar pada Bank pemberi kredit)</td>
                </tr>
                <tr>
                  <td>Pekerjaan / Uraian Pekerjaan</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="width:100%; display: inline-block;border-bottom: 1px solid;">{{spajk.occupation}}</span></td>
                </tr>
                <tr>
                  <td>Nama Tempat Kerja/Usaha</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="width:100%; display: inline-block;border-bottom: 1px solid;">{{spajk.companyName}}</span></td>
                </tr>
                <tr>
                  <td>Alamat Tempat Kerja/Usaha</td>
                  <td>:</td>
                  <td style="padding-right: 25px;" colspan="4"><span style="width:100%; display: inline-block;border-bottom: 1px solid;">{{spajk.officeAddress}} | {{spajk.officeCity}} | {{spajk.officeProvince}}</span></td>
                </tr>

                <tr>
                  <td>Korespondensi Melalui Email</td>
                  <td>:</td>
                  <td>
                    <input style="margin-left: 0px; margin-bottom: 0px;" class="checkbox-input" [checked]= "spajk.emailCorrespondenceFlag==='Y'?true:false" name="korespondensi" type="checkbox" value="Y" id="ya"><label class="label-check-box" style="margin-right: 30px;" for="ya">YA</label>
                    <input style="margin-bottom: 0px;" class="checkbox-input" [checked]= "spajk.emailCorrespondenceFlag==='N'?true:false" name="korespondensi" type="checkbox" value="N" id="tidak"><label class="label-check-box" style="margin-right: 30px;" for="tidak">TIDAK</label>
                  </td>
                  <td colspan="3">Alamat Email: <span style="margin-left: 5px; width: 83%; display: inline-block; border-bottom: 1px solid;">{{spajk.emailAddress}}</span></td>
                </tr>
                <tr>
                  <td>Sertifikat Asuransi Versi Elektronik</td>
                  <td>:</td>
                  <td colspan="4">
                    <input style="margin-left: 0px;" class="checkbox-input" [checked]= "spajk.ecertificateFlag==='Y'?true:false" name="sertifikasi" type="checkbox" value="Y" id="ya"><label class="label-check-box" style="margin-right: 30px;" for="ya">YA</label>
                    <input class="checkbox-input" [checked]= "spajk.ecertificateFlag==='N'?true:false" name="sertifikasi" type="checkbox" value="N" id="tidak"><label class="label-check-box" style="margin-right: 30px;" for="tidak">TIDAK</label>
                  </td>
                </tr>
              </table>
            </td>
          </tr> <!-- ***************************end of calon-tertanggung**********************k******** -->
          <tr><td colspan="3" style="height: 20px;"></td></tr>
          <!-- ***************************starts of perincian pertanggunan****************************** -->
          <style>
            .rincian-pertanggungan{
              font-size: 14px;
            }
          </style>
          <tr>
            <td colspan="3">
              <table border="0" class="rincian-pertanggungan">
                <tr>
                  <td colspan="6" class="title-detail">2. PERINCIAN PERTANGGUNGAN</td>
                </tr>
                <tr>
                  <td class="td-depan-width">Usia Masuk Tertanggung</td>
                  <td style="width: 2%;">:</td>
                  <td colspan="4"><span style="width:20%;height: 25px; display: inline-block; border-bottom: 1px solid;">{{this.calculator.entryAge}}</span>Tahun (sesuai dengan Usia Nasabah pada kalkulator premi)</td>
                </tr>
                <tr>
                  <td>Bunga Pinjaman</td>
                  <td>:</td>
                  <td><span style="width:59%; align-items: center; display: inline-block; border-bottom: 1px solid;">{{spajk.interestRate}}</span></td>
                  <td>Masa Pertanggungan</td>
                  <td>:</td>
                  <td><span style="width:50%; align-items: center; display: inline-block; border-bottom: 1px solid;">{{spajk.tenure}}</span>Bulan</td>
                </tr>
                <tr>
                  <td style="padding-bottom: 10px;">Premi Asuransi</td>
                  <td>:</td>
                  <td>Rp. <span style="width:50%; align-items: center; display: inline-block; border-bottom: 1px solid;">{{this.calculator.totalPremium.toLocaleString("id-ID")}}</span></td>
                  <td>Uang Pertanggungan</td>
                  <td>:</td>
                  <td>Rp. <span style="width:50%; align-items: center; display: inline-block; border-bottom: 1px solid;">{{spajk.sumAssured.toLocaleString("id-ID")}}</span></td>
                </tr>
              </table>
            </td>
          </tr> <!-- ***************************end of perincian pertanggunan****************************** -->
          <tr><td  colspan="3" style="height: 20px;"></td></tr>

          <!-- ***************************starts of keteranga kesehatan calon ****************************** -->
          <tr>
            <td class="keterangan-kesehatan" colspan="3">
              <table class="keterangan-kesehatan-table">
                <tr>
                  <td colspan="6" style="font-size: 14px;padding-left: 10px;" class="title-detail">3. KETERANGAN KESEHATAN CALON TERTANGGUNG - Beri tanda (v) pada jawaban yang dipilih (Hanya diisi jika Uang Pertanggungan diatas Rp. 100,000,000)</td>
                </tr>
                <tr>
                  <td style="font-size: 14px;padding-left: 10px; border-bottom: 0px;" class="title-detail" colspan="3">Apakah Uang Pertanggungan lebih besar dari Rp. 100,000,000?</td>
                  <td style="border-bottom: 0px;font-size: 14px" class="title-detail" colspan="3"><input style="margin-right: 10px;margin-top: 5px;margin-bottom: 0px;margin-left: 0px;" class="checkbox-input" [checked]= "spajk.sumAssured > 100000000" type="checkbox" value="non-gio" id="non-gio"><label class="label-check-box" style="margin-right: 30px;" for="non-gio">Ya (Wajib isi pertanyaan poin 3.a hingga 3.f)</label></td>
                </tr>
                <tr>
                  <td class="title-detail" style="font-style: italic;padding-left: 10px;font-size: 14px" colspan="3">(Rp. 100,000,001 atau lebih)</td>
                  <td class="title-detail" colspan="3"><input style="margin-right: 10px;margin-top: 5px;margin-bottom: 0px;margin-left: 0px;font-size: 14px" class="checkbox-input"  [checked]= "spajk.sumAssured <= 100000000" type="checkbox" value="gio" id="gio"><label class="label-check-box" style="margin-right: 30px;" for="gio">Tidak (Lanjut ke nomor 4)</label></td>
                </tr>
                <tr style="margin-right: 2px;">
                  <td colspan="4"></td>
                  <td style="width: 6%; border-bottom: 1px solid;font-size: 14px; border-left: 1px solid;border-right: 1px solid;" align="center">YA</td>
                  <td style="width: 6%; border-bottom: 1px solid;border-right: 1px solid;" align="center">TIDAK</td>
                </tr>
                <tr>
                  <td class="td-depan-width" style="padding-top: 5px;font-size: 14px;padding-left: 10px;">A. Tinggi Badan & Berat Badan</td>
                  <!-- <td style="padding-top: 5px;">:</td> -->
                  <td colspan="3" style="padding-top: 5px;">:
                    <span align="center" style="font-size: 14px; margin-left: 10px;border-bottom: 1px solid; width: 50px;display: inline-block;">{{spajk.height}}</span>
                    <span>cm & </span>
                    <span align="center" style="font-size: 14px;border-bottom: 1px solid; width: 50px;display: inline-block;">{{spajk.weight}}</span>
                    <span>Kg</span>
                    <span style="margin-left:25px;font-size: 14px">Berat badan bertambah/berkurang dalam 12 bulan terakhir, <b>Jika “YA” jelaskan</b> kenaikan/penurunan berat badan <span align="center" style="border-bottom: 1px solid; width: 50px;display: inline-block;">{{spajk.weightGainlossKg}}</span> kg</span>
                  </td>
                  <td style="border: 1px solid black;font-size: 14px" align="center"><input class="checkbox-input" [checked]= "spajk.weightGainlossFlag === 'Y'?true:false" name="beratbadanya" type="checkbox" value="Y" id="beratbadanya"></td>
                  <td style="border: 1px solid black;font-size: 14px" align="center"><input class="checkbox-input" [checked]= "spajk.weightGainlossFlag !== 'Y'" name="beratbadantdk" type="checkbox" value="N" id="beratbadantdk"></td>
                </tr>
                <tr style="border-top: 1px solid black;">
                  <td  class="td-depan-width" style="padding-left: 10px;">B. Apakah Anda merokok?</td>
                  <!-- <td>:</td> -->
                  <td colspan="3">: <b style="margin-left: 10px;font-size: 14px">Jika "YA" Jelaskan</b> <span align="center" style="width:10%; display: inline-block; border-bottom: 1px solid;">{{spajk.smokingPcs}}</span>Batang/Bungkus per hari</td>
                  <td style="border: 1px solid black;font-size: 14px" align="center"><input class="checkbox-input" [checked]= "spajk.smokingFlag==='Y'" name="merokokya" type="checkbox" value="Y" id="rokokya"></td>
                  <td style="border: 1px solid black;font-size: 14px" align="center"><input class="checkbox-input" [checked]= "spajk.smokingFlag!=='Y'" name="merokoktdk" type="checkbox" value="N" id="rokoktdk"></td>
                </tr>
                <tr style="border-top: 1px solid black;">
                  <td  style="padding-left: 10px;font-size: 14px" colspan="4">C. Dalam 5 tahun terakhir, apakah Anda pernah/sedang menjalani pengobatan atau perawatan di Rumah Sakit atau mengetahui menderita penyakit/keadaan sebagai berikut :</td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"><input class="checkbox-input" [checked]= "spajk.preexistingConditionFlag==='Y'" name="preexistingya" type="checkbox" value="Y" id="preexistingya"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"><input class="checkbox-input" [checked]= "spajk.preexistingConditionFlag!=='Y'" name="preexistingtdk" type="checkbox" value="N" id="preexistingtdk"></td>
                </tr>
                <tr>
                  <td class="td-ket-kes">
                    <input class="checkbox-input" [checked]= "spajk.chestPain==='Y'?true:false" type="checkbox" value="chestpain" id="chestpain"><label class="label-check-box" style="margin-right: 30px;" for="chestpain">Sakit dada (chest pain)</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.leukimia==='Y'?true:false" type="checkbox" value="leukimia" id="leukimia"><label class="label-check-box" style="margin-right: 30px;" for="leukimia">Leukimia</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.brainAbnormalities==='Y'?true:false" type="checkbox" value="Segala jenis kelainan otak" id="kelainan-otak"><label class="label-check-box" style="margin-right: 30px;" for="kelainan-otak">Segala jenis kelainan otak</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.alcoholic==='Y'?true:false" type="checkbox" value="Penggunaan/ketergantungan Alkohol" id="alkolholik"><label class="label-check-box" style="margin-right: 30px;" for="alkolholik">Penggunaan/ketergantungan Alkohol</label>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                </tr>
                <tr>
                  <td class="td-ket-kes">
                    <input class="checkbox-input" [checked]= "spajk.highBloodPresure==='Y'?true:false" type="checkbox" value="Darah Tinggi" id="darting"><label class="label-check-box" style="margin-right: 30px;" for="darting">Darah Tinggi</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.lungs==='Y'?true:false" type="checkbox" value="Paru-paru" id="paru-paru"><label class="label-check-box" style="margin-right: 30px;" for="paru-paru">Paru-paru</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.lymphgland==='Y'?true:false" type="checkbox" value="Kelenjar getah bening" id="kelenjar-getah-bening"><label class="label-check-box" style="margin-right: 30px;" for="kelenjar-getah-bening">Kelenjar getah bening</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.aidsHiv==='Y'?true:false" type="checkbox" value="AIDS/HIV" id="hiv-aids"><label class="label-check-box" style="margin-right: 30px;" for="hiv-aids">AIDS/HIV</label>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                </tr>
                <tr>
                  <td class="td-ket-kes">
                    <input class="checkbox-input" [checked]= "spajk.stroke==='Y'?true:false" type="checkbox" value="Stroke" id="stroke"><label class="label-check-box" style="margin-right: 30px;" for="stroke">Stroke</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.cancerTumor==='Y'?true:false" type="checkbox" value="Kanker/Tumor" id="kanker"><label class="label-check-box" style="margin-right: 30px;" for="kanker">Kanker/Tumor</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.stomachIntenstine==='Y'?true:false" type="checkbox" value="Lambung/usus" id="lambung-usus"><label class="label-check-box" style="margin-right: 30px;" for="lambung-usus">Lambung/usus</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.othersFlag==='Y'?true:false" type="checkbox" value="Lainnya, sebutkan" id="lainnya"><label class="label-check-box" style="margin-right: 30px;" for="lainnya">Lainnya, sebutkan<span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.othersRemark}}</span></label>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                </tr>
                <tr>
                  <td class="td-ket-kes">
                    <input class="checkbox-input" [checked]= "spajk.heartBloodVessel==='Y'?true:false" type="checkbox" value="Jantung & Pembuluh darah" id="jantung-pembuluh"><label class="label-check-box" style="margin-right: 30px;" for="jantung-pembuluh">Jantung & Pembuluh darah</label>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.diabetic==='Y'?true:false" type="checkbox" value="Kencing Manis" id="diabetic"><label class="label-check-box" style="margin-right: 30px;" for="diabetic">Kencing Manis</label>
                  <td colspan="2">
                    <input class="checkbox-input" [checked]= "spajk.liver==='Y'?true:false" type="checkbox" value="Liver" id="liver"><label class="label-check-box" style="margin-right: 30px;" for="liver">Liver</label>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                </tr>
                <tr>
                  <td class="td-ket-kes">
                    <input class="checkbox-input" [checked]= "spajk.bloodDisorder==='Y'?true:false" type="checkbox" value="Kelainan Darah" id="bloodDisorder"><label class="label-check-box" style="margin-right: 30px;" for="bloodDisorder">Kelainan Darah</label>
                  </td>
                  <td>
                    <input class="checkbox-input" [checked]= "spajk.mentalDisorders==='Y'?true:false" type="checkbox" value="Kelainan Jiwa" id="mentalDisorders"><label class="label-check-box" style="margin-right: 30px;" for="mentalDisorders">Kelainan Jiwa</label>
                  </td>
                  <td colspan="2">
                    <input class="checkbox-input" [checked]= "spajk.drugAbuse==='Y'?true:false" type="checkbox" value="Penggunaan/ketergantungan Obat" id="drugAbuse"><label class="label-check-box" style="margin-right: 30px;" for="drugAbuse">Penggunaan/ketergantungan Obat</label>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center"></td>
                </tr>
                <tr style="border-top: 1px solid gray;border-bottom: 1px solid gray;">
                  <td colspan="4" style="padding-left: 10px;font-size: 14px;">
                    D. Khusus Wanita, apakah saat ini Anda sedang hamil? : <b>Jika “YA” jelaskan</b> umur kehamilan
                    <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.pregnantPeriod}}</span><span>bulan</span>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.pregnantFlag==='Y'" name="hamil" type="checkbox" value="{{spajk.pregnantPeriod}}" id="hamilY">
                  </td>
                  <td style="border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.pregnantFlag!=='Y'" name="hamil" type="checkbox" value="{{spajk.pregnantPeriod}}" id="hamilTdk">
                  </td>
                </tr>
                <tr style="border-bottom: 1px solid gray;">
                  <td colspan="4" style="padding-left: 10px;font-size: 14px;">
                    E. Apakah ada anggota keluarga terdekat Anda (ayah,ibu, kakak,adik) yang menderita atau meninggal karena penyakit-penyakit sebagaimana disebutkan pada butir (a) diatas? <b>Jika "YA" jelaskan</b>
                    <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.familyHealthCondition}}</span>
                  </td>
                  <td style="font-size: 14px;border-left: 1px solid black;border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.familyHealthFlag==='Y'" name="family" type="checkbox" value="Y" id="familyY">
                  </td>
                  <td style="font-size: 14px;border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.familyHealthFlag!=='Y'" name="family" type="checkbox" value="N" id="familyTdk">
                  </td>
                </tr>
                <tr>
                  <td colspan="4" style="padding-left: 10px;">
                    F. Apakah permohonan pertanggungan asuransi Anda pernah ditolak, ditangguhkan atau dikenakan premi tambahan ?
                    <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.rejectionPolicyHistory}}</span>
                  </td>
                  <td style="border-left: 1px solid black;border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.rejectionPolicyFlag==='Y'" name="permohonan" type="checkbox" value="Y" id="permohonanY">
                  </td>
                  <td style="border-right: 1px solid black;" align="center">
                    <input class="checkbox-input" [checked]= "spajk.rejectionPolicyFlag!=='Y'" name="permohonan" type="checkbox" value="Y" id="permohonanTdk">
                  </td>
                </tr>
              </table>
              <style>
                .sub-title-keterangan-kesehatan-table td{
                  padding-left: 5px;
                }
              </style>
              <table class="sub-title-keterangan-kesehatan-table">
                <!-- <tr class="sub-title-keterangan-kesehatan">
                    <td colspan="6">Beri tanda (  v   ) pada jawaban yang dipilih :</td>
                </tr> -->

                <!-- <tr>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.chestPain==='Y'?true:false" type="checkbox" value="chestpain" id="chestpain"><label class="label-check-box" style="margin-right: 30px;" for="chestpain">Sakit dada (chest pain)</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.leukimia==='Y'?true:false" type="checkbox" value="leukimia" id="leukimia"><label class="label-check-box" style="margin-right: 30px;" for="leukimia">Leukimia</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.brainAbnormalities==='Y'?true:false" type="checkbox" value="Segala jenis kelainan otak" id="kelainan-otak"><label class="label-check-box" style="margin-right: 30px;" for="kelainan-otak">Segala jenis kelainan otak</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.drugAbuse==='Y'?true:false" type="checkbox" value="Penggunaan/ketergantungan Alkohol" id="alkolholik"><label class="label-check-box" style="margin-right: 30px;" for="alkolholik">Penggunaan/ketergantungan Alkohol</label>
                    </td>
                    <td style="border: 1px solid black;" align="center"></td>
                    <td style="border: 1px solid black;" align="center"></td>
                </tr> -->
                <!-- <tr>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.highBloodPresure==='Y'?true:false" type="checkbox" value="Darah Tinggi" id="darting"><label class="label-check-box" style="margin-right: 30px;" for="darting">Darah Tinggi</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.lungs==='Y'?true:false" type="checkbox" value="Paru-paru" id="paru-paru"><label class="label-check-box" style="margin-right: 30px;" for="paru-paru">Paru-paru</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.preexistingConditionFlag==='Y'?true:false" type="checkbox" value="Kelenjar getah bening" id="kelenjar-getah-bening"><label class="label-check-box" style="margin-right: 30px;" for="kelenjar-getah-bening">Kelenjar getah bening</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.lymphgland==='Y'?true:false" type="checkbox" value="AIDS/HIV" id="hiv-aids"><label class="label-check-box" style="margin-right: 30px;" for="hiv-aids">AIDS/HIV</label>
                    </td>
                    <td style="border-left: 1px solid;" colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.stroke==='Y'?true:false" type="checkbox" value="Stroke" id="stroke"><label class="label-check-box" style="margin-right: 30px;" for="stroke">Stroke</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.cancerTumor==='Y'?true:false" type="checkbox" value="Kanker/Tumor" id="kanker"><label class="label-check-box" style="margin-right: 30px;" for="kanker">Kanker/Tumor</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.stomachIntenstine==='Y'?true:false" type="checkbox" value="Lambung/usus" id="lambung-usus"><label class="label-check-box" style="margin-right: 30px;" for="lambung-usus">Lambung/usus</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.othersFlag==='Y'?true:false" type="checkbox" value="Lainnya, sebutkan" id="lainnya"><label class="label-check-box" style="margin-right: 30px;" for="lainnya">Lainnya, sebutkan<span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.othersRemark}}</span></label>
                    </td>
                    <td style="border-left: 1px solid;" colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td style="width: 21%;">
                        <input class="checkbox-input" [checked]= "spajk.heartBloodVessel==='Y'?true:false" type="checkbox" value="Jantung & Pembuluh darah" id="jantung-pembuluh"><label class="label-check-box" style="margin-right: 30px;" for="jantung-pembuluh">Jantung & Pembuluh darah</label>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.diabetic==='Y'?true:false" type="checkbox" value="Kencing Manis" id="diabetic"><label class="label-check-box" style="margin-right: 30px;" for="diabetic">Kencing Manis</label>
                    <td colspan="2">
                        <input class="checkbox-input" [checked]= "spajk.liver==='Y'?true:false" type="checkbox" value="Liver" id="liver"><label class="label-check-box" style="margin-right: 30px;" for="liver">Liver</label>
                    <td style="border-left: 1px solid;" colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.bloodDisorder==='Y'?true:false" type="checkbox" value="Kelainan Darah" id="bloodDisorder"><label class="label-check-box" style="margin-right: 30px;" for="bloodDisorder">Kelainan Darah</label>
                    </td>
                    <td>
                        <input class="checkbox-input" [checked]= "spajk.mentalDisorders==='Y'?true:false" type="checkbox" value="Kelainan Jiwa" id="mentalDisorders"><label class="label-check-box" style="margin-right: 30px;" for="mentalDisorders">Kelainan Jiwa</label>
                    </td>
                    <td colspan="2">
                        <input class="checkbox-input" [checked]= "spajk.drugAbuse==='Y'?true:false" type="checkbox" value="Penggunaan/ketergantungan Obat" id="drugAbuse"><label class="label-check-box" style="margin-right: 30px;" for="drugAbuse">Penggunaan/ketergantungan Obat</label>
                    </td>
                    <td style="border-left: 1px solid;" colspan="2"></td>
                </tr> -->
                <!-- <tr>
                    <td colspan="4">Untuk setiap jawaban "YA" atau pilihan (v) di atas, Anda berkewajiban untuk menjawab/mengisi lembar PERTANYAAN tentang penyakit tersebut dengan lebih lengkap.</td>
                    <td style="border-left: 1px solid;" colspan="2"></td>
                </tr> -->
                <!-- <tr style="border-top: 1px solid gray;border-bottom: 1px solid gray;">
                    <td colspan="4">
                        D. Khusus Wanita, apakah saat ini Anda sedang hamil? : Jika “YA” jelaskan umur kehamilan
                        <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.pregnantPeriod}}</span><span>bulan</span>
                    </td>
                    <td style="border-left: 1px solid;" align="center">
                        <input class="checkbox-input" [checked]= "spajk.pregnantFlag==='Y'?true:false" name="hamil" type="checkbox" value="Y" id="hamilY">
                    </td>
                    <td align="center">
                        <input class="checkbox-input" [checked]= "spajk.pregnantFlag==='N'?true:false" name="hamil" type="checkbox" value="N" id="hamilTdk">
                    </td>
                </tr> -->
                <!-- <tr style="border-bottom: 1px solid gray;">
                    <td colspan="4">
                        c. Apakah ada anggota keluarga terdekat Anda (ayah,ibu, kakak,adik) yang menderita atau meninggal karena penyakit-penyakit sebagaimana disebutkan pada butir (a) diatas? Jika "YA", jelaskan
                        <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.familyHealthCondition}}</span>
                    </td>
                    <td style="border-left: 1px solid;" align="center">
                        <input class="checkbox-input" [checked]= "spajk.familyHealthFlag==='Y'?true:false" name="family" type="radio" value="Y" id="familyY">
                    </td>
                    <td align="center">
                        <input class="checkbox-input" [checked]= "spajk.familyHealthFlag==='N'?true:false" name="family" type="radio" value="N" id="familyTdk">
                    </td>
                </tr> -->
                <!-- <tr>
                    <td colspan="4">
                        d. Apakah permohonan pertanggungan asuransi Anda pernah ditolak, ditangguhkan atau dikenakan premi tambahan ?
                        <span align="center" style="width: 150px; display: inline-block;border-bottom: 1px solid;">{{spajk.rejectionPolicyHistory}}</span>
                    </td>
                    <td style="border-left: 1px solid;" align="center">
                        <input class="checkbox-input" [checked]= "spajk.rejectionPolicyFlag==='Y'?true:false" name="permohonan" type="radio" value="Y" id="permohonanY">
                    </td>
                    <td align="center">
                        <input class="checkbox-input" [checked]= "spajk.rejectionPolicyFlag==='N'?true:false" name="permohonan" type="radio" value="Y" id="permohonanTdk">
                    </td>
                </tr> -->
              </table>
            </td>
          </tr> <!-- end of keterangan kesehatan -->
          <tr><td class="border" colspan="3" style="height: 20px;"></td></tr>
          <tr>
            <td colspan="3">
              <table border="0" class="rincian-pertanggungan">
                <tr>
                  <td colspan="6" class="title-detail">4. PERSETUJUAN PEMASARAN</td>
                </tr>
                <tr>
                  <td colspan="4" style="text-align: justify;">Apakah Anda (Calon Tertanggung) bersedia (i) untuk menerima informasi, penawaran produk dan/atau layanan serta dihubungi oleh Allianz,
                    anak perusahaan dan/atau afiliasinya ("Grup Allianz") (serta pihak ketiga yang ditunjuk oleh Grup Allianz); dan (ii) data pribadi Anda diproses
                    oleh Grup Allianz dan pihak ketiga yang ditunjuk oleh Grup Allianz untuk tujuan pemasaran, peningkatan kualitas data pribadi serta
                    peningkatan layanan?
                  </td>
                  <td align="center">YA<input class="checkbox-input" [checked]= "marketingChecked ==='Y'" name="pemasaranya" type="checkbox" value="Y" id="pemasaranya"></td>
                  <td align="center">TIDAK<input class="checkbox-input" [checked]= "marketingChecked==='N'" name="pemasarantdk" type="checkbox" value="N" id="pemasarantdk"></td>
                </tr>
                <tr>
                  <td style="text-align: justify;" colspan="6">Dalam hal Anda menjawab "YA", Anda mengerti dan menyetujui bahwa persetujuan diatas meliputi: (i) persetujuan untuk dihubungi dan/atau dikirimkan informasi mengenai
                    penawaran produk dan/atau layanan, survei nasabah dan kampanye produk, melalui setiap media komunikasi yang tersedia (seperti surat, surat elektronik (email), Short Message
                    Service (SMS), telepon, aplikasi pesan singkat dan media komunikasi digital/elektronik lainnya) oleh Grup Allianz (serta pihak ketiga yang ditunjuk oleh Grup Allianz); dan (ii)
                    persetujuan pemberian kuasa kepada Grup Allianz (serta pihak yang ditunjuk oleh Grup Allianz) untuk mengungkapkan informasi data pribadi Anda kepada setiap pihak lainnya
                    terkait kepentingan yang dimaksud pada poin (i).</td>
                </tr>
                <tr>
                  <td colspan="6" style="text-align: justify;"><b>Perhatian:</b> Dalam hal Anda tidak lagi bersedia dihubungi dan menerima informasi penawaran produk dan/atau layanan Grup Allianz (serta pihak ketiga yang ditunjuk oleh Grup Allianz), Anda dapat menghubungi AllianzCare di 1500-136 atau mengirimkan email ke alamat contactus@allianz.co.id</td>
                </tr>
              </table>
            </td>
          </tr> <!-- end of persetujuan pemasaran -->
          <tr><td class="border" colspan="3" style="height: 20px;"></td></tr>
          <tr>
            <style>
              .grid{
                width: 2%;
                border: 1px solid gray;
                height: 20px;
              }
            </style>
            <td colspan="3">
              <table border="0" class="rincian-pertanggungan">
                <tr>
                  <td colspan="6" class="title-detail">5. Diisi oleh Staf Bank/Perusahaan Pemberi Kredit</td>
                </tr>
                <tr>
                  <td style="width: 16%;border-bottom: 1px solid black;">Email Staf Bank</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5">
                    <table style="width: 100%;">
                      <tr>
                        <td class="grid">{{this.getCharatIndex(0)}}</td>
                        <td class="grid">{{this.getCharatIndex(1)}}</td>
                        <td class="grid">{{this.getCharatIndex(2)}}</td>
                        <td class="grid">{{this.getCharatIndex(3)}}</td>
                        <td class="grid">{{this.getCharatIndex(4)}}</td>
                        <td class="grid">{{this.getCharatIndex(5)}}</td>
                        <td class="grid">{{this.getCharatIndex(6)}}</td>
                        <td class="grid">{{this.getCharatIndex(7)}}</td>
                        <td class="grid">{{this.getCharatIndex(8)}}</td>
                        <td class="grid">{{this.getCharatIndex(9)}}</td>
                        <td class="grid">{{this.getCharatIndex(10)}}</td>
                        <td class="grid">{{this.getCharatIndex(11)}}</td>
                        <td class="grid">{{this.getCharatIndex(12)}}</td>
                        <td class="grid">{{this.getCharatIndex(13)}}</td>
                        <td class="grid">{{this.getCharatIndex(14)}}</td>
                        <td class="grid">{{this.getCharatIndex(15)}}</td>
                        <td class="grid">{{this.getCharatIndex(16)}}</td>
                        <td class="grid">{{this.getCharatIndex(17)}}</td>
                        <td class="grid">{{this.getCharatIndex(18)}}</td>
                        <td class="grid">{{this.getCharatIndex(19)}}</td>
                        <td class="grid">{{this.getCharatIndex(20)}}</td>
                        <td class="grid">{{this.getCharatIndex(21)}}</td>
                        <td class="grid">{{this.getCharatIndex(22)}}</td>
                        <td class="grid">{{this.getCharatIndex(23)}}</td>
                        <td class="grid">{{this.getCharatIndex(24)}}</td>
                        <td class="grid">{{this.getCharatIndex(25)}}</td>
                        <td class="grid">{{this.getCharatIndex(26)}}</td>
                        <td class="grid">{{this.getCharatIndex(27)}}</td>
                        <td class="grid">{{this.getCharatIndex(28)}}</td>
                        <td class="grid">{{this.getCharatIndex(29)}}</td>
                        <td class="grid">{{this.getCharatIndex(30)}}</td>
                        <td class="grid">{{this.getCharatIndex(31)}}</td>
                        <td class="grid">{{this.getCharatIndex(32)}}</td>
                        <td class="grid">{{this.getCharatIndex(33)}}</td>
                        <td class="grid">{{this.getCharatIndex(34)}}</td>
                        <td class="grid">{{this.getCharatIndex(35)}}</td>
                        <td class="grid">{{this.getCharatIndex(36)}}</td>
                        <td class="grid">{{this.getCharatIndex(37)}}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;">Kode Bank</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5"></td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;">Cabang/Unit</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5">{{loginResponse.branchOffice}}</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;">Cabang Proses Kredit</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5">{{loginResponse.creditBranchOffice}}</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;">No. Polis</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5">{{spajk.policyNo}}</td>
                </tr>
                <tr>
                  <td style="border-bottom: 1px solid black;">Tanggal Permohonan</td>
                  <td style="border-left: 1px solid black;border-bottom: 1px solid black;" colspan="5">{{this.today | date:"dd - MMMM - Y"}}</td>
                </tr>
              </table>
            </td>
          </tr>
<!--          <tr>-->
<!--            <td>SPAJ Kumpulan/SmartProtection/SIO&GIO/Ver.3.2.2Mantap/050923</td>-->
<!--          </tr>-->
          <!-- end of Diisi oleh Staf Bank/Perusahaan Pemberi Kredit -->
          <tr><td class="border" colspan="3" style="height: 20px;"></td></tr>
          <tr>
            <td colspan="3">
              <table style="width: 100%;">
                <tr>
                  <td style="width: 50%;"><img src="../../../assets/logo/logo.png" alt=""></td>
                  <td align="right" style="width: 50%;font-size: 20px;font-weight: 700;">PT Asuransi Allianz Life Indonesia</td>
                </tr>
                <tr>
                  <td align="center" colspan="2"><span style="font-size: 16px;font-weight: 500;border-bottom: 1px solid;">PERNYATAAN DAN SURAT KUASA</span></td>
                </tr>
                <tr>
                  <style>
                    li{
                      text-align: justify;
                    }
                  </style>
                  <td style="vertical-align: top;"> <!-- first column-->
                    <ol type="1">
                      <li>Semua keterangan yang diberikan di dalam SPAJK Kumpulan ini dan keterangan
                        lain yang Saya sampaikan kepada PT Asuransi Allianz Life Indonesia (selanjutnya
                        disebut "Allianz") atau kepada pemeriksa kesehatan yang ditunjuk oleh Allianz
                        adalah benar sudah Saya tuliskan di dalam SPAJK Kumpulan ini (termasuk yang
                        ditulis di dalam dokumen lainnya yang menjadi kelengkapan dari permohonan
                        asuransi ini, jika ada) dan tidak ada keterangan - keterangan dan hal-hal lain yang
                        Saya sembunyikan, serta Saya sendiri yang menandatangani SPAJK Kumpulan ini</li>
                      <li>Pembayaran yang dilakukan oleh Saya untuk pembayaran Premi berdasarkan
                        SPAJK Kumpulan ini tidak berasal dari maupun digunakan untuk tujuan tindak
                        pidana pencucian uang dan pendanaan terorisme sebagaimana dimaksud UU No.
                        8 Tahun 2010 tentang Pencegahan Dan Pemberantasan Tindak Pidana Pencucian
                        Uang dan UU No. 9 Tahun 2013 tentang Pencegahan Dan Pemberantasan Tindak
                        Pidana Pendanaan Terorisme (dan/ atau perubahannya), termasuk tidak terlibat
                        dan/atau terdapat dalam daftar pendanaan proliferasi senjata pemusnah massal.
                        Apabila ada indikasi pelanggaran atas Undang-Undang dan ketentuan-ketentuan
                        tersebut di atas, maka Allianz akan melaksanakan kewajiban sesuai dengan
                        ketentuan yang berlaku atau atas perintah Lembaga Negara yang berwenang,
                        termasuk melakukan kewajiban pelaporan dan memberikan laporan atas transaksi
                        keuangan yang mencurigakan. Dalam rangka memenuhi kewajiban Uji Tuntas
                        Nasabah, Saya bersedia untuk memberikan data yang diminta sesuai dengan
                        peraturan yang berlaku dan Saya bersedia untuk melakukan pengkinian data
                        dalam hal terdapat perubahan data dikemudian hari.</li>
                      <li>Saya dengan ini memberikan persetujuan dan izin, kepada Allianz untuk
                        <ol type="a">
                          <li>menyimpan dan memproses data dan/atau informasi pribadi Saya (termasuk
                            data dan informasi kesehatan serta finansial Saya) dalam rangka dan/atau
                            untuk setiap tujuan berikut ini:
                            <ol type="i">
                              <li>proses pengajuan permohonan asuransi;</li>
                              <li>penerbitan Polis dan/atau Sertifikat Asuransi;</li>
                              <li>penilaian risiko asuransi;</li>
                              <li>kegiatan operasional Allianz;</li>
                              <li>pengajuan dan penanganan setiap klaim (termasuk namun tidak terbatas pada pemeriksaan dan investigasi klaim);</li>
                              <li>pembayaran manfaat asuransi/klaim;</li>
                              <li>keperluan ko-asuransi termasuk penanganan klaim yang timbul atas koasuransi;</li>
                              <li>pelayanan nasabah termasuk penanganan keluhan/komplain;</li>
                              <li>pembayaran komisi;</li>
                              <li>kerja sama dalam rangka penemuan atau pencegahan penipuan dan kejahatan keuangan</li>
                              <li>setiap proses hukum yang melibatkan Allianz dan/atau tenaga pemasarnya</li>
                              <li>kerja sama reasuransi dan/atau retrosesi</li>
                              <li>kepatuhan terhadap perintah pengadilan, hukum dan peraturan perundang-undangan yang berlaku; dan/atau</li>
                              <li>proses lainnya yang terkait dengan penyelenggaraan kegiatan asuransi.</li>
                            </ol>
                          </li>
                          <li>mengungkapkan data dan/atau informasi pribadi Saya (termasuk data dan informasi kesehatan serta finansial Saya) kepada pihak-pihak berikut ini:
                            <ol type="i">
                              <li>ahli medis dan/atau pihak ketiga lainnya (termasuk mereka yang
                                memberikan perawatan, pelayanan kesehatan dan/atau layanan
                                lainnya kepada Saya);</li>
                              <li>setiap perusahaan (termasuk perusahaan penyedia layanan,
                                perusahaan asuransi, dan perusahaan reasuransi), mitra, orang atau
                                pihak ketiga yang ditunjuk dan/atau bekerja sama dengan Allianz;</li>
                              <li>perusahaan induk Allianz, afiliasi, anak perusahaan, agen dan
                                perwakilannya dan/atau usaha patungan; dan/atau</li>
                              <li>otoritas, asosiasi asuransi dan/atau badan pemerintah (regulator),
                                dalam rangka dan/atau untuk setiap tujuan sebagaimana yang diuraikan dalam
                                butir 3 (a) di atas.</li>
                            </ol>
                          </li>
                          <li>Menyimpan serta memproses data dan/atau informasi pribadi Saya (termasuk
                            data dan informasi kesehatan serta finansial Saya dan mengungkapkan data
                            dan/atau informasi pribadi tersebut untuk menanggapi perintah pengadilan
                            atau proses hukum atau permintaan dari regulator manapun atau pihak
                            berwenang atau untuk melindungi terhadap penipuan atau aktivitas ilegal
                            lainnya atau untuk tujuan manajemen risiko atau untuk memungkinkan Allianz
                            untuk melakukan upaya hukum yang tersedia atau membatasi kerusakan yang
                            mungkin terjadi terhadap Allianz, dan/atau mematuhi hukum dan proses hukum
                            termasuk tetapi tidak terbatas pada peraturan tentang FATCA dan CRS
                            (“Kebutuhan Pelaporan Pertukaran Informasi Antar Negara”). Saya juga
                            bersedia untuk memberikan informasi/dokumen yang diperlukan guna
                            memenuhi kebutuhan terkait (“Informasi Terkait”) dan dengan segera
                            menyampaikan pengikian data kepada Allianz jika ada perubahan atas
                            Informasi Terkait tersebut.</li>
                        </ol>
                      </li>
                    </ol>
                  </td><!-- end first column-->
                  <td  style="vertical-align: top;"> <!-- second column-->
                    <ol start="4">
                      <li>Bahwa apabila ternyata pernyataan, keterangan, dokumen dan jawaban yang
                        Saya berikan tidak benar / diragukan kebenarannya, maka Saya memahami serta
                        menyetujui sepenuhnya bahwa Sertifikat Asuransi menjadi batal dan Allianz
                        dibebaskan dari segala kewajibannya membayar Uang Pertanggungan, segala
                        gugatan, tuntutan, klaim atau bagian dari itu dalam bentuk dan nama apapun,
                        maupun mengembalikan Premi, baik saat ini maupun di kemudian hari.</li>
                      <li>Pertanggungan asuransi akan mulai berlaku penuh bila hal-hal berikut ini
                        dipenuhi:
                        <ol type="a">
                          <li>Pertanggungan atas Tertanggung mulai berlaku terhitung sejak tanggal
                            Fasilitas Kredit dicairkan oleh Pemegang Polis kepada Tertanggung</li>
                          <li>Premi beserta biaya-biaya lainnya (apabila ada), telah dibayar lunas.</li>
                        </ol>
                      </li>
                      <li>Saya, dengan ini memberi kuasa kepada setiap dokter, rumah sakit, klinik,
                        puskesmas, instansi lain, perusahaan asuransi, badan hukum, perorangan atau
                        organisasi lainnya, yang mempunyai catatan atau mengetahui keadaan atau
                        kesehatan Saya, suami/istri atau putra/putri Saya, riwayat pengobatan atau
                        perawatan dirumah sakit, nasihat-nasihat dokter baik selama Saya masih hidup
                        atau meninggal dunia dan/atau setiap dokter, rumah sakit, klinik, puskesmas,
                        instansi lain, perusahaan asuransi, badan hukum, perorangan atau organisasi
                        lainnya yang ditunjuk atau direkomendasikan oleh Allianz, untuk memberitahu
                        dan mengungkapkan kepada Allianz atau mereka yang diberi kuasa olehnya,
                        segala keterangan mengenai diri dan kesehatan Saya, suami/istri, atau
                        putra/putri Saya.</li>
                      <li>Saya dengan ini melepaskan ketentuan-ketentuan yang termaktub dalam Pasal
                        1813, 1814, dan 1816 Kitab Undang-Undang Hukum Perdata, serta pemberian
                        kuasa ini tidak dapat ditarik kembali dan mengikat para pengganti/ahli waris/
                        penerusnya dan orang yang ditunjuk dan tetap berlaku setelah Saya meninggal
                        atau dalam keadaan cacat atau berada di bawah pengampuan. Salinan
                        (fotokopi) surat kuasa ini adalah sah dan mempunyai kekuatan hukum yang sama
                        seperti aslinya.</li>
                      <li>Saya telah mendapatkan penjelasan dan sepenuhnya mengerti serta menerima
                        bahwa apabila Saya membatalkan Sertifikat Asuransi dalam jangka waktu 14
                        (empat belas) hari sejak Sertifikat Asuransi Saya terima, maka Allianz wajib
                        mengembalikan paling sedikit sejumlah Premi yang telah Saya bayarkan
                        dikurangi biaya pencetakan dan pengiriman Sertifikat Asuransi, serta biaya
                        pemeriksaan kesehatan Tertanggung (apabila ada).</li>
                      <li>Saya menyadari sepenuhnya bahwa Allianz memiliki hak untuk menolak /
                        menerima, membatalkan / mengubah (dalam hal ini termasuk untuk
                        menambahkan / mengurangi) ketentuan dalam perjanjian pertanggungan ini,
                        termasuk antara lain untuk menentukan bahwa apabila Calon Tertanggung
                        ditolak kepesertaannya oleh Allianz, maka seluruh Premi yang dibayarkan
                        dikembalikan dikurangi biaya-biaya (apabila ada).</li>
                      <li>Saya mengerti bahwa dalam kapasitas Saya sebagai Calon Tertanggung, Saya
                        dapat membatalkan pertanggungan ini dan berhak untuk menerima Premi yang
                        sudah dibayarkan dikurangi biaya-biaya (apabila ada).</li>
                      <li>Saya sadar dan sudah mengetahui serta memahami sepenuhnya produk yang
                        akan dibeli dan segala risiko atas produk tersebut dan Saya telah mendapatkan
                        penjelasan atas produk yang akan Saya beli dan segala risiko atas produk
                        tersebut berdasarkan SPAJK Kumpulan ini</li>
                      <li>Dalam hal Saya memilih korespondensi melalui email dan/atau Sertifikat Asuransi
                        versi elektronik sebagaimana keterangan yang Saya berikan dalam SPAJK
                        Kumpulan ini, maka Saya menyatakan dan menyetujui setiap bentuk
                        korespondensi dan/atau Sertifikat Asuransi versi elektronik dikirimkan melalui
                        email.</li>
                      <li>Saya mengerti bahwa untuk mempelajari lebih lanjut tentang bagaimana Allianz
                        melindungi data pribadi dan hak-hak Saya dengan mengakses tautan berikut:
                        https://www.allianz.co.id/kebijakan-privasi.html</li>
                      <li>Saya mengakui dan menyetujui bahwa SPAJK Kumpulan ini dapat
                        ditandatangani dengan tanda tangan elektronik (baik seluruhnya atau sebagian),
                        yang harus dianggap sebagai tanda tangan asli untuk semua tujuan dan yang
                        memiliki kekuatan hukum yang sama dengan tanda tangan asli (basah). Saya
                        mengakui dan menyetujui bahwa, dalam setiap proses hukum dalam setiap hal
                        yang sehubungan dengan SPAJK Kumpulan ini dan/atau produk asuransi
                        dan/atau permohonan ini, Saya secara tegas mengesampingkan setiap hak untuk
                        (i) mengajukan pembelaan atau pelepasan tanggung jawab / kewajiban;
                        dan/atau (ii) membatalkan SPAJK Kumpulan ini, atas alasan penandatanganan
                        SPAJK Kumpulan ini oleh Saya dengan tanda tangan elektronik.</li>
                      <li>Saya menyatakan bahwa Saya tidak tunduk pada Qanun No. 11 Tahun 2018
                        tentang Lembaga Keuangan Syari’ah dan peraturan pelaksanaannya
                        (sebagaimana dapat diubah, ditambah atau digantikan dari waktu ke waktu)
                        sehingga tidak ada larangan bagi Saya untuk mengajukan dan menandatangani
                        SPAJK Kumpulan dan membeli produk asuransi non-syari’ah.</li>
                      <li>Dengan menandatangani SPAJK Kumpulan ini, maka Saya telah menyetujui
                        pernyataan dan kuasa ini</li>
                    </ol>
                  </td><!-- end second column-->
                </tr>
                <tr>
                  <td colspan="2"><span style="width: 20%;display: inline-block;">Tempat/Ditandatangani di</span>:<span style="margin-left: 5px;width: 20%;display: inline-block;border-bottom: 1px solid black;">{{this.tandaTangan ? this.tandaTangan.tempatTandaTangan : ""}}</span></td>
                </tr>
                <tr>
                  <td colspan="2"><span style="width: 20%;display: inline-block;">Tanggal</span>:<span style="margin-left: 5px;width: 20%;display: inline-block;border-bottom: 1px solid black;">{{this.today | date:"dd - MMMM - Y"}}</span></td>
                </tr>
                <tr><td colspan="2" style="height: 25px;"></td></tr>
                <tr style="border: 3px solid black;">
                  <td align="center" style="display: inline-block;width: 100%;border-right: 1px solid #000">
                    <span >Staf Bank Pemberi Referensi</span>
                    <span style="width: 100%;height: 125px;display: inline-block;"
                          [ngStyle]="this.tandaTangan && { 'background-image': 'url(' + this.tandaTangan.ttdSales + ')',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center',
                            'height': '13em'}">
                    </span>
                    <span style="display: inline-block;width: 100%;">({{namaUser()}})</span>
                  </td>
                  <td align="center" >
                    <span style="display: inline-block;width: 100%;">Calon Tertanggung</span>
                    <span style="width: 100%;height: 125px;display: inline-block;"
                          [ngStyle]="this.tandaTangan && { 'background-image': 'url(' + this.tandaTangan.ttdCandidate + ')',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center',
                            'height': '13em'}">
                    </span>
                    <span style="display: inline-block;width: 100%;">({{spajk.fullName}})</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <!-- end of spajk -->
      <!-- 000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000 -->
      <div class="print-width" style="padding-top: 50px;background-color: rgba(0,0,0,0.2);padding-bottom: 20px;"><h2 style="width: 10%; margin: auto;">RIPLAY</h2></div>
      <div class="riplay-wrapper print-width" id="riplay-wrapper" #riplayWrapper>
        <table style="width: 100%;">
          <!-- title -->
          <tr>
            <td colspan="3">
              <table class="title-riplay" style="margin-bottom: 30px;">
                <tr>
                  <td class="pad-left" rowspan="3" style="width: 20%">
                    <img style="width: 76%;padding-bottom: 10px;" src="../../../assets/logo/logo.png" alt="">
                    <span style="display: block;font-size: 12px;padding-right: 25%;">PT AsuransiAllianz Life Indonesia berizin
                                dan diawasioleh Otoritas Jasa Keuangan</span>
                  </td>
                  <td class="title-middle" style="vertical-align: bottom; padding-top: 20px;">PT Asuransi Allianz Life Indonesia</td>
                  <td class="title-middle" rowspan="3">Tanggal Cetak Dokumen: {{this.today | date:"dd - MMMM - Y"}}</td>
                </tr>
                <tr>
                  <td class="title-middle">RINGKASAN INFORMASI PRODUK DAN LAYANAN (RIPLAY) PERSONAL</td>
                </tr>
                <tr>
                  <td class="title-middle" style="vertical-align:top;padding-bottom: 20px;">{{spajk.productName}}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <!-- kolom 1 -->
            <td style="width: 33%; vertical-align: top;">
              <table style="background-color: rgb(202 227 234); width: 100%;">
                <tr>
                  <td colspan="3" style="padding-left: 20px;padding-top: 10px;"><img style="width: 16%;padding-bottom: 10px;padding-left: 10px;padding-right: 20px;" src="../../../assets/logo/riplay_people.png" alt=""> DATA PERUSAHAAN DAN PEMEGANG POLIS</td>
                </tr>
                <tr>
                  <td style="padding-left: 40px; padding-top: 5px;">Pemegang Polis</td>
                  <td>:</td>
                  <td>{{spajk.partnerName}}</td>
                </tr>
                <tr>
                  <td style="padding-left: 40px;padding-top: 5px;">Nama Produk</td>
                  <td>:</td>
                  <td>{{spajk.productName}}</td>
                </tr>
                <tr>
                  <td style="padding-left: 40px;padding-top: 5px; padding-bottom: 5px;">Jenis Produk</td>
                  <td>:</td>
                  <td>{{spajk.jenisProduct}}</td>
                </tr>
                <tr>
                  <td style="padding-left: 40px; padding-bottom: 10px;">Mata Uang</td>
                  <td>:</td>
                  <td>RUPIAH</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td style="padding-top: 20px;padding-bottom: 20px;" colspan="3">{{spajk.productName}}</td>
                </tr>
                <tr>
                  <td colspan="3" style="text-align: justify;padding-bottom: 20px;padding-right: 20px;">{{this.productInfo()}}</td>
                </tr>
                <tr>
                  <td style="width: 59%;">Usia Masuk Tertanggung <span style="display: block;font-size: 12px;">(Sesuai dengan usia nasabah pada kalkulator premi)</span></td>
                  <td>:</td>
                  <td><span style="border-bottom: 1px solid;width: 70%;display: inline-block;">{{this.calculator.entryAge}}</span>Tahun</td>
                </tr>
                <tr>
                  <td>Premi <span style="display: block;font-size: 12px;">(Sesuai perhitungan pada kalkulator premi)</span></td>
                  <td>:</td>
                  <td>Rp. <span style="border-bottom: 1px solid;width: 70%;display: inline-block;">{{this.calculator.totalPremium.toLocaleString("id-ID")}}</span></td>
                </tr>
                <tr>
                  <td>Masa Pertanggungan <span style="display: block;font-size: 12px;">(Sesuai dengan tenor pinjaman)</span></td>
                  <td>:</td>
                  <td><span style="border-bottom: 1px solid;width: 70%;display: inline-block;">{{spajk.tenure}}</span>Bulan</td>
                </tr>
                <tr>
                  <td>Uang Pertanggungan <span style="display: block;font-size: 12px;">(Sesuai dengan tenor pinjaman)</span></td>
                  <td>:</td>
                  <td>Rp. <span style="border-bottom: 1px solid;width: 70%;display: inline-block;">{{spajk.sumAssured.toLocaleString("id-ID")}}</span></td>
                </tr>
                <tr>
                  <td>Cara Pembayaran Premi</td>
                  <td>:</td>
                  <td>Sekaligus</td>
                </tr>
                <tr>
                  <td>Mata Uang</td>
                  <td>:</td>
                  <td>RUPIAH</td>
                </tr>
              </table>
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td class="sub-title">Manfaat</td>
                </tr>
                <tr><td style="padding-top: 10px; padding-bottom: 10px;font-weight: 600;">Manfaat Meninggal Dunia</td></tr>
                <tr><td style="text-align: justify;padding-right: 20px;">
                  <ul>
                    <li>Manfaat Asuransi akan dibayarkan apabila Tertanggung meninggal dunia karena sebab alami dan/atau Penyakit dan/atau Kecelakaan selama Masa Pertanggungan.</li>
                    <li>Kami akan membayar Manfaat Asuransi kepada Penerima Manfaat sebesar Uang Pertanggungan yang jumlahnya menurun sesuai dengan Tabel Penurunan Uang Pertanggungan dari Kami.</li>
                    <li>Uang Pertanggungan tidak termasuk bunga, denda, penalti dan/atau tunggakan lainnya.</li>
                    <li>Apabila Manfaat Asuransi telah dibayarkan kepada Penerima Manfaat/Pemegang Polis, maka pertanggungan untuk Tertanggung tersebut berakhir.</li>
                  </ul>
                </td></tr>
              </table>
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td class="sub-title">Resiko</td>
                </tr>
                <tr><td style="padding-top: 10px; padding-bottom: 10px;font-weight: 600;padding-left: 20px;">Risiko Kredit</td></tr>
                <tr><td style="text-align: justify;padding-right: 20px;padding-left: 20px;">Dalam hal ini Kami bertindak selaku penyeleksi risiko dari produk asuransi. Risiko kredit berkaitan dengan kemampuan membayar dari Kami yang merupakan kewajiban dari Kami terhadap nasabahnya. Kami telah berhasil mempertahankan kinerjanya untuk melebihi minimum kecukupan modal yang ditentukan oleh Pemerintah.
                </td></tr>
              </table>
            </td>
            <!-- end of kolom 1 -->
            <td style="width: 33%;padding-left: 20px;padding-right: 20px; vertical-align: top;">
              <table style="width: 100%; padding-left: 20px; padding-right: 20px;">
                <tr>
                  <td colspan="2" class="sub-title">Fitur Utama Asuransi Jiwa Kredit Kumpulan</td>
                </tr>
                <tr>
                  <td style="vertical-align: top; font-size: 14px;" class="pe-4">
                    <ul>
                      <li><b>Usia Masuk Tertanggung</b>
                        <span class="li-span">25 – 74 tahun (ulang tahun terdekat),
                                    maksimum usia top-up adalah
                                    77 tahun, pertanggungan maksimum
                                    hingga usia 78 tahun</span></li>
                      <li><b>Masa Pertanggungan</b>
                        <span class="li-span">6 bulan – 15 tahun</span></li>
                      <li><b>Uang Pertanggungan</b>
                        <span class="li-span">Rp10 Juta – Rp500 Juta</span></li>
                      <li><b>Periode Pembayaran Premi</b>
                        <span class="li-span">Premi Tunggal</span></li>
                    </ul>
                  </td>
                  <td style="vertical-align: top;font-size: 14px;width: 38%;">
                    <ul style="padding: 0px; ">
                      <li><b>Mata Uang</b>
                        <span class="li-span">Rupiah</span></li>
                      <li><b>Cara Pembayaran Premi</b>
                        <span class="li-span">Sekaligus</span></li>
                      <li><b>Kondisi Yang Telah Ada Sebelumnya</b>
                        <span class="li-span">Tidak Ada</span></li>
                      <li><b>Masa Tunggu</b>
                        <span class="li-span">Tidak Ada</span></li>
                      <li><b>Masa Peninjauan Polis dan Sertifikat Asuransi</b>
                        <span class="li-span">14 Hari</span></li>
                    </ul>
                  </td>
                </tr>
              </table>
              <table style="width: 100%; padding-left: 20px; padding-right: 20px;">
                <tr>
                  <td class="sub-title">Pengembalian Premi</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-right: 20px; padding-left: 15px;">
                    Apabila (a) Tertanggung melunasi Fasilitas Kredit sebelum tanggal jatuh tempo pelunasan Fasilitas Kredit; atau (b) Kami membatalkan atau mengakhiri Polis dan/atau Sertifikat Asuransi (selain karena alasan terdapatnya unsur penipuan, kekeliruan atau kegagalan dalam mengungkapkan kebenaran atas dokumen yang menjadi dasar Polis dan/atau Sertifikat Asuransi), Kami berkewajiban untuk mengembalikan sisa Premi untuk jangka waktu pertanggungan yang belum terlewati kepada Tertanggung melalui Pemegang Polis sesuai dengan formula pengembalian Premi, sebagai berikut:
                    <br><br>(n-t)/n x Premi x Faktor pengembalian
                    <br><br>
                    <table  style="border: 2px solid black; width: 100%;">
                      <tr>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">Tenor (bulan)</td>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">Faktor pengembalian</td>
                      </tr>
                      <tr>
                        <td style="padding-left:15px; border: 2px solid black; font-weight: 800;">6 - 72</td>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">10%</td>
                      </tr>
                      <tr>
                        <td style="padding-left:15px;border: 2px solid black; font-weight: 800;">78 - 108</td>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">20%</td>
                      </tr>
                      <tr>
                        <td style="padding-left:15px;border: 2px solid black; font-weight: 800;">114 - 144</td>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">30%</td>
                      </tr>
                      <tr>
                        <td style="padding-left:15px;border: 2px solid black; font-weight: 800;">150 - 180</td>
                        <td style="border: 2px solid black; text-align: center; font-weight: 800;">50%</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <table style="font-size: 12px;margin-bottom: 10px">
                <tr>
                  <td style="vertical-align: top;width: 18%; padding-left: 15px;">Catatan</td>
                  <td>n = Masa Pertanggungan
                    <br>t = Ulang tahun polis yang sudah dilalui
                    <br>perhitungan pengembalian premi dalam harian</td>
                </tr>
              </table>
              <table>
                <tr>
                  <td style="padding-left: 15px;text-align: justify;padding-right: 20px;">Pengembalian Premi akan dilakukan oleh Kami setelah dikurangi biaya-biaya yang diperlukan sehubungan dengan pemeliharaan dan/atau pelaksanaan Polis ini.</td>
                </tr>
              </table>
              <table style="width: 100%; padding-left: 20px; padding-right: 20px; margin-top: 20px;">
                <tr>
                  <td class="sub-title">Biaya</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-top: 5px;padding-left: 15px;padding-right: 20px;">Biaya-biaya terkait Polis, antara lain termasuk namun tidak terbatas pada biaya pencetakan dan pengiriman Polis, Sertifikat Asuransi, pemeriksaan kesehatan, pemeliharaan dan/atau pelaksanaan Polis, biaya dalam rangka penutupan Polis dan penerbitan Sertifikat Asuransi, apabila ada.</td>
                </tr>
              </table>
            </td>
            <td style="width: 33%; vertical-align: top;">
              <table style="width: 100%">
                <tr>
                  <td class="sub-title">Persyaratan dan Tata Cara Pengajuan Asuransi Anda</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-right: 20px;padding-left: 15px;">
                    Dalam hal mengajukan asuransi maka Anda harus mengisi, melengkapi, dan menandatangani dokumen sebagai berikut:
                    <ol>
                      <li>SPAJK Kumpulan atau Surat Permohonan Asuransi Jiwa Kredit Kumpulan.</li>
                      <li>Bagi Anda yang disyaratkan melakukan pemeriksaan kesehatan (medis) sesuai dengan ketentuan underwriting yang berlaku di Kami, Anda tersebut diwajibkan untuk melakukan pemeriksaan kesehatan sesuai dengan ketentuan underwriting yang berlaku di Kami.</li>
                      <li>Dokumen-dokumen lainnya yang berkaitan dengan keperluan pengajuan Asuransi</li>
                    </ol>
                  </td>
                </tr>
              </table>
              <table style="width: 100%">
                <tr>
                  <td class="sub-title">Persyaratan dan Tata Cara Pengajuan Klaim Anda</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-right: 20px;">
                    <ol>
                      <li>Penerima Manfaat harus mengajukan klaim secara tertulis dan memberikan dokumen-dokumen dalam Poin 2 kepada Kami tidak lebih dari 180 hari kalendar dan Kami berhak untuk mendapatkan/meminta dokumen dari pihak lainnya yang bersangkutan apabila dokumen dianggap belum cukup.</li>
                      <li>Dokumen-dokumen yang diperlukan untuk pengajuan klaim oleh Penerima Manfaat adalah sebagai berikut:
                        <br><br>
                        <ol type="a">
                          <li>Formulir klaim meninggal dunia yang disediakan oleh Kami.</li>
                          <li>Salinan Kartu Tanda Penduduk Tertanggung.</li>
                          <li>Salinan Kartu Keluarga Tertanggung.</li>
                          <li>Salinan Kartu Tanda Penduduk ahli waris.</li>
                          <li>Surat keterangan sebab-sebab meninggal dari dokter yang merawat.</li>
                          <li>Surat Kematian dari Kelurahan.</li>
                          <li>Surat Keterangan dari Kepolisian dalam hal meninggal tidak wajar atau karena kecelakaan lalu lintas.</li>
                          <li>Surat Keterangan dari Kantor Perwakilan Republik Indonesia setempat dalam hal meninggal di luar negeri.</li>
                        </ol>
                      </li>
                      <li>Pembayaran klaim atas Manfaat Asuransi akan dilakukan oleh Kami selambat-lambatnya dalam waktu 7 (tujuh) hari kerja terhitung setelah dokumen lengkap dan benar diterima oleh Kami dan klaim telah disetujui oleh Kami berdasarkan Polis.</li>
                    </ol>
                  </td>
                </tr>
              </table>
              <table style="width: 100%">
                <tr>
                  <td class="sub-title">Pengecualian</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-right: 20px;;padding-left: 15px;">
                    Kami tidak akan membayar Manfaat Asuransi sesuai ketentuan berikut:
                    <ol>
                      <li>Dalam jangka waktu 12 (dua belas) bulan pertama sejak tanggal berlakunya Masa Pertanggungan, Tertanggung meninggal karena bunuh diri; atau</li>
                      <li>Tertanggung meninggal dalam Masa Pertanggungan karena dihukum mati oleh pengadilan, atau karena dengan sengaja melakukan atau turut serta dalam suatu tindak kejahatan atau suatu percobaan tindak kejahatan, baik aktif maupun tidak, atau apabila Tertanggung meninggal akibat tindak kejahatan asuransi yang dilakukan oleh pihak yang memiliki atau turut memiliki kepentingan dalam pertanggungan asuransi ini.</li>
                    </ol>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <!-- kolom 1 -->
            <td  style="vertical-align:top;">
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td colspan="2" class="sub-title">Simulasi</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-left: 20px;padding-right: 20px;">
                    Debitur A membeli produk SmartProtection BTPN Purnabakti dengan rincian sebagai berikut:
                  </td>
                </tr>
                <ul>
                  <tr>
                    <td style="width: 52%"><li>Uang Pertanggungan</li></td>
                    <td>: Rp140.000.000,-</td>
                  </tr>
                  <tr>
                    <td><li>Suku Bunga efektif per tahun</li></td>
                    <td>: 20%</td>
                  </tr>
                  <tr>
                    <td><li>Masa Pertanggungan</li></td>
                    <td>: 8 Tahun / 96 Bulan / 2922 Hari</td>
                  </tr>
                  <tr>
                    <td><li>Tanggal Mulai Pertanggungan</li></td>
                    <td>: 1 Januari 2023</td>
                  </tr>
                  <tr>
                    <td><li>Tanggal Akhir Pertanggungan</li></td>
                    <td>: 1 Januari 2031</td>
                  </tr>
                  <tr>
                    <td><li>Usia masuk</li></td>
                    <td>60 Tahun</td>
                  </tr>
                </ul>
                <tr>
                  <td colspan="2" style="padding-left: 20px;">Berdasarkan data di atas, maka:</td>
                </tr>
                <tr>
                  <td style="text-align: justify;padding-right: 20px;padding-left: 15px;">
                    <ol>
                      <li>Premi yang di bayarkan:
                        Tarif premi x Uang Pertanggungan
                        111,83‰ x Rp140.000.000,- = Rp15.656.200</li>
                      <li>Dalam hal setelah menjalani 2 Tahun / 24 Bulan / 731 Hari Masa Pertanggungan:
                        <ol type="a">
                          <li>Debitur A meninggal dunia:
                            Manfaat Asuransi
                            Uang Pertanggungan yang dibayarkan:
                            (Tarif Tabel Penurunan Uang Pertanggungan/1000) x Uang Pertanggungan (874,77/1000) x Rp140.000.000 = Rp122.467.800</li>
                          <li>Debitur A melakukan percepatan pelunasan:
                            Pengembalian Premi
                            Premi yang dikembalikan = Rp2.347.894</li>
                        </ol>
                      </li>
                    </ol>
                  </td></tr>
              </table>
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td colspan="2" class="sub-title">Tata Cara Pengaduan & Penyelesaian Sengketa</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-left: 0px;text-align: justify;padding-right: 20px;">
                    <ol>
                      <li>Apabila
                        timbul sengketa antara Anda dan Kami atau pihak lain yang
                        berkepentingan dengan Polis, maka sengketa dapat terlebih dahulu
                        diselesaikan melalui musyawarah untuk mufakat</li>
                      <li>Anda/
                        Pemegang Polis berhak menyampaikan pengaduan kepada
                        Kami dan Kami akan segera menindaklanjuti dan menyelesaikan
                        pengaduan tersebut sesuai dengan prosedur dan peraturan yang
                        ditetapkan oleh Otoritas Jasa Keuangan</li>
                      <li>Dalam hal pengaduan sebagaimana disebutkan dalam poin (1) tidak dapat diselesaikan dan tidak mencapai kesepakatan, Kami dan Pemegang Polis dapat menyelesaikan sengketa di luar pengadilan atau melalui pengadilan yang berwenang</li>
                      <li>Penyelesaian sengketa di luar pengadilan sebagaimana yang dimaksud
                        pada poin 3 dilakukan melalui Lembaga Alternatif Penyelesaian
                        Sengketa yang ditetapkan oleh Otoritas Jasa Keuangan (
                        antara lain Lembaga Alternatif Penyelesaian Sengketa Sektor Jasa
                        Keuangan atau Lembaga Alternatif Penyelesaian Sengketa lainnya
                        yang berwenang dan ditetapkan oleh OJK dari waktu ke waktu</li>
                    </ol>
                  </td>
                </tr>
              </table>
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td class="sub-title">Pengiriman Dokumen Klaim</td>
                </tr>
                <tr><td style="padding-left: 20px;text-align: justify;font-weight: 600;padding-top: 10px;">
                  Allianz Document Management Center (ADMC)
                </td></tr>
                <tr>
                  <td style="padding-left: 20px;text-align: justify;">Setiabudi Atrium, Lt. 3 Suite 308 A-309</td>
                </tr>
                <tr>
                  <td style="padding-left: 20px;text-align: justify;">Jl. H.R. Rasuna Said Kav. 62 Kuningan, Karet Kuningan Kec. Setiabudi</td>
                </tr>
                <tr>
                  <td style="padding-left: 20px;text-align: justify;">Jakarta Selatan 12920</td>
                </tr>
              </table>
            </td>
            <!-- end of column 1 -->
            <td  style="padding-left: 20px;padding-right: 20px; vertical-align: top;">
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td colspan="2" class="sub-title">Prosedur Pelayanan, Penyelesaian Pengaduan dan Klaim</td>
                </tr>
                <tr>
                  <td colspan="2" style="padding-bottom: 15px;">Apabila Anda memiliki pertanyaan dan keluhan terkait produk dan/atau layanan Kami, Anda dapat menyampaikannya melalui Customer Center Kami:</td>
                </tr>
                <tr>
                  <td>
                    <span style="display: block;font-weight: 600;">Alamat:</span>
                    <span style="display: block;font-weight: 600;">PT Asuransi Allianz Life Indonesia</span>
                    <span style="display: block;font-style: italic;">Customer Lounge</span>
                    <span style="display: block;">World Trade Centre 6, Ground Floor
                            Jl. Jenderal Sudirman Kav. 29-31
                            Jakarta Selatan 12920, Indonesia</span>
                    <span style="display: block;padding-top: 15px;">Corporate Number:<br>
                            +6221 2926 8888</span></td>
                  <td style="vertical-align: top;">
                            <span style="display: block;padding-bottom: 15px;">AllianzCare <br>
                            1500 136</span>
                    <span style="display: block;padding-bottom: 15px;">Email: <br>
                            ContactUs@allianz.co.id</span>
                    <span style="display: block;padding-bottom: 15px;">Fax No.: <br>
                            +6221 2926 8080</span>
                    <span style="display: block;">Website: <br>
                            www.allianz.co.id</span>
                  </td>
                </tr>
              </table>
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td class="sub-title">Catatan</td>
                </tr>
                <tr>
                  <td style="text-align: justify;background-color: gainsboro; padding-right: 20px;">
                    <ul>
                      <li>PT Asuransi Allianz Life Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan, dan Tenaga Pemasarnya telah memegang lisensi dari Asosiasi Asuransi Jiwa Indonesia</li>
                      <li>{{spajk.productName}} adalah produk asuransi yang diterbitkan oleh PT Asuransi Allianz Life Indonesia. PT Bank BTPN, Tbk ("Bank") hanya bertindak sebagai pemberi referensi {{spajk.productName}}. {{spajk.productName}} bukan produk Bank sehingga Bank tidak bertanggung jawab atas setiap dan semua klaim dan segala risiko apapun atas Polis yang diterbitkan oleh PT Asuransi Allianz Life Indonesia. {{spajk.productName}} tidak dijamin oleh Bank dan afiliasi-afiliasinya dan tidak termasuk dalam cakupan objek program penjaminan Pemerintah Republik Indonesia atau Lembaga Penjamin Simpanan (“LPS”).</li>
                      <li>
                        Penjelasan pertanggungan asuransi yang lengkap terdapat pada Polis dan/atau Sertifikat Asuransi. Pertanggungan asuransi berlaku ketentuan Pengecualian yang terdapat pada Polis dan/atau Sertifikat Asuransi, yaitu hal-hal yang tidak ditanggung dalam Polis dan/atau Sertifikat Asuransi.
                      </li>
                      <li>Premi yang dibayarkan sudah termasuk komisi untuk pihak Bank</li>
                      <li>manfaat, biaya, risiko, syarat,dan ketentuan sebagaimana tercantum di dalam Polis paling lambat 30 (tiga puluh) hari kerja sebelum berlakunya perubahan manfaat, biaya, risiko, syarat, dan ketentuan tersebut.</li>
                      <li>RIPLAY Personal ini bukan merupakan kontrak atau perjanjian asuransi antara Kami dengan nasabah sehingga tidak mengikat Kami/nasabah</li>
                      <li>Anda wajib membaca, memahami dan menandatangani aplikasi dan dokumen-dokumen pendukung pengajuan asuransi.</li>
                      <li>Anda telah membaca, menerima penjelasan atas pertanyaan Anda (jika ada) dan memahami produk asuransi ini sesuai RIPLAY Personal</li>
                      <li>Informasi yang tercakup dalam RIPLAY Personal ini berlaku sejak tanggal cetak dokumen sampai dengan 30 (tiga puluh) hari kalender dari tanggal cetak dokumen.</li>
                    </ul>
                  </td>
                </tr>
              </table>
            </td> <!-- end of column 2 -->
            <td  style="vertical-align:top;">
              <table style="width: 100%;padding-top: 10px; padding-left: 20px; padding-right: 20px;margin-top: 20px;">
                <tr>
                  <td style="text-align: justify;background-color: gainsboro; padding-right: 20px;">
                    <ul>
                      <li>RIPLAY Personal ini dapat ditandatangani dengan tanda tangan elektronik (baik seluruhnya atau sebagian), yang harus dianggap sebagai tanda tangan asli untuk semua tujuan dan yang memiliki kekuatan hukum yang sama dengan tanda tangan asli (basah). Anda mengakui dan menyetujui bahwa Anda secara tegas mengesampingkan setiap hak untuk (i) mengajukan pembelaan atau pelepasan tanggung jawab/kewajiban; dan/atau (ii) membatalkan RIPLAY Personal ini, atas alasan penandatanganan RIPLAY Personal ini oleh Anda dengan tanda tangan elektronik</li>
                      <li>RIPLAY Personal ini bukan merupakan dari aplikasi pengajuan asuransi dan Polis dan/atau Sertifikat Asuransi</li>
                      <li>Kami berhak menolak pengajuan Polis Anda, apabila tidak memenuhi persyaratan dan peraturan yang berlaku</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td style="padding-left: 20px;background-color: gainsboro;">Definisi</td>
                </tr>
                <tr>
                  <td style="text-align: justify;background-color: gainsboro;padding-right: 20px;">
                    <ul>
                      <li>Anda adalah Calon Tertanggung</li>
                      <li>Kami atau Allianz adalah PT Asuransi Allianz Life Indonesia</li>
                      <li>Pemegang Polis adalah badan hukum yang mengadakan perjanjian Asuransi Jiwa Kredit Kumpulan dengan Allianz dan yang namanya tercantum dalam Data Polis.</li>
                      <li>Tertanggung adalah debitur yang diikutsertakan dalam pertanggungan Asuransi Jiwa Kredit Kumpulan ini oleh Pemegang Polis, yang jiwanya dipertanggungkan dalam Polis, telah didaftarkan secara tertulis oleh Pemegang Polis serta telah disetujui oleh Allianz.</li>
                    </ul>
                  </td>
                </tr>
              </table>
              <table style="width: 100%;margin-bottom: 45px;">
                <tr>
                  <td style="width: 42%;padding-top: 40px;">Tempat/Ditandatangani di</td>
                  <td style="width: 2%;padding-top: 40px;">:</td>
                  <td><span style="border-bottom: 1px solid;width: 100%;display: inline-block;padding-top: 40px;">{{this.tandaTangan ? this.tandaTangan.tempatTandaTangan : ""}}</span></td>
                </tr>
                <tr>
                  <td style="width: 42%;padding-top: 20px;">Tanggal</td>
                  <td style="width: 2%;padding-top: 20px;">:</td>
                  <td><span style="border-bottom: 1px solid;width: 100%;display: inline-block;padding-top: 20px;">{{this.today | date:"dd - MMMM - Y"}}</span></td>
                </tr>
              </table>
              <table>
                <tr>
                  <td class="sub-title" style="width: 49%;text-align: center;border: 2px solid gainsboro;">Tanda Tangan Staf Bank Pemberi Referensi</td>
                  <td class="sub-title" style="width: 49%;text-align: center;border: 2px solid gainsboro;">Tanda Tangan Anda</td>
                </tr>
                <tr>
                  <td style="text-align: center;border: 2px solid gainsboro;height: 229px;">
                    <span style="width: 100%;height: 125px;display: inline-block;"
                          [ngStyle]="this.tandaTangan && { 'background-image': 'url(' + this.tandaTangan.ttdSales + ')',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center',
                            'height': '13em'}">
                    </span></td>
                  <td style="text-align: center;border: 2px solid gainsboro;">
                    <span style="width: 100%;height: 125px;display: inline-block;"
                          [ngStyle]="this.tandaTangan && { 'background-image': 'url(' + this.tandaTangan.ttdCandidate + ')',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center',
                            'height': '13em'}">
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: center;border: 2px solid gainsboro;">({{namaUser()}})</td>
                  <td  style="text-align: center;border: 2px solid gainsboro;">({{spajk.fullName}})</td>
                </tr>
                <tr><td></td>
                  <td style="text-align: justify;padding-top: 20px;">Saya telah menerima, membaca, serta memahami dan menyetujui semua informasi di Ilustrasi dan RIPLAY Personal ini. Saya juga telah menerima penjelasan atas pertanyaan saya (jika ada) dari Tenaga Pemasar asuransi</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center flex-gap">
    <button class="btn btn-back mt-3 py-3 flex-grow-1" type="button" (click)="downloadSpajkAndRiplay()">
      UNDUH PREVIEW SPAJK DAN RIPLAY
    </button>
<!--    <button class="btn btn-back mt-3 py-3 flex-grow-1" type="button">SIMPAN</button>-->
    <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="continue()" [disabled]="this.isLoading">
      <span *ngIf="this.isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      LANJUT</button>
  </div>
</div>


