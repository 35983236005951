import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoginService} from "./shared/login.service";
import {LoginModel} from "./shared/login.model";
import {SessionStorageEnum} from '../global/enum/session-storage.enum';
import pkg from '../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  errorLogin: string = "";
  appVersion: string = pkg.version;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  ngOnInit() {
    console.log("App Version: " + this.appVersion);
    localStorage.clear()
    sessionStorage.clear()
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      alert("Email dan/atau kata sandi tidak valid, mohon periksa kembali masukkan");
    } else {
      this.loginService.login(this.loginForm.value).subscribe((res: LoginModel) => {
        if (res.notes == "success" || res.emailStaff) {
          let product = {
            productcode: res.productCode,
            partnerid: res.partnerId
          }
          const param = {
            queryParams: product
          }
          res.userid = Number(sessionStorage.getItem("session-id")) ?? 0
          sessionStorage.setItem("product", JSON.stringify(product))
          sessionStorage.setItem('login', JSON.stringify(res))
          sessionStorage.setItem(SessionStorageEnum.PARTNER_ID, JSON.stringify(product.partnerid))
          this.router.navigate(["eform/menu"], param);
        } else {
          this.loginForm.setErrors({invalidCredentials: true})
          this.errorLogin = res.notes!;
        }

      });
    }
  }

  goToRoute(route: string) {
    this.router.navigate([route]);
  }
}
