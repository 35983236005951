import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {HttpEformService} from "../../global/HttpEformService";
import {DokumenResponseModel} from "../model/dokumen-response.model";
import {MemberStatusModel} from "../model/member-status.model";
import {AutoUWRequest, AutoUWResponse, NbSubmitRequest, NbSubmitResponse} from "../model/keterangan-kesehatan.model";
import {NewBusinessProcessingRequest, NewBusinessProcessingResponse} from "../model/new-business-processing.model";

@Injectable({
  providedIn: 'root'
})
export class NewCoveringService extends HttpEformService {

  constructor(protected override http: HttpClient) {
    super(http)
  }

  uploadDocument(referenceNumber: string, blob: any, fileType: string): Observable<DokumenResponseModel> {
    let formData = new FormData()
    let url = this.url + "nb/documents/newbusiness/" + referenceNumber;
    const params: HttpParams = new HttpParams().set("fileType", fileType);
    formData.append("file", blob)
    return this.http
      .post<DokumenResponseModel>(url, formData, {
        params: params
      });
  }

  public dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], {type: mimeString});
  }

  getMemberStatus(referenceNumber: string): Observable<MemberStatusModel> {
    let featureUrl = this.url + "result/" + referenceNumber;
    return this.http
      .get<MemberStatusModel>(featureUrl);
  }

  // https://crl-gateway-sit.nonprd.aws.allianz.co.id/eform/nb/processing


  newBusinessProcessing(request: NewBusinessProcessingRequest): Observable<NewBusinessProcessingResponse> {
    let featureUrl = this.url + "nb/processing";
    return this.http
      .post<NewBusinessProcessingResponse>(featureUrl, request).pipe(catchError(this.handleError));
  }

  newBusinessCovering(request: NbSubmitRequest): Observable<NbSubmitResponse> {
    let featureUrl = this.url + "nb/submit"
    return this.http
      .post<NbSubmitResponse>(featureUrl, request).pipe(catchError(this.handleError));
  }

  autoUW(request: AutoUWRequest): Observable<AutoUWResponse> {
    let featureUrl = this.url + "nb/autouw"
    return this.http
      .post<NbSubmitResponse>(featureUrl, request).pipe(catchError(this.handleError));
  }

  buildAutoUW(): AutoUWRequest {
    let calculator = JSON.parse(localStorage.getItem("calculator") ?? "");
    let user = JSON.parse(sessionStorage.getItem('login') ?? "");
    let productInfo = JSON.parse(localStorage.getItem('selected-product') ?? "");
    let rincianDataKesehatan = JSON.parse(localStorage.getItem("rincian-data-kesehatan") ?? "");

    let uwRequest: AutoUWRequest = {
      userId: user.userid,
      partnerId: productInfo.partnerId,
      referenceNo: productInfo.referenceNumber,
      productCode: productInfo.productcode,
      productName: productInfo.productname,

      loanAccountId: productInfo.referenceNumber,
      // loanTypeDescription: "KTA",
      creditBranchCode: user.creditBranchCode,
      branchCode: user.branchCode,
      branchDescription: user.branchOffice,
      bankBranchEmail: user.companyEmail,
      bankStaffName: "Staff BSI", //kurang
      bankStaffEmail: user.emailStaff,

      interestRate: calculator.premiumRate,

      height: rincianDataKesehatan.tinggiBadan,
      weight: rincianDataKesehatan.beratBadan,
      gender: rincianDataKesehatan.jenisKelamin,
      weightGainlossFlag: "",
      weightGainlossKg: "0",
      smokingFlag: "N",
      smokingPcs: "",
      preExistingFlag: "N",
      chestPainFlag: "N",
      highBloodPresureFlag: "N",
      bloodDisorderFlag: "N",
      lungFlag: "N",
      cancerFlag: "N",
      diabeticFlag: "N",
      mentalDisorderFlag: "N",
      brainDisorderFlag: "N",
      lymphGlandFlag: "N",
      intestinesFlag: "N",
      hepatitisFlag: "N",
      drugAbuseFlag: "N",
      alcoholicFlag: "N",
      kidneyFlag: "N",
      cholesterolFlag: "N",
      nervousDisorderFlag: "N",
      hivAidsFlag: "N",
      othersFlag: "N",
      othersRemark: "",
      familyMedicalFlag: "N",
      familyMedicalDesc: "",
      insuranceRejectionFlag: "N",
      insuranceRejectionDesc: "",
      hospitalCareFlag: "N",
      hospitalCareDesc: "",
      medicalOpinionFlag: "N",
      medicalOpinionDesc: ""
    }

    if (calculator.healthQuestionFlag != "N") {
      let keteranganKesehatan = JSON.parse(localStorage.getItem('keterangan-kesehatan') ?? "");
      uwRequest = {
        ...uwRequest,
        height: rincianDataKesehatan.tinggiBadan,
        weight: rincianDataKesehatan.beratBadan,
        gender: rincianDataKesehatan.jenisKelamin,
        weightGainlossFlag: keteranganKesehatan.weightLossKg ? "Y" : "N",
        weightGainlossKg: keteranganKesehatan.weightLossKg.toString(),
        smokingFlag: keteranganKesehatan.smokingFlag ? "Y" : "N",
        smokingPcs: keteranganKesehatan.smokingPcs,
        preExistingFlag: "Y",
        chestPainFlag: keteranganKesehatan.chestPainFlag ? "Y" : "N",
        highBloodPresureFlag: keteranganKesehatan.highBloodPresureFlag ? "Y" : "N",
        bloodDisorderFlag: keteranganKesehatan.bloodDisorderFlag ? "Y" : "N",
        lungFlag: keteranganKesehatan.lungFlag ? "Y" : "N",
        cancerFlag: keteranganKesehatan.cancerFlag ? "Y" : "N",
        diabeticFlag: keteranganKesehatan.diabeticFlag ? "Y" : "N",
        mentalDisorderFlag: keteranganKesehatan.mentalDisorderFlag ? "Y" : "N",
        brainDisorderFlag: keteranganKesehatan.brainDisorderFlag ? "Y" : "N",
        lymphGlandFlag: keteranganKesehatan.lymphGlandFlag ? "Y" : "N",
        intestinesFlag: keteranganKesehatan.intestinesFlag ? "Y" : "N",
        hepatitisFlag: keteranganKesehatan.hepatitisFlag ? "Y" : "N",
        drugAbuseFlag: keteranganKesehatan.drugAbuseFlag ? "Y" : "N",
        alcoholicFlag: keteranganKesehatan.alcoholicFlag ? "Y" : "N",
        kidneyFlag: keteranganKesehatan.kidneyFlag ? "Y" : "N",
        cholesterolFlag: keteranganKesehatan.cholesterolFlag ? "Y" : "N",
        nervousDisorderFlag: keteranganKesehatan.nervousDisorderFlag ? "Y" : "N",
        hivAidsFlag: keteranganKesehatan.hivAidsFlag ? "Y" : "N",
        othersFlag: keteranganKesehatan.othersFlag ? "Y" : "N",
        othersRemark: keteranganKesehatan.othersRemark,
        // pregnantFlag: rincianDataKesehatan.pregnantFlag == "Y" ? "Y" : "",
        // pregnantPeriod: rincianDataKesehatan.pregnantPeriod == "N" ? "" : rincianDataKesehatan.pregnantPeriod.toString(),
        familyMedicalFlag: keteranganKesehatan.familyMedicalFlag ? "Y" : "N",
        familyMedicalDesc: keteranganKesehatan.familyMedicalDesc,
        insuranceRejectionFlag: keteranganKesehatan.insuranceRejectionFlag ? "Y" : "N",
        insuranceRejectionDesc: keteranganKesehatan.insuranceRejectionDesc,
        hospitalCareFlag: keteranganKesehatan.hospitalCareFlag ? "Y" : "N",
        hospitalCareDesc: keteranganKesehatan.hospitalCareDesc,
        medicalOpinionFlag: keteranganKesehatan.medicalOpinionFlag ? "Y" : "N",
        medicalOpinionDesc: keteranganKesehatan.medicalOpinionDesc,
      }
      uwRequest = this.preExistingConditonBsiValidation(uwRequest)
    }
    localStorage.setItem("auto-uw", JSON.stringify(uwRequest))
    return uwRequest;
  }

  buildNewBusinessBsiRequest(): NbSubmitRequest {
    let kontakPribadi = JSON.parse(localStorage.getItem("kontak-pribadi") ?? "");
    let dataPribadi = JSON.parse(localStorage.getItem("data-pribadi") ?? "");
    let kontakKantor = JSON.parse(localStorage.getItem("kontak-kantor") ?? "");
    let rincianPertanggungan = JSON.parse(localStorage.getItem("rincian-pertanggungan") ?? "");
    let calculator = JSON.parse(localStorage.getItem("calculator") ?? "");
    let productInfo = JSON.parse(localStorage.getItem('selected-product') ?? "");
    let user = JSON.parse(sessionStorage.getItem('login') ?? "");
    let rincianDataKesehatan = JSON.parse(localStorage.getItem("rincian-data-kesehatan") ?? "");
    let autoUW: AutoUWRequest = JSON.parse(localStorage.getItem("auto-uw") ?? "");

    let tanggalMulaiAsuransi = new Date(rincianPertanggungan.tanggalMulaiAsuransi)
    let tanggalAkhirAsuransi = new Date(rincianPertanggungan.tanggalAkhirAsuransi)

    let Difference_In_Time =
      tanggalAkhirAsuransi.getTime() - tanggalMulaiAsuransi.getTime();

    let Difference_In_Days =
      Math.round
      (Difference_In_Time / (1000 * 3600 * 24));
    let tenure = Math.ceil(Difference_In_Days / 30)

    let nbRequest: NbSubmitRequest = {
      userId: user.userid,
      partnerId: productInfo.partnerId,
      referenceNo: productInfo.referenceNumber,
      // customerNo: dataPribadi.nik,
      loanAccountNo: productInfo.referenceNumber,
      loanTypeCode: "3", //kurang
      loanTypeDescription: "KTA", //kurang
      interestRate: calculator.premiumRate,
      savingAccountNo: "", //kurang
      // cifSaving: "string", //kurang
      productCode: productInfo.productcode,
      productName: productInfo.productname,
      policyNo: productInfo.policynumber,
      partnerCode: productInfo.partnercode,
      partnerName: user.companyName,
      creditBranchCode: user.creditBranchCode,
      branchCode: user.branchCode,
      branchDescription: user.branchOffice,
      emailaddressbranch: user.companyEmail,
      partnerStaffName: "Staff BSI", //kurang
      emailaddressstaff: user.emailStaff,
      phoneNo: kontakPribadi.nomorHPPribadi,
      npk: "BSI01", //kurang
      effectiveDate: rincianPertanggungan.tanggalAkhirAsuransi,
      maturityDate: "null",
      tenure: tenure.toString(),
      sumAssured: rincianPertanggungan.uangPertanggungan.replaceAll(".", ""),
      fullName: dataPribadi.namaLengkap,
      pob: dataPribadi.tempatLahir,
      dob: rincianPertanggungan.dob,
      gender: rincianDataKesehatan.jenisKelamin,
      maritalStatus: dataPribadi.statusPerkawinan,
      identityType: "1",
      identityNumber: dataPribadi.nik.toString(),
      // npwp: "1666777788889997658",
      address: kontakPribadi.alamatPribadi,
      city: kontakPribadi.kotaPribadi,
      province: kontakPribadi.provinsiPribadi,
      zipcode: kontakPribadi.kodePosPribadi.toString(),
      // homephoneno: kontakPribadi.nomorHPPribadi,
      mobilePhoneNo: kontakPribadi.nomorHPPribadi,
      emailAddress: kontakPribadi.emailPribadi,
      occupation: "Karyawan Swasta",
      companyName: kontakKantor.namaKantor,
      officeAddress: kontakKantor.alamatKantor,
      officeCity: kontakKantor.kotaKantor,
      officeProvince: kontakKantor.provinsiKantor,
      officeZipCode: kontakKantor.kodePosKantor.toString(),
      // emailCorrespondenceFlag: rincianPertanggungan.emailKoresponden,
      // ecertificateFlag: rincianPertanggungan.eSertifikat,
      ...this.convertAutoUWtoNewBusinessRequest(autoUW)
    }
    localStorage.setItem("new-business", JSON.stringify(nbRequest))
    return nbRequest;
  }

  convertAutoUWtoNewBusinessRequest(autoUW: AutoUWRequest): NbSubmitRequest {
    return {
      preexistingConditionFlag: autoUW.preExistingFlag,
      chestPain: autoUW.chestPainFlag,
      highBloodPresure: autoUW.highBloodPresureFlag,
      // stroke: autoUW.,
      // heartBloodVessel: autoUW.highBloodPresureFlag,
      bloodDisorder: autoUW.bloodDisorderFlag,
      // leukimia: autoUW.cancerFlag,
      lungs: autoUW.lungFlag,
      cancerTumor: autoUW.cancerFlag,
      diabetic: autoUW.diabeticFlag,
      mentalDisorders: autoUW.mentalDisorderFlag,
      brainAbnormalities: autoUW.brainDisorderFlag,
      lymphgland: autoUW.lymphGlandFlag,
      stomachIntenstine: autoUW.intestinesFlag,
      liver: autoUW.hepatitisFlag,
      drugAbuse: autoUW.drugAbuseFlag,
      alcoholic: autoUW.alcoholicFlag,
      aidsHiv: autoUW.hivAidsFlag,
      kidney: autoUW.kidneyFlag,
      highCholesterol: autoUW.cholesterolFlag,
      nervousSystemDisorder: autoUW.nervousDisorderFlag,
      othersFlag: autoUW.othersFlag,
      othersRemark: autoUW.othersRemark,
      pregnantFlag: "N",
      pregnantPeriod: "",
      familyHealthFlag: autoUW.familyMedicalFlag,
      familyHealthCondition: autoUW.familyMedicalDesc,
      rejectionPolicyFlag: autoUW.insuranceRejectionFlag,
      rejectionPolicyHistory: autoUW.insuranceRejectionDesc,
      weightGainlossFlag: autoUW.weightGainlossFlag,
      weightGainlossKg: autoUW.weightGainlossKg,
      smokingFlag: autoUW.smokingFlag,
      smokingPcs: autoUW.smokingPcs
    }

  }

  buildNewBusinessRequest(): NbSubmitRequest {

    let kontakPribadi = JSON.parse(localStorage.getItem("kontak-pribadi") ?? "");
    let kontakKantor = JSON.parse(localStorage.getItem("kontak-kantor") ?? "");
    let dataPribadi = JSON.parse(localStorage.getItem("data-pribadi") ?? "");
    let rincianPertanggungan = JSON.parse(localStorage.getItem("rincian-pertanggungan") ?? "");
    let calculator = JSON.parse(localStorage.getItem("calculator") ?? "");
    let productInfo = JSON.parse(localStorage.getItem('selected-product') ?? "");
    let user = JSON.parse(sessionStorage.getItem('login') ?? "");
    let nbRequest: NbSubmitRequest = {
      userId: user.userid,
      partnerId: productInfo.partnerId,
      referenceNo: productInfo.referenceNumber,
      // customerNo: dataPribadi.nik,
      loanAccountNo: productInfo.referenceNumber,
      loanTypeCode: "3", //kurang
      loanTypeDescription: "KTA", //kurang
      interestRate: calculator.premiumRate,
      savingAccountNo: "", //kurang
      // cifSaving: "string", //kurang
      productCode: productInfo.productcode,
      productName: productInfo.productname,
      policyNo: productInfo.policynumber,
      partnerCode: productInfo.partnercode,
      partnerName: user.companyName,
      creditBranchCode: user.creditBranchCode,
      branchCode: user.branchCode,
      branchDescription: user.branchOffice,
      emailaddressbranch: user.companyEmail,
      partnerStaffName: "Staff BTPN", //kurang
      emailaddressstaff: user.emailStaff,
      phoneNo: kontakPribadi.nomorHPPribadi,
      npk: "BKP01", //kurang
      effectiveDate: rincianPertanggungan.effectiveDate,
      maturityDate: "null",
      tenure: rincianPertanggungan.masaPertanggungan,
      sumAssured: rincianPertanggungan.uangPertanggungan.replaceAll(".", ""),
      fullName: dataPribadi.namaLengkap,
      pob: dataPribadi.tempatLahir,
      dob: rincianPertanggungan.dob,
      gender: dataPribadi.jenisKelamin,
      maritalStatus: dataPribadi.statusPerkawinan,
      identityType: "1",
      identityNumber: dataPribadi.nik.toString(),
      // npwp: "1666777788889997658",
      address: kontakPribadi.alamatPribadi,
      city: kontakPribadi.kotaPribadi,
      province: kontakPribadi.provinsiPribadi,
      zipcode: kontakPribadi.kodePosPribadi.toString(),
      // homephoneno: kontakPribadi.nomorHPPribadi,
      mobilePhoneNo: kontakPribadi.nomorHPPribadi,
      emailAddress: kontakPribadi.emailPribadi,
      occupation: dataPribadi.perkerjaan,
      companyName: kontakKantor.namaKantor,
      officeAddress: kontakKantor.alamatKantor,
      officeCity: kontakKantor.kotaKantor,
      officeProvince: kontakKantor.provinsiKantor,
      officeZipCode: kontakKantor.kodePosKantor.toString(),
      emailCorrespondenceFlag: rincianPertanggungan.emailKoresponden,
      ecertificateFlag: rincianPertanggungan.eSertifikat,
      preexistingConditionFlag: "N",
      chestPain: "N",
      highBloodPresure: "N",
      stroke: "N",
      heartBloodVessel: "N",
      bloodDisorder: "N",
      leukimia: "N",
      lungs: "N",
      cancerTumor: "N",
      diabetic: "N",
      mentalDisorders: "N",
      brainAbnormalities: "N",
      lymphgland: "N",
      stomachIntenstine: "N",
      liver: "N",
      drugAbuse: "N",
      alcoholic: "N",
      aidsHiv: "N",
      othersFlag: "",
      othersRemark: "",
      pregnantFlag: "",
      pregnantPeriod: "",
      familyHealthFlag: "",
      familyHealthCondition: "",
      rejectionPolicyFlag: "",
      rejectionPolicyHistory: "",
      weightGainlossFlag: "N",
      weightGainlossKg: "0",
      smokingFlag: "N",
      smokingPcs: "",
      height: "0",
      weight: "0",
    }
    if (calculator.healthQuestionFlag == "Y") {
      let rincianDataKesehatan = JSON.parse(localStorage.getItem("rincian-data-kesehatan") ?? "");
      let keteranganKesehatan = JSON.parse(localStorage.getItem('keterangan-kesehatan') ?? "");
      nbRequest = {
        ...nbRequest,
        height: rincianDataKesehatan.tinggiBadan,
        weight: rincianDataKesehatan.beratBadan,
        chestPain: keteranganKesehatan.chestPain ? "Y" : "N",
        highBloodPresure: keteranganKesehatan.highBloodPresure ? "Y" : "N",
        stroke: keteranganKesehatan.stroke ? "Y" : "N",
        heartBloodVessel: keteranganKesehatan.heartBloodVessel ? "Y" : "N",
        bloodDisorder: keteranganKesehatan.bloodDisorder ? "Y" : "N",
        leukimia: keteranganKesehatan.leukimia ? "Y" : "N",
        lungs: keteranganKesehatan.lungs ? "Y" : "N",
        cancerTumor: keteranganKesehatan.cancerTumor ? "Y" : "N",
        diabetic: keteranganKesehatan.diabetic ? "Y" : "N",
        mentalDisorders: keteranganKesehatan.mentalDisorders ? "Y" : "N",
        brainAbnormalities: keteranganKesehatan.brainAbnormalities ? "Y" : "N",
        lymphgland: keteranganKesehatan.lymphgland ? "Y" : "N",
        stomachIntenstine: keteranganKesehatan.stomachIntenstine ? "Y" : "N",
        liver: keteranganKesehatan.liver ? "Y" : "N",
        drugAbuse: keteranganKesehatan.drugAbuse ? "Y" : "N",
        alcoholic: keteranganKesehatan.alcoholic ? "Y" : "N",
        aidsHiv: keteranganKesehatan.aidsHiv ? "Y" : "N",
        othersFlag: keteranganKesehatan.othersFlag ? "Y" : "",
        othersRemark: keteranganKesehatan.othersRemark,
        pregnantFlag: rincianDataKesehatan.pregnantFlag == "Y" ? "Y" : "",
        pregnantPeriod: rincianDataKesehatan.pregnantPeriod == "N" ? "" : rincianDataKesehatan.pregnantPeriod.toString(),
        familyHealthFlag: keteranganKesehatan.familyHealthFlag ? "Y" : "",
        familyHealthCondition: keteranganKesehatan.familyHealthCondition,
        rejectionPolicyFlag: keteranganKesehatan.rejectionPolicyFlag ? "Y" : "",
        rejectionPolicyHistory: keteranganKesehatan.rejectionPolicyHistory,
        weightGainlossFlag: keteranganKesehatan.weightLossKg ? "Y" : "",
        weightGainlossKg: keteranganKesehatan.weightLossKg.toString(),
        smokingFlag: keteranganKesehatan.smokingFlag ? "Y" : "",
        smokingPcs: keteranganKesehatan.smokingPcs
      }
    }
    localStorage.setItem("new-business", JSON.stringify(this.preExistingConditonValidation(nbRequest)))
    return nbRequest;
  }

  preExistingConditonValidation(newBusiness: NbSubmitRequest): NbSubmitRequest {
    if (
      newBusiness.chestPain == "Y" ||
      newBusiness.highBloodPresure == "Y" ||
      newBusiness.stroke == "Y" ||
      newBusiness.heartBloodVessel == "Y" ||
      newBusiness.bloodDisorder == "Y" ||
      newBusiness.leukimia == "Y" ||
      newBusiness.lungs == "Y" ||
      newBusiness.lymphgland == "Y" ||
      newBusiness.cancerTumor == "Y" ||
      newBusiness.mentalDisorders == "Y" ||
      newBusiness.brainAbnormalities == "Y" ||
      newBusiness.stomachIntenstine == "Y" ||
      newBusiness.liver == "Y" ||
      newBusiness.alcoholic == "Y" ||
      newBusiness.aidsHiv == "Y" ||
      newBusiness.othersFlag == "Y"
    ) {
      newBusiness.preexistingConditionFlag = "Y"
      return newBusiness
    } else {
      newBusiness.preexistingConditionFlag = "N"
      return newBusiness
    }
  }

  preExistingConditonBsiValidation(uw: AutoUWRequest): AutoUWRequest {
    if (
      uw.chestPainFlag == "Y" ||
      uw.highBloodPresureFlag == "Y" ||
      uw.kidneyFlag == "Y" ||
      uw.bloodDisorderFlag == "Y" ||
      uw.cholesterolFlag == "Y" ||
      uw.lungFlag == "Y" ||
      uw.cancerFlag == "Y" ||
      uw.diabeticFlag == "Y" ||
      uw.mentalDisorderFlag == "Y" ||
      uw.nervousDisorderFlag == "Y" ||
      uw.brainDisorderFlag == "Y" ||
      uw.lymphGlandFlag == "Y" ||
      uw.intestinesFlag == "Y" ||
      uw.hepatitisFlag == "Y" ||
      uw.drugAbuseFlag == "Y" ||
      uw.alcoholicFlag == "Y" ||
      uw.hivAidsFlag == "Y" ||
      uw.othersFlag == "Y"
    ) {
      uw.preExistingFlag = "Y"
      return uw
    } else {
      uw.preExistingFlag = "N"
      return uw
    }
  }
}
