import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {LOCALE_ID, NgModule} from "@angular/core";
import {FooterComponent} from './footer/footer.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HeaderComponent} from "./header/header.component";
import {LayoutComponent} from './layout/layout.component';
import {EformModule} from "./eform.module";
import {FinalDecisionComponent} from "./final-decision/final-decision.component";
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
// import {NdbxIconModule} from "@allianz/ngx-ndbx/icon";
// import {NxExpertModule} from "@aposin/ng-aquila/config";
import localeId from '@angular/common/locales/id';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ElliseInterceptor} from "./global/ellise.interceptor";

registerLocaleData(localeId);
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    EformModule,
    // NdbxIconModule,
    // NxExpertModule,
  ],
  // providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'id-ID'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ElliseInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
