<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img *ngIf="environment.partnerIdBSI == login.partnerId" src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
    <img *ngIf="environment.partnerIdBSI !== login.partnerId" src="assets/Logo-eSPAJK.jpg" width="250" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5 color-blue"><b>ELLISE Sales Dashboard</b></h2>
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-2">
  <div class="bg-white form-content pb-3 align-content-center mb-4">
    <div class="px-2">

      <p (click)="back()" class="cursor-pointer"><i class="bi bi-arrow-left"></i> Kembali</p>
    </div>
    <div class="d-flex justify-content-between align-content-center px-2">
      <span>Search by <select class="bottom-border" (change)="selectSearch($event)">
          <option *ngFor="let searchBy of searchByList" [value]="searchBy.value"> {{searchBy.label}} </option>
        </select>
      </span>
      <span>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="resetTable()"><i class="bi-repeat"></i> Muat
          Ulang</button>
      </span>
    </div>
    <div class="d-flex justify-content-between align-content-center px-2 pt-1 flex-gap">
      <span>
        <input type="text" (change)="searchFromTable($event)">
      </span>
      <span> Status Filter <select class="bottom-border" (change)="selectStatus($event)">
          <option style='display: none'></option>
          <option *ngFor="let status of statusList" [value]="status.value"> {{status.label}} </option>
        </select>
      </span>
    </div>
    <div class="table-responsive mt-3">
      <table class="table">
        <thead class="table-secondary">
          <tr>
            <th scope="col">No Ref.</th>
            <th scope="col">Nama</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let model of viewDashboardModels">
            <td>{{model.referenceNo}}</td>
            <td>{{model.fullName}}</td>
            <td><button class="btn d-inline-block rounded px-2" [ngClass]="getStatusClass(model.status)"
                (click)="uploadDokumen(model, false)">{{statusDescription(model.status)}}</button>
              <button class="btn btn-success d-inline-block rounded px-2" *ngIf="model.status == 'PENDING_DOCUMENT'" (click)="uploadDokumen(model, true)">Unduh Ulang Spajk/Riplay</button>
            </td>
          </tr>
<!--        <tr *ngFor="let model of viewDashboardModels">-->
<!--          <td>{{model.referenceNo}}</td>-->
<!--          <td>{{model.fullName}}</td>-->
<!--          <td><button class="btn d-inline-block rounded px-2" [ngClass]="getStatusClass(model.status)" (click)="model.status == 'PENDING_DOCUMENT' || model.status == 'PENDING_DATA' ? uploadDokumen(model) : null">{{statusDescription(model.status)}}</button></td>-->
<!--        </tr>-->
        </tbody>
      </table>
    </div>

    <div *ngIf="isLoading" class="d-flex justify-content-center">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
  </div>
</div>
