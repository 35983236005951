<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5"><b>Rincian Data Kesehatan</b></h2>
    <h6 class="text-center">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <div class="container mt-5 pt-4 form-header">
    <div class="row d-flex justify-content-around">
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
    </div>
    <div class="row">
      <p class="col x-2">Step 4 dari 4</p>
      <p class="text-end col-8 px-2">Data Kesehatan</p>
    </div>
  </div>
</div>
<div class="container form-container">
  <form [formGroup]="keteranganKesehatanForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p class="" (click)="back()"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title color-light-blue bold">Keterangan Kesehatan</p>
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['weightLossKg'].dirty || form.submitted) && !keteranganKesehatanForm.controls['weightLossKg'].valid}">
        <p class="title-input m-0">Berapa perubahan berat badan dalam 12 bulan terakhir? *</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="0" max="99" formControlName="weightLossKg"
                 [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['weightLossKg'].dirty || form.submitted) && !keteranganKesehatanForm.controls['weightLossKg'].valid}">
          <span>Kg</span>
        </div>
        <p class="description-input">Isi dengan angka saja (kg penambahan atau pengurangan berat badan)</p>
      </div>

      <div class="question-container bottom-border">
        <span class="question-text">Apakah Anda Merokok *</span>
        <label class="switch">
          <input type="checkbox" formControlName="smokingFlag">
          <span class="slider round"></span>
        </label>
      </div>
      <div *ngIf="keteranganKesehatanForm.controls['smokingFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['smokingPcs'].dirty || form.submitted) && !keteranganKesehatanForm.controls['smokingPcs'].valid}">
        <p class="title-input m-0">Jika "Ya" sebutkan jumlah batang per hari?</p>
        <div class="d-flex align-items-center">
          <input class="bottom-border flex-grow-1" type="number" min="0" max="99" formControlName="smokingPcs"
                 [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['smokingPcs'].dirty || form.submitted) && !keteranganKesehatanForm.controls['smokingPcs'].valid}">
          <span>Batang</span>
        </div>
      </div>
      <p class="text-justify">Apakah anda pernah/dianjurkan/sedang menjalani pengobatan atau perawatan di Rumah Sakit untuk mengetahui atau
        menderita penyakit/keadaan sebagai berikut:</p>

      <div class="question-container">
        <span class="question-text">Sakit dada (chest pain)/Jantung & Pembuluh Darah *</span>
        <label class="switch">
          <input type="checkbox" formControlName="chestPainFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Darah tinggi/Stroke *</span>
        <label class="switch">
          <input type="checkbox" formControlName="highBloodPresureFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Ginjal/Kandung Kemih/Prostat *</span>
        <label class="switch">
          <input type="checkbox" formControlName="kidneyFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kelainan Darah/Anemia/Leukimia *</span>
        <label class="switch">
          <input type="checkbox" formControlName="bloodDisorderFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kolesterol Tinggi *</span>
        <label class="switch">
          <input type="checkbox" formControlName="cholesterolFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Paru-Paru/Gangguan Pernapasan *</span>
        <label class="switch">
          <input type="checkbox" formControlName="lungFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kanker/Tumor/Kista *</span>
        <label class="switch">
          <input type="checkbox" formControlName="cancerFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kencing Manis *</span>
        <label class="switch">
          <input type="checkbox" formControlName="diabeticFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kelainan Jiwa/Depresi *</span>
        <label class="switch">
          <input type="checkbox" formControlName="mentalDisorderFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Gangguan Sistem Saraf/Sendi/Tulang/Epilepsi *</span>
        <label class="switch">
          <input type="checkbox" formControlName="nervousDisorderFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Segala Jenis Kelainan Otak *</span>
        <label class="switch">
          <input type="checkbox" formControlName="brainDisorderFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Kelenjar Getah Bening/Tiroid *</span>
        <label class="switch">
          <input type="checkbox" formControlName="lymphGlandFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Lambung/Usus *</span>
        <label class="switch">
          <input type="checkbox" formControlName="intestinesFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Hepatitis/Liver * </span>
        <label class="switch">
          <input type="checkbox" formControlName="hepatitisFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Penggunaan/Ketergantungan Obat *</span>
        <label class="switch">
          <input type="checkbox" formControlName="drugAbuseFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">Penggunaan/Ketergantungan Alkohol *</span>
        <label class="switch">
          <input type="checkbox" formControlName="alcoholicFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div class="question-container">
        <span class="question-text">AIDS/HIV/Penyakit Menular Seksual *</span>
        <label class="switch">
          <input type="checkbox" formControlName="hivAidsFlag">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Jika ada penyakit lainnya, mohon sebutkan?</p>
        <input class="bottom-border" type="text" formControlName="othersRemark">
      </div>

      <div class="question-container bottom-border">
        <span class="question-text">Dalam 5 Tahun terakhir, (i) Apakah anda pernah disarankan untuk dirawat di rumah sakit dan menjalankan operasi bedah (selain flu biasa, atau komplikasi kehamilan atau operasi caesar), ATAU (ii) Apakah anda mendapatkan hasil yang tidak normal dari pemeriksaan kesehatan seperti USG, mammogram, CT Scan, biopsy, pap smear, EKG, rontgen, hasil darah atau hasil urine yang disarankan dokter untuk mengulang tes/pemeriksaan lanjutan? *</span>
        <label class="switch">
          <input type="checkbox" formControlName="hospitalCareFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['hospitalCareFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['hospitalCareDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['hospitalCareDesc'].valid}">
        <p class="title-input m-0">Jelaskan alasannya*</p>
        <input class="bottom-border" type="text" formControlName="hospitalCareDesc"
               [required]="keteranganKesehatanForm.controls['hospitalCareFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['hospitalCareDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['hospitalCareDesc'].valid}">
      </div>

      <div class="question-container  bottom-border">
        <span class="question-text">Apakah anda bermaksud mencari pendapat medis atau pernah disarankan untuk menjalani pemeriksaan kesehatan yang sedang berlangsung atau belum selesai (selain flu biasa, kehamilan tanpa komplikasi) *</span>
        <label class="switch">
          <input type="checkbox" formControlName="medicalOpinionFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['medicalOpinionFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['medicalOpinionDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['medicalOpinionDesc'].valid}">
        <p class="title-input m-0">Jelaskan alasannya*</p>
        <input class="bottom-border" type="text" formControlName="medicalOpinionDesc"
               [required]="keteranganKesehatanForm.controls['medicalOpinionFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['medicalOpinionDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['medicalOpinionDesc'].valid}">
      </div>

      <div class="question-container  bottom-border">
        <span class="question-text">Apakah ada anggota keluarga terdekat anda (Ayah, Ibu, Kakak, Adik) yang menderita atau meninggal karena penyakit kanker, jantung, stroke, diabetes, atau penyakit keturunan lainnya sebelum usia 60 tahun? *</span>
        <label class="switch">
          <input type="checkbox" formControlName="familyMedicalFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['familyMedicalFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['familyMedicalDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['familyMedicalDesc'].valid}">
        <p class="title-input m-0">Jelaskan alasannya*</p>
        <input class="bottom-border" type="text" formControlName="familyMedicalDesc"
               [required]="keteranganKesehatanForm.controls['familyMedicalFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['familyMedicalDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['familyMedicalDesc'].valid}">
      </div>

      <div class="question-container  bottom-border">
        <span class="question-text">Apakah permohonan perlindungan asuransi Anda pernah ditolak, ditangguhkan atau dikenakan kontribusi tambahan? *</span>
        <label class="switch">
          <input type="checkbox" formControlName="insuranceRejectionFlag">
          <span class="slider round"></span>
        </label>
      </div>

      <div *ngIf="keteranganKesehatanForm.controls['insuranceRejectionFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['insuranceRejectionDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['insuranceRejectionDesc'].valid}">
        <p class="title-input m-0">Jelaskan alasannya*</p>
        <input class="bottom-border" type="text" formControlName="insuranceRejectionDesc"
               [required]="keteranganKesehatanForm.controls['insuranceRejectionFlag'].value"
               [ngClass]="{'text-danger' : (keteranganKesehatanForm.controls['insuranceRejectionDesc'].dirty || form.submitted) && !keteranganKesehatanForm.controls['insuranceRejectionDesc'].valid}">
      </div>
    </div>


    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>
</div>
