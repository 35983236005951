import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {LoginModule} from "./login/login.module";
import {RiplayUmumModule} from "./riplay-umum/riplay-umum.module";
import {RincianPertanggunganModule} from "./rincian-pertanggungan/rincian-pertanggungan.module";
import {RincianDataKesehatanModule} from "./rincian-data-kesehatan/rincian-data-kesehatan.module";
import {PengisianDataPribadiModule} from "./pengisian-data-pribadi/pengisian-data-pribadi.module";
import {KontakDataPribadiModule} from "./kontak-data-pribadi/kontak-data-pribadi.module";
import {KeteranganKesehatanModule} from "./keterangan-kesehatan/keterangan-kesehatan.module";
import {GlobalModule} from "./global/global.module";
import {EformRoutingModule} from "./eform-routing.module";
import {KontakDataKantorModule} from "./kontak-data-kantor/kontak-data-kantor.module";
import {SpajkRiplayModule} from "./spajk-riplay/spajk-riplay-btpn/spajk-riplay.module";
import {VideoPernyataanModule} from "./video-pernyataan/video-pernyataan.module";
import {SummaryModule} from "./summary/summary.module";
import {FinalDecisionModule} from "./final-decision/final-decision.module";
import {RegistrationModule} from "./registration/registration.module";
import {VerifyComponent} from './verify/verify.component';
import {ForgotPasswordModule} from "./forgot-password/forgot-password.module";
import {ChangePasswordModule} from "./change-password/change-password.module";
import {ErrorModule} from "./error/error.module";
import {
  RincianPertanggunganBsiModule
} from "./pertanggungan/rincian-pertanggungan-bsi/rincian-pertanggungan-bsi.module";
import {SpajkRiplayBsiModule} from "./spajk-riplay/spajk-riplay-bsi/spajk-riplay-bsi.module";
import {MenuModule} from "./menu/menu.module";
import {HealthQuestionBsiModule} from "./health-question/health-question-bsi/health-question-bsi.module";
import {RincianKesehatanBsiModule} from "./health-question/rincian-kesehatan-bsi/rincian-kesehatan-bsi.module";
import {FinalBsiModule} from "./final-bsi/final-bsi.module";
import {PreviewBsiModule} from "./preview-bsi/preview-bsi.module";
import {PengisianDataPribadiBsiModule} from "./pengisian-data-pribadi-bsi/pengisian-data-pribadi-bsi.module";
import {DashboardModule} from "./dashboard/dashboard.module";
import {UploadDokumenModule} from "./upload-dokumen/upload-dokumen.module";
import {FinalDokumenModule} from "./final-dokumen/final-dokumen.module";
import { DraftSavedModule } from "./draft-saved/draft-saved.module";
import { InsuredSummaryModule } from "./insured-summary/insured-summary.module";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    EformRoutingModule,
    KontakDataKantorModule,
    LoginModule,
    RiplayUmumModule,
    RincianPertanggunganModule,
    RincianDataKesehatanModule,
    PengisianDataPribadiModule,
    KontakDataPribadiModule,
    KeteranganKesehatanModule,
    GlobalModule,
    SpajkRiplayModule,
    VideoPernyataanModule,
    SummaryModule,
    FinalDecisionModule,
    RegistrationModule,
    ForgotPasswordModule,
    ChangePasswordModule,
    ErrorModule,
    RincianPertanggunganBsiModule,
    SpajkRiplayBsiModule,
    MenuModule,
    HealthQuestionBsiModule,
    RincianKesehatanBsiModule,
    FinalBsiModule,
    PreviewBsiModule,
    PengisianDataPribadiBsiModule,
    DashboardModule,
    UploadDokumenModule,
    FinalDokumenModule,
    DraftSavedModule,
    InsuredSummaryModule
  ],
  declarations: [
    VerifyComponent,
  ],
  exports: [],
  providers: [
    NgbActiveModal,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: ''
    },
    {
      provide: LOCALE_ID,
      useValue: 'id-id'
    },
  ]
})
export class EformModule {
}
