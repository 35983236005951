import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";

@Component({
  selector: 'app-pengisian-data-pribadi',
  templateUrl: './pengisian-data-pribadi.component.html',
  styleUrls: ['./pengisian-data-pribadi.component.css']
})
export class PengisianDataPribadiComponent {

  queryParam: any;
  productInfo: RiplayUmumModel = {};
  dataPribadiForm: FormGroup;
  calculatorModel: CalculatorResponse = {}
  rincianPertanggungan: any = {}
  calculator: any = {}
  gambarUrl: any = null;
  isCameraActive: boolean = false;
  camera: string = "user";
  canvasWidth: number = 0;
  canvasHeight: number = 0;

  @ViewChild("video") video!: ElementRef;
  @ViewChild("canvas") canvas!: ElementRef;
  @ViewChild("dokumenKtp") dokumenKtp!: ElementRef;
  @ViewChild("optionCamera") optionCamera!: ElementRef;

  listJenisKelamin: any[] = [
    {
      jenisKelamin: "Pria",
      value: "M"
    },
    {
      jenisKelamin: "Wanita",
      value: "F"
    }];
  listStatusPerkawinan: any[] = [
    {
      statusPerkawinan: "Belum Menikah",
      value: "S"
    },
    {
      statusPerkawinan: "Menikah",
      value: "M"
    },
    {
      statusPerkawinan: "Janda",
      value: "W"
    }];

  listPekerjaan: any[] = ["Karyawan", "Pensiunan", "Ibu Rumah Tangga", "PNS", "Lainnya"];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonPertanggungan = localStorage.getItem('rincian-pertanggungan')
    let jsonCalculator = localStorage.getItem('calculator')
    if (jsonProduct != null && jsonPertanggungan != null && jsonCalculator != null) {
      this.productInfo = JSON.parse(jsonProduct);
      this.rincianPertanggungan = JSON.parse(jsonPertanggungan);
      this.calculator = JSON.parse(jsonCalculator);
    }

    this.dataPribadiForm = formBuilder.group({
      nik: ['', [Validators.required, this.requiredDigitsValidator(16)]],
      namaLengkap: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      jenisKelamin: ['', Validators.required],
      statusPerkawinan: ['', Validators.required],
      tempatLahir: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      usia: [{value: this.calculator.entryAge, disabled: true}],
      perkerjaan: ['', Validators.required],
      fileInput: ['', Validators.required]
    })

    this.queryParam = {
      productcode: this.route.snapshot.queryParamMap.get('productcode'),
      partnerid: this.route.snapshot.queryParamMap.get('partnerid')
    }
    let calculatorString = localStorage.getItem("calculator");
    if (calculatorString !== null) {
      this.calculatorModel = JSON.parse(calculatorString);
      this.dataPribadiForm.patchValue({
        usia: this.calculatorModel.entryAge,
        masaPertanggungan: this.rincianPertanggungan.masaPertanggungan
      })
    } else {
      console.error("Calculator data not found in local storage");
    }
  }

  requiredDigitsValidator(maxDigits: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value && value.toString().length != maxDigits) {
        return {maxDigits: true};
      }
      return null;
    };
  }

  continue() {
    if (this.dataPribadiForm.valid) {
      this.video.nativeElement.srcObject.stop()
      localStorage.setItem("data-pribadi", JSON.stringify(this.dataPribadiForm.value))
      this.router.navigate(["eform/kontak-data-pribadi"]);
    }
  }

  async mulaiCamera(cameraFacing: string) {
    this.dokumenKtp.nativeElement.style.height = "375px"
    this.gambarUrl = ""
    this.isCameraActive = true;

    let constraint: any = {
      video: {
        facingMode: {
          ideal: cameraFacing
        }
      }
    }

    this.video.nativeElement.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
    this.video.nativeElement.addEventListener('loadedmetadata', () => {
      this.canvasWidth = this.video.nativeElement.videoWidth
      this.canvasHeight = this.video.nativeElement.videoHeight
    });
  }

  ambilGambar(event: any) {
    event.stopPropagation();
    this.isCameraActive = false;
    this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0, this.canvasWidth, this.canvasHeight);
    this.gambarUrl = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.video.nativeElement.srcObject.stop();
    this.dokumenKtp.nativeElement.style.height = "194px"
    this.dataPribadiForm.controls['fileInput'].patchValue(this.gambarUrl)
  }

  resetFotoKtp() {
    this.gambarUrl = ""
    this.isCameraActive = false;
    this.dataPribadiForm.controls['fileInput'].patchValue(this.gambarUrl)
    if (this.video.nativeElement.srcObject) this.video.nativeElement.srcObject.stop();
  }

  switchCamera() {
    this.video.nativeElement.srcObject.stop()
    this.camera = this.camera == "user" ? "environment" : "user";
    this.mulaiCamera(this.camera)
  }

  back() {
    this.video.nativeElement.srcObject.stop()
    this.router.navigate(['eform/rincian-pertanggungan'], {
      queryParams: this.queryParam
    })
  }
}
