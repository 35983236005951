import {Component} from '@angular/core';
import {RiplayUmumModel} from "../../riplay-umum/shared/riplay-umum.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {NewCoveringService} from "../../service/new-covering/new-covering.service";

@Component({
  selector: 'app-health-question-bsi',
  templateUrl: './health-question-bsi.component.html',
  styleUrls: ['./health-question-bsi.component.css']
})
export class HealthQuestionBsiComponent {
  productInfo: RiplayUmumModel = {};
  keteranganKesehatanForm: FormGroup;

  constructor(
    private router: Router,
    private newCoveringService: NewCoveringService,
    private formBuilder: FormBuilder,
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

      this.keteranganKesehatanForm = formBuilder.group({
        weightLossKg: ['', Validators.required],
        chestPainFlag: [false],
        highBloodPresureFlag: [false],
        kidneyFlag: [false],
        bloodDisorderFlag: [false],
        cholesterolFlag: [false],
        lungFlag: [false],
        cancerFlag: [false],
        diabeticFlag: [false],
        mentalDisorderFlag: [false],
        nervousDisorderFlag: [false],
        brainDisorderFlag: [false],
        lymphGlandFlag: [false],
        intestinesFlag: [false],
        hepatitisFlag: [false],
        drugAbuseFlag: [false],
        alcoholicFlag: [false],
        hivAidsFlag: [false],

        othersFlag: [false],
        othersRemark: [''],
        familyMedicalFlag: [false],
        familyMedicalDesc: [''],
        smokingFlag: [false,],
        smokingPcs: [''],
        insuranceRejectionFlag: [false],
        insuranceRejectionDesc: [''],
        hospitalCareFlag: [false],
        hospitalCareDesc: [''],
        medicalOpinionFlag: [false],
        medicalOpinionDesc: [''],
      }, {validators: this.customFormGroupValidator})
  }

  continue() {
    if (this.keteranganKesehatanForm.valid) {
      if (this.keteranganKesehatanForm.controls['othersRemark'].value) {
        this.keteranganKesehatanForm.controls['othersFlag'].patchValue(true)
      }
      if (!this.keteranganKesehatanForm.controls['hospitalCareFlag'].value) {
        this.keteranganKesehatanForm.controls['hospitalCareDesc'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['smokingFlag'].value) {
        this.keteranganKesehatanForm.controls['smokingPcs'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['insuranceRejectionFlag'].value) {
        this.keteranganKesehatanForm.controls['insuranceRejectionDesc'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['medicalOpinionFlag'].value) {
        this.keteranganKesehatanForm.controls['medicalOpinionDesc'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['familyMedicalFlag'].value) {
        this.keteranganKesehatanForm.controls['familyMedicalDesc'].patchValue("")
      }

      if (!localStorage.getItem('keterangan-kesehatan')) {
        localStorage.setItem("keterangan-kesehatan", JSON.stringify(this.keteranganKesehatanForm.value))
      }

      this.newCoveringService.autoUW(this.newCoveringService.buildAutoUW()).subscribe(res => {
        if (res.statusId == "1") {
          localStorage.setItem("auto-uw-result", JSON.stringify(res))
          this.router.navigate(["eform/preview-bsi"]);
        } else {
          this.router.navigate(['eform/error'])
        }
      }, () => {
        this.router.navigate(['eform/error'])
      })
    }
  }

  back() {
    this.router.navigate(['eform/rincian-kesehatan-bsi'])
  }

  customFormGroupValidator(form: FormGroup) {
    const smokingPcs = form.get('smokingPcs')!
    if (form.get('smokingFlag')!.value && typeof smokingPcs.value !== "number") {
      smokingPcs.setErrors({ requiredIfSmokingFlagTrue: true });
    } else {
      smokingPcs.setErrors(null);
    }

    if (form.get('hospitalCareFlag')!.value && !form.get('hospitalCareDesc')!.value) {
      form.get('hospitalCareDesc')!.setErrors({ requiredIfHospitalCareFlagTrue: true });
    } else {
      form.get('hospitalCareDesc')!.setErrors(null);
    }

    if (form.get('medicalOpinionFlag')!.value && !form.get('medicalOpinionDesc')!.value) {
      form.get('medicalOpinionDesc')!.setErrors({ requiredIfMedicalOpinionFlagTrue: true });
    } else {
      form.get('medicalOpinionDesc')!.setErrors(null);
    }

    if (form.get('insuranceRejectionFlag')!.value && !form.get('insuranceRejectionDesc')!.value) {
      form.get('insuranceRejectionDesc')!.setErrors({ requiredIfInsuranceRejectionFlagTrue: true });
    } else {
      form.get('insuranceRejectionDesc')!.setErrors(null);
    }

    if (form.get('familyMedicalFlag')!.value && !form.get('familyMedicalDesc')!.value) {
      form.get('familyMedicalDesc')!.setErrors({ requiredIfFamilyMedicalFlagTrue: true });
    } else {
      form.get('familyMedicalDesc')!.setErrors(null);
    }
    return null;
  }
}
