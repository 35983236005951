import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CalculatorResponse} from "../rincian-pertanggungan/shared/calculator.model";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {DraftService} from "../service/save-draft/save-draft.service";
import {NasabahModel} from "../service/model/dashboard.model";
import {SessionStorageEnum} from '../global/enum/session-storage.enum';
import {PartnerIdEnum} from '../global/enum/partner.enum';
import {LoginModel} from "../login/shared/login.model";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-kontak-data-kantor',
  templateUrl: './kontak-data-kantor.component.html',
  styleUrls: ['./kontak-data-kantor.component.css']
})
export class KontakDataKantorComponent {

  kontakDataKantorForm: FormGroup
  calculatorResponse: CalculatorResponse = {};
  productInfo: RiplayUmumModel = {};
  login: LoginModel = {};
  rincian: any;
  isFromDashboard: boolean = false;

  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };
  isItShariaProduct: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private newCoveringService: NewCoveringService,
    private draftService: DraftService
  ) {
    this.isItShariaProduct = sessionStorage.getItem(SessionStorageEnum.PARTNER_ID) === JSON.stringify(PartnerIdEnum.BSI_ID);
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    this.kontakDataKantorForm = formBuilder.group({
      namaKantor: [''],
      alamatKantor: [''],
      kodePosKantor: [''],
      provinsiKantor: [''],
      kotaKantor: [''],
    })

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonRincian = localStorage.getItem('rincian-pertanggungan')
    let jsonLogin = sessionStorage.getItem('login')

    if (jsonLogin) {
      this.login = JSON.parse(jsonLogin);
    }

    if (jsonProduct != null && jsonCalculator != null && jsonRincian != null) {
      this.productInfo = JSON.parse(jsonProduct);
      this.calculatorResponse = JSON.parse(jsonCalculator);
      this.rincian = JSON.parse(jsonRincian);
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.productInfo.referenceNumber = this.nasabah.nasabahUw.referenceNo ?? this.nasabah.nasabahNewCovering.referenceNo
      this.productInfo.partnerId = this.nasabah.nasabahUw.partnerId ?? this.nasabah.nasabahNewCovering.partnerId
      this.productInfo.productcode = this.nasabah.nasabahUw.productCode ?? this.nasabah.nasabahNewCovering.productCode
    }

    this.draftService.getDraft({
      userId: 1,
      pageId: "draftCover3",
      partnerId: this.productInfo.partnerId,
      productCode: this.productInfo.productcode,
      referenceNo: this.productInfo.referenceNumber
    }).subscribe(res => {
      if (res.statusId == 1) {
        this.kontakDataKantorForm.patchValue({
          namaKantor: res.companyName,
          alamatKantor: res.officeAddress,
          kodePosKantor: res.officeZipCode,
          provinsiKantor: res.officeProvince,
          kotaKantor: res.officeCity,
        })
      }
    })

  }

  saveDraft() {
    localStorage.setItem("kontak-kantor", JSON.stringify(this.kontakDataKantorForm.value))
    this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
      this.router.navigate(["eform/draft-saved"])
    })
  }

  buildSaveRequest(): any {
    return {
      userId: 1,
      pageId: "draftCover3",
      partnerId: this.productInfo.partnerId,
      productCode: this.productInfo.productcode,
      referenceNo: this.productInfo.referenceNumber,
      companyName: this.kontakDataKantorForm.controls['namaKantor'].value,
      officeAddress: this.kontakDataKantorForm.controls['alamatKantor'].value,
      officeZipCode: this.kontakDataKantorForm.controls['kodePosKantor'].value ? this.kontakDataKantorForm.controls['kodePosKantor'].value.toString() : "",
      officeProvince: this.kontakDataKantorForm.controls['provinsiKantor'].value,
      officeCity: this.kontakDataKantorForm.controls['kotaKantor'].value,
    }
  }

  continue() {
    if (this.kontakDataKantorForm.valid) {
      localStorage.setItem("kontak-kantor", JSON.stringify(this.kontakDataKantorForm.value))
      this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
        if (this.productInfo.partnerId == environment.partnerIdBSI) {
          this.newCoveringService.newBusinessProcessing({
            userId: this.login.userid!.toString(),
            partnerId: this.productInfo.partnerId,
            referenceNo: this.productInfo.referenceNumber,
            productCode: this.productInfo.productcode,
          }).subscribe(res => {
            localStorage.setItem('new-business-processing', JSON.stringify(res))
            this.router.navigate(['eform/spajk-riplay-bsi'])
          }, () => {
            this.router.navigate(['eform/error'])
          })
        } else {
          if (this.calculatorResponse.healthQuestionFlag == "Y" || this.nasabah.nasabahNewCovering.healthQuestionFlag == 'Y') {
            this.router.navigate(["eform/rincian-kesehatan"]);
          } else {
            this.newCoveringService.newBusinessCovering(this.newCoveringService.buildNewBusinessRequest())
              .subscribe(res => {
                localStorage.setItem("new-covering-response", JSON.stringify(res))
                localStorage.setItem("isPreview", "false")
                this.router.navigate(["eform/final"]);
              }, () => {
                this.router.navigateByUrl('eform/error')
              })
          }
        }
      })

    }
  }

  back() {
    this.router.navigate(['eform/kontak-data-pribadi'])
  }

  onInput(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const filteredValue = input.value.replace(/[^A-Za-z\s]/g, '');
    input.value = filteredValue;
    this.kontakDataKantorForm.controls[controlName].setValue(filteredValue, { emitEvent: false });
  }

  protected readonly environment = environment;
}
