<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
  </div>
  <div class="riplay-title color-light-blue">
    <h2 class="text-center mt-5"><b>Perlindungan Asuransi</b></h2>
    <h6 class="text-center">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <app-form-header formHeaderTitle="Rincian Perlindungan" [step]="2" [dariStep]="4"></app-form-header>
</div>
<div class="container form-container">
  <form [formGroup]="rincianForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p (click)="back()" class="cursor-pointer"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Data Wajib Diisi</p>
      </div>
      <p class="content-title">Rincian Perlindungan</p>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['fullName'].dirty || form.submitted) && !rincianForm.controls['fullName'].valid}">
        <p class="title-input m-0">Nama Lengkap *</p>
        <input class="bottom-border" type="text" formControlName="fullName"
               [ngClass]="{'text-danger' : (rincianForm.controls['fullName'].dirty || form.submitted) && !rincianForm.controls['fullName'].valid}">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['dob'].dirty || form.submitted) && !rincianForm.controls['dob'].valid}">
        <p class="title-input m-0">Tanggal Lahir *</p>
        <input class="bottom-border"
               [ngClass]="{'text-danger' : (rincianForm.controls['dob'].dirty || form.submitted) && !rincianForm.controls['dob'].valid}"
               type="date" formControlName="dob" (change)="validateCalculator()">

      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['pekerjaan'].dirty || form.submitted) && !rincianForm.controls['pekerjaan'].valid}">
        <p class="title-input m-0">Pekerjaan *</p>
        <select class="bottom-border" formControlName="pekerjaan">
          <option *ngFor="let pekerjaan of listPekerjaan" [value]="pekerjaan">
            {{pekerjaan}}
          </option>
        </select>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['uangPertanggungan'].dirty || form.submitted) && !rincianForm.controls['uangPertanggungan'].valid}">
        <p class="title-input m-0">Nilai Fasilitas Pembiayaan Awal *</p>
        <input class="bottom-border" type="text" appNumberSeparator formControlName="uangPertanggungan" (change)="validateCalculator()"
               [ngClass]="{'text-danger' : (rincianForm.controls['uangPertanggungan'].dirty || form.submitted) && !rincianForm.controls['uangPertanggungan'].valid}"
        >
        <p class="description-input">Min {{minPembiayaan | number}} & Max {{maxPembiayaan | number}}</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['tanggalMulaiAsuransi'].dirty || form.submitted) && !rincianForm.controls['tanggalMulaiAsuransi'].valid}">
        <p class="title-input m-0">Tanggal Mulai Asuransi</p>
        <input class="bottom-border" type="date" formControlName="tanggalMulaiAsuransi" (change)="validateCalculator()" [min]="minInsuraceStartDate" [max]="maxInsuraceStartDate"
               [ngClass]="{'text-danger' : (rincianForm.controls['tanggalMulaiAsuransi'].dirty || form.submitted) && !rincianForm.controls['tanggalMulaiAsuransi'].valid}"
        >
        <p class="description-input">Diisi dengan tanggal pengajuan Asuransi</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['tanggalAkhirAsuransi'].dirty || form.submitted) && !rincianForm.controls['tanggalAkhirAsuransi'].valid}">
        <p class="title-input m-0">Tanggal Akhir Pembiayaan</p>
        <input class="bottom-border" type="date" formControlName="tanggalAkhirAsuransi" (change)="validateCalculator()" [min]="minTenureDate"
               [ngClass]="{'text-danger' : (rincianForm.controls['tanggalAkhirAsuransi'].dirty || form.submitted) && !rincianForm.controls['tanggalAkhirAsuransi'].valid}"
        >
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['kontribusiDasar'].dirty || form.submitted) && !rincianForm.controls['kontribusiDasar'].valid}">
        <p class="title-input m-0">Kontribusi Dasar *</p>
        <input class="bottom-border" type="text" formControlName="kontribusiDasar" readonly
               [ngClass]="{'text-danger' : (rincianForm.controls['kontribusiDasar'].dirty || form.submitted) && !rincianForm.controls['kontribusiDasar'].valid}"
        >
      </div>

      <div class="d-flex flex-row align-items-center mb-2 flex-gap">
        <input class="big-checkbox" type="checkbox" formControlName="standardPremiFlag" (change)="validateCalculator()">
        <p class="title-input m-0">Atribusikan Biaya Asuransi</p>
      </div>

      <div class="d-flex flex-row align-items-center mb-2 flex-gap">
        <input class="big-checkbox" type="checkbox" formControlName="penaltyFlag" (change)="validateCalculator()">
        <p class="title-input m-0">Atribusikan Biaya Penalti</p>
      </div>

      <div *ngIf="rincianForm.controls['penaltyFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['penaltyAmount'].dirty || form.submitted) && !rincianForm.controls['penaltyAmount'].valid}">
        <p class="title-input m-0">Biaya Penalti *</p>
        <input class="bottom-border" type="text" appNumberSeparator formControlName="penaltyAmount" (change)="validateCalculator()"
               [ngClass]="{'text-danger' : (rincianForm.controls['penaltyAmount'].dirty || form.submitted) && !rincianForm.controls['penaltyAmount'].valid}"
        >
      </div>

      <div *ngIf="rincianForm.controls['standardPremiFlag'].value || rincianForm.controls['penaltyFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['totalSumAssured'].dirty || form.submitted) && !rincianForm.controls['totalSumAssured'].valid}">
        <p class="title-input m-0">Total Pembiayaan</p>
        <input class="bottom-border" type="text" formControlName="totalSumAssured" readonly
               [ngClass]="{'text-danger' : (rincianForm.controls['totalSumAssured'].dirty || form.submitted) && !rincianForm.controls['totalSumAssured'].valid}"
        >
        <p class="description-input">Total Pembiayaan terdiri dari Nilai Fasilitas Pembiayaan Awal dan Nilai Biaya Atribusi Awal</p>
      </div>

      <div *ngIf="rincianForm.controls['standardPremiFlag'].value || rincianForm.controls['penaltyFlag'].value" class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['totalPremi'].dirty || form.submitted) && !rincianForm.controls['totalPremi'].valid}">
        <p class="title-input m-0">Total Kontribusi Dasar</p>
        <input class="bottom-border" type="text" formControlName="totalPremi" readonly
               [ngClass]="{'text-danger' : (rincianForm.controls['totalPremi'].dirty || form.submitted) && !rincianForm.controls['totalPremi'].valid}"
        >
        <p class="description-input">Total Kontribusi Dasar sudah termasuk Kontribusi Atribusi Dasar, belum termasuk Kontribusi Tambahan jika terdapat kondisi kesehatan khusus</p>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="rounded-2 btn-continue mt-3 py-3 flex-grow-1" type="submit">Lanjut</button>
    </div>
  </form>

</div>
