import { Component } from '@angular/core';
import {Router} from "@angular/router";
import { SessionStorageEnum } from '../global/enum/session-storage.enum';
import { PartnerIdEnum } from '../global/enum/partner.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  url : String;
  isItShariaProduct: boolean;
  isLoginScreen: boolean;

  constructor(private router: Router ) {
    this.url = router.url
    //cek if there's session storage with key login
    this.isLoginScreen = !sessionStorage.getItem('login');
    this.isItShariaProduct = sessionStorage.getItem(SessionStorageEnum.PARTNER_ID) === JSON.stringify(PartnerIdEnum.BSI_ID);
  }

}
