import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {NewCoveringService} from "../../service/new-covering/new-covering.service";
import html2canvas from 'html2canvas';
import jspdf from "jspdf";
import {LoginModel} from "../../login/shared/login.model";
import {NbSubmitResponse, PremiData} from "../../service/model/keterangan-kesehatan.model";

@Component({
  selector: 'app-spajk-riplay',
  templateUrl: './spajk-riplay.component.html',
  styleUrls: ['./spajk-riplay.component.css']
})
export class SpajkRiplayComponent implements AfterViewInit {
  dataSpajk: any = {};
  tandaTangan: any;
  dataPribadi: any;
  calculator: any;
  rincianPertanggungan: any;
  @ViewChild('spajkWraper') spajkWrapper!: ElementRef;
  @ViewChild('riplayWrapper') riplayWrapper!: ElementRef;
  documentArray: string[] = []
  isLoading: boolean = true;
  today: Date = new Date()
  marketingChecked: string | null = "";
  loginResponse: LoginModel = {}
  premiData: PremiData = {}
  newCoveringResponse: NbSubmitResponse = {}

  // maritalStatus = this.spajk.maritalStatus;
  constructor(private router: Router,
              private newCoveringService: NewCoveringService) {

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    let jsonDummy = localStorage.getItem('new-business')
    let jsonTandaTangan = localStorage.getItem('tanda-tangan')
    let jsonDataPribadi = localStorage.getItem('data-pribadi')
    let jsonCalculator = localStorage.getItem('calculator')
    let jsonRincianPertanggungan = localStorage.getItem('rincian-pertanggungan')
    let jsonLogin = sessionStorage.getItem('login')
    let jsonNewCoveringResponse = localStorage.getItem('new-covering-response')
    this.marketingChecked = localStorage.getItem('marketing-checked')

    if (jsonDummy != null && jsonNewCoveringResponse) {
      this.dataSpajk = JSON.parse(jsonDummy);
      this.dataSpajk.sumAssured = Number(this.dataSpajk.sumAssured)
      this.newCoveringResponse = JSON.parse(jsonNewCoveringResponse)
      this.premiData = JSON.parse(this.newCoveringResponse.premiData!)
    }

    if (jsonCalculator != null) {
      this.calculator = JSON.parse(jsonCalculator);
      this.calculator.totalPremium = Number(this.premiData.standardPremi) + Number(this.premiData.extraPremium ?? "0")
    }

    if (jsonRincianPertanggungan != null) {
      this.rincianPertanggungan = JSON.parse(jsonRincianPertanggungan);
      this.dataSpajk.loanAccountNo = this.rincianPertanggungan.nomorRekening
    }

    if (jsonDataPribadi != null) {
      this.dataPribadi = JSON.parse(jsonDataPribadi);
    }

    if (jsonTandaTangan != null) {
      this.tandaTangan = JSON.parse(jsonTandaTangan)
    }

    if (jsonLogin != null) {
      this.loginResponse = JSON.parse(jsonLogin);
    }

    // this.dataDummy()
    this.dobParse();
    this.isLoading = !(this.tandaTangan == null)
    window.setTimeout(() => {
      this.isLoading = false;
    }, 90000)
  }

  @Input('spajk')
  get spajk() {
    return this.dataSpajk;
  }

  continue() {
    if (!this.tandaTangan) {
      this.router.navigate(['/eform/summary'])
    } else {
      this.isLoading = true;
      this.router.navigate(['eform/final'])
    }
  }

  getCharatIndex(i: any): string {
    return this.spajk.emailaddressstaff[i];
  }

  sendImage() {
    this.convertToImage(this.spajkWrapper, "SPAJK")
    // this.convertToImage(this.riplayWrapper, "RIPLAY")
  }

  dobParse(): void {
    const dobP = this.dataSpajk.dob.split('-');
    this.dataSpajk.thn = dobP[0];
    this.dataSpajk.bln = dobP[1];
    this.dataSpajk.tgl = dobP[2];
  }

  convertToImage(elementRef: ElementRef, fileType: string) {
    const element = elementRef.nativeElement;
    if (screen.width < 1024) {
      element.setAttribute("content", "width=1200px");
    }
    html2canvas(element, {
      allowTaint: true,
      removeContainer: true,
      backgroundColor: "white",
      imageTimeout: 15000,
      logging: true,
      scale: 0.5,
      useCORS: true
    })
      .then((canvas) => {
        canvas.toBlob(blob => {
          this.newCoveringService.uploadDocument(this.dataSpajk.referenceNo, blob, fileType).subscribe(res => {
            if (res.statusId == "6" || res.statusId == "1") {
              this.documentArray.push(fileType + " SUCCESS")
            } else {
              this.documentArray.push(fileType + " FAILED")
            }
            this.isLoading = !!(this.documentArray.length != 2 && this.tandaTangan);

            if (this.documentArray.length == 1) {
              this.convertToImage(this.riplayWrapper, "RIPLAY")
            }
          }, error => {
            this.documentArray.push(fileType + " FAILED")
            this.isLoading = !!(this.documentArray.length != 2 && this.tandaTangan);

            if (this.documentArray.length == 1) {
              this.convertToImage(this.riplayWrapper, "RIPLAY")
            }
          })
        })
      })
      .catch(err => {
        console.error("log error", err)
      });
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    if (this.tandaTangan) {
      this.isLoading = true;
      this.sendImage()
      this.newCoveringService.uploadDocument(this.dataSpajk.referenceNo, this.newCoveringService.dataURItoBlob(this.dataPribadi.fileInput), "KTPID")
        .subscribe(res => {
          localStorage.setItem("dokumen-upload", JSON.stringify(res))
        })
    }
    // this.convertToImage()
  }

  productInfo(): any {
    return "SmartProtection BTPN Purnabakti adalah produk Asuransi Jiwa Kredit Kumpulan yang memberi perlindungan bagi Tertanggung sebagai debitur apabila terjadi risiko meninggal dunia karena sebab alami dan/atau Penyakit dan/atau Kecelakaan.";
  }

  public convertToPDF(element: ElementRef, title: string) {
    // element.nativeElement
    // this.renderer.setStyle(element.nativeElement, 'width', '1294px')

    // var data = document.getElementById('contentToConvert');
    html2canvas(element.nativeElement, {
      scale: 0.65
    }).then(canvas => {
      // window.open(canvas.toDataURL())
// Few necessary setting options
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let doc = new jspdf('p', 'mm');
      let position = 0;

      doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(title + '.pdf'); // Generated PDF
    });
  }

  downloadSpajkAndRiplay() {
    this.convertToPDF(this.spajkWrapper, 'SPAJK-PREVIEW')
    this.convertToPDF(this.riplayWrapper, 'RIPLAY-PREVIEW')
  }

  namaUser(): string {
    switch (this.loginResponse.emailStaff) {
      case "cynthia.kamadjaja@allianz.co.id":
        return "Kamadjaja Cynthia"
      case "crl_adm@creditlife.com":
        return "Credit Life ADM"
      case "crl_cmn@creditlife.com":
        return "Credit Life CMN"
      case "crl_rd@creditlife.com":
        return "Credit Life RD"
      case "crl_dev@creditlife.com":
        return "Credit Life DEV"
      case "rizky.halim@allianz.co.id":
        return "Rizky Halim"
      default:
        return "Staff BTPN"
    }
  }

  dataDummy() {
    let data: any = {
      "userid": 1,
      "partnerId": "6187346",
      "referenceNo": "E6017-240228000033",
      "loanTypeCode": "3",
      "loanTypeDescription": "KTA",
      "interestRate": 20,
      "savingAccountNo": "",
      "productCode": "SPKTAPBTPN",
      "productName": "Smart Protection KTA BTPN Purnabakti",
      "policyNo": "CL 2022 076",
      "partnerCode": "BTPN0422",
      "partnerName": "PT Bank BTPN, Tbk",
      "creditBranchCode": "0459",
      "branchCode": "0459",
      "branchDescription": "Mampang Prapatan",
      "emailaddressbranch": "btpn@btpn.com",
      "partnerStaffName": "Staff BTPN",
      "emailaddressstaff": "crl_adm@creditlife.com",
      "phoneNo": "",
      "npk": "BKP01",
      "effectiveDate": "2024-02-28",
      "maturityDate": "null",
      "tenure": "6",
      "sumAssured": "1200000",
      "fullName": "asdf",
      "pob": "asdf",
      "dob": "1990-02-28",
      "gender": "M",
      "maritalStatus": "S",
      "identityType": "1",
      "identityNumber": 8881927839128799,
      "address": "",
      "city": "",
      "province": "",
      "zipcode": "",
      "mobilePhoneNo": "",
      "emailAddress": "",
      "occupation": "Karyawan",
      "companyName": "",
      "officeAddress": "",
      "officeCity": "",
      "officeProvince": "",
      "officeZipCode": "",
      "emailCorrespondenceFlag": "Y",
      "ecertificateFlag": "Y",
      "preexistingConditionFlag": "N",
      "chestPain": "N",
      "highBloodPresure": "N",
      "stroke": "N",
      "heartBloodVessel": "N",
      "bloodDisorder": "N",
      "leukimia": "N",
      "lungs": "N",
      "cancerTumor": "N",
      "diabetic": "N",
      "mentalDisorders": "N",
      "brainAbnormalities": "N",
      "lymphgland": "N",
      "stomachIntenstine": "N",
      "liver": "N",
      "drugAbuse": "N",
      "alcoholic": "N",
      "aidsHiv": "N",
      "othersFlag": "N",
      "othersRemark": "N",
      "pregnantFlag": "N",
      "pregnantPeriod": "N",
      "familyHealthFlag": "N",
      "familyHealthCondition": "N",
      "rejectionPolicyFlag": "N",
      "rejectionPolicyHistory": "N",
      "weightGainlossFlag": "N",
      "smokingFlag": "N",
      "smokingPcs": "",
      "height": "0",
      "weight": "0"
    }
    this.calculator = {
      entryAge: 50,
      standardPremium: 10000000
    };
    this.dataSpajk = data;
  }
}
