<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title color-light-blue">
    <h2 class="text-center mt-5"><b>Pertanggungan</b></h2>
    <h6 class="text-center">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <div class="container mt-5 pt-4 form-header">
    <div class="row d-flex justify-content-around">
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-active"></div>
      <div class="allianz-progress-step step-inactive"></div>
      <div class="allianz-progress-step step-inactive"></div>
    </div>
    <div class="row">
      <p class="col x-2">Step 2 dari 4</p>
      <p class="text-end col-8 px-2">Rincian Pertanggungan</p>
    </div>
  </div>
</div>
<div class="container form-container">
  <form [formGroup]="rincianForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="bg-white p-2 form-content">
      <div class="d-flex justify-content-between">
        <p (click)="back()" class="cursor-pointer"><i class="bi bi-arrow-left"></i> Kembali</p>
        <p class="text-end">(*) Semua informasi data yang harus diisi</p>
      </div>
      <p class="content-title">Rincian Pertanggungan</p>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['dob'].dirty || form.submitted) && !rincianForm.controls['dob'].valid}">
        <p class="title-input m-0">Tanggal Lahir *</p>
        <input class="bottom-border"
               [ngClass]="{'text-danger' : (rincianForm.controls['dob'].dirty || form.submitted) && !rincianForm.controls['dob'].valid}"
               type="date" formControlName="dob" (change)="validateCalculator()">

      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">No Rekening</p>
        <input class="bottom-border" type="number" formControlName="nomorRekening">
        <p class="description-input">Nomor rekening yang terdaftar pada bank pemberi
          pembiayaan</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['uangPertanggungan'].dirty || form.submitted) && !rincianForm.controls['uangPertanggungan'].valid}">
        <p class="title-input m-0">Uang Pertanggungan *</p>
        <input class="bottom-border" type="text" appNumberSeparator formControlName="uangPertanggungan"
               [ngClass]="{'text-danger' : (rincianForm.controls['uangPertanggungan'].dirty || form.submitted) && !rincianForm.controls['uangPertanggungan'].valid}"
               (change)="validateCalculator()">
        <p class="description-input">Nilai plafon pinjaman</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['masaPertanggungan'].dirty || form.submitted) && !rincianForm.controls['masaPertanggungan'].valid}">
        <p class="title-input m-0">Masa Pertanggungan *</p>
        <div class="d-flex align-items-center">
          <select class="bottom-border flex-grow-1" formControlName="masaPertanggungan" (change)="validateCalculator()">
            <option *ngFor="let bulan of masaPertanggunganBulan" [value]="bulan" (change)="validateCalculator()">
              {{bulan}}
            </option>
          </select>
          <span>Bulan</span>
        </div>
      </div>

      <div class="d-flex flex-column mb-2">
        <p class="title-input m-0">Bunga Pinjaman</p>
        <input class="bottom-border" type="text" formControlName="bungaPinjaman">
        <p class="description-input">pastikan nilai suku bunga yang dipilih produk asuransi sama dengan atau mendekati
          bunga yang diberikan oleh bank</p>
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['effectiveDate'].dirty || form.submitted) && !rincianForm.controls['effectiveDate'].valid}">
        <p class="title-input m-0">Tanggal Akad Pembiayaan</p>
        <input class="bottom-border" type="date" formControlName="effectiveDate" [min]="'2023-12-01'" [max]="maxDate"
               [ngClass]="{'text-danger' : (rincianForm.controls['effectiveDate'].dirty || form.submitted) && !rincianForm.controls['effectiveDate'].valid}"
               (change)="validateCalculator()">
        <p class="description-input">tanggal dimulainya pertanggungan asuransi</p>
      </div>

      <div *ngIf="productInfo.partnercode == 'maybank'" class="d-flex flex-column mb-2">
        <p class="title-input m-0">Diskon Premi *</p>
        <input class="bottom-border" type="text" formControlName="diskonPremi">
      </div>

      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (rincianForm.controls['premiDasar'].dirty || form.submitted) && !rincianForm.controls['premiDasar'].valid}">
        <p class="title-input m-0">Premi Dasar *</p>
        <input class="bottom-border" type="text" formControlName="premiDasar" readonly
               [ngClass]="{'text-danger' : (rincianForm.controls['premiDasar'].dirty || form.submitted) && !rincianForm.controls['premiDasar'].valid}"
        >
      </div>

      <div>
        <p>Korespondensi melalui Surat Elektronik</p>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="emailKoresponden" id="yesEmail" value="Y" checked>
          <label class="form-check-label" for="yesEmail">Ya</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="emailKoresponden" id="noEmail" value="N">
          <label class="form-check-label" for="noEmail">Tidak</label>
        </div>
      </div>

      <div *ngIf="productInfo.partnerId != '6187346'">
        <p>Sertifikat Asuransi Versi Elektronik</p>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="eSertifikat" id="yesSertifikat" value="Y" checked>
          <label class="form-check-label" for="yesSertifikat">Ya</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="eSertifikat" id="noSertifikat" value="N">
          <label class="form-check-label" for="noSertifikat">Tidak</label>
        </div>
      </div>


    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="rounded-2 btn-back mt-3 py-3 flex-grow-1" type="button" (click)="back()">KEMBALI</button>
      <button class="rounded-2 btn-continue mt-3 py-3 flex-grow-1" type="submit">LANJUT</button>
    </div>
  </form>

</div>
