import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-keterangan-kesehatan',
  templateUrl: './keterangan-kesehatan.component.html',
  styleUrls: ['./keterangan-kesehatan.component.css']
})
export class KeteranganKesehatanComponent {

  productInfo: RiplayUmumModel = {};
  keteranganKesehatanForm: FormGroup;

  constructor(
    private router: Router,
    private newCoveringService: NewCoveringService,
    private formBuilder: FormBuilder,
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    if (this.productInfo.partnerId == environment.partnerIdBSI) {
      this.keteranganKesehatanForm = formBuilder.group({
        weightLossKg: ['', Validators.required],
        chestPain: [false],
        highBloodPresure: [false],
        kidney: [false],
        heartBloodVessel: [false],
        bloodDisorder: [false],
        highCholesterol: [false],
        lungs: [false],
        cancerTumor: [false],
        diabetic: [false],
        mentalDisorders: [false],
        nervousSystemDisorder: [false],
        brainAbnormalities: [false],
        lymphgland: [false],
        stomachIntenstine: [false],
        liver: [false],
        drugAbuse: [false],
        alcoholic: [false],
        aidsHiv: [false],
        othersFlag: [false],
        othersRemark: [''],
        familyHealthFlag: [false],
        familyHealthCondition: [''],
        smokingFlag: [false,],
        smokingPcs: [''],
        rejectionPolicyFlag: [false],
        rejectionPolicyHistory: [''],
      }, {validators: this.customFormGroupValidator})
    } else {
      this.keteranganKesehatanForm = formBuilder.group({
        weightLossKg: ['', Validators.required],
        chestPain: [false],
        highBloodPresure: [false],
        stroke: [false],
        heartBloodVessel: [false],
        bloodDisorder: [false],
        leukimia: [false],
        cancerTumor: [false],
        diabetic: [false],
        lungs: [false],
        mentalDisorders: [false],
        brainAbnormalities: [false],
        lymphgland: [false],
        stomachIntenstine: [false],
        liver: [false],
        drugAbuse: [false],
        alcoholic: [false],
        aidsHiv: [false],
        othersFlag: [false],
        othersRemark: [''],
        familyHealthFlag: [false],
        familyHealthCondition: [''],
        smokingFlag: [false,],
        smokingPcs: [''],
        rejectionPolicyFlag: [false],
        rejectionPolicyHistory: [''],
      }, {validators: this.customFormGroupValidator})
    }
  }

  continue() {
    if (this.keteranganKesehatanForm.valid) {
      if (this.keteranganKesehatanForm.controls['othersRemark'].value) {
        this.keteranganKesehatanForm.controls['othersFlag'].patchValue(true)
      }

      if (!this.keteranganKesehatanForm.controls['smokingFlag'].value) {
        this.keteranganKesehatanForm.controls['smokingPcs'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['rejectionPolicyFlag'].value) {
        this.keteranganKesehatanForm.controls['rejectionPolicyHistory'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['familyHealthFlag'].value) {
        this.keteranganKesehatanForm.controls['familyHealthCondition'].patchValue("")
      }
      localStorage.setItem("keterangan-kesehatan", JSON.stringify(this.keteranganKesehatanForm.value))
      this.router.navigate(["eform/final"]);
    }
  }

  customFormGroupValidator(form: FormGroup) {
    const smokingPcs = form.get('smokingPcs')!
    if (form.get('smokingFlag')!.value && typeof smokingPcs.value !== "number") {
      smokingPcs.setErrors({ requiredIfSmokingFlagTrue: true });
    } else {
      smokingPcs.setErrors(null);
    }

    if (form.get('rejectionPolicyFlag')!.value && !form.get('rejectionPolicyHistory')!.value) {
      form.get('rejectionPolicyHistory')!.setErrors({ requiredIfRejectionPolicyFlagTrue: true });
    } else {
      form.get('rejectionPolicyHistory')!.setErrors(null);
    }

    if (form.get('familyHealthFlag')!.value && !form.get('familyHealthCondition')!.value) {
      form.get('familyHealthCondition')!.setErrors({ requiredIfFamilyHealthFlagTrue: true });
    } else {
      form.get('familyHealthCondition')!.setErrors(null);
    }
    return null;
  }

  back() {
    this.router.navigate(['eform/rincian-kesehatan'])
  }
}
