<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img *ngIf="isItShariaProduct" src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
    <img *ngIf="!isItShariaProduct" src="assets/Logo-eSPAJK.jpg" width="160" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5 color-blue"><b>Menu Utama Ellise</b></h2>
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">

  </div>
</div>
<div class="container form-container pb-2">
  <!--  <form [formGroup]="kontakDataKantorForm" (ngSubmit)="continue()" #form="ngForm">-->
  <form #form="ngForm">

    <div class="bg-white px-5 form-content pb-3">
      <div class="mb-5">
        <div class="p-2 d-inline-block rounded shadow">
          <img src="assets/Icon-Ajukan-Klaim-Kesehatan.png" width="58">
        </div>
        <h2 class="mt-4 color-light-blue font-weight-bold"><b>Buat Aplikasi</b></h2>
        <p class="color-light-blue">Ajukan Asuransi Jiwa Pembiayaan, dapatkan perlindungan pembiayaan untuk nasabah anda</p>
        <div class="d-flex flex-row justify-content-center flex-gap">
          <button (click)="goToRiplay()" class="btn btn-continue mt-3 py-3 flex-grow-1" type="button">Mulai Simulasi Pengajuan</button>
        </div>
      </div>

      <div class="mb-5">
        <div class="p-2 d-inline-block rounded shadow">
          <img src="assets/Icon-Dashboard-Pengajuan.png" width="58">
        </div>
        <h2 class="mt-4 color-light-blue font-weight-bold"><b>Dashboard Pengajuan</b></h2>
        <p class="color-light-blue">Lihat draft pengajuan anda yang belum selesai atau pantau dan ulas pengajuan sebelumnya</p>
        <div class="d-flex flex-row justify-content-center flex-gap">
          <button (click)="goToDashboard()" class="btn btn-continue mt-3 py-3 flex-grow-1" type="button">Menuju Dashboard</button>
        </div>
      </div>
    </div>
  </form>
</div>
