import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SpajkRiplayRoutingModule} from "./spajk-riplay-routing.module";
import {GlobalModule} from "../../global/global.module";
import {SpajkRiplayComponent} from "./spajk-riplay.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SpajkRiplayRoutingModule,
    GlobalModule,
  ],
  declarations: [
    SpajkRiplayComponent
  ]
})
export class SpajkRiplayModule {

}
