<div class="container mt-5 pt-4 form-header">
  <div class="row d-flex justify-content-around" #stepCounter>
<!--    <div class="allianz-progress-step step-active"></div>-->
<!--    <div class="allianz-progress-step step-active"></div>-->
<!--    <div class="allianz-progress-step step-active"></div>-->
<!--    <div class="allianz-progress-step step-active"></div>-->
  </div>
  <div class="row">
    <p class="col x-2"><b>{{this.step}}</b> dari <b>{{this.dariStep}}</b></p>
    <p class="text-end col-8 px-2 tc-orange">{{this.formHeaderTitle}}</p>
  </div>
</div>
