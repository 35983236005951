<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div>
    <div class="text-center">
      <img src="assets/Forgot-Password-Icon.png">
    </div>
    <h2 class="color-dark-blue">Lupa Kata Sandi</h2>
    <p class="color-light-blue">Mohon hubungi pihak Allianz untuk mengatur ulang kata sandi</p>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="continue()">Kembali</button>
    </div>
  </div>
</div>
