import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {environment} from "../../environments/environment";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-keterangan-kesehatan',
  templateUrl: './keterangan-kesehatan.component.html',
  styleUrls: ['./keterangan-kesehatan.component.css']
})
export class KeteranganKesehatanComponent {

  productInfo: RiplayUmumModel = {};
  keteranganKesehatanForm: FormGroup;

  isFromDashboard: boolean = false;
  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };

  constructor(
    private router: Router,
    private newCoveringService: NewCoveringService,
    private formBuilder: FormBuilder,
  ) {
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    this.keteranganKesehatanForm = formBuilder.group({
      weightLossKg: ['', Validators.required],
      chestPain: [false],
      highBloodPresure: [false],
      stroke: [false],
      heartBloodVessel: [false],
      bloodDisorder: [false],
      leukimia: [false],
      cancerTumor: [false],
      diabetic: [false],
      lungs: [false],
      mentalDisorders: [false],
      brainAbnormalities: [false],
      lymphgland: [false],
      stomachIntenstine: [false],
      liver: [false],
      drugAbuse: [false],
      alcoholic: [false],
      aidsHiv: [false],
      othersFlag: [false],
      othersRemark: [''],
      familyHealthFlag: [false],
      familyHealthCondition: [''],
      smokingFlag: [false,],
      smokingPcs: [''],
      rejectionPolicyFlag: [false],
      rejectionPolicyHistory: [''],
    }, {validators: this.customFormGroupValidator})

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);

      this.productInfo.referenceNumber = this.nasabah.nasabahNewCovering.referenceNo
      this.productInfo.partnerId = this.nasabah.nasabahNewCovering.partnerId
      this.productInfo.productcode = this.nasabah.nasabahNewCovering.productCode
      this.keteranganKesehatanForm.patchValue({
        weightLossKg: this.nasabah.nasabahNewCovering.weightGainlossKg,
        chestPain: this.nasabah.nasabahNewCovering.chestPain == "Y",
        highBloodPresure: this.nasabah.nasabahNewCovering.highBloodPresure == "Y",
        stroke: this.nasabah.nasabahNewCovering.stroke == "Y",
        heartBloodVessel: this.nasabah.nasabahNewCovering.heartBloodVessel == "Y",
        bloodDisorder: this.nasabah.nasabahNewCovering.bloodDisorder == "Y",
        leukimia: this.nasabah.nasabahNewCovering.leukimia == "Y",
        cancerTumor: this.nasabah.nasabahNewCovering.cancerTumor == "Y",
        diabetic: this.nasabah.nasabahNewCovering.diabetic == "Y",
        lungs: this.nasabah.nasabahNewCovering.lungs == "Y",
        mentalDisorders: this.nasabah.nasabahNewCovering.mentalDisorders == "Y",
        brainAbnormalities: this.nasabah.nasabahNewCovering.brainAbnormalities == "Y",
        lymphgland: this.nasabah.nasabahNewCovering.lymphgland == "Y",
        stomachIntenstine: this.nasabah.nasabahNewCovering.stomachIntenstine == "Y",
        liver: this.nasabah.nasabahNewCovering.liver == "Y",
        drugAbuse: this.nasabah.nasabahNewCovering.drugAbuse == "Y",
        alcoholic: this.nasabah.nasabahNewCovering.alcoholic == "Y",
        aidsHiv: this.nasabah.nasabahNewCovering.aidsHiv == "Y",
        othersFlag: this.nasabah.nasabahNewCovering.othersFlag == "Y",
        othersRemark: this.nasabah.nasabahNewCovering.othersRemark,
        familyHealthFlag: this.nasabah.nasabahNewCovering.familyHealthFlag == "Y",
        familyHealthCondition: this.nasabah.nasabahNewCovering.familyHealthCondition,
        smokingFlag: this.nasabah.nasabahNewCovering.smokingFlag == "Y",
        smokingPcs: this.nasabah.nasabahNewCovering.smokingPcs,
        rejectionPolicyFlag: this.nasabah.nasabahNewCovering.rejectionPolicyFlag == "Y",
        rejectionPolicyHistory: this.nasabah.nasabahNewCovering.rejectionPolicyHistory,
      })
    }
  }

  continue() {
    if (this.keteranganKesehatanForm.valid) {
      if (this.keteranganKesehatanForm.controls['othersRemark'].value) {
        this.keteranganKesehatanForm.controls['othersFlag'].patchValue(true)
      }

      if (!this.keteranganKesehatanForm.controls['smokingFlag'].value) {
        this.keteranganKesehatanForm.controls['smokingPcs'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['rejectionPolicyFlag'].value) {
        this.keteranganKesehatanForm.controls['rejectionPolicyHistory'].patchValue("")
      }
      if (!this.keteranganKesehatanForm.controls['familyHealthFlag'].value) {
        this.keteranganKesehatanForm.controls['familyHealthCondition'].patchValue("")
      }
      localStorage.setItem("keterangan-kesehatan", JSON.stringify(this.keteranganKesehatanForm.value))
      this.newCoveringService.newBusinessCovering(this.newCoveringService.buildNewBusinessRequest())
        .subscribe(res => {
          console.log("ress", res)
          localStorage.setItem("new-covering-response", JSON.stringify(res))
          localStorage.setItem("isPreview", "false")
          this.router.navigate(["eform/final"]);
        }, () => {
          this.router.navigateByUrl('eform/error')
        })
    }
  }

  customFormGroupValidator(form: FormGroup) {
    const smokingPcs = form.get('smokingPcs')!
    if (form.get('smokingFlag')!.value && typeof smokingPcs.value !== "number") {
      smokingPcs.setErrors({ requiredIfSmokingFlagTrue: true });
    } else {
      smokingPcs.setErrors(null);
    }

    if (form.get('rejectionPolicyFlag')!.value && !form.get('rejectionPolicyHistory')!.value) {
      form.get('rejectionPolicyHistory')!.setErrors({ requiredIfRejectionPolicyFlagTrue: true });
    } else {
      form.get('rejectionPolicyHistory')!.setErrors(null);
    }

    if (form.get('familyHealthFlag')!.value && !form.get('familyHealthCondition')!.value) {
      form.get('familyHealthCondition')!.setErrors({ requiredIfFamilyHealthFlagTrue: true });
    } else {
      form.get('familyHealthCondition')!.setErrors(null);
    }
    return null;
  }

  back() {
    this.router.navigate(['eform/rincian-kesehatan'])
  }
}
