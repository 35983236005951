<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-center mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="200" height="auto">
  </div>
  <div class="container form-header bg-white mt-5 py-3">
    <h3 class="m-0 p-0 text-center">Registration</h3>
    <h6 class="m-0 p-0 text-center">(*) Semua informasi data yang harus diisi</h6>

  </div>
</div>
<div class="container form-container pb-3">
  <form [formGroup]="registrationForm" #form="ngForm" (ngSubmit)="onSubmit()">

    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['namaStaff'].dirty || form.submitted) && !registrationForm.controls['namaStaff'].valid}">
        <p class="title-input m-0">Nama Staff *</p>
        <input class="bottom-border" type="text" formControlName="namaStaff"
               [ngClass]="{'text-danger' : (registrationForm.controls['namaStaff'].dirty || form.submitted) && !registrationForm.controls['namaStaff'].valid}"/>
        <p class="description-input">Sesuai KTP, tanpa gelar dan simbol ‘,-</p>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['emailStaff'].dirty || form.submitted) && !registrationForm.controls['emailStaff'].valid}">
        <p class="title-input m-0">Email Perusahaan *</p>
        <input class="bottom-border" type="text" formControlName="emailStaff"
               [ngClass]="{'text-danger' : (registrationForm.controls['emailStaff'].dirty || form.submitted) && !registrationForm.controls['emailStaff'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['nomorHP'].dirty || form.submitted) && !registrationForm.controls['nomorHP'].valid}">
        <p class="title-input m-0">Nomor HP *</p>
        <input class="bottom-border" type="text" formControlName="nomorHP"
               [ngClass]="{'text-danger' : (registrationForm.controls['nomorHP'].dirty || form.submitted) && !registrationForm.controls['nomorHP'].valid}"/>
        <p class="description-input">Pastikan nomor ponsel Anda dapat dihubungi</p>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['perusahaan'].dirty || form.submitted) && !registrationForm.controls['perusahaan'].valid}">
        <p class="title-input m-0">Bank/Perusahaan</p>
        <input class="bottom-border" type="text" formControlName="perusahaan"
               [ngClass]="{'text-danger' : (registrationForm.controls['perusahaan'].dirty || form.submitted) && !registrationForm.controls['perusahaan'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['cabang1'].dirty || form.submitted) && !registrationForm.controls['cabang1'].valid}">
        <p class="title-input m-0">Cabang/Unit *</p>
        <input class="bottom-border" type="text" formControlName="cabang1"
               [ngClass]="{'text-danger' : (registrationForm.controls['cabang1'].dirty || form.submitted) && !registrationForm.controls['cabang1'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['cabang2'].dirty || form.submitted) && !registrationForm.controls['cabang2'].valid}">
        <p class="title-input m-0">Cabang/Unit *</p>
        <input class="bottom-border" type="text" formControlName="cabang2"
               [ngClass]="{'text-danger' : (registrationForm.controls['cabang2'].dirty || form.submitted) && !registrationForm.controls['cabang2'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['cabang3'].dirty || form.submitted) && !registrationForm.controls['cabang3'].valid}">
        <p class="title-input m-0">Cabang/Unit *</p>
        <input class="bottom-border" type="text" formControlName="cabang3"
               [ngClass]="{'text-danger' : (registrationForm.controls['cabang3'].dirty || form.submitted) && !registrationForm.controls['cabang3'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (registrationForm.controls['kataSandi'].dirty || form.submitted) && !registrationForm.controls['kataSandi'].valid}">
        <p class="title-input m-0">Kata Sandi *</p>
        <input class="bottom-border" type="password" formControlName="kataSandi"
               [ngClass]="{'text-danger' : (registrationForm.controls['kataSandi'].dirty || form.submitted) && !registrationForm.controls['kataSandi'].valid}"/>
      </div>
    </div>
    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-row align-items-baseline mb-2 flex-gap"
           [ngClass]="{'text-danger' : (registrationForm.controls['agreement'].dirty || form.submitted) && !registrationForm.controls['agreement'].valid}">
        <input type="checkbox" id="flexCheckDefault" formControlName="agreement">
        <label for="flexCheckDefault">
          Saya telah membaca, mengerti, dan menyetujui Syarat & Ketentuan dan Kebijakan Privasi
        </label>
      </div>
    </div>
    <div class="d-flex flex-column">
      <button class="btn-continue mt-3 py-3 rounded-3" type="submit">REGISTER</button>
    </div>
  </form>

</div>
