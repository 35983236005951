<footer class="bg-light text-dark py-3 text-center mt-4 shadow-footer"
        [ngClass]="{'shadow-footer': url === 'eform'}">
  <div class="container">
    <div class="d-flex flex-column bottom-border pb-3">
      <div>
        <p class=" color-dark-blue">Media Sosial Kami</p>
      </div>
      <div class="d-flex flex-row justify-content-between">
        <a href="https://www.facebook.com/AllianzIndonesia/" target="_blank"><img src="assets/Footer/Icons/Facebook.png" width="38"></a>
        <a href="https://twitter.com/allianzid" target="_blank"><img src="assets/Footer/Icons/Twitter.png" width="38"></a>
        <a href="https://www.linkedin.com/company/allianzindonesia/" target="_blank"><img src="assets/Footer/Icons/Linkedin.png" width="38"></a>
        <a href="https://instagram.com/allianzindonesia" target="_blank"><img src="assets/Footer/Icons/Instagram.png" width="38"></a>
        <a href="https://www.youtube.com/user/AllianzIndonesiaMM" target="_blank"><img src="assets/Footer/Icons/Youtube.png" width="38"></a>
      </div>
    </div>
    <div class="row mt-4">
      <div *ngIf="!isLoginScreen">
        <p *ngIf="isItShariaProduct">PT Asuransi Allianz Life Syariah Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan</p>
      </div>
      <div *ngIf="isLoginScreen">
        <p>PT Asuransi Allianz Life Indonesia dan PT Asuransi Allianz Life Syariah Indonesia berizin dan diawasi oleh Otoritas Jasa Keuangan</p>
      </div>
    </div>
    <div class="row">
      <p>Syarat & Ketentuan | <a href="https://www.allianz.co.id/pemberitahuan-privasi.html" class="text-dark text-decoration-none" target="_blank">Kebijakan Privasi</a></p>
    </div>
    <div class="row">
      <p>&copy; Allianz Indonesia 2023</p>
    </div>

  </div>
</footer>
