import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent {
  @Input() options: string[] = [];
  selectedOption: string | undefined;

  toggleDropdownFlag: boolean = false;

  ngOnInit() {
    // Set the selectedOption to the first option when the component is initialized
    if (this.options.length > 0) {
      this.selectedOption = this.options[0];
    }
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.toggleDropdownFlag = false;
  }

  toggleDropdown() {
    this.toggleDropdownFlag = !this.toggleDropdownFlag;
  }
}
