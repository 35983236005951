import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {RiplayUmumRoutingModule} from "./riplay-umum-routing.module";
import {RiplayUmumComponent} from "./riplay-umum.component";
import {GlobalModule} from "../global/global.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RiplayUmumRoutingModule,
    GlobalModule,
  ],
  declarations: [
    RiplayUmumComponent
  ]
})
export class RiplayUmumModule {

}
