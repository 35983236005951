<div class="container">
  <div class="card pt-3 my-5">
    <div class="card-header no-border bg-white">
      <img src="assets/Logo-eSPAJK.jpg" alt="" class="img-fluid">
    </div>
    <div class="card-body p0">
      <form [formGroup]="loginForm" class="d-flex-column" #form="ngForm" (submit)="onSubmit()">
        <div class="form-group">
          <input type="text" id="username" class="form-control bottom-border px-0" placeholder="Email Perusahaan*" formControlName="email"
                 [ngClass]="{'border-danger' : (loginForm.controls['email'].dirty || form.submitted) && !loginForm.controls['email'].valid}">
        </div>
        <div class="form-group mt-3">
          <input type="password" id="password" class="form-control bottom-border px-0" placeholder="Password" formControlName="password"
                 [ngClass]="{'border-danger' : (loginForm.controls['password'].dirty || form.submitted) && !loginForm.controls['password'].valid}">
        </div>
        <div class="form-group text-end fw-bold">
          <small><a (click)="goToRoute('eform/forgot-password')">Lupa Kata Sandi?</a></small>
        </div>
        <div *ngIf="loginForm.hasError('invalidCredentials') && (loginForm.dirty || loginForm.touched)" class="alert alert-danger" role="alert" [innerHTML]="errorLogin"></div>

<!--        <div class="form-group mt-3">-->
<!--          <input type="text" class="form-control bottom-border px-0" />-->
<!--          <span style="position: absolute; top: 58%; right: 20px;">Month</span>-->
<!--        </div>-->

        <div class="text-center">
          <button type="submit" class="btn-login rounded-3 mb-1">MASUK</button>
          <p class="text-center" style="font-size: 10px; color: #cccccc;">V{{appVersion}}</p>
        </div>
      </form>

    </div>
    <!--    <div class="text-center p-0">-->
      <!--      <p>Belum punya akun? <a (click)="goToRoute('eform/rtc')">Video test</a></p>-->
    <!--    </div>-->
  </div>
</div>
