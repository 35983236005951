import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {GlobalModule} from "../global/global.module";
import {RincianDataKesehatanRoutingModule} from "./rincian-data-kesehatan-routing.module";
import {RincianDataKesehatanComponent} from "./rincian-data-kesehatan.component";



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RincianDataKesehatanRoutingModule,
        GlobalModule,
    ],
  declarations: [
    RincianDataKesehatanComponent
  ]
})
export class RincianDataKesehatanModule { }
