<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK-syariah.png" width="357">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div class="text-center">
    <img src="assets/Data-Berhasil-Disimpan-Icon.png">
  </div>
  <h2 class="color-light-blue"><b>Dokumen Diterima!</b></h2>
  <p class="color-light-blue">Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
  <p class="bg-warning-subtle d-inline-block rounded-3 p-1 text-warning">No Referensi: <b>{{referenceNumber}}</b></p>
  <p class="bg-light rounded border shadow color-light-blue p-1 my-3"> Allianz akan melakukan pemeriksaan terhadap
    dokumen yang sudah diunggah. Setelah pemeriksaan dokumen dilakukan dan dokumen dinyatakan benar serta telah sesuai
    dengan ketentuan Allianz, maka Sertifikat Asuransi akan dikirimkan melalui email oleh Allianz. <br><br> Untuk
    selanjutnya, Bank wajib menyampaikan kepada Calon Pihak Yang Diasuransikan.</p>
  <div class="d-flex flex-column justify-content-center flex-gap">
    <button class="btn btn-outline-warning bg-warning-subtle mt-3 py-3 flex-grow-1" type="button"
      (click)="goToDashboard()">Menuju Halaman Dashboard </button>
    <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="goToMenu()">Kembali ke Halaman Awal
    </button>
  </div>
</div>