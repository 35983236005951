import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardRoutingModule } from "./insured-summary-routing.module";
import { InsuredSummaryComponent } from "./insured-summary.component";
import { GlobalModule } from "../global/global.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DashboardRoutingModule,
    GlobalModule,
  ],
  declarations: [
    InsuredSummaryComponent
  ],
  providers: [
    DatePipe
  ]
})
export class InsuredSummaryModule {
}
