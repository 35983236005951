import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appNumberSeparator]'
})
export class NumberSeparatorDirective {
  constructor(private el: ElementRef) {}

  ngOnInit() {
    // Ensure initial formatting
    this.formatValue();
  }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    this.formatValue();
  }

  @HostListener('window:resize')
  onResize() {
    // Reformat the value when window is resized
    this.formatValue();
  }

  private formatValue(): void {
    const inputElement = this.el.nativeElement;
    let value = inputElement.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, '');

    // Add commas as thousand separators
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    inputElement.value = value;
  }

}
