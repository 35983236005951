import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PengisianDataPribadiRoutingModule} from "./pengisian-data-pribadi-routing.module";
import {PengisianDataPribadiComponent} from "./pengisian-data-pribadi.component";
import {GlobalModule} from "../global/global.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalModule,
    NgbModule,
    PengisianDataPribadiRoutingModule
  ],
  declarations: [
    PengisianDataPribadiComponent
  ]
})
export class PengisianDataPribadiModule {

}
