<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div *ngIf="pageState == 'PREVIEW'" class="color-light-blue">
    <div class="text-center">
      <img src="assets/Data-Berhasil-Disimpan-Icon.png">
    </div>
    <h2>Syarat Terpenuhi!</h2>
    <p>Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <p>Calon Tertanggung dapat melanjutkan pendaftaran asuransi sesuai proses yang berlaku</p>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <button class="btn btn-outline-warning bg-warning-subtle color-dark-orange mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">SIMPAN DAN KEMBALI KE HALAMAN AWAL</button>
      <button class="btn btn-continue py-3 flex-grow-1" type="button" (click)="continue()">LANJUT</button>
    </div>
  </div>

  <div *ngIf="pageState == 'REJECTED'" class="color-light-blue">
    <div class="text-center">
      <img src="assets/Ditolak-eSPAJK-Icon.png">
    </div>
    <h2>Ditolak!</h2>
    <p>Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <h4 class="text-center ">No Aplikasi : {{productInfo.referenceNumber}}</h4>
    <p>Calon Peserta tidak dapat melanjutkan pendaftaran asuransi karena data calon peserta tidak sesuai dengan ketentuan Underwriting Allianz.</p>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <a class="btn btn-back mt-3 py-3 flex-grow-1" type="button"  #btnAcceptance (click)="downloadAvailable('REJECTED')">UNDUH SURAT PENOLAKAN</a>

      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">KEMBALI KE HALAMAN AWAL</button>
    </div>
  </div>

  <div *ngIf="pageState == 'SUBMITTED'" class="color-light-blue">
    <div class="text-center">
      <img src="assets/Diterima-eSPAJK-Icon.png">
    </div>
    <h2>Disetujui</h2>
    <p>Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <h4 class="text-center ">No Aplikasi : {{productInfo.referenceNumber}}</h4>
    <h3 class="text-center">{{dataPribadi.namaLengkap}}</h3>
    <b>Pengajuan Asuransi sudah diterima dan disetujui oleh Allianz. Surat akseptasi (CN) akan dikirimkan terpisah ke email Sales dan Nasabah.</b>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <a class="btn btn-back mt-3 py-3 flex-grow-1" type="button"  #btnAcceptance (click)="downloadAvailable('ACCEPTED')">UNDUH SURAT AKSEPTASI (CN)</a>

      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">KEMBALI KE HALAMAN AWAL</button>
    </div>
  </div>

  <div *ngIf="pageState == 'ADDITIONALINFORMATION'" class="color-light-blue">
    <div class="text-center">
      <img src="assets/Diperlukan-Informasi-Tambahan-Icon.png">
    </div>
    <h2>Diperlukan Informasi Tambahan</h2>
    <p>Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <h4 class="text-center ">No Aplikasi : {{productInfo.referenceNumber}}</h4>
    <h3 class="text-center">{{dataPribadi.namaLengkap}}</h3>
    <b>Aplikasi dan data sudah diterima oleh pihak Allianz, namun masih dibutuhkan informasi tambahan untuk pengajuan asuransi ini. Untuk langkah selanjutnya, mohon hubungi PIC Area Allianz</b>
    <div class="d-flex flex-column justify-content-center flex-gap">

      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">KEMBALI KE HALAMAN AWAL</button>
    </div>
  </div>

  <div *ngIf="pageState == 'EXTRAPREMI'" class="color-light-blue">
    <div class="text-center">
      <img src="assets/Diterima-dengan-Tambahan-Premi-Icon.png">
    </div>
    <h2>Diterima dengan Tambahan Premi</h2>
    <p>Tanggal Formulir : {{this.todayDate | date:"dd - M - Y"}}</p>
    <h4 class="text-center ">No Aplikasi : {{productInfo.referenceNumber}}</h4>
    <h3 class="text-center">{{dataPribadi.namaLengkap}}</h3>
    <b>Pengajuan Asuransi dengan tambahan premi sebesar {{this.extraPremi | currency: 'IDR' : 'symbol' : '4.0-0'}} sudah diterima dan disetujui oleh Allianz. Surat akseptasi akan dikirimkan terpisah ke email Sales dan Nasabah.</b>
    <div class="d-flex flex-column justify-content-center flex-gap">
      <a class="btn btn-back mt-3 py-3 flex-grow-1" type="button"  #btnAcceptance (click)="downloadAvailable('ACCEPTED')">UNDUH SURAT AKSEPTASI (CN)</a>
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">KEMBALI KE HALAMAN AWAL</button>
    </div>
  </div>

</div>
