import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RiplayUmumModel} from "../riplay-umum/shared/riplay-umum.model";
import {DraftService} from "../service/save-draft/save-draft.service";
import {NasabahModel} from "../service/model/dashboard.model";
import { SessionStorageEnum } from '../global/enum/session-storage.enum';
import { PartnerIdEnum } from '../global/enum/partner.enum';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-kontak-data-pribadi',
  templateUrl: './kontak-data-pribadi.component.html',
  styleUrls: ['./kontak-data-pribadi.component.css']
})
export class KontakDataPribadiComponent {

  kontakPribadiForm: FormGroup;
  productInfo: RiplayUmumModel = {};
  isItShariaProduct: boolean
  selectedItem: any;
  isFromDashboard: boolean = false;
  sameAddress : boolean = false;
  protected readonly environment = environment;

  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private draftService: DraftService
  ) {
    this.isItShariaProduct = sessionStorage.getItem(SessionStorageEnum.PARTNER_ID) === JSON.stringify(PartnerIdEnum.BSI_ID);
    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }
    this.kontakPribadiForm = formBuilder.group({
      nomorHPPribadi: ['', [Validators.pattern(/^0\d{0,14}$/)]],
      emailPribadi: ['', [Validators.email]],
      alamatPribadi: ['', Validators.required],
      kodePosPribadi: ['', Validators.required],
      provinsiPribadi: ['', Validators.required],
      kotaPribadi: ['', Validators.required],
      alamatDomisili: [''],
      kodePosDomisili: [''],
      provinsiDomisili: [''],
      kotaDomisili: [''],
      alamatSama: [false]
    })
    let jsonProduct = localStorage.getItem('selected-product')
    if (jsonProduct != null) {
      this.productInfo = JSON.parse(jsonProduct);
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.productInfo.referenceNumber = this.nasabah.nasabahUw.referenceNo ?? this.nasabah.nasabahNewCovering.referenceNo
      this.productInfo.partnerId = this.nasabah.nasabahUw.partnerId ?? this.nasabah.nasabahNewCovering.partnerId
      this.productInfo.productcode = this.nasabah.nasabahUw.productCode ?? this.nasabah.nasabahNewCovering.productCode
    }

    this.draftService.getDraft({
      userId: 1,
      pageId: "draftCover2",
      partnerId: this.productInfo.partnerId,
      productCode: this.productInfo.productcode,
      referenceNo: this.productInfo.referenceNumber
    }).subscribe(res => {
      if (res.statusId == 1) {
        this.kontakPribadiForm.patchValue({
          nomorHPPribadi: res.mobilePhoneNo,
          emailPribadi: res.emailAddress,
          alamatPribadi: res.address,
          kodePosPribadi: res.zipcode,
          provinsiPribadi: res.province,
          kotaPribadi: res.city,
          alamatDomisili: res.addressDomicile,
          kodePosDomisili: res.zipcodeDomicile,
          provinsiDomisili: res.provinceDomicile,
          kotaDomisili: res.cityDomicile,
        })
      }
    })
  }

  copyInputAddress(from: string, to: string) {
    if (this.kontakPribadiForm.controls['alamatSama'].value) {
      this.kontakPribadiForm.controls[to].patchValue(
        this.kontakPribadiForm.controls[from].value
      )
    }
  }

  toggleSameAddress(input: any) {
    this.sameAddress = input.target.checked
    if (this.sameAddress) {
      this.kontakPribadiForm.patchValue({
        alamatDomisili: this.kontakPribadiForm.controls['alamatPribadi'].value,
        kodePosDomisili: this.kontakPribadiForm.controls['kodePosPribadi'].value,
        provinsiDomisili: this.kontakPribadiForm.controls['provinsiPribadi'].value,
        kotaDomisili: this.kontakPribadiForm.controls['kotaPribadi'].value,
      })
    } else {
      this.kontakPribadiForm.patchValue({
        alamatDomisili: '',
        kodePosDomisili: '',
        provinsiDomisili: '',
        kotaDomisili: '',
      })
    }
  }

  saveDraft() {
    //re trigger method if user input checked the box before inputting address information
    this.toggleSameAddress({
      target: {
        checked: this.sameAddress
      }
    })

    localStorage.setItem("kontak-pribadi", JSON.stringify(this.kontakPribadiForm.value))
    this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
      this.router.navigate(["eform/draft-saved"])
    })
  }

  buildSaveRequest() : any {
    return {
      userId: 1,
      pageId: "draftCover2",
      partnerId: this.isFromDashboard ? this.nasabah.nasabahUw.partnerId! : this.productInfo.partnerId,
      productCode: this.isFromDashboard ? this.nasabah.nasabahUw.productCode! : this.productInfo.productcode,
      referenceNo: this.isFromDashboard ? this.nasabah.nasabahUw.referenceNo! : this.productInfo.referenceNumber,
      mobilePhoneNo: this.kontakPribadiForm.controls['nomorHPPribadi'].value,
      emailAddress: this.kontakPribadiForm.controls['emailPribadi'].value,
      address: this.kontakPribadiForm.controls['alamatPribadi'].value,
      zipcode: this.kontakPribadiForm.controls['kodePosPribadi'].value ? this.kontakPribadiForm.controls['kodePosPribadi'].value.toString() : "",
      city: this.kontakPribadiForm.controls['kotaPribadi'].value,
      province: this.kontakPribadiForm.controls['provinsiPribadi'].value,
      addressDomicile: this.kontakPribadiForm.controls['alamatDomisili'].value,
      zipcodeDomicile: this.kontakPribadiForm.controls['kodePosDomisili'].value ? this.kontakPribadiForm.controls['kodePosDomisili'].value.toString() : "",
      cityDomicile: this.kontakPribadiForm.controls['kotaDomisili'].value,
      provinceDomicile: this.kontakPribadiForm.controls['provinsiDomisili'].value
    }
  }

  continue() {
    if (this.kontakPribadiForm.valid) {
      this.draftService.saveDraft(this.buildSaveRequest()).subscribe(() => {
        localStorage.setItem("kontak-pribadi", JSON.stringify(this.kontakPribadiForm.value))
        this.router.navigate(["/eform/kontak-data-kantor"]);
      })
    }
  }

  back() {
    if (this.productInfo.partnerId == environment.partnerIdBSI) {
      this.router.navigate(['eform/pengisian-data-pribadi-bsi'])
    } else {
      this.router.navigate(['eform/pengisian-data-pribadi'])
    }
  }

  onInput(event: Event, controlName: string) {
    const input = event.target as HTMLInputElement;
    const filteredValue = input.value.replace(/[^A-Za-z\s]/g, '');
    input.value = filteredValue;
    this.kontakPribadiForm.controls[controlName].setValue(filteredValue, { emitEvent: false });
  }
}
