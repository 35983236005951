import {ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {VideoRecordingService} from "../service/video-recording.service";
import {DomSanitizer} from "@angular/platform-browser";
import {NewCoveringService} from "../service/new-covering/new-covering.service";
import {NasabahModel} from "../service/model/dashboard.model";

@Component({
  selector: 'app-foto-nasabah-sales',
  templateUrl: './foto-nasabah-sales.component.html',
  styleUrls: ['./foto-nasabah-sales.component.css']
})
export class FotoNasabahSalesComponent {
  productInfo: any;
  dataPribadi: any;

  gambarUrl: any = null;
  isCameraActive: boolean = false;
  camera: string = "user";
  canvasWidth: number = 0;
  canvasHeight: number = 0;

  @ViewChild("video") videoCamera!: ElementRef;
  @ViewChild("canvas") canvas!: ElementRef;
  @ViewChild("dokumenNasabahDenganSales") documentInsuredWithStaff!: ElementRef;
  @ViewChild("optionCamera") optionCamera!: ElementRef;

  isFromDashboard: boolean = false;
  nasabah: NasabahModel = {
    nasabahUw: {},
    nasabahNewCovering: {},
    nasabahCalculator: {}
  };

  constructor(
    private router: Router,
    private videoRecordingService: VideoRecordingService,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private newCoveringService: NewCoveringService
  ) {

    let background = document.getElementById("background");
    if (background != null) {
      background.className = "allianz-background-faded"
    }

    let jsonProduct = localStorage.getItem('selected-product')
    let jsonDataPribadi = localStorage.getItem('data-pribadi')
    if (jsonProduct != null && jsonDataPribadi) {
      this.productInfo = JSON.parse(jsonProduct);
      this.dataPribadi = JSON.parse(jsonDataPribadi);
    }

    this.isFromDashboard = JSON.parse(localStorage.getItem("from-dashboard")!)
    if (this.isFromDashboard) {
      this.nasabah = JSON.parse(localStorage.getItem("nasabah")!);
      this.productInfo = {
        referenceNumber: this.nasabah.nasabahNewCovering.referenceNo
      }
    }
  }

  async mulaiCamera(cameraFacing: string) {
    this.documentInsuredWithStaff.nativeElement.style.height = "375px"
    this.gambarUrl = ""
    this.isCameraActive = true;

    let constraint: any = {
      video: {
        facingMode: {
          ideal: cameraFacing
        }
      }
    }

    this.videoCamera.nativeElement.srcObject = await navigator.mediaDevices.getUserMedia(constraint);
    this.videoCamera.nativeElement.addEventListener('loadedmetadata', () => {
      this.canvasWidth = this.videoCamera.nativeElement.videoWidth
      this.canvasHeight = this.videoCamera.nativeElement.videoHeight
    });
  }

  ambilGambar(event: any) {
    event.stopPropagation();
    this.isCameraActive = false;
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoCamera.nativeElement, 0, 0, this.canvasWidth, this.canvasHeight);
    this.gambarUrl = this.canvas.nativeElement.toDataURL('image/jpeg');
    this.videoCamera.nativeElement.srcObject.stop();
    this.documentInsuredWithStaff.nativeElement.style.height = "194px"
    // this.dataPribadiForm.controls['fileInput'].patchValue(this.gambarUrl)
  }

  resetFotoKtp() {
    this.gambarUrl = ""
    this.isCameraActive = false;
    if (this.videoCamera.nativeElement.srcObject) this.videoCamera.nativeElement.srcObject.stop();
  }

  switchCamera() {
    this.videoCamera.nativeElement.srcObject.stop()
    this.camera = this.camera == "user" ? "environment" : "user";
    this.mulaiCamera(this.camera)
  }

  continue() {
    if (this.gambarUrl !== "") {
      let blob = new Blob()
      // this.isLoading = true;
      this.newCoveringService.uploadDocument(this.productInfo.referenceNumber, this.newCoveringService.dataURItoBlob(this.gambarUrl), "INSUREDWITHSTAFFPHOTO").subscribe(res => {
        this.router.navigateByUrl('eform/spajk-riplay')
      })
    }
  }

  back() {
    this.router.navigate(['eform/summary'])
  }
}
