import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {KontakDataKantorRoutingModule} from "./kontak-data-kantor-routing.module";
import {GlobalModule} from "../global/global.module";
import {KontakDataKantorComponent} from "./kontak-data-kantor.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalModule,
    NgbModule,
    KontakDataKantorRoutingModule
  ],
  declarations: [
    KontakDataKantorComponent
  ]
})
export class KontakDataKantorModule {

}
