<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-center mt-2">
    <img src="assets/Logo-eSPAJK.jpg" width="200" height="auto">
  </div>
  <div class="container form-header bg-white mt-5 py-3">
    <h3 class="m-0 p-0 text-center">Change Password</h3>
    <h6 class="m-0 p-0 text-center">(*) Semua informasi data yang harus diisi</h6>

  </div>
</div>
<div class="container form-container pb-3">
  <form [formGroup]="changePasswordForm" #form="ngForm" (ngSubmit)="onSubmit()">


    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (changePasswordForm.controls['emailStaff'].dirty || form.submitted) && !changePasswordForm.controls['emailStaff'].valid}">
        <p class="title-input m-0">Email Perusahaan *</p>
        <input class="bottom-border" type="text" formControlName="emailStaff"
               [ngClass]="{'text-danger' : (changePasswordForm.controls['emailStaff'].dirty || form.submitted) && !changePasswordForm.controls['emailStaff'].valid}"/>
      </div>
    </div>

    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (changePasswordForm.controls['kataSandi'].dirty || form.submitted) && !changePasswordForm.controls['kataSandi'].valid}">
        <p class="title-input m-0">Kata Sandi *</p>
        <input class="bottom-border" type="password" formControlName="kataSandi"
               [ngClass]="{'text-danger' : (changePasswordForm.controls['kataSandi'].dirty || form.submitted) && !changePasswordForm.controls['kataSandi'].valid}"/>
      </div>
    </div>

    <div class="bg-white p-2 form-content">
      <div class="d-flex flex-column mb-2"
           [ngClass]="{'text-danger' : (changePasswordForm.controls['confirmKataSandi'].dirty || form.submitted) && !changePasswordForm.controls['confirmKataSandi'].valid}">
        <p class="title-input m-0">Konfirmasi Kata Sandi *</p>
        <input class="bottom-border" type="password" formControlName="confirmKataSandi"
               [ngClass]="{'text-danger' : (changePasswordForm.controls['confirmKataSandi'].dirty || form.submitted) && !changePasswordForm.controls['confirmKataSandi'].valid}"/>
      </div>
    </div>
    <div class="d-flex flex-column">
      <button class="btn-continue mt-3 py-3 rounded-3" type="submit">UBAH KATA SANDI</button>
    </div>
  </form>

</div>
