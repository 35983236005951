<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
    <img src="assets/Logo-eSPAJK-syariah.png" width="250" height="auto">
  </div>
  <div class="riplay-title">
    <h2 class="text-center mt-5 color-blue"><b>Pengunggahan Dokumen</b></h2>
    <h6 class="text-center color-blue">No Referensi : {{productInfo.referenceNumber}}</h6>
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">

  </div>
</div>
<div class="container form-container pb-2">
  <p (click)="back()" class="cursor-pointer"><i class="bi bi-arrow-left"></i> Kembali</p>
  <form [formGroup]="uploadDokumenForm" (ngSubmit)="continue()" #form="ngForm">

    <div class="d-flex justify-content-between align-content-center">
      <div>
        <p
          [ngClass]="{'text-danger' : (uploadDokumenForm.controls['ktp'].dirty || form.submitted) && !uploadDokumenForm.controls['ktp'].valid}">
          Foto Dokumen Identitas Diri *
        </p>
      </div>
      <div>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="resetFotoKtp()"><i class="bi-repeat"></i>
          Ulang
        </button>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="switchCamera()"><i class="bi-repeat"></i>
          Ganti Kamera
        </button>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div #dokumenKtp class="rounded-3 py-5 mb-3" (click)="mulaiCamera(this.camera)"
           [ngStyle]="{ 'background-image': 'url(' + gambarUrl + ')',
                            'background-size': 'contain',
                            'background-color': '#eaeaea',
                            'background-repeat': 'no-repeat',
                            'background-position': 'center center'}">

        <div class="d-flex flex-column flex-gap justify-content-center align-items-center">
          <video [hidden]="!isCameraActive" #video id="video" width="320" height="240" playsinline autoplay></video>
          <button [hidden]="!isCameraActive" class="btn btn-continue mx-1" type="button" id="click-photo"
                  (click)="ambilGambar($event)">Foto
          </button>
          <canvas [hidden]="true" #canvas id="canvas" [width]="canvasWidth" [height]="canvasHeight"></canvas>
        </div>

        <div *ngIf="!isCameraActive && !gambarUrl"
             class="d-flex flex-column flex-gap justify-content-center align-items-center">
          <img src="assets/Camera.png" height="48" width="48">
          <p class="text-center">Foto Dokumen Identitas Diri Anda</p>
        </div>
      </div>

      <div>
        <b class="mb-1">Ketentuan Foto</b>
        <ol>
          <li>Foto harus jelas dan tidak blur</li>
          <li>Menggunakan Foto asli dan bukan fotokopi</li>
          <li>Pastikan hasil foto telah sesuai dengan contoh</li>
        </ol>
      </div>
      <p class="mb-0">Contoh Foto:</p>
      <div class="contoh-foto d-flex flex-gap">
        <div>
          <img src="assets/Bitmap.png">
          <p class="bi-check-circle-fill"> Benar</p>
        </div>
        <div>
          <img src="assets/Bitmap-1.png">
          <p class="bi-x-circle-fill"> Salah</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between align-content-center">
      <div>
        <p
          [ngClass]="{'text-danger' : isInputSpajkRiplayDirty && (!spajkDokumen || !riplayDokumen)}">
          Unggah SPAJPK Syariah dan RIPLAY Personal *
        </p>
      </div>
      <div>
        <button type="button" class="btn-reset bg-dark-blue py-0" (click)="resetSpajkRiplay()"><i class="bi-repeat"></i>
          Hapus
        </button>
      </div>
    </div>
    <div class="d-flex flex-column">
      <label for="spajkUpload">
        <div #dokumenSpajk class="rounded-3 py-5 mb-3 bg-gray">
          <input [hidden]="true" type="file" id="spajkUpload" accept="application/pdf" (change)="inputSpajkDokumen($event)"/>

          <div class="d-flex flex-column flex-gap justify-content-center align-items-center">
            <p class="text-center">{{filenameSpajkDokumen}}</p>
          </div>
        </div>
      </label>

      <label for="riplayUpload">
        <div #dokumenRiplay class="rounded-3 py-5 mb-3 bg-gray">

          <input [hidden]="true" type="file" id="riplayUpload" accept="application/pdf" (change)="inputRiplayDokumen($event)"/>

          <div class="d-flex flex-column flex-gap justify-content-center align-items-center">
            <p class="text-center">{{filenameRiplayDokumen}}</p>
          </div>
        </div>
      </label>
      <div>
        <b class="mb-1">Ketentuan SPAJPK Syariah dan RIPLAY Personal</b>
        <ol>
          <li>SPAJPK Syariah dan RIPLAY Personal harus ditandatangani oleh Calon Pihak Yang Diasuransikan dan karyawan Bank yang mereferensikan Produk Asuransi ini.</li>
          <li>Tanda tangan Calon Pihak Yang Diasuransikan harus sesuai dengan tanda tangan pada Kartu Tanda Penduduk.</li>
          <li>Dokumen yang diunggah harus jelas dan dapat terbaca.</li>
        </ol>
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn-continue rounded-3 mt-3 py-3 flex-grow-1" type="submit" [disabled]="this.isLoading">
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lanjut
      </button>
<!--      <button (click)="convertToBlob(base64Spajk)">TEST</button>-->
    </div>
  </form>
</div>
<!--<div #pdfCanvas></div>-->
