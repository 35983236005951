<div class="container flex-column" xmlns="http://www.w3.org/1999/html">
  <div class="d-flex justify-content-end mt-2">
  </div>
  <div class="riplay-title text-center">
    <img src="assets/Logo-eSPAJK.jpg">
  </div>
  <div class="container mt-5 pt-4 form-header bg-white">
  </div>
</div>
<div class="container form-container pb-3 bg-white text-center">
  <div class="color-light-blue">
    <div class="text-center">
      <img src="assets/Icon-Ajukan-Perubahan-Non-Finansial.png" width="400">
    </div>
    <h2 class="my-4"><b>Ellise is Under Construction!</b></h2>
    <p>Rekan - rekan dari pihak bank, mohon maaf sebelumnya atas gangguan yang dialami. Saat ini ELLISE sedang mengalami perawatan sistem secara berkala.</p>
    <p>Mohon untuk mencoba mengakses layanan ELLISE di lain waktu, atau kirimkan pesan kepada tim Allianz untuk informasi lebih lanjut.</p>
    <div class="d-flex flex-row justify-content-center flex-gap">
      <button class="btn btn-continue mt-3 py-3 flex-grow-1" type="button" (click)="backHomePage()">KEMBALI KE HALAMAN AWAL</button>
    </div>
  </div>

</div>
